import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Grid, Typography, ClickAwayListener } from '@material-ui/core';
import { TextPoint, IconButton, Input, Loading, CancelModal } from '@wdynamo/common';
import { ReactComponent as goBackIcon } from '../../../../assets/img/icons_dynamo/icon_back.svg';
import { ReactComponent as addIcon } from '../../../../assets/img/icons_dynamo/icon_add.svg';
import { ReactComponent as filterIcon } from '../../../../assets/img/icons_dynamo/icon_filter.svg';
import { ReactComponent as searchIcon } from '../../../../assets/img/icons_dynamo/icon_search.svg';
import { ReactComponent as editIcon } from '../../../../assets/img/icons_dynamo/icon_edit_modal.svg';
import { ReactComponent as trashIcon } from '../../../../assets/img/icons_dynamo/icon_trash_modal.svg';
import CancelIllustration from '../../../../assets/img/illustrations/CancelIllustration.jpg';
// eslint-disable-next-line no-unused-vars
import { History } from 'history';
import { FilterPopper } from './components/FilterPopper';
import { Analytics } from '@wdynamo/common/lib/services';
// eslint-disable-next-line no-unused-vars
import { IGroupsProps } from './models';
import { uris } from '../../../../siteMap';
/* eslint-disable no-unused-vars */

interface IGroupsListProps {
  getParentsFetch(): void;
  getGroupsFetch(parent?: string, users?: string[]): void;
  deleteGroupFetch(id: number): void;
  groupsStore: IGroupsProps;
  className?: string;
  history: History;
}

const CleanGroupsListComponent: React.FC<IGroupsListProps> = (props: IGroupsListProps) => {
  const analytics = Analytics();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSearchBarVisible, setIsSearchBarVisible] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>('');
  const { history, groupsStore, getGroupsFetch, deleteGroupFetch, getParentsFetch } = props;

  useEffect(() => {
    if (keycloak?.authenticated)
      setTimeout(() => {
        keycloak?.loadUserInfo().then(() => {
          getGroupsFetch();
          getParentsFetch();
        });
      });
  }, [keycloak?.authenticated]);

  useEffect(() => {
    analytics.pageview('display-groups');
  }, []);

  const togglePopper = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const toggleSearchBar = () => {
    setIsSearchBarVisible(!isSearchBarVisible);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchText.trim() !== '') {
      //TODO: Falta que este definido el param en getGroupsFetch
    }
  };

  const toggleCancelModal = () => {
    setOpenModal(!openModal);
  };
  const handleRemove = (id: number) => {
    setSelectedGroup(id);
    toggleCancelModal();
  };
  const handleRemoveModalConfirm = () => {
    toggleCancelModal();
    deleteGroupFetch(selectedGroup);
    getGroupsFetch();
  };

  const renderModal = () => (
    <>
      <CancelModal
        open={openModal}
        handleClose={toggleCancelModal}
        onClickConfirm={toggleCancelModal}
        onClickCancel={handleRemoveModalConfirm}
        messages={{
          TITLE: t('GROUPS.TABLE.REMOVE_MODAL.TITLE'),
          CANCEL_BUTTON: t('GROUPS.TABLE.REMOVE_MODAL.CANCEL'),
          CONTINUE_BUTTON: t('GROUPS.TABLE.REMOVE_MODAL.CONTINUE')
        }}
        img={CancelIllustration}
        description={t('GROUPS.TABLE.REMOVE_MODAL.DESCRIPTION')}
      />
    </>
  );

  const { groups, loading } = groupsStore;

  const groupList = () => {
    if (!groups.length && loading) return <Loading />;
    else if (!groups.length) return <></>;
    else
      return (
        <Grid item xs={12}>
          <Grid container className='grid'>
            <Grid item xs={12} className='header'>
              <Grid container>
                <Grid item xs={3} md={3}>
                  {t('GROUPS.TABLE.HEADERS.NAME')}
                </Grid>
                <Grid item xs={3} md={3}>
                  {t('GROUPS.TABLE.HEADERS.PARENT')}
                </Grid>
                <Grid item xs={3} md={3}>
                  {t('GROUPS.TABLE.HEADERS.DESCRIPTION')}
                </Grid>
                <Grid item xs={3} md={3} className='actions'>
                  {t('GROUPS.TABLE.HEADERS.ACTIONS')}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className='content'>
              {groups.map((item, i) => {
                return (
                  <Grid container key={i} className={'row group'}>
                    <Grid item xs={3}>
                      {item.name}
                    </Grid>
                    <Grid item xs={3}>
                      {item.parent_name}
                    </Grid>
                    <Grid item xs={3}>
                      {item.description}
                    </Grid>
                    <Grid item container xs={3} direction='row' justify='flex-end'>
                      <IconButton
                        Icon={editIcon}
                        className='filter-icons'
                        onClick={() =>
                          history.push(uris.groupInfo.uri, {
                            group: { ...item }
                          })
                        }
                      />
                      <IconButton
                        Icon={trashIcon}
                        className='filter-icons remove-icon'
                        onClick={() => {
                          handleRemove(item.id);
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      );
  };

  return (
    <div className={props.className}>
      {renderModal()}
      <Grid container alignItems='center'>
        <Grid item container xs={12} justify='space-between'>
          <h1>
            {t('GROUPS.TITLE')}
            <TextPoint />
          </h1>
          <IconButton Icon={addIcon} className='add-button' onClick={() => history.push(uris.groupInfo.uri)} />
        </Grid>
        <Grid item container xs={12} justify='space-between' direction='row'>
          <Grid item container xs={3} direction='row' alignItems='center' onClick={() => history.push(uris.users.uri)}>
            <IconButton Icon={goBackIcon} />
            <Typography variant='h6'>{t('COMMONS.BUTTONS.GOBACK')}</Typography>
          </Grid>
          <ClickAwayListener mouseEvent='onMouseDown' touchEvent='onTouchStart' onClickAway={handleClickAway}>
            <Grid item container xs={3} direction='row' justify='flex-end'>
              <div onClick={togglePopper}>
                <IconButton Icon={filterIcon} className='filter-icons'></IconButton>
              </div>

              <FilterPopper
                anchorEl={anchorEl}
                toggleOpen={togglePopper}
                onClick={(parent?: string, users?: string[]) => getGroupsFetch(parent, users)}
                onClickReset={() => getGroupsFetch()}
                parentList={groupsStore.parents.groups}
              />

              {isSearchBarVisible && (
                <Input
                  label={t('GROUPS.INPUT.SEARCH')}
                  onChange={(value: string): void => setSearchText(value)}
                  onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>): void => handleKeyPress(event)}
                  testId='Search'
                  fullWidth={true}
                ></Input>
              )}
              <IconButton Icon={searchIcon} className='filter-icons' onClick={toggleSearchBar} />
            </Grid>
          </ClickAwayListener>
        </Grid>
        {groupList()}
      </Grid>
    </div>
  );
};

export const GroupsListComponent = styled(({ ...props }) => <CleanGroupsListComponent {...props} />)`
  .actions {
    text-align: right;
  }
  .add-button {
    height: min-content;
    margin-top: 0.8em;
  }
  .filter-icons {
    height: min-content;
  }
  .grid {
    .header {
      border: none;
      font-weight: bold;
      text-align: left;
      padding: 6px 0;
      div {
        padding: 5px 0;
      }
    }
    .content {
      .disabled {
        text-decoration: line-through;
      }
      .row {
        .actions {
          text-align: right;
        }
        padding: 10px 0;
        border-bottom: 1px solid var(--color-grey-border);
      }
      .checkbox {
        color: var(--color-orange);
      }
    }
  }
`;
