import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line no-unused-vars
import { IFormSteps, IGetInspectionFormParams, ISendInspectionFormParams } from './models';
import { FormHeaderComponent } from './components/FormHeader';
import { FormBodyComponent } from './components/FormBody';
import { Loading, StyledButton } from '@wdynamo/common';
import { ReactComponent as AlertIcon } from '../../assets/img/icons_dynamo/icon_alert.svg';
import carFrontSide from '../../assets/img/icons_dynamo/icon_car_front_side.svg';
import carBackSide from '../../assets/img/icons_dynamo/icon_car_back_side.svg';
import carLeftSide from '../../assets/img/icons_dynamo/icon_car_left_side.svg';
import carRightSide from '../../assets/img/icons_dynamo/icon_car_right_side.svg';
import spareWheel from '../../assets/img/icons_dynamo/icon_spare_wheel.svg';
import driversLicense from '../../assets/img/icons_dynamo/icon_drivers_license.svg';
import genericImage from '../../assets/img/icons_dynamo/icon_generic_image.svg';

import { ColoredSymbolComponent, ModalComponent } from '../../components';

interface InspectionComponentProps {
  className: string;
  location: Location;
  inspectionStore: any;
  getSettingsFetch(partnerId: string): void;
  getInspectionFormFetch(params: IGetInspectionFormParams): void;
  sendInspectionFormFetch(params: ISendInspectionFormParams): void;
}

const CleanInspectionComponent: React.FC<InspectionComponentProps> = (props: InspectionComponentProps) => {
  const { className, location, inspectionStore, getSettingsFetch, getInspectionFormFetch, sendInspectionFormFetch } =
    props;
  const { t } = useTranslation();
  const { search } = location;
  const queryParams = JSON.parse(
    '{"' + decodeURI(search.slice(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
  );
  const { partner_id, request, branch, userName, idForm } = queryParams;
  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const formSteps: IFormSteps = {
    initial: 0,
    fail: 1,
    success: 2
  };
  const [currentStep, setCurrentStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalDescription, setModalDescription] = useState('');

  useEffect(() => {
    getSettingsFetch(partner_id);
    getInspectionFormFetch({ request, branch, idForm });
  }, []);

  useEffect(() => {
    if (inspectionStore.getForm.data.length && inspectionStore.partnerConfigInfo.color) {
      handleSetInitialState();
      setLoader(false);
    }
  }, [inspectionStore.getForm.data, inspectionStore.partnerConfigInfo.color]);

  useEffect(() => {
    if (!inspectionStore.sendForm.loading && inspectionStore.getForm.data.length) {
      if (inspectionStore.sendForm.error || !inspectionStore.sendForm.data.ok) setCurrentStep(formSteps.fail);
      else setCurrentStep(formSteps.success);
      setLoader(false);
    }
  }, [inspectionStore.sendForm.loading]);

  const setImage = (id: string) => {
    switch (id) {
      case '60':
        return carFrontSide;
      case '61':
        return carBackSide;
      case '62':
        return carLeftSide;
      case '63':
        return carRightSide;
      case '64':
        return spareWheel;
      case '65':
        return driversLicense;
      default:
        return genericImage;
    }
  };

  const handleSetInitialState = () => {
    const initialState: any = {};
    const documentsToUpload = inspectionStore.getForm.data[0].document_available_types;
    documentsToUpload.forEach((documentType: any) => {
      if (documentType.quantity) {
        initialState[documentType.documentTypeDescription] = {
          documentIdType: documentType.documenetIdType,
          fileName: documentType.documentTypeDescription,
          stringBase64: setImage(documentType.documenetIdType)
        };
      }
    });
    setFormData(initialState);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleValidateSteps = () => {
    let validForm = true;
    const formDataState: any = Object.keys(formData);
    formDataState.forEach((image: string) => {
      if (formData[image].stringBase64 === carFrontSide) validForm = false;
    });
    if (!validForm) {
      setModalDescription(t('INSPECTION.MODAL.MISSING_IMAGE'));
      setOpenModal(!validForm);
    }
    return validForm;
  };

  const handleSendForm = () => {
    const documents = Object.keys(formData).map((document) => ({
      sequence: 0,
      file_name: formData[document].fileName,
      document_type: formData[document].documentIdType,
      file_base64: formData[document].stringBase64
    }));
    sendInspectionFormFetch({
      user: userName,
      request: idForm,
      inspections: [
        {
          number: inspectionStore.getForm.data[0].inspection_number,
          state: '',
          damage_card: '',
          questions: [],
          documents
        }
      ]
    });
    setLoader(true);
  };

  const handleNextStep = () => {
    if (handleValidateSteps()) handleSendForm();
  };

  const handlePrevStep = () => setCurrentStep((currentStep) => currentStep - 1);

  const handleHideNextButton = () => {
    if (Object.keys(formData).length) return true;
    else return false;
  };

  const renderNavButtons = () => {
    if (currentStep !== formSteps.success)
      return (
        <Grid container justifyContent='space-between' style={{ margin: '2rem 0' }}>
          <Grid item>
            {(currentStep > formSteps.initial || currentStep === formSteps.fail) && (
              <StyledButton onClick={handlePrevStep} className='secondary'>
                {t('COMMONS.BUTTONS.GOBACK')}
              </StyledButton>
            )}
          </Grid>
          <Grid item>
            {currentStep !== formSteps.fail && handleHideNextButton() && (
              <StyledButton onClick={handleNextStep} className='default next-button'>
                {t('COMMONS.BUTTONS.CONTINUE')}
              </StyledButton>
            )}
          </Grid>
        </Grid>
      );
  };

  const renderModal = () => (
    <ModalComponent
      open={openModal}
      icon={<AlertIcon width={100} height={80} />}
      iconColor={'var(--color-orange)'}
      title={
        <>
          {t('COMMONS.MODALS.CANCEL.SORRY')}
          <ColoredSymbolComponent value={'...'} color={'var(--color-orange)'} />
        </>
      }
      description={modalDescription}
      buttonColor={'var(--color-orange)'}
      buttonText={t('COMMONS.BUTTONS.CONTINUE')}
      onClickButton={handleCloseModal}
    />
  );

  return (
    <Container className={className}>
      {loader ? (
        <Grid container justifyContent='center' alignContent='center' style={{ height: '91vh' }}>
          <Loading />
        </Grid>
      ) : (
        <>
          <FormHeaderComponent inspectionStore={inspectionStore} request={request} />
          <FormBodyComponent
            formData={formData}
            setFormData={setFormData}
            inspectionStore={inspectionStore}
            formSteps={formSteps}
            currentStep={currentStep}
            setOpenModal={setOpenModal}
            setModalDescription={setModalDescription}
          />
          {renderNavButtons()}
        </>
      )}
      {renderModal()}
    </Container>
  );
};

export const InspectionComponent = styled(({ ...props }) => <CleanInspectionComponent {...props} />)`
  margin: 2rem 0;
`;
