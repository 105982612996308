import axios from 'axios';
// import { IContact } from './models';

const Api = () => {
  const getContactDetailsFetch = (contactId: number): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers/${contactId}/communications`);
  //axios.get(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/quotation/customer/${contactId}`);
  // axios.get('http://localhost:3005/quotebycontacts');
  const patchChangeStatus = (params: any): Promise<unknown> =>
    /* axios.patch(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers/communications/${params.id}`, {
      state: params.state
    });*/

    axios.post(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers/${params.id}/reject/${params.idProduct}`);

  return { getContactDetailsFetch, patchChangeStatus };
};

export default Api;
