import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { Box, Typography } from '@material-ui/core';

interface ISpinnerProps {
  className?: string;
}

const CleanSpinner: React.FC<ISpinnerProps> = (props: ISpinnerProps) => {
  const { className } = props;

  return (
    <Box className={className}>
      <CircularProgress size={20} />
      <Typography className='ml'>Descargando...</Typography>
    </Box>
  );
};

export const Spinner = styled(({ ...props }) => <CleanSpinner {...props} />)`
  display: flex;
  flex-direction: row;
  align-items: center;
  .ml {
    margin-left: 6px;
  }
`;
