import React from 'react';
import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import { StyledButton, IconButton, Input } from '@wdynamo/common';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line no-unused-vars
import { ICustomField, ITypeField } from '../../models';
import { ReactComponent as iconTrash } from '../../../../../../assets/img/icons_dynamo/icon_trash_service.svg';

interface ICustomFieldProps {
  className?: string;
  item: ICustomField;
  title: string;
  index: number;
  service: any;
  onChange(service: any): void;
}

const CleanCustomField: React.FC<ICustomFieldProps> = (props: ICustomFieldProps) => {
  const { item, title, onChange, index, service } = props;
  const { t } = useTranslation();

  const MOCK_TYPES: ITypeField[] = [
    { name: 'Number', id: '2' },
    { name: 'String', id: '1' },
    { name: 'Options', id: '3', has_items: true }
  ];

  const handleAddOption = () => {
    const prevService = { ...service };
    const initList = [''];
    Array.isArray(prevService.customFields.value[index].items)
      ? prevService.customFields.value[index].items.push('')
      : (prevService.customFields.value[index].items = initList);
    onChange(prevService);
  };

  const handleOnChangeInput = (value: string, field: string) => {
    const prevService = { ...service };
    const currentField = prevService.customFields.value[index];
    currentField[field] = value;
    onChange(prevService);
  };
  const handleRemoveField = () => {
    const prevService = { ...service };
    const prevCustomFields = prevService.customFields;
    prevCustomFields.value.splice(index, 1);
    onChange(prevService);
  };
  const handleRemoveOption = (optionIndex: number) => {
    const prevService = { ...service };
    const currentField = prevService.customFields.value[index];
    currentField.items.splice(optionIndex, 1);
    onChange(prevService);
  };
  const handleOnChangeOption = (value: string, optionIndex: number) => {
    const prevService = { ...service };
    const currentField = prevService.customFields.value[index];
    currentField.items[optionIndex] = value;
    onChange(prevService);
  };

  const hasItems = (): boolean => {
    const id_type = item.id_type;
    const objType = MOCK_TYPES.find((item) => item.id === id_type);
    return objType?.has_items ? true : false;
  };

  return (
    <Box className={props.className} py={2}>
      <Typography variant='h6'>{title}</Typography>
      <Grid className='row' direction='row' container spacing={2}>
        <Grid item xs={12} sm={5} className='form-input'>
          <Input
            label={t('SERVICES.UPDATE.CUSTOM_FIELDS.NAME')}
            defaultValue={item.name}
            onChange={(value: string): void => handleOnChangeInput(value, 'name')}
            testId='name'
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={5} className='form-input'>
          <Input
            label={t('SERVICES.UPDATE.CUSTOM_FIELDS.TYPE')}
            //error={userInfoForm.groups.error}
            defaultValue={item.id_type}
            onChange={(value: string): void => handleOnChangeInput(value, 'id_type')}
            testId='type'
            required={true}
            select={true}
          >
            {MOCK_TYPES.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </Input>
        </Grid>
        <Grid item container xs={12} sm={1} className='form-input' direction='column' alignContent='center'>
          <IconButton Icon={iconTrash} className='trash-icon remove-field' onClick={() => handleRemoveField()} />
        </Grid>
      </Grid>
      <Grid item sm={6} className='row' direction='row' container>
        {item.items?.map((option, j) => (
          <React.Fragment key={j}>
            <Grid container justify='flex-start' direction='row'>
              <Input
                key={j}
                label={`${t('SERVICES.UPDATE.CUSTOM_FIELDS.OPTION')} ${j + 1}:`}
                defaultValue={option}
                onChange={(value: string): void => handleOnChangeOption(value, j)}
                testId='option'
                className='options'
              />
              <IconButton Icon={iconTrash} onClick={() => handleRemoveOption(j)} />
            </Grid>
          </React.Fragment>
        ))}
        {hasItems() && (
          <StyledButton className='secondary add-option-button' onClick={() => handleAddOption()}>
            {t('SERVICES.UPDATE.CUSTOM_FIELDS.BUTTONS.ADD_OPTION')}
          </StyledButton>
        )}
      </Grid>
    </Box>
  );
};

export const CustomField = styled(({ ...props }) => <CleanCustomField {...props} />)`
  .options {
    padding-bottom: 0.5em;
    max-width: 80%;
  }
  .add-option-button {
    padding-bottom: 0;
    padding-top: 0;
  }
`;
