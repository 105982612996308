import React, { useState } from 'react';
// import { OrdersScroll } from '../../ordersScroll';
import { Grid, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledButton } from '@wdynamo/common';

const CleanGroupSales = (props: any) => {
  const { mySalesStore } = props;
  // eslint-disable-next-line no-unused-vars
  const [currentGroup, setCurrentGroup] = useState();
  const { t } = useTranslation();

  const handleGroupChange = (e: any) => {
    console.log(e);
    // setCurrentGroup(e);
  };

  return (
    <Grid container className={`${props.className}`}>
      <Grid item xs={12}>
        <div className='title'>
          <p style={{ fontSize: '1.5em' }}>
            {t('MY_SALES.GROUP_SALES.TITLE')}
            <span className='colon'>:</span>
          </p>
        </div>
      </Grid>
      <Grid item xs={12} className='groups'>
        <RadioGroup value={currentGroup} onChange={handleGroupChange}>
          {mySalesStore?.groups.items?.map((group: any, key: number) => (
            <FormControlLabel key={key} value={group.name} control={<Radio />} label={group.name} />
          ))}
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        <div style={{ textAlign: 'right' }}>
          <StyledButton className='button'> {t('MY_SALES.GROUP_SALES.VIEW_SALES')}</StyledButton>
        </div>
      </Grid>
    </Grid>
  );
};

export const GroupSales = styled(({ ...props }) => <CleanGroupSales {...props} />)`
  .groups {
    color: black;
  }
  .title {
    font-weight: bold;
    color: black;
  }
  .colon {
    color: var(--color-main);
  }
  .button {
    padding-top: 1em;
  }
`;
