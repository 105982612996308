import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import { NavButtons } from '../../common/NavButtons';
// eslint-disable-next-line no-unused-vars
import { Input, UnclosableModal } from '@wdynamo/common';
import { Analytics } from '@wdynamo/common/lib/services';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import { UnderlineButton } from '../../../../../../components';
// import { IQuotationInput } from '../../../../Emissions/models';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import { LoadingPlanBox } from '../../AutoQuotation/Plans/components/LoadingPlanBox';
//TODO PASAR PLAN CARD A LA CARPETA DE COMMON
import { PlanCard } from '../../HomeQuotation/HomePlans/components/PlanCard';
// eslint-disable-next-line no-unused-vars
import { ReactComponent as DownloadArrowIcon } from '../../../../../../assets/img/icons_dynamo/icon_arrow_download.svg';
// eslint-disable-next-line no-unused-vars
import { ReactComponent as CheckIcon } from '../../../../../../assets/img/icons_dynamo/icon_check.svg';
// eslint-disable-next-line no-unused-vars
import CreatingOrderIllustration from '../../../../../../assets/img/illustrations/CreatingOrderIllustration.svg';
//swiper
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import SwiperCore, { Navigation, Pagination } from 'swiper';

interface IApPlansProps {
  className?: string;
  stepsApStore: any;
  dataForm: any;
  setDataForm(data: any): void;
  onChange(data: any): void;
  plansData: any;
  getPlans(requoteParams?: any): void;
  productInfo: any;
  nextStep(): void;
  formatPrice?(number: number): string;
  getBillingModeFetch(params: any): void;
  getPaymentMethodFetch(params: any): void;
  getPaymentConditionFetch(params: any): void;
  savePlan(planParams: any): void;
}

const CleanApPlansComponent: React.FC<IApPlansProps> = (props: IApPlansProps) => {
  const { t } = useTranslation();
  const {
    stepsApStore,
    dataForm,
    // eslint-disable-next-line no-unused-vars
    setDataForm,
    onChange,
    // eslint-disable-next-line no-unused-vars
    plansData,
    getPlans,
    productInfo,
    nextStep,
    formatPrice,
    getBillingModeFetch,
    getPaymentMethodFetch,
    getPaymentConditionFetch,
    savePlan
  } = props;

  const billingModeList = stepsApStore?.billingMode?.data;
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [paymentConditionList, setPaymentConditionList] = useState([]);
  /* eslint-disable no-unused-vars */
  const [loader, setLoader] = useState(false);
  const [billingSelected, setBillingSelected] = useState('');
  const [paymentMethodSelected, setPaymentMethodSelected] = useState('');
  const [paymentConditionSelected, setPaymentConditionSelected] = useState('');

  useEffect(() => {
    const productId = productInfo?.id;
    const subProductId = dataForm?.general_data?.has_motorcycle?.value === '0' ? '3' : '4'; //TODO MODIFICAR POR LOCATION.STATE.SERVICE ETC
    const producer = '2';
    const commercialPlan = 'PCAPM';
    const currency = '';

    getBillingModeFetch({
      productId: productId,
      subProductId: subProductId,
      producer: producer,
      commercialPlan: commercialPlan,
      currency: currency
    });

    Analytics().pageview('ap-badget | plans');
  }, []);

  useEffect(() => {
    setPaymentMethodList(stepsApStore.paymentMethod.data);
  }, [stepsApStore.paymentMethod.data]);

  useEffect(() => {
    setPaymentConditionList(stepsApStore.paymentCondition.data);
  }, [stepsApStore.paymentCondition.data]);

  const getPaymentMethod = () => {
    const productId = productInfo?.id;
    const subProductId = dataForm?.general_data?.has_motorcycle?.value === '0' ? '3' : '4'; //TODO MODIFICAR POR LOCATION.STATE.SERVICE ETC
    const commercialPlan = 'PCAPM';
    const billingMode = dataForm?.plans?.billing_mode?.value || stepsApStore?.billingMode[0]?.data?.value;
    const currency = '';
    getPaymentMethodFetch({
      productId: productId, // '3'
      subProductId: subProductId,
      commercialPlan: commercialPlan,
      billingMode: billingMode,
      currency: currency
    });
  };

  const paymentCondition = () => {
    const policyType = dataForm?.general_data?.has_motorcycle?.value === '0' ? '10401' : '10402'; //TODO MODIFICAR POR LOCATION.STATE.SERVICE ETC
    const producer = '2';
    const commercialPlan = 'PCAPM';
    const currency = '';
    const company = '1';
    const branch = productInfo?.branch;
    const date = moment(productInfo.updatedAt).format('YYYYMMDD');

    getPaymentConditionFetch({
      company: company,
      branch: branch,
      date: date,
      producer: producer,
      commercialPlan: commercialPlan,
      billingMode: dataForm?.plans?.billing_mode?.value,
      currency: currency,
      policyType: policyType,
      paymentMethod: dataForm?.plans?.payment_method?.value,
      insuranceType: ''
    });
  };

  const handleOnChange = (value: any, field: string) => {
    const prevState = { ...dataForm };
    prevState.plans[field].value = value;
    onChange(prevState);
    if (field === 'billing_mode') {
      setBillingSelected(value);
      setPaymentMethodList([]);
      setPaymentMethodSelected('');
      getPaymentMethod();
    }
    if (field === 'payment_method') {
      setPaymentMethodSelected(value);
      setPaymentConditionList([]);
      setPaymentConditionSelected('');
      paymentCondition();
    }
    if (field === 'payment_condition') {
      setPaymentConditionSelected(value);
      getPlans();
    }
  };

  const handleSelectPlan = (value: any) => {
    const prevState = { ...dataForm };
    prevState.plans.name.value = value.text;
    prevState.plans.code.value = value?.plan_code;
    prevState.plans.first_payment.value = value.first_payment;
    prevState.plans.remaining_payments.value = value.payments_price;
    prevState.plans.price.value = value?.price;
    prevState.plans.characteristics = value?.characteristics;
    savePlan({
      plan: {
        plan: value.text,
        plan_code: value.plan_code,
        first_payment: Number(value.first_payment),
        payment_left: Number(value.payments_price),
        price: Number(value.price),
        bills_amount: value.payments_amount
      },
      payment_method: value.payments_amount.toString()
    });
    onChange(prevState);
    nextStep();
  };

  const showErrorMessage = () => {
    const whithoutPlans = plansData.data?.quote?.planList?.length === 0;
    return (
      <Box className='align-text'>
        <Typography variant='h5'>¡Lo sentimos!</Typography>
        <Typography>
          {whithoutPlans ? t('QUOTATIONS.STEP_3.WHITHOUT_PLANS') : t('QUOTATIONS.STEP_3.SERVICE_ERROR')}
        </Typography>
        {!whithoutPlans && <Typography>{plansData.message.errors}</Typography>}
      </Box>
    );
  };

  const handleDownloadPdf = () => {
    // setLoader(true);
    // Api()
    //   .getDownloadPlans(stepsStore.plans.data?.quote?.id)
    //   .then((response: any) => {
    //     if (response) {
    //       const url = window.URL.createObjectURL(response?.data);
    //       const link = document.createElement('a');
    //       link.href = url;
    //       link.setAttribute('download', 'planes.pdf');
    //       document.body.appendChild(link);
    //       link.click();
    //       setLoader(false);
    //     } else {
    //       setLoader(false);
    //     }
    //   });
  };

  const renderPlansCards = () => {
    return plansData?.data?.quote?.planList?.map((plan: any, i: number) => (
      <span key={i}>
        <PlanCard
          data={plan}
          formatPrice={formatPrice}
          currencySimbol={'₲'}
          onSelect={handleSelectPlan}
          widthTitle={'81%'}
          itemsToShow={6}
          productId={productInfo.id}
        />
      </span>
    ));
  };

  const renderPlans = () => {
    SwiperCore.use([Navigation, Pagination]);
    const params = {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 40,
      grabCursor: true,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index: number, className: any) => {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        }
      }
    };
    if (plansData.loading)
      return (
        <>
          <LoadingPlanBox />
          <LoadingPlanBox />
          <LoadingPlanBox />
        </>
      );
    else
      return (
        <div>
          <Swiper {...params}>{renderPlansCards()}</Swiper>
        </div>
      );
  };

  return (
    <>
      <Box className={props.className}>
        {plansData.error ? (
          showErrorMessage()
        ) : (
          <>
            <Grid container spacing={3} alignItems='flex-end'>
              <Grid item xs={12} md={4}>
                <Typography>
                  {t('QUOTATIONS.STEP_1.BILLING_MODE')}
                  <span className='colored-mark'>*</span>
                </Typography>
                <Input
                  defaultValue={billingSelected}
                  onChange={(value: string): void => handleOnChange(value, 'billing_mode')}
                  disabled={!billingModeList.length}
                  required
                  select
                >
                  {billingModeList?.map((item: any) => (
                    <MenuItem key={String(item.code)} value={String(item.code)}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Input>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>
                  {t('QUOTATIONS.STEP_1.PAYMENT_METHOD')}
                  <span className='colored-mark'>*</span>
                </Typography>
                <Input
                  error={dataForm?.plans?.payment_method?.error}
                  defaultValue={paymentMethodSelected}
                  onChange={(value: string): void => handleOnChange(value, 'payment_method')}
                  disabled={!paymentMethodList.length}
                  required
                  select
                >
                  {paymentMethodList.map((item: any) => (
                    <MenuItem key={String(item.code)} value={String(item.code)}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Input>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>
                  {t('QUOTATIONS.STEP_1.PAYMENT_CONDITION')}
                  <span className='colored-mark'>*</span>
                </Typography>
                <Input
                  defaultValue={paymentConditionSelected}
                  onChange={(value: string): void => handleOnChange(value, 'payment_condition')}
                  disabled={!paymentConditionList.length}
                  required
                  select
                >
                  {paymentConditionList.map((item: any) => (
                    <MenuItem key={String(item.code)} value={String(item.code)}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Input>
              </Grid>
            </Grid>
            <Grid container justifyContent='center' className='plans'>
              {renderPlans()}
            </Grid>
            {plansData?.data?.quote?.planList.length && (
              <Typography style={{ margin: '50px 0 0 41px' }}>(*) {t('HOME_QUOTATIONS.STEP_2.INFO_PLANS')}</Typography>
            )}
            {/* <UnderlineButton
            onClick={handleDownloadPdf}
            label='Descargar planes PDF'
            icon={<DownloadArrowIcon width='16' height='16' />}
          />
          {loader && (
            <UnclosableModal img={CreatingOrderIllustration} messages={{ TITLE: t('COMMONS.MODALS.LOADING.TITLE') }} />
          )} */}
            <NavButtons {...props} />
          </>
        )}
      </Box>
    </>
  );
};

export const ApPlansComponent = styled(({ ...props }) => <CleanApPlansComponent {...props} />)`
  margin-top: 2em;
  .swiper-container {
    height: 100%;
    max-height: 100vw;
    min-height: 0;
    min-width: 0;
    max-width: 45vw;
    width: 100%;
    overflow: hidden;
  }
  .swiper-slide {
    width: 100%;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
  }
  .swiper-wrapper {
    width: 100%;
    max-height: 100%;
    height: 100%;
    display: flex;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 610px;
  }
  .swiper-button-prev {
    left: 27%;
  }
  .swiper-button-next {
    right: 11%;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    left: 239px;
    margin-top: 25px;
    bottom: initial;
    max-width: 83.5%;
  }
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    background-color: var(--color-main);
    color: var(--color-white);
    font-size: 12px;
    line-height: 20px;
    opacity: 0.5;
    text-align: center;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
  .plans {
    margin-top: 2.6em;
  }
  .align-text {
    text-align: center;
  }
`;
