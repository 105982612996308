import React, { useEffect } from 'react';
import styled from 'styled-components';
import { uris } from '../../../../../siteMap';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Step, StepLabel } from '@material-ui/core';
import { Redirect, useHistory } from 'react-router-dom';
import { Loading, Stepper, TextPoint } from '@wdynamo/common/lib/components';
import { GeneralData } from './GeneralData';
import { HomePlans } from './HomePlans';
import { HomeSummary } from './HomeSummary';
// eslint-disable-next-line no-unused-vars
import { ICustomHomeDataForm, ISourceHomeParams, IContactParams, IContact, IHomePlansParams } from './model';

import { Analytics } from '@wdynamo/common/lib/services';
// eslint-disable-next-line no-unused-vars

interface QuotationsHomeComponentProps {
  className?: string;
  location: any;
  getHomeStepsFetch(text: string): void;
  getSourceHomeListFetch(listParams: ISourceHomeParams): void;
  getDataHomeSourceFetch(dataParams: ISourceHomeParams): void;
  stepsHomeStore: any;
  // setItemsToStore(data: any): void;
  createContactFetch(params: IContactParams): void;
  getHomePlansFetch(params: any): void;
  updateQuotationFetch(quotationId: string, params: any): void;
  cleanStore(): void;
}

const initialState: any = {
  general_data: {
    home_type: { value: '', isRequired: true, error: false },
    province: { value: '', isRequired: true, error: false },
    city: { value: '', isRequired: false, error: false },
    telephone: { value: '', isRequired: true, error: false },
    mail: { value: '', isRequired: true, error: false }
  },
  plans: {
    billing_mode: { value: '', isRequired: true, error: false },
    payment_method: { value: '', isRequired: true, error: false },
    payment_condition: { value: '', isRequired: true, error: false },
    name: { value: '', isRequired: true, error: false },
    code: { value: '', isRequired: true, error: false },
    first_payment: { value: '', isRequired: true, error: false },
    remaining_payments: { value: '', isRequired: true, error: false },
    bonus: { value: '0', isRequired: true, error: false },
    price: { value: '', isRequired: false, error: false },
    characteristics: { value: '', isRequired: false, error: false }
  }
};

let productId: string;
let currentQuoteParams: any;
const initialCustomState: any = {};

const CleanHomeQuotationsComponent: React.FC<QuotationsHomeComponentProps> = (props: QuotationsHomeComponentProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const fixedSteps: string[] = [
    t('HOME_QUOTATIONS.STEP_1.LABEL'),
    t('HOME_QUOTATIONS.STEP_2.LABEL'),
    t('HOME_QUOTATIONS.STEP_3.LABEL')
  ];
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [dataForm, setDataForm] = React.useState<ICustomHomeDataForm>(initialState);
  const [steps, setSteps] = React.useState<string[]>(fixedSteps);
  const [currentId, setCurrentId] = React.useState<number>();
  // eslint-disable-next-line no-unused-vars
  const [summaryData, setSummaryData] = React.useState([]);
  const [currentContact, setCurrentContact] = React.useState<IContact>();
  const defaultRedirect = <Redirect to={uris.services.uri} />;
  const {
    location,
    getHomeStepsFetch,
    getSourceHomeListFetch,
    getDataHomeSourceFetch,
    stepsHomeStore,
    // setItemsToStore,
    createContactFetch,
    getHomePlansFetch,
    updateQuotationFetch,
    cleanStore
  } = props;

  useEffect(() => {
    Analytics().pageview('home-badget');
    if (!location?.state?.service?.id && !location?.state?.requote?.customer?.id) history.push(uris?.services.uri);
    return () => {
      cleanStore();
      initialState.general_data.mail.value = location?.state?.requote?.customer?.mail
        ? location?.state?.requote?.customer?.email
        : '';
      initialState.general_data.telephone.value = location?.state?.requote?.customer?.telephone
        ? location?.state?.requote?.customer?.telephone
        : '';
      setDataForm(initialState);
    };
  }, []);

  useEffect(() => {
    if (currentStep && currentStep !== 0 && currentStep !== 1) {
      setCurrentStep(1);
    }
  }, []);

  useEffect(() => {
    productId = location?.state?.service?.id ? location.state.service.id : '2';
    if (location?.state?.service?.id || location?.state?.requote?.customer?.id) {
      getHomeStepsFetch(productId);
      setCurrentStep(0);
      // if (location?.state?.requote?.customer?.id) {
      //   const requote = location.state.requote;
      //   const costumer = location.state.requote.customer;
      //   setCurrentContact(costumer);
      // if (location?.state?.requote?.planName) setCurrentStep(2);
      //   else setCurrentStep(0);
      //   console.log('location?.state: ' + JSON.stringify(location?.state));
      // setDataForm((prevState: any) => ({
      //   ...prevState,
      //     general_info: {
      //       ...prevState.general_info,
      //       email: { ...prevState.general_info.email, value: costumer.mail },
      //       phone_number: {
      //         ...prevState.general_info.phone_number,
      //         value: costumer.phone
      //       },
      //       producer_code: { ...prevState.general_info.producer_code, value: requote.producer },
      //       commercial_plan: { ...prevState.general_info.commercial_plan, value: requote.commercialPlan },
      //       discount_code: { ...prevState.general_info.discount_code, value: requote.discountCode }
      //     },
      //     plans: {
      //       billing_mode: { ...prevState.plans.billing_mode, value: requote.billingMethod },
      //       payment_method: { ...prevState.plans.payment_method, value: requote.paymentMethod },
      //       payment_condition: { ...prevState.plans.payment_condition, value: requote.paymentCondition },
      //       name: { ...prevState.plans.bonus, value: '' },
      //       first_payment: { ...prevState.plans.bonus, value: '' },
      //       remaining_payments: { ...prevState.plans.bonus, value: '' },
      //       bonus: { ...prevState.plans.bonus, value: '0' }
      //     }
      //   }));
      // }
    }
  }, [location.state]);

  useEffect(() => {
    if (stepsHomeStore.steps.error) {
      alert('Ocurrio un error');
      history.push(uris.services.uri);
    }
  }, [stepsHomeStore.steps.loading]);

  useEffect(() => {
    if (stepsHomeStore.steps?.data?.label) {
      setSteps(steps);
      let summaryInputs: any = [];
      // const loadedInputState: any = {};
      // const loadedInputsFromContact = location?.state?.requote?.inputs && location.state.requote.inputs;
      //Generate custom_step State
      stepsHomeStore.steps.data.input_groups.forEach((group: any) => {
        //   loadedInputState[group.name] = {};
        group.inputs.forEach((field: any) => {
          //     const loadedStateGroup = loadedInputState[group.name];
          //     //generate clean custom State for requoting
          //     initialCustomState = {
          //       ...initialCustomState,
          //       [group.name]: {
          //         ...initialCustomState[group.name],
          //         [field.name]: {
          //           error: false,
          //           isRequired: field.is_required,
          //           value: {
          //             id: field.default_value ? field.default_value.toString() : '',
          //             description: field.default_value ? field.default_value.toString() : ''
          //           }
          //         }
          //       }
          //     };
          //     loadedStateGroup[field.name] = {
          //       value: loadedInputsFromContact
          //         ? loadedInputsFromContact[field.name]
          //         : field.default_value && field.default_value,
          //       error: false,
          //       isRequired: field.is_required
          //     };
          summaryInputs = [
            ...summaryInputs,
            {
              name: field.name,
              label: field.label,
              type: field.type,
              groupName: group.name,
              // true: field.text_true,
              // false: field.text_false,
              isRequired: field.is_required
              // span: field.span
            }
          ];
        });
      });
      setSummaryData(summaryInputs);
      setDataForm({ ...dataForm });
    }
  }, [stepsHomeStore.steps.data]);

  useEffect(() => {
    if (stepsHomeStore?.createContact?.data?.data?.clientRepresentation[0].id) {
      setCurrentId(stepsHomeStore.createContact.data.data.clientRepresentation[0].id);
      handleNext();
    }
  }, [stepsHomeStore.createContact.data]);

  const handleCreateContact = (currentContact?: any) => {
    if (currentContact) {
      setCurrentId(currentContact.id);
      setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
    } else {
      createContactFetch([
        {
          index: 0,
          telephone: dataForm.general_data.telephone.value as string,
          mail: dataForm.general_data.mail.value as string
        }
      ]);
    }
  };

  const getPlans = (requoteParams?: any) => {
    const inputs = {
      homeType: {
        code: dataForm.general_data.home_type.value.id,
        description: dataForm.general_data.home_type.value.description
      },
      province: dataForm.general_data.province.value
    };
    const subProductId = 2;

    currentQuoteParams = requoteParams
      ? {
          product_id: productId,
          customer_id: currentId ? currentId : location?.state?.requote?.customer?.id,
          inputs: inputs,
          ...requoteParams
        }
      : {
          producer_code: '2',
          commercial_plan: 'HPB',
          insurance_type: '001',
          bonus_percentage: 0,
          fop_changed: false,
          product_id: productId,
          sub_product_id: subProductId,
          customer_id: currentId,
          inputs: inputs,
          billing_method: dataForm.plans.billing_mode.value, // dataForm.plans.billing_mode.value || '12',
          payment_method: dataForm.plans.payment_method.value, // dataForm.plans.payment_method.value || '2',
          payment_condition: dataForm.plans.payment_condition.value, // dataForm.plans.payment_condition.value || '12',
          request: location?.state?.requote?.quotationExternalId
            ? Number(location.state.requote.quotationExternalId)
            : undefined
        };

    getHomePlansFetch(currentQuoteParams);
  };

  const handleNext = () => setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
  const handleBack = () => setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);

  const savePlan = (planParams: any): void => {
    updateQuotationFetch(stepsHomeStore.plans.data.quote.id, { ...currentQuoteParams, ...planParams });
  };

  const formatPrice = (number: number): string => {
    return number.toLocaleString('es-AR', { maximumFractionDigits: 0 });
  };

  const isValid = (stepName: string): boolean => {
    let valid = true;
    const stepData = dataForm[stepName];
    if (stepData) {
      Object.keys(stepData).forEach((field) => {
        if (stepData[field].isRequired && (stepData[field].value === '' || stepData[field].error)) valid = false;
      });
    }

    return valid;
  };

  const cleanState = () => {
    const cleanForm = {
      ...initialState,
      general_data: initialCustomState
    };
    setCurrentContact(undefined);
    setDataForm(cleanForm);
  };

  const handleCancel = () => {
    const initialForm = { ...initialState };
    initialForm.general_data.home_type.value = '';
    initialForm.general_data.province.value = '';
    initialForm.general_data.city.value = '';
    history.push(uris?.services.uri);
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <GeneralData
            isFirstStep={true}
            // setItemsToStore={setItemsToStore}
            getSourceHomeListFetch={getSourceHomeListFetch}
            getDataHomeSourceFetch={getDataHomeSourceFetch}
            data={stepsHomeStore.steps.data}
            listSources={stepsHomeStore.listSources}
            // dataSources={stepsHomeStore.dataSources}
            stepsHomeStore={stepsHomeStore}
            isNextButtonDisabled={!isValid('general_data')}
            prevStep={handleCancel}
            nextStep={handleCreateContact}
            dataForm={dataForm}
            onChange={setDataForm}
            currentContact={currentContact}
            setCurrentContact={setCurrentContact}
          />
        );
      case 1:
        return (
          <HomePlans
            getPlans={getPlans}
            nextStep={handleNext}
            prevStep={handleBack}
            dataForm={dataForm}
            setDataForm={setDataForm}
            onChange={setDataForm}
            plansData={stepsHomeStore?.plans}
            productInfo={location?.state?.service}
            stepsHomeStore={stepsHomeStore}
            isNextButtonHide={true}
            formatPrice={formatPrice}
            savePlan={savePlan}
          />
        );
      case 2:
        return (
          <HomeSummary
            listSources={stepsHomeStore.listSources}
            stepsHomeStore={stepsHomeStore}
            dataForm={dataForm}
            productInfo={location.state.service}
            prevStep={handleBack}
            setCurrentStep={setCurrentStep}
            cleanState={cleanState}
            formatPrice={formatPrice}
          />
        );

      default:
        return defaultRedirect;
    }
  };

  const renderForm = () => {
    return (
      <Container className={props.className}>
        <Grid item>
          <h1>
            {t('HOME_QUOTATIONS.TITLE')}
            <TextPoint />
          </h1>
        </Grid>

        {stepsHomeStore.steps.loading ? (
          <Loading />
        ) : (
          <>
            <Box className='labels-root'>
              <Grid container>
                {steps.map((label, i) => (
                  <Box key={label} className={`${currentStep === i && 'label-active'} label-item`}>
                    <Grid item>{label}</Grid>
                  </Box>
                ))}
              </Grid>
            </Box>
            <Stepper activeStep={currentStep} alternativeLabel steps={steps}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>{getStepContent(currentStep)}</div>
          </>
        )}
      </Container>
    );
  };
  return <Container>{renderForm()}</Container>;
};

export const HomeQuotationsComponent = styled(({ ...props }) => <CleanHomeQuotationsComponent {...props} />)`
  .labels-root {
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
  }
  .label-item {
    padding-left: 8px;
    padding-right: 8px;
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .label-active {
    font-weight: bolder;
  }

  .colored-mark {
    color: var(--color-main);
    padding-left: 0.3em;
  }
  .colored-text {
    color: var(--color-main);
  }
  .MuiSwitch-track {
    border-radius: 20px;
  }
  .MuiSwitch-root {
    width: 64px;
    height: 47px;
  }
  .MuiSwitch-switchBase {
    top: 4px;
    left: 5px;
  }
`;
