import { connect } from 'react-redux';
import { CheckoutComponent } from './view';
import {
  getDocNumberInfoFetch,
  updateClientInfo,
  updateAddressInfo,
  updatePaymentInfo,
  confirmationFetch,
  resetCheckout,
  getPaymentUrlFetch
} from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  checkoutStore: state.checkoutState
});
const mapDispatchToProps = {
  getDocNumberInfoFetch,
  updateClientInfo,
  updateAddressInfo,
  updatePaymentInfo,
  confirmationFetch,
  resetCheckout,
  getPaymentUrlFetch
};

export const Checkout = connect(mapStateToProps, mapDispatchToProps)(CheckoutComponent);
export { checkoutSaga } from './sagas';
export { checkoutReducer } from './reducers';
export type { ICheckoutProps } from './models';
export { resetCheckout } from './actions';
