import React from 'react';
import { IMaskInput } from 'react-imask';

const PhoneMask = React.forwardRef<HTMLElement, any>(function phoneMask(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={'{595}#00-000000'}
      definitions={{
        '#': /0|9/
      }}
      unmask={true}
      inputRef={ref}
      maxLength={14}
      onAccept={(unmaskedValue: string) => onChange({ target: { value: unmaskedValue || '', name: props.name } })}
    />
  );
});

export default PhoneMask;
