export type actionType = {
  type: string;
  error?: boolean;
  payload?: any;
};

export interface IBaseStateProps {
  loading: boolean;
  error: boolean;
  data?: any;
}

export const ACTIONS = {
  EXAMPLE: {
    FETCH: 'EXAMPLE_GET',
    SUCCESS: 'EXAMPLE_SUCCESS',
    FAIL: 'EXAMPLE_FAIL'
  },
  AUTH: {
    UPDATE_CURRENT_USER_INFO: 'AUTH_UPDATE_CURRENT_USER_INFO'
  },
  PRODUCTS: {
    LIST: {
      FETCH: 'PRODUCTS_LIST_FETCH',
      SUCCESS: 'PRODUCTS_LIST_SUCCESS',
      FAIL: 'PRODUCTS_LIST_FAIL'
    },
    CHOICE: 'PRODUCTS_CHOICE'
  },
  CHECKOUT: {
    RESET: 'CHECKOUT_RESET',
    FIND_DOC_NUMBER: {
      FETCH: 'CHECKOUT_FIND_DOC_NUMBER_FETCH',
      SUCCESS: 'CHECKOUT_FIND_DOC_NUMBER_SUCCESS',
      FAIL: 'CHECKOUT_FIND_DOC_NUMBER_FAIL',
      RESET: 'CHECKOUT_FIND_DOC_NUMBER_RESET'
    },
    UPDATE_CLIENT_INFO: 'CHECKOUT_UPDATE_CLIENT_INFO',
    UPDATE_ADDRESS_INFO: 'CHECKOUT_UPDATE_ADDRESS_INFO',
    UPDATE_PAYMENT_INFO: 'CHECKOUT_UPDATE_PAYMENT_INFO',
    ZIPCODE: {
      FETCH: 'CHECKOUT_ZIPCODE_FETCH',
      SUCCESS: 'CHECKOUT_ZIPCODE_SUCCESS',
      FAIL: 'CHECKOUT_ZIPCODE_FAIL'
    },
    CONFIRMATION: {
      FETCH: 'CHECKOUT_CONFIRMATION_FETCH',
      SUCCESS: 'CHECKOUT_CONFIRMATION_SUCCESS',
      FAIL: 'CHECKOUT_CONFIRMATION_FAIL'
    },
    PAYMENT_URL: {
      FETCH: 'CHECKOUT_PAYMENT_URL_FETCH',
      SUCCESS: 'CHECKOUT_PAYMENT_URL_SUCCESS',
      FAIL: 'CHECKOUT_PAYMENT_URL_FAIL'
    }
  },
  MY_SALES: {
    TABLE: {
      FETCH: 'MY_SALES_TABLE_FETCH',
      SUCCESS: 'MY_SALES_TABLE_SUCCESS',
      FAIL: 'MY_SALES_TABLE_FAIL',
      NEXT_PAGE: 'MY_SALES_TABLE_NEXT_PAGE_FETCH'
    },
    DASHBOARD: {
      FETCH: 'MY_SALES_DASHBOARD_FETCH',
      SUCCESS: 'MY_SALES_DASHBOARD_SUCCESS',
      FAIL: 'MY_SALES_DASHBOARD_FAIL'
    },
    GROUPS: {
      FETCH: 'MY_SALES_GROUP_FETCH',
      SUCCESS: 'MY_SALES_GROUP_SUCCESS',
      FAIL: 'MY_SALES_GROUP_FAIL'
    }
  },
  USERS: {
    FETCH: 'USERS_FETCH',
    SUCCESS: 'USERS_SUCCESS',
    FAIL: 'USERS_FAIL',
    NEXT_PAGE: 'USERS_NEXT_PAGE_FETCH',
    ROLES: {
      FETCH: 'USERS_ROLES_FETCH',
      SUCCESS: 'USERS_ROLES_FETCH_SUCCESS',
      FAIL: 'USERS_ROLES_FETCH_FAIL',
      DELETE: 'USERS_ROLES_DELETE',
      DELETE_SUCCESS: 'USERS_ROLES_DELETE_SUCCESS',
      DELETE_FAIL: 'USERS_ROLES_DELETE_FAIL'
    },
    GET_ROLES: {
      FETCH: 'USERS_GET_ROLES_FETCH',
      SUCCESS: 'USERS_GET_ROLES_SUCCESS',
      FAIL: 'USERS_GET_ROLES_FAIL'
    },
    USER_INFO: {
      FETCH: 'USERS_USER_INFO_FETCH',
      SUCCESS: 'USERS_USER_INFO_SUCCESS',
      FAIL: 'USERS_USER_INFO_FAIL'
    },
    ROLE_INFO: {
      ADD: {
        FETCH: 'USERS_ROLE_INFO_ADD',
        FAIL: 'USERS_ROLE_INFO_ADD_FAIL',
        SUCCESS: 'USERS_ROLE_INFO_ADD_SUCCESS'
      },
      UPDATE: {
        FETCH: 'USERS_ROLE_INFO_UPDATE',
        FAIL: 'USERS_ROLE_INFO_UPDATE_FAIL',
        SUCCESS: 'USERS_ROLE_INFO_UPDATE_SUCCESS'
      },
      FETCH: 'USERS_ROLE_INFO_FETCH',
      SUCCESS: 'USERS_ROLE_INFO_FETCH_SUCCESS',
      FAIL: 'USERS_ROLE_INFO_FETCH_FAIL',
      GET_PERMISSIONS: {
        FETCH: 'USERS_ROLE_INFO_GET_PERMISSIONS_FETCH',
        SUCCESS: 'USERS_ROLE_INFO_GET_PERMISSIONS_SUCCESS',
        FAIL: 'USERS_ROLE_INFO_GET_PERMISSIONS_FAIL'
      }
    },
    NON_PAGED_LIST: {
      FETCH: 'USERS_NON_PAGED_LIST_FETCH',
      SUCCESS: 'USERS_NON_PAGED_LIST_SUCCESS',
      FAIL: 'USERS_NON_PAGED_LIST_FAIL'
    }
  },
  REPORTS: {
    ORDERS_GROUPED_BY_DATE: {
      FETCH: 'REPORTS_ORDERS_GROUPED_BY_DATE_FETCH',
      SUCCESS: 'REPORTS_ORDERS_GROUPED_BY_DATE_SUCCESS',
      FAIL: 'REPORTS_ORDERS_GROUPED_BY_DATE_FAIL'
    },
    PRODUCTS: { FETCH: 'REPORTS_PRODUCTS_FETCH', SUCCESS: 'REPORTS_PRODUCTS_SUCCESS', FAIL: 'REPORTS_PRODUCTS_FAIL' },
    SALESTEAMS: {
      FETCH: 'REPORTS_SALESTEAMS_FETCH',
      SUCCESS: 'REPORTS_SALESTEAMS_SUCCESS',
      FAIL: 'REPORTS_SALESTEAMS_FAIL'
    },
    SALESMEN: { FETCH: 'REPORTS_SALESMEN_FETCH', SUCCESS: 'REPORTS_SALESMEN_SUCCESS', FAIL: 'REPORTS_SALESMEN_FAIL' },
    ORDERS: {
      FETCH: 'REPORTS_ORDERS_FETCH',
      SUCCESS: 'REPORTS_ORDERS_SUCCESS',
      FAIL: 'REPORTS_ORDERS_FAIL',
      NEXT_PAGE: 'REPORTS_ORDERS_NEXT_PAGE',
      TO_EXPORT: {
        FETCH: 'REPORTS_ORDERS_TO_EXPORT_FETCH',
        SUCCESS: 'REPORTS_ORDERS_TO_EXPORT_SUCCESS'
      }
    }
  },
  SETTINGS: {
    FETCH: 'SETTINGS_FETCH',
    SUCCESS: 'SETTINGS_SUCCESS',
    FAIL: 'SETTINGS_FAIL',
    IMAGE: {
      FETCH: 'SETTINGS_IMAGE_FETCH',
      SUCCESS: 'SETTINGS_IMAGE_SUCCESS',
      FAIL: 'SETTINGS_IMAGE_FAIL'
    },
    UPDATE: {
      FETCH: 'SETTINGS_UPDATE_FETCH',
      SUCCESS: 'SETTINGS_UPDATE_SUCCESS',
      FAIL: 'SETTINGS_UPDATE_FAIL'
    }
  },
  CONTACTS: {
    FETCH: 'CONTACTS_FETCH',
    SUCCESS: 'CONTACTS_SUCCESS',
    FAIL: 'CONTACTS_FAIL',
    NEXT_PAGE: 'CONTACTS_NEXT_PAGE_FETCH',
    CONTACT_INFO: {
      FETCH: 'CONTACTS_CONTACT_INFO_FETCH',
      SUCCESS: 'CONTACTS_CONTACT_INFO_SUCCESS',
      FAIL: 'CONTACTS_CONTACT_INFO_FAIL'
    },
    CONTACT_DETAILS: {
      FETCH: 'CONTACTS_CONTACT_DETAILS_FETCH',
      SUCCESS: 'CONTACTS_CONTACT_DETAILS_SUCCESS',
      FAIL: 'CONTACTS_CONTACT_DETAILS_FAIL'
    },
    CHANGE_STATUS: {
      FETCH: 'CONTACTS_CONTACT_CHANGE_STATUS_FETCH',
      SUCCESS: 'CONTACTS_CONTACT_CHANGE_STATUS_SUCCESS',
      FAIL: 'CONTACTS_CONTACT_CHANGE_STATUS_FAIL'
    },
    EXCEL_IMPORT_EXAMPLE: {
      FETCH: 'CONTACTS_CONTACT_EXCEL_EXAMPLE_FETCH',
      SUCCESS: 'CONTACTS_CONTACT_EXCEL_EXAMPLE_SUCCESS',
      FAIL: 'CONTACTS_CONTACT_EXCEL_EXAMPLE_FAIL'
    }
  },
  GROUPS: {
    FETCH: 'GROUPS_FETCH',
    SUCCESS: 'GROUPS_SUCCESS',
    FAIL: 'GROUPS_FAIL',
    NEXT_PAGE: 'GROUPS_NEXT_PAGE_FETCH',
    REMOVE: {
      FETCH: 'GROUP_REMOVE_FETCH',
      SUCCESS: 'GROUP_REMOVE_SUCCESS',
      FAIL: 'GROUP_REMOVE_FAIL'
    },
    GROUP_INFO: {
      FETCH: 'GROUP_INFO_FETCH',
      SUCCESS: 'GROUP_INFO_SUCCESS',
      FAIL: 'GROUP_INFO_FAIL'
    },
    GROUP_CREATE: {
      FETCH: 'GROUP_CREATE_FETCH',
      SUCCESS: 'GROUP_CREATE_SUCCESS',
      FAIL: 'GROUP_CREATE_FAIL'
    },
    GROUP_MEMBERS: {
      FETCH: 'GROUP_MEMBERS_FETCH',
      SUCCESS: 'GROUP_MEMBERS_SUCCESS',
      FAIL: 'GROUP_MEMBERS_FAIL',
      REMOVE: {
        FETCH: 'GROUP_MEMBER_REMOVE_FETCH',
        SUCCESS: 'GROUP_MEMBER_REMOVE_SUCCESS',
        FAIL: 'GROUP_MEMBER_REMOVE_FAIL'
      }
    },
    GROUP_PARENTS: {
      FETCH: 'GROUP_PARENTS_FETCH',
      SUCCESS: 'GROUP_PARENTS_SUCCESS',
      FAIL: 'GROUP_PARENTS_FAIL'
    }
  },
  SERVICES: {
    FETCH: 'SERVICES_FETCH',
    SUCCESS: 'SERVICES_SUCCESS',
    FAIL: 'SERVICES_FAIL',
    UPDATE: {
      FETCH: 'SERVICES_UPDATE',
      SUCCESS: 'SERVICES_UPDATE_SUCCESS',
      FAIL: 'SERVICES_UPDATE_FAIL'
    }
  },
  PERSON: {
    CREATE: {
      FETCH: 'PERSON_CREATE',
      SUCCESS: 'PERSON_CREATE_SUCCESS',
      FAIL: 'PERSON_CREATE_FAIL'
    }
  },
  QUOTATIONS: {
    STEPS: {
      FETCH: 'QUOTATIONS_STEPS_FETCH',
      SUCCESS: 'QUOTATIONS_STEPS_SUCCESS',
      FAIL: 'QUOTATIONS_STEPS_FAIL'
    },
    UPDATE: {
      FETCH: 'QUOTATIONS_UPDATE_FETCH',
      SUCCESS: 'QUOTATIONS_UPDATE_SUCCESS',
      FAIL: 'QUOTATIONS_UPDATE_FAIL'
    },
    LISTSOURCES: {
      FETCH: 'QUOTATIONS_LISTSOURCES_FETCH',
      SUCCESS: 'QUOTATIONS_LISTSOURCES_SUCCESS',
      FAIL: 'QUOTATIONS_LISTSOURCES_FAIL',
      SET_ITEMS_FROM_INPUTTER: 'QUOTATIONS_SET_ITEMS'
    },
    DATASOURCES: {
      FETCH: 'QUOTATIONS_DATASOURCES_FETCH',
      SUCCESS: 'QUOTATIONS_DATASOURCES_SUCCESS',
      FAIL: 'QUOTATIONS_DATASOURCES_FAIL'
    },
    CONTACTS: {
      FETCH: 'QUOTATIONS_CONTACTS_FETCH',
      SUCCESS: 'QUOTATIONS_CONTACTS_SUCCESS',
      FAIL: 'QUOTATIONS_CONTACTS_FAIL',
      CLEAR: 'QUOTATIONS_CONTACTS_CLEAR'
    },
    NEW_CONTACT: {
      FETCH: 'QUOTATIONS_NEW_CONTACT_FETCH',
      SUCCESS: 'QUOTATIONS_NEW_CONTACT_SUCCESS',
      FAIL: 'QUOTATIONS_NEW_CONTACT_FAIL'
    },
    PLANS: {
      FETCH: 'QUOTATIONS_PLANS_FETCH',
      SUCCESS: 'QUOTATIONS_PLANS_SUCCESS',
      FAIL: 'QUOTATIONS_PLANS_FAIL'
    },
    COMMERCIAL_PLAN: {
      FETCH: 'QUOTATIONS_COMMERCIAL_PLAN_FETCH',
      SUCCESS: 'QUOTATIONS_COMMERCIAL_PLAN_SUCCESS',
      FAIL: 'QUOTATIONS_COMMERCIAL_PLAN_FAIL'
    },
    BILLING_MODE: {
      FETCH: 'QUOTATIONS_BILLING_MODE_FETCH',
      SUCCESS: 'QUOTATIONS_BILLING_MODE_SUCCESS',
      FAIL: 'QUOTATIONS_BILLING_MODE_FAIL'
    },
    PAYMENT_METHOD: {
      FETCH: 'QUOTATIONS_PAYMENT_METHOD_FETCH',
      SUCCESS: 'QUOTATIONS_PAYMENT_METHOD_SUCCESS',
      FAIL: 'QUOTATIONS_PAYMENT_METHOD_FAIL'
    },
    PAYMENT_CONDITION: {
      FETCH: 'QUOTATIONSPAYMENT_CONDITION_FETCH',
      SUCCESS: 'QUOTATIONS_PAYMENT_CONDITION_SUCCESS',
      FAIL: 'QUOTATIONS_PAYMENT_CONDITION_FAIL'
    },
    PRODUCER: {
      FETCH: 'QUOTATIONS_PRODUCER_FETCH',
      SUCCESS: 'QUOTATIONS_PRODUCER_SUCCESS',
      FAIL: 'QUOTATIONS_PRODUCER_FAIL'
    },
    ACCESORY: {
      FETCH: 'QUOTATIONS_ACCESORY_FETCH',
      SUCCESS: 'QUOTATIONS_ACCESORY_SUCCESS',
      FAIL: 'QUOTATIONS_ACCESORY_FAIL'
    },
    CLEAN: 'QUOTATIONS_STORE_CLEAN'
  },
  ISSUE: {
    CLEAN: 'ISSUE_STORE_CLEAN',
    CLEAR_ERROR: 'ISSUE_CLEAR_ERROR',
    STEPS: {
      FETCH: 'ISSUE_STEPS_FETCH',
      SUCCESS: 'ISSUE_STEPS_SUCCESS',
      FAIL: 'ISSUE_STEPS_FAIL'
    },
    BANK: {
      FETCH: 'ISSUE_BANK_FETCH',
      SUCCESS: 'ISSUE_BANK_SUCCESS',
      FAIL: 'ISSUE_BANK_FAIL'
    },
    CARD: {
      FETCH: 'ISSUE_CARD_FETCH',
      SUCCESS: 'ISSUE_CARD_SUCCESS',
      FAIL: 'ISSUE_CARD_FAIL'
    },
    NATIONALITY: {
      FETCH: 'ISSUE_NATIONALITY_FETCH',
      SUCCESS: 'ISSUE_NATIONALITY_SUCCESS',
      FAIL: 'ISSUE_NATIONALITY_FAIL'
    },
    PROVINCE: {
      FETCH: 'ISSUE_PROVINCE_FETCH',
      SUCCESS: 'ISSUE_PROVINCE_SUCCESS',
      FAIL: 'ISSUE_PROVINCE_FAIL'
    },
    CITY: {
      FETCH: 'ISSUE_CITY_FETCH',
      SUCCESS: 'ISSUE_CITY_SUCCESS',
      FAIL: 'ISSUE_CITY_FAIL'
    },
    NEIGHBORHOOD: {
      FETCH: 'ISSUE_NEIGHBORHOOD_FETCH',
      SUCCESS: 'ISSUE_NEIGHBORHOOD_SUCCESS',
      FAIL: 'ISSUE_NEIGHBORHOOD_FAIL'
    },
    ID_TYPE: {
      FETCH: 'ISSUE_ID_TYPE_FETCH',
      SUCCESS: 'ISSUE_ID_TYPE_SUCCESS',
      FAIL: 'ISSUE_ID_TYPE_FAIL'
    },
    MARITAL_STATUS: {
      FETCH: 'ISSUE_MARITAL_STATUS_FETCH',
      SUCCESS: 'ISSUE_MARITAL_STATUS_SUCCESS',
      FAIL: 'ISSUE_MARITAL_STATUS_FAIL'
    },
    ACTIVITY: {
      FETCH: 'ISSUE_ACTIVITY_FETCH',
      SUCCESS: 'ISSUE_ACTIVITY_SUCCESS',
      FAIL: 'ISSUE_ACTIVITY_FAIL'
    },
    CREATE: {
      FETCH: 'ISSUE_CREATE_FETCH',
      SUCCESS: 'ISSUE_CREATE_SUCCESS',
      FAIL: 'ISSUE_CREATE_FAIL'
    },
    SAVE_FIRST_STEP: {
      FETCH: 'ISSUE_SAVE_FIRST_STEP_FETCH',
      SUCCESS: 'ISSUE_SAVE_FIRST_STEP_SUCCESS',
      FAIL: 'ISSUE_SAVE_FIRST_STEP_FAIL'
    },
    INITIALIZE_PERSISTENCE: {
      FETCH: 'ISSUE_INITIALIZE_PERSISTENCE_FETCH',
      SUCCESS: 'ISSUE_INITIALIZE_PERSISTENCE_SUCCESS',
      FAIL: 'ISSUE_INITIALIZE_PERSISTENCE_FAIL'
    },
    SAVE_SECOND_STEP: {
      FETCH: 'ISSUE_SAVE_SECOND_STEP_FETCH',
      SUCCESS: 'ISSUE_SAVE_SECOND_STEP_SUCCESS',
      FAIL: 'ISSUE_SAVE_SECOND_STEP_FAIL'
    },
    SAVE_THIRD_STEP: {
      FETCH: 'ISSUE_SAVE_THIRD_STEP_FETCH',
      SUCCESS: 'ISSUE_SAVE_THIRD_STEP_SUCCESS',
      FAIL: 'ISSUE_SAVE_THIRD_STEP_FAIL'
    },
    SAVE_FOURTH_STEP: {
      FETCH: 'ISSUE_SAVE_FOURTH_STEP_FETCH',
      SUCCESS: 'ISSUE_SAVE_FOURTH_STEP_SUCCESS',
      FAIL: 'ISSUE_SAVE_FOURTH_STEP_FAIL'
    },
    LISTSOURCES: {
      FETCH: 'ISSUE_LISTSOURCES_FETCH',
      SUCCESS: 'ISSUE_LISTSOURCES_SUCCESS',
      FAIL: 'ISSUE_LISTSOURCES_FAIL',
      SET_ITEMS_FROM_INPUTTER: 'ISSUE_SET_ITEMS'
    },
    SECOND_INSTALLMENT_DATE: {
      FETCH: 'ISSUE_SECOND_INSTALLMENT_DATE_FETCH',
      SUCCESS: 'ISSUE_SECOND_INSTALLMENT_DATE_SUCCESS',
      FAIL: 'ISSUE_SECOND_INSTALLMENT_DATE_FAIL'
    },
    SOURCE_FUND: {
      FETCH: 'ISSUE_SOURCE_FUND_FETCH',
      SUCCESS: 'ISSUE_SOURCE_FUND_SUCCESS',
      FAIL: 'ISSUE_SOURCE_FUND_FAIL'
    }
  },
  INSPECTION: {
    GET_FORM: {
      FETCH: 'INSPECTION_GET_FORM_FETCH',
      SUCCESS: 'INSPECTION_GET_FORM_SUCCESS',
      FAIL: 'INSPECTION_GET_FORM_FAIL'
    },
    SEND_FORM: {
      FETCH: 'INSPECTION_SEND_FORM_FETCH',
      SUCCESS: 'INSPECTION_SEND_FORM_SUCCESS',
      FAIL: 'INSPECTION_SEND_FORM_FAIL'
    }
  },
  QUOTE_MANAGEMENT: {
    GET_QUOTES_INFO: {
      FETCH: 'QUOTE_MANAGEMENT_FETCH',
      SUCCESS: 'QUOTE_MANAGEMENT_SUCCESS',
      FAIL: 'QUOTE_MANAGEMENT_FAIL'
    },
    GET_ALL_QUOTES_INFO: {
      FETCH: 'QUOTE_ALL_FETCH',
      SUCCESS: 'QUOTE_ALL_SUCCESS',
      FAIL: 'QUOTE_ALL_FAIL'
    },
    GET_PRODUCTS: {
      FETCH: 'QUOTE_PRODUCTS_FETCH',
      SUCCESS: 'QUOTE_PRODUCTS_SUCCESS',
      FAIL: 'QUOTE_PRODUCTS_FAIL'
    },
    CLEAR_ALL_QUOTES: {
      FETCH: 'CLEAR_ALL_QUOTES_FETCH'
    }
  },
  QUOTATION_BY_ID: {
    FETCH: 'QUOTATION_BY_ID_FETCH',
    SUCCESS: 'QUOTATION_BY_ID_SUCCESS',
    FAIL: 'QUOTATION_BY_ID_FAIL',
    CLEAN: 'QUOTATION_BY_ID_CLEAN'
  },
  HOME_QUOTATIONS: {
    STEPS: {
      FETCH: 'QUOTATION_HOME_STEPS_FETCH',
      SUCCESS: 'QUOTATION_HOME_STEPS_SUCCESS',
      FAIL: 'QUOTATION_HOME_STEPS_FAIL'
    },
    LISTSOURCES: {
      FETCH: 'QUOTATION_HOME_LISTSOURCES_FETCH',
      SUCCESS: 'QUOTATION_HOME_LISTSOURCES_SUCCESS',
      FAIL: 'QUOTATION_HOME_LISTSOURCES_FAIL',
      SET_ITEMS_FROM_INPUTTER: 'QUOTATIONS_HOME_SET_ITEMS'
    },
    DATASOURCES: {
      FETCH: 'QUOTATION_HOME_LISTSOURCES_FETCH',
      SUCCESS: 'QUOTATION_HOME_LISTSOURCES_SUCCESS',
      FAIL: 'QUOTATION_HOME_LISTSOURCES_FAIL'
    },
    CREATE_CONTACT: {
      FETCH: 'QUOTATION_HOME_CREATE_CONTACT_FETCH',
      SUCCESS: 'QUOTATION_HOME_CREATE_CONTACT_SUCCESS',
      FAIL: 'QUOTATION_HOME_CREATE_CONTACT_FAIL'
    },
    CONTACTS: {
      FETCH: 'QUOTATIONS_HOME_CONTACTS_FETCH',
      SUCCESS: 'QUOTATIONS_HOME_CONTACTS_SUCCESS',
      FAIL: 'QUOTATIONS_HOME_CONTACTS_FAIL',
      CLEAR: 'QUOTATIONS_HOME_CONTACTS_CLEAR'
    },
    CITIES: {
      FETCH: 'QUOTATIONS_HOME_CITIES_FETCH',
      SUCCESS: 'QUOTATIONS_HOME_CITIES_SUCCESS',
      FAIL: 'QUOTATIONS_HOME_CITIES_FAIL'
    },
    PLANS: {
      FETCH: 'QUOTATIONS_HOME_PLANS_FETCH',
      SUCCESS: 'QUOTATIONS_HOME_PLANS_SUCCESS',
      FAIL: 'QUOTATIONS_HOME_PLANS_FAIL'
    },
    BILLING_MODE: {
      FETCH: 'QUOTATIONS_HOME_BILLING_MODE_FETCH',
      SUCCESS: 'QUOTATIONS_HOME_BILLING_MODE_SUCCESS',
      FAIL: 'QUOTATIONS_HOME_BILLING_MODE_FAIL'
    },
    PAYMENT_METHOD: {
      FETCH: 'QUOTATIONS_HOME_PAYMENT_METHOD_FETCH',
      SUCCESS: 'QUOTATIONS_HOME_PAYMENT_METHOD_SUCCESS',
      FAIL: 'QUOTATIONS_HOME_PAYMENT_METHOD_FAIL'
    },
    PAYMENT_CONDITION: {
      FETCH: 'QUOTATIONS_HOME_PAYMENT_CONDITION_FETCH',
      SUCCESS: 'QUOTATIONS_HOME_PAYMENT_CONDITION_SUCCESS',
      FAIL: 'QUOTATIONS_HOME_PAYMENT_CONDITION_FAIL'
    },
    UPDATE: {
      FETCH: 'QUOTATIONS_HOME_UPDATE_FETCH',
      SUCCESS: 'QUOTATIONS_HOME_UPDATE_SUCCESS',
      FAIL: 'QUOTATIONS_HOME_UPDATE_FAIL'
    },
    CLEAN: 'QUOTATIONS_HOME_STORE_CLEAN'
  },
  AP_QUOTATIONS: {
    STEPS: {
      FETCH: 'QUOTATION_AP_STEPS_FETCH',
      SUCCESS: 'QUOTATION_AP_STEPS_SUCCESS',
      FAIL: 'QUOTATION_AP_STEPS_FAIL'
    },
    CREATE_CONTACT: {
      FETCH: 'QUOTATION_AP_CREATE_CONTACT_FETCH',
      SUCCESS: 'QUOTATION_AP_CREATE_CONTACT_SUCCESS',
      FAIL: 'QUOTATION_AP_CREATE_CONTACT_FAIL'
    },
    CONTACTS: {
      FETCH: 'QUOTATION_AP_CONTACT_FETCH',
      SUCCESS: 'QUOTATION_AP_CONTACT_SUCCESS',
      FAIL: 'QUOTATION_AP_CONTACT_FAIL',
      CLEAR: 'QUOTATION_AP_CONTACT_CLEAR'
    },
    BILLING_MODE: {
      FETCH: 'QUOTATIONS_AP_BILLING_MODE_FETCH',
      SUCCESS: 'QUOTATIONS_AP_BILLING_MODE_SUCCESS',
      FAIL: 'QUOTATIONS_AP_BILLING_MODE_FAIL'
    },
    PAYMENT_METHOD: {
      FETCH: 'QUOTATIONS_AP_PAYMENT_METHOD_FETCH',
      SUCCESS: 'QUOTATIONS_AP_PAYMENT_METHOD_SUCCESS',
      FAIL: 'QUOTATIONS_AP_PAYMENT_METHOD_FAIL'
    },
    PAYMENT_CONDITION: {
      FETCH: 'QUOTATIONS_AP_PAYMENT_CONDITION_FETCH',
      SUCCESS: 'QUOTATIONS_AP_PAYMENT_CONDITION_SUCCESS',
      FAIL: 'QUOTATIONS_AP_PAYMENT_CONDITION_FAIL'
    },
    PLANS: {
      FETCH: 'QUOTATIONS_AP_PLANS_FETCH',
      SUCCESS: 'QUOTATIONS_AP_PLANS_SUCCESS',
      FAIL: 'QUOTATIONS_AP_PLANS_FAIL'
    },
    UPDATE: {
      FETCH: 'QUOTATIONS_AP_UPDATE_FETCH',
      SUCCESS: 'QUOTATIONS_AP_UPDATE_SUCCESS',
      FAIL: 'QUOTATIONS_AP_UPDATE_FAIL'
    },
    CLEAN: 'QUOTATION_AP_STORE_CLEAN'
  }
};
