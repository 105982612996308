import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface IFormPepProp {
  className?: string;
  dataForm: any;
  handleOnChange(value: string, name: string): void;
  statePath: string;
}

const CleanformPep: React.FC<IFormPepProp> = (props: IFormPepProp) => {
  const { dataForm, handleOnChange, statePath } = props;

  return (
    <>
      <Grid item md={12}>
        <Typography>Datos P.E.P. Persona expuesta politicamente)</Typography>
      </Grid>
      <Grid item md={4}>
        <TextField
          name={`${statePath}.position`}
          value={dataForm.position.value}
          error={dataForm.position.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.position.isRequired}
          label={'Cargo/Jerarquia/Función'}
        ></TextField>
      </Grid>
      <Grid item md={4}>
        <TextField
          name={`${statePath}.agency`}
          value={dataForm.agency.value}
          error={dataForm.agency.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.agency.isRequired}
          label={'Organismo/Dependencia'}
        ></TextField>
      </Grid>
      <Grid item md={4}>
        <TextField
          name={`${statePath}.character`}
          value={dataForm.character.value}
          error={dataForm.character.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.character.isRequired}
          label={'Carácter'}
        ></TextField>
      </Grid>
    </>
  );
};
export const FormPEP = styled(({ ...props }) => <CleanformPep {...props} />)`
  .radio-label {
    font-size: 13px;
  }
`;
