import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './saga';
import { exampleReducer } from './views/Example';
import { authReducer } from './views/Auth';
import { roleInfoReducer } from './views/Users/components/Roles/RoleInfo';
import { userInfoReducer } from './views/Users/components/UserInfo';
import { rolesListReducer } from './views/Users/components/Roles/RolesList';
import { productsReducer } from './views/Products';
import { checkoutReducer } from './views/Checkout';
import { mySalesReducer } from './views/MySales';
import { usersReducer } from './views/Users';
import { reportsReducer } from './views/Reports';
import { settingsReducer } from './views/Settings/reducers';
import { contactsReducer } from './views/Contacts';
import { contactInfoReducer } from './views/Contacts/components/ContactInfo';
import { groupsReducer } from './views/Users/components/Groups';
import { groupInfoReducer } from './views/Users/components/Groups/components/GroupInfo';
import { serviceUpdateReducer } from './views/Services/ServicesUpdate';
import { paymentsFormReducer } from './views/Services/components/PaymentsForm';
import { customFieldsFormReducer } from './views/Services/components/CustomFields';
import { contactDetailsReducer } from './views/Contacts/components/ContactDetails';
import { quotationStepsReducer } from './views/Services/Quotations/components/AutoQuotation';
import { emissionStepsReducer } from './views/Services/Emissions';
import { servicesReducer } from './views/Services/ServicesList/reducers';
import { inspectionReducer } from './views/Inspection';
import { quotationHomeStepsReducer } from './views/Services/Quotations/components/HomeQuotation';
import { quotationApStepsReducer } from './views/Services/Quotations/components/APQuotation';
import { homeQuotationGeneralDataReducer } from './views/Services/Quotations/components/HomeQuotation/GeneralData';

/* eslint-disable no-unused-vars */
import { IBaseStore } from './views/Users/components/UserInfo';
import { IBaseStateProps } from './views/ActionTypes';
import { IMySalesProps } from './views/MySales';
import { IUsersProps } from './views/Users';
import { IReportsProps } from './views/Reports';
import { IContactsStore } from './views/Contacts';
import { IContactInfoStore } from './views/Contacts/components/ContactInfo';
import { IServicesStore } from './views/Services/ServicesList/models';
import { IInspectionStore } from './views/Inspection/models';
import { quotesManagementReducer } from './views/QuoteManagement/reducers';
import { quotesManagementAllReducer } from './views/QuoteManagement/reducers';
import { IBaseHomeStore } from './views/Services/Quotations/components/HomeQuotation';
import { IBaseApStore } from './views/Services/Quotations/components/APQuotation';
import { IGeneralDataBaseStore } from './views/Services/Quotations/components/HomeQuotation/GeneralData';
/* eslint-enable no-unused-vars */

const sagaMiddleware = createSagaMiddleware();

export interface IAppState {
  exampleState: IBaseStateProps;
  authState: IBaseStore;
  userInfoState: IBaseStore;
  roleInfoReducer: any;
  rolesState: any;
  productsReducer: IBaseStateProps;
  checkoutReducer: IBaseStateProps;
  mySalesReducer: IMySalesProps;
  usersReducer: IUsersProps;
  reportsReducer: IReportsProps;
  settingsReducer: IBaseStateProps;
  contactsReducer: IContactsStore;
  contactInfoReducer: IContactInfoStore;
  serviceUpdateReducer: IBaseStateProps;
  paymentsFormReducer: IBaseStateProps;
  customFieldsFormReducer: IBaseStateProps;
  quotationStepsReducer: IBaseStateProps;
  emissionStepsReducer: IBaseStateProps;
  servicesReducer: IServicesStore;
  inspectionReducer: IInspectionStore;
  quotationHomeStepsReducer: IBaseHomeStore;
  quotationApStepsReducer: IBaseApStore;
  homeQuotationGeneralDataReducer: IGeneralDataBaseStore;
}

const persistConfig = {
  key: 'example',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['data'],
  blacklist: ['loading', 'error']
};
const persistAuthConfig = {
  key: 'authReducer',
  storage: storage,
  whitelist: ['currentUserInfo']
};
const reducers = combineReducers({
  exampleState: persistReducer(persistConfig, exampleReducer),
  authState: persistReducer(persistAuthConfig, authReducer),
  userInfoState: userInfoReducer,
  roleInfoState: roleInfoReducer,
  rolesState: rolesListReducer,
  productsState: productsReducer,
  checkoutState: checkoutReducer,
  mySalesState: mySalesReducer,
  usersState: usersReducer,
  reportsState: reportsReducer,
  settingsState: settingsReducer,
  contactsState: contactsReducer,
  contactInfoState: contactInfoReducer,
  contactDetailsState: contactDetailsReducer,
  groupsState: groupsReducer,
  groupInfoState: groupInfoReducer,
  serviceUpdateState: serviceUpdateReducer,
  paymentsFormState: paymentsFormReducer,
  customFieldsFormState: customFieldsFormReducer,
  quotationState: quotationStepsReducer,
  emissionState: emissionStepsReducer,
  servicesState: servicesReducer,
  inspectionState: inspectionReducer,
  quoteManagementState: quotesManagementReducer,
  quoteManagementeAllState: quotesManagementAllReducer,
  quotationHomeState: quotationHomeStepsReducer,
  quotationApState: quotationApStepsReducer,
  generalDataState: homeQuotationGeneralDataReducer
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(sagaMiddleware)));
const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export const Store = store;
export const Persistor = persistor;
export type RootState = ReturnType<typeof reducers>;
