import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
// import { IconButton } from '@wdynamo/common';
import { ImageUploader } from '../ImageUploader';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as EditIcon } from '../../../../assets/img/icons_dynamo/icon_edit_modal.svg';
import EditIcon from '../../../../assets/img/icons_dynamo/icon_edit_modal.svg';
// import { ReactComponent as TrashIcon } from '../../assets/img/icons_dynamo/icon_trash.svg';
import TrashIcon from '../../../../assets/img/icons_dynamo/icon_trash.svg';

interface LogoComponentProps {
  logoFilename: string;
  uploadImageFetch(imageData: any): void;
  onImageUploaded(location: string): void;
  className?: any;
}

const LogoComponent = (props: LogoComponentProps) => {
  const { t } = useTranslation();

  const uploadLogo = (logo_filename: any) => {
    const formData = new FormData();
    formData.append('image', logo_filename);
    // props.uploadImageFetch(formData);
  };
  return (
    <Grid className={`${props.className}`} container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='caption'>{t('SERVICES.NEW_SERVICE.STEP_2.IMAGE')}</Typography>
      </Grid>
      <Grid item xs={6} container spacing={3} direction='column'>
        <Grid item>
          <Typography variant='body2'>{t('SERVICES.NEW_SERVICE.STEP_2.ALLOWED_FORMATS')}</Typography>
          <Typography variant='body1' style={{ fontWeight: 'bold' }}>
            {t('SERVICES.NEW_SERVICE.STEP_2.FORMATS')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body2'>{t('SERVICES.NEW_SERVICE.STEP_2.SUPPORTED_SIZE')}</Typography>
          <Typography variant='body1' style={{ fontWeight: 'bold' }}>
            {t('SERVICES.NEW_SERVICE.STEP_2.SIZE')}
          </Typography>
        </Grid>
        <Grid item>
          <ImageUploader onImageSelected={(selectedImage: any) => uploadLogo(selectedImage)} />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <div>
          {props.logoFilename && (
            <div className='div-box'>
              <img alt='edit-icon' src={EditIcon} className='edit-icon' />
              <img alt='trash-icon' src={TrashIcon} className='trash-icon' />
              <img src={props.logoFilename} className='logo' alt='logo' />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export const Logo = styled(({ ...props }) => <LogoComponent {...props} />)`
  .div-box {
    position: relative;
    border: 1px solid black;
    width: 264px;
    height: 117px;
  }
  .logo {
    width: auto;
    height: 100%;
  }
  .edit-icon {
    position: absolute;
    background-color: white;
    top: 20px;
    right: 10px;
    z-index: 999;
    width: 20px;
    height: 20px;
  }
  .trash-icon {
    position: absolute;
    background-color: white;
    top: 20px;
    right: 40px;
    z-index: 999;
    width: 20px;
    height: 20px;
  }
`;
