import {
  Radio,
  RadioGroup,
  Grid,
  MenuItem,
  TextField,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Typography
} from '@material-ui/core';

import React from 'react';
import styled from 'styled-components';

// eslint-disable-next-line no-unused-vars
import { IQuotationInput } from '../../../../models';
import RucMask from '../common/RucMask';
import TextMask from '../common/TextMask';

interface IFormNPProp {
  className?: string;
  dataForm: any;
  handleOnChange(value: string | number | boolean, name: string): void;
  handleOnChangeRuc(e: any, path: string, secondPath?: string): void;
  statePath: string;
  idTypeList: IQuotationInput[];
}

const CleanformRadioInputsNaturalPerson: React.FC<IFormNPProp> = (props: IFormNPProp) => {
  const { dataForm, handleOnChange, handleOnChangeRuc, statePath, idTypeList } = props;
  const isRuc: number = 80;
  const invalidRuc: any = dataForm.holder.id.error;
  const invalidRucMssg: string = 'Ruc inválido';

  return (
    <>
      <Grid item md={3}>
        <FormLabel className='radio-label'>Quien declara es titular</FormLabel>
        <FormGroup>
          <RadioGroup
            name={`${statePath}.is_holder`}
            value={dataForm.is_holder.value}
            onChange={(event): void => handleOnChange(event.target.value === 'true', `${statePath}.is_holder`)}
            row={true}
          >
            <FormControlLabel value={false} control={<Radio />} label={'No'} />
            <FormControlLabel value={true} control={<Radio />} label={'Sí'} />
          </RadioGroup>
        </FormGroup>
      </Grid>

      {dataForm.is_holder.value === false && (
        <>
          <Grid item md={12}>
            <Typography>Datos Titular</Typography>
          </Grid>
          <Grid item md={3}>
            <TextField
              name={`${statePath}.holder.name`}
              value={dataForm.holder.name.value}
              error={dataForm.holder.name.error}
              onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
              fullWidth
              required={dataForm.holder.name.isRequired}
              label={'Nombre'}
              InputProps={{
                inputComponent: TextMask as any
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              name={`${statePath}.holder.last_name`}
              value={dataForm.holder.last_name.value}
              error={dataForm.holder.last_name.error}
              onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
              fullWidth
              required={dataForm.holder.last_name.isRequired}
              label={'Apellido'}
              InputProps={{
                inputComponent: TextMask as any
              }}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              name={`${statePath}.holder.id_type`}
              value={dataForm.holder.id_type.value}
              error={dataForm.holder.id_type.error}
              onChange={(event): void => handleOnChange(Number(event.target.value), event.target.name)}
              fullWidth
              required={dataForm.holder.id_type.isRequired}
              label={'Tipo'}
              select={true}
            >
              {idTypeList.map((item: any, i: number) => (
                <MenuItem key={i} value={item.code}>
                  {item.description}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={2} style={{ height: '80px' }}>
            {dataForm.holder.id_type.value === isRuc ? (
              <TextField
                name={`${statePath}.holder.id`}
                value={dataForm.holder.id.value}
                error={dataForm.holder.id.error}
                type={'text'}
                onChange={(e) => handleOnChangeRuc(e, statePath, 'holder')}
                fullWidth
                required={dataForm.holder.id.isRequired}
                label={'Documento'}
                InputProps={{
                  inputComponent: RucMask as any
                }}
                InputLabelProps={{ shrink: true }}
                helperText={invalidRuc ? invalidRucMssg : ''}
              />
            ) : (
              <TextField
                name={`${statePath}.holder.id`}
                value={dataForm.holder.id.value}
                error={dataForm.holder.id.error}
                type={'number'}
                onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
                fullWidth
                required={dataForm.holder.id.isRequired}
                label={'Documento'}
              />
            )}
          </Grid>
          <Grid item md={2}>
            <TextField
              name={`${statePath}.holder.character`}
              value={dataForm.holder.character.value}
              error={dataForm.holder.character.error}
              onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
              fullWidth
              required={dataForm.holder.character.isRequired}
              label={'Carácter'}
              inputProps={{
                maxLength: 60
              }}
            />
          </Grid>
        </>
      )}
    </>
  );
};
export const FormRadioInputsNaturalPerson = styled(({ ...props }) => <CleanformRadioInputsNaturalPerson {...props} />)`
  .radio-label {
    font-size: 13px;
  }
`;
