import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { StyledButton } from '@wdynamo/common/lib/components';
import plus from '../../../assets/img/icons_dynamo/plus.svg';

interface IImageInputProps {
  className: string;
  currentFile: { documentIdType: string; imageTitle: string; fileName: string; stringBase64: string };
  onChange(value: object): void;
  onError(error: boolean, value: string): void;
  imageSize: string;
  acceptFormats: string;
  fileSize: number;
}

const CleanImageInputComponent: React.FC<IImageInputProps> = (props: IImageInputProps) => {
  const {
    className,
    currentFile,
    onChange,
    onError,
    imageSize = '200px',
    acceptFormats = '.png,.jpg',
    fileSize = 524288
  } = props;
  const fileInput = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleOnChange = (e: any) => {
    try {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      if (file.size < fileSize)
        reader.onload = (e) => {
          const result = String(e.target?.result);
          onChange({ documentIdType: currentFile.documentIdType, fileName: file.name, stringBase64: result });
        };
      else onError(true, t('COMMONS.MODALS.FILE_SIZE'));
    } catch (e) {
      console.log(`Error: ${e}`);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={3} className={className}>
      <Typography>{currentFile.imageTitle}</Typography>
      <img
        className='input-image'
        src={currentFile.stringBase64}
        alt={currentFile.fileName}
        height={imageSize}
        width={imageSize}
        onClick={() => fileInput.current?.click()}
      />
      <StyledButton className='button-add-image' onClick={() => fileInput.current?.click()}>
        <img src={plus} className='image-add' alt={t('INSPECTION.FORM.ADD_IMAGE_ICON_ALT')} />
        {t('INSPECTION.FORM.ADD_IMAGE_BUTTON')}
      </StyledButton>

      <input type='file' accept={acceptFormats} ref={fileInput} style={{ display: 'none' }} onChange={handleOnChange} />
    </Grid>
  );
};

export const ImageInputComponent = styled(({ ...props }) => <CleanImageInputComponent {...props} />)`
  display: flex;
  margin: 0.5rem 0;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  .input-image {
    border-radius: 20px;
    cursor: pointer;
  }
  .button-add-image {
    position: relative;
    top: -24px;
    left: center;
    width: 10rem;
    height: 1.5rem;
    padding: 0.3rem;
    font-size: 0.7rem;
  }
  .image-add {
    height: 0.6rem;
    margin-right: 0.5rem;
  }
`;
