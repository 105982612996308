import { call, put, takeLatest, all } from 'redux-saga/effects';

import Api from './Api';
import ApiMock from './ApiMock';
import {
  getDocNumberInfoSuccess,
  getDocNumberInfoFail,
  confirmationSuccess,
  confirmationFail,
  getPaymentUrlSuccess,
  getPaymentUrlFail
} from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasDocNumberInfoFetch(action: actionType) {
  try {
    const response = yield call(api.getDocNumberInfoFetch, action.payload.docType, action.payload.docNumber);
    yield put(getDocNumberInfoSuccess(response.data));
  } catch (e) {
    yield put(getDocNumberInfoFail(e));
  }
}

function* sagasConfirmationFetch(action: actionType) {
  try {
    const response = yield call(api.confirmationFetch, action.payload.checkout);
    const splitedLocation: string[] = response.headers.location.split('/');
    yield put(confirmationSuccess(splitedLocation[splitedLocation.length - 1]));
  } catch (e) {
    yield put(confirmationFail(e));
  }
}

function* sagasPaymentUrlFetch(action: actionType) {
  try {
    const response = yield call(api.getPaymentUrl, action.payload.orderId);
    if (response.data.payments && response.data.payments[0])
      yield put(getPaymentUrlSuccess(response.data.payments[0].payment_request_url));
    else yield put(getPaymentUrlSuccess());
  } catch (e) {
    yield put(getPaymentUrlFail(e));
  }
}

// Listen for redux actions
function* checkoutSaga() {
  yield all([takeLatest(ACTIONS.CHECKOUT.FIND_DOC_NUMBER.FETCH, sagasDocNumberInfoFetch)]);
  yield all([takeLatest(ACTIONS.CHECKOUT.CONFIRMATION.FETCH, sagasConfirmationFetch)]);
  yield all([takeLatest(ACTIONS.CHECKOUT.PAYMENT_URL.FETCH, sagasPaymentUrlFetch)]);
}

export { checkoutSaga };
