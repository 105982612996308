import { connect } from 'react-redux';
import { LoginComponent } from './view';
import { updateCurrentUserInfo } from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  authStore: state.authState
});
const mapDispatchToProps = {
  updateCurrentUserInfo
};

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
export { authReducer } from './reducers';
