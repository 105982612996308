import { Typography, Container, Box, Grid } from '@material-ui/core';
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { ICustomField } from './models';
import { CustomField } from './components/CustomField';
import { StyledButton } from '@wdynamo/common';
import { useTranslation } from 'react-i18next';

interface ICustomFieldsComponentProps {
  service: any;
  onChange(service: any): void;
  customFieldsFormStore: any;
  getTypesFetch(): void;
  className?: string;
}

export const CustomFieldsFormComponent: React.FC<ICustomFieldsComponentProps> = (
  props: ICustomFieldsComponentProps
) => {
  const { getTypesFetch, service, onChange } = props;
  const { t } = useTranslation();

  React.useEffect(() => {
    getTypesFetch();
  }, []);

  // eslint-disable-next-line no-unused-vars
  const emptyCustomField: ICustomField = {
    id: '',
    name: '',
    id_type: ''
  };

  const isTheLastItem = (position: number, list: any[]): boolean => {
    return position === list?.length - 1;
  };

  const fields = service?.customFields?.value.map((item: ICustomField, i: number) => (
    <React.Fragment key={i}>
      <CustomField title={`${t('SERVICES.UPDATE.CUSTOM_FIELDS.FIELD')} ${i}:`} item={item} index={i} {...props} />
      {!isTheLastItem(i, service?.customFields.value) && <hr></hr>}
    </React.Fragment>
  ));

  const handleAddfield = () => {
    const prevCustomFields = service.customFields?.value ? service.customFields.value : [];
    onChange({ ...service, customFields: { ...service.customFields, value: [...prevCustomFields, emptyCustomField] } });
  };

  return (
    <Container maxWidth='sm'>
      <Box py={3}>
        <Typography>{t('SERVICES.UPDATE.CUSTOM_FIELDS.OPTIONAL')}</Typography>
        <Typography variant='h6'>{t('SERVICES.UPDATE.CUSTOM_FIELDS.SUBTITLE')}</Typography>
      </Box>
      {fields}
      <Grid container direction='row' justify='flex-end'>
        <StyledButton className='default' onClick={() => handleAddfield()}>
          {t('SERVICES.UPDATE.CUSTOM_FIELDS.BUTTONS.ADD_FIELD')}
        </StyledButton>
      </Grid>
    </Container>
  );
};
