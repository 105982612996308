import { ACTIONS } from '../../../../ActionTypes';

//get Steps
export const getApStepsFetch = (text: string) => ({
  type: ACTIONS.AP_QUOTATIONS.STEPS.FETCH,
  payload: { params: text }
});

export const getApStepsSuccess = (data: any) => ({
  type: ACTIONS.AP_QUOTATIONS.STEPS.SUCCESS,
  payload: { data: data }
});

export const getApStepsFail = (error: object) => ({
  type: ACTIONS.AP_QUOTATIONS.STEPS.FAIL,
  payload: { error: error }
});

//create contact
export const createContactFetch = (params: any) => ({
  type: ACTIONS.AP_QUOTATIONS.CREATE_CONTACT.FETCH,
  payload: { params }
});

export const createContactSuccess = (data: any) => ({
  type: ACTIONS.AP_QUOTATIONS.CREATE_CONTACT.SUCCESS,
  payload: data
});

export const createContactFail = (error: object) => ({
  type: ACTIONS.AP_QUOTATIONS.CREATE_CONTACT.FAIL,
  payload: { error: error }
});

//get contacts
export const getContactsFetch = (params: any) => ({
  type: ACTIONS.AP_QUOTATIONS.CONTACTS.FETCH,
  payload: { params }
});

export const getContactsSuccess = (data: any) => ({
  type: ACTIONS.AP_QUOTATIONS.CONTACTS.SUCCESS,
  payload: data
});

export const getContactsFail = (error: object) => ({
  type: ACTIONS.AP_QUOTATIONS.CONTACTS.FAIL,
  payload: { error: error }
});

export const getContactsClear = () => ({
  type: ACTIONS.AP_QUOTATIONS.CONTACTS.CLEAR
});

//get billingMode
export const getBillingModeFetch = (params: any) => ({
  type: ACTIONS.AP_QUOTATIONS.BILLING_MODE.FETCH,
  payload: { params }
});

export const getBillingModeSuccess = (data: any) => ({
  type: ACTIONS.AP_QUOTATIONS.BILLING_MODE.SUCCESS,
  payload: data
});

export const getBillingModeFail = (error: object) => ({
  type: ACTIONS.AP_QUOTATIONS.BILLING_MODE.FAIL,
  payload: { error: error }
});

//getPaymentMethod
export const getPaymentMethodFetch = (params: any) => ({
  type: ACTIONS.AP_QUOTATIONS.PAYMENT_METHOD.FETCH,
  payload: { params }
});

export const getPaymentMethodSuccess = (data: any) => ({
  type: ACTIONS.AP_QUOTATIONS.PAYMENT_METHOD.SUCCESS,
  payload: data
});

export const getPaymentMethodFail = (error: object) => ({
  type: ACTIONS.AP_QUOTATIONS.PAYMENT_METHOD.FAIL,
  payload: { error: error }
});

//get paymentCondition
export const getPaymentConditionFetch = (params: any) => ({
  type: ACTIONS.AP_QUOTATIONS.PAYMENT_CONDITION.FETCH,
  payload: { params }
});

export const getPaymentConditionSuccess = (data: any) => ({
  type: ACTIONS.AP_QUOTATIONS.PAYMENT_CONDITION.SUCCESS,
  payload: data
});

export const getPaymentConditionFail = (error: object) => ({
  type: ACTIONS.AP_QUOTATIONS.PAYMENT_CONDITION.FAIL,
  payload: { error: error }
});

//get plans
export const getPlansFetch = (params: any) => ({
  type: ACTIONS.AP_QUOTATIONS.PLANS.FETCH,
  payload: { params }
});

export const getPlansSuccess = (data: any) => ({
  type: ACTIONS.AP_QUOTATIONS.PLANS.SUCCESS,
  payload: data
});

export const getPlansFail = (error: object) => ({
  type: ACTIONS.AP_QUOTATIONS.PLANS.FAIL,
  payload: { error: error }
});

//update quotation
export const updateQuotationFetch = (quotationId: string, params: any) => ({
  type: ACTIONS.AP_QUOTATIONS.UPDATE.FETCH,
  payload: { quotationId: quotationId, params }
});

export const updateQuotationSuccess = (data: any) => ({
  type: ACTIONS.AP_QUOTATIONS.UPDATE.SUCCESS,
  payload: data
});

export const updateQuotationFail = (error: object) => ({
  type: ACTIONS.AP_QUOTATIONS.UPDATE.FAIL,
  payload: { error: error }
});

//clean store
export const cleanStore = () => ({
  type: ACTIONS.AP_QUOTATIONS.CLEAN
});
