import { connect } from 'react-redux';
import { ServiceUpdateComponent } from './view';
import { updateServiceFetch } from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../store';

const mapStateToProps = (state: RootState) => ({
  serviceUpdateStore: state.serviceUpdateState
});
const mapDispatchToProps = {
  updateServiceFetch
};

export const ServicesUpdate = connect(mapStateToProps, mapDispatchToProps)(ServiceUpdateComponent);

export { serviceUpdateReducer } from './reducers';
export { serviceUpdateSaga } from './sagas';
