import { fork } from 'redux-saga/effects';

import { exampleSaga } from './views/Example';
import { userInfoSaga } from './views/Users/components/UserInfo';
import { rolesListSaga } from './views/Users/components/Roles/RolesList';
import { productsSaga } from './views/Products';
import { checkoutSaga } from './views/Checkout';
import { mySalesSaga } from './views/MySales';
import { usersSaga } from './views/Users';
import { reportsSaga } from './views/Reports';
import { groupSaga } from './views/Users/components/Groups/components/GroupInfo';
import { groupsSaga } from './views/Users/components/Groups';
import { settingsSaga } from './views/Settings/sagas';
import { contactsSaga } from './views/Contacts';
import { contactInfoSaga } from './views/Contacts/components/ContactInfo';
import { roleInfoSaga } from './views/Users/components/Roles/RoleInfo';
import { serviceUpdateSaga } from './views/Services/ServicesUpdate';
import { paymentsFormSaga } from './views/Services/components/PaymentsForm';
import { customFieldsFormSaga } from './views/Services/components/CustomFields';
import { contactDetailsSaga } from './views/Contacts/components/ContactDetails';
import { quotationsSaga } from './views/Services/Quotations/components/AutoQuotation';
import { emissionsSaga } from './views/Services/Emissions';
import { servicesSaga } from './views/Services/ServicesList/sagas';
import { inspectionSaga } from './views/Inspection';
import { qouteManagementSaga } from './views/QuoteManagement/sagas';
import { quotationsHomeSaga } from './views/Services/Quotations/components/HomeQuotation';
import { quotationsApSaga } from './views/Services/Quotations/components/APQuotation';
import { homeQuotationGeneralDataSaga } from './views/Services/Quotations/components/HomeQuotation/GeneralData';

export default function* rootSaga() {
  yield fork(exampleSaga); // TODO: This is an example
  yield fork(userInfoSaga);
  yield fork(productsSaga);
  yield fork(checkoutSaga);
  yield fork(mySalesSaga);
  yield fork(usersSaga);
  yield fork(reportsSaga);
  yield fork(settingsSaga);
  yield fork(contactsSaga);
  yield fork(contactInfoSaga);
  yield fork(roleInfoSaga);
  yield fork(rolesListSaga);
  yield fork(groupSaga);
  yield fork(groupsSaga);
  yield fork(serviceUpdateSaga);
  yield fork(paymentsFormSaga);
  yield fork(customFieldsFormSaga);
  yield fork(contactDetailsSaga);
  yield fork(quotationsSaga);
  yield fork(emissionsSaga);
  yield fork(servicesSaga);
  yield fork(inspectionSaga);
  yield fork(qouteManagementSaga);
  yield fork(quotationsHomeSaga);
  yield fork(quotationsApSaga);
  yield fork(homeQuotationGeneralDataSaga);
}
