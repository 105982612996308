import { ACTIONS } from '../../ActionTypes';

// Get services
export const getServicesFetch = () => ({
  type: ACTIONS.SERVICES.FETCH
});
export const getServicesSuccess = (data: any) => ({
  type: ACTIONS.SERVICES.SUCCESS,
  payload: data
});
export const getServicesFail = (error: object) => ({
  type: ACTIONS.SERVICES.FAIL,
  payload: { error: error }
});
