import { connect } from 'react-redux';
import { ContactsComponent } from './view';
import { getContactsFetch } from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  contactsStore: state.contactsState
});
const mapDispatchToProps = {
  getContactsFetch
};

export const ENCRYPTION_KEY = '2s5v8y/B?E(H+MbQ';

export const Contacts = connect(mapStateToProps, mapDispatchToProps)(ContactsComponent);

export { contactsSaga } from './sagas';
export { contactsReducer } from './reducers';
export type { IContactsStore } from './models';
