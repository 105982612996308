import { connect } from 'react-redux';
import { QuotationsComponent } from './view';
import {
  getStepsFetch,
  getDataSourceFetch,
  getListSourceFetch,
  getContactsByPartial,
  getContactsClear,
  createContactFetch,
  getPlansFetch,
  setItemsToStore,
  updateQuotationFetch,
  //getCommercialPlanFetch,
  getProducerFetch,
  getAccessoryFetch,
  cleanStore
} from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../../store';

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state: RootState) => ({
  stepsStore: state.quotationState
});
const mapDispatchToProps = {
  getStepsFetch,
  getListSourceFetch,
  getDataSourceFetch,
  getContactsByPartial,
  getContactsClear,
  createContactFetch,
  getPlansFetch,
  setItemsToStore,
  updateQuotationFetch,
  //getCommercialPlanFetch,
  getProducerFetch,
  getAccessoryFetch,
  cleanStore
};

export { quotationsSaga } from './sagas';
export { quotationStepsReducer } from './reducers';
export const Quotations = connect(mapStateToProps, mapDispatchToProps)(QuotationsComponent);
