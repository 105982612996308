import { connect } from 'react-redux';
import { getInspectionFormFetch, sendInspectionFormFetch } from './actions';
import { getSettingsFetch } from '../Settings/actions';
import { InspectionComponent } from './view';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  inspectionStore: { ...state.inspectionState, partnerConfigInfo: state.settingsState.data }
});

const mapDispatchToProps = { getInspectionFormFetch, sendInspectionFormFetch, getSettingsFetch };

export const Inspection = connect(mapStateToProps, mapDispatchToProps)(InspectionComponent);

export { inspectionSaga } from './sagas';
export { inspectionReducer } from './reducers';
