import React from 'react';
import styled from 'styled-components';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ColoredSymbolComponent } from '../../../components';
import { ImageInputComponent } from './ImageInput';
// eslint-disable-next-line no-unused-vars
import { IFormSteps } from '../models';

interface IFormBodyComponentProps {
  className: string;
  inspectionStore: any;
  formData: any;
  setFormData(dataForm: any): void;
  formSteps: IFormSteps;
  currentStep: number;
  setOpenModal(open: boolean): void;
  setModalDescription(description: string): void;
}

const CleanFormBodyComponent: React.FC<IFormBodyComponentProps> = (props: IFormBodyComponentProps) => {
  const {
    className,
    inspectionStore,
    formData,
    setFormData,
    formSteps,
    currentStep,
    setOpenModal,
    setModalDescription
  } = props;
  const { t } = useTranslation();

  const handleOnChange = (value: any, field: string) => {
    const prevState = { ...formData };
    prevState[field] = value;
    setFormData(prevState);
  };

  const handleOnError = (openModal: boolean, modalDescription: string) => {
    setOpenModal(openModal);
    setModalDescription(modalDescription);
  };

  const renderCarImageInput = () => {
    return Object.keys(formData).map((image, i) => (
      <ImageInputComponent
        key={formData[image].fileName + i}
        currentFile={{
          documentIdType: formData[image].documentIdType,
          imageTitle: image,
          fileName: formData[image].fileName,
          stringBase64: formData[image].stringBase64
        }}
        onChange={(value: any) => handleOnChange(value, image)}
        onError={handleOnError}
      />
    ));
  };

  const renderLoadDocumentation = () => (
    <>
      <Typography variant='subtitle1'>{t('INSPECTION.FORM.PHOTOGRAPHS')}</Typography>
      <ColoredSymbolComponent value='*' color={inspectionStore.partnerConfigInfo.color} />
      <Typography style={{ display: 'block' }}>{t('INSPECTION.FORM.PHOTOGRAPHS_DESCRIPTION')}</Typography>
      <Grid container style={{ margin: '1rem 0' }}>
        {renderCarImageInput()}
      </Grid>
    </>
  );

  const renderFinalMessage = (title: string, description: string) => (
    <>
      <Grid item xs={12} style={{ marginBottom: '1rem' }}>
        <Typography variant='h5' align='center'>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6' align='center'>
          {description}
        </Typography>
      </Grid>
    </>
  );

  const renderForm = () => {
    let currentComponent = null;
    let formTitle = '';
    if (currentStep === formSteps.initial) {
      formTitle = t('INSPECTION.FORM.LOAD_DOCUMENTATION');
      currentComponent = renderLoadDocumentation();
    }
    if (currentStep === formSteps.success) {
      formTitle = t('INSPECTION.FORM.ENDING');
      currentComponent = renderFinalMessage(t('INSPECTION.FORM.FINAL_TITLE'), t('INSPECTION.FORM.FINAL_DESCRIPTION'));
    }
    if (currentStep === formSteps.fail) {
      formTitle = t('INSPECTION.FORM.ENDING');
      currentComponent = renderFinalMessage(t('INSPECTION.FORM.ERROR_TITLE'), t('INSPECTION.FORM.ERROR_DESCRIPTION'));
    }
    return (
      <>
        <Grid className='form-title'>
          <Typography>{formTitle}</Typography>
        </Grid>
        {currentComponent}
      </>
    );
  };

  return <Box className={className}>{renderForm()}</Box>;
};

export const FormBodyComponent = styled(({ ...props }) => <CleanFormBodyComponent {...props} />)`
  .form-title {
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    color: var(--color-white);
    background-color: ${(props) => props.inspectionStore.partnerConfigInfo.color};
  }

  .MuiTypography-subtitle1 {
    display: inline;
    font-weight: bold;
    margin-top: 1rem;
  }

  .MuiTypography-body1 {
    display: inline;
  }
`;
