import { ACTIONS } from '../ActionTypes';

export const getSettingsFetch = (partnerId: string) => ({ type: ACTIONS.SETTINGS.FETCH, payload: { partnerId } });

export const getSettingsSuccess = (data: object) => ({ type: ACTIONS.SETTINGS.SUCCESS, payload: { data } });

export const getSettingsFail = (error: object) => ({ type: ACTIONS.SETTINGS.FAIL, payload: error });

export const uploadImageFetch = (imageData: any, partnerId: string) => ({
  type: ACTIONS.SETTINGS.IMAGE.FETCH,
  payload: { imageData, partnerId }
});

export const uploadImageSuccess = (data: object) => ({ type: ACTIONS.SETTINGS.IMAGE.SUCCESS, payload: { data } });

export const uploadImageFail = (error: object) => ({ type: ACTIONS.SETTINGS.IMAGE.FAIL, payload: error });

export const updatePartnerFetch = (partnerData: any, partnerId: string) => ({
  type: ACTIONS.SETTINGS.UPDATE.FETCH,
  payload: { partnerData, partnerId }
});

export const updatePartnerSuccess = (data: object) => ({ type: ACTIONS.SETTINGS.UPDATE.SUCCESS, payload: { data } });

export const updatePartnerFail = (error: object) => ({ type: ACTIONS.SETTINGS.UPDATE.FAIL, payload: error });
