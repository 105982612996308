import { connect } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../../store';
import { ApQuotationsComponent } from './view';
import {
  getApStepsFetch,
  createContactFetch,
  getContactsClear,
  getPlansFetch,
  updateQuotationFetch,
  cleanStore
} from './actions';

const mapStateToProps = (state: RootState) => ({
  stepsApStore: state.quotationApState
});

const mapDispatchToProps = {
  getApStepsFetch,
  createContactFetch,
  getContactsClear,
  getPlansFetch,
  updateQuotationFetch,
  cleanStore
};

export { quotationsApSaga } from './sagas';
export { quotationApStepsReducer } from './reducers';

export const ApQuotations = connect(mapStateToProps, mapDispatchToProps)(ApQuotationsComponent);
export type { IBaseApStore } from './models';
