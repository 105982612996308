import { ACTIONS } from '../../../ActionTypes';
// eslint-disable-next-line no-unused-vars

// Get groups
export const getGroupsFetch = (parent: string, users: string[]) => ({
  type: ACTIONS.GROUPS.FETCH,
  payload: { parent: parent, users: users }
});

export const getGroupsSuccess = (list: any[]) => ({
  type: ACTIONS.GROUPS.SUCCESS,
  payload: { data: list }
});

export const getGroupsFail = (error: object) => ({
  type: ACTIONS.GROUPS.FAIL,
  payload: { data: error }
});

// Get parents
export const getParentsFetch = (parent: string) => ({
  type: ACTIONS.GROUPS.GROUP_PARENTS.FETCH,
  payload: { parent: parent }
});

export const getParentsSuccess = (list: any[]) => ({
  type: ACTIONS.GROUPS.GROUP_PARENTS.SUCCESS,
  payload: { data: list }
});

export const getParentsFail = (error: object) => ({
  type: ACTIONS.GROUPS.GROUP_PARENTS.FAIL,
  payload: { data: error }
});

//Delete group
export const deleteGroupFetch = (id: string) => ({
  type: ACTIONS.GROUPS.REMOVE.FETCH,
  payload: { id: id }
});

export const deleteGroupSuccess = (resp: string) => ({
  type: ACTIONS.GROUPS.REMOVE.SUCCESS,
  payload: { data: resp }
});

export const deleteGroupFail = (error: object) => ({
  type: ACTIONS.GROUPS.REMOVE.FAIL,
  payload: { data: error }
});
