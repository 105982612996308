import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { Pagination, Autocomplete } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Input, IconButton, CancelModal } from '@wdynamo/common';
import CancelIllustration from '../../../../../../../assets/img/illustrations/CancelIllustration.jpg';
import { ReactComponent as addIcon } from '../../../../../../../assets/img/icons_dynamo/icon_add.svg';
import { ReactComponent as iconTrash } from '../../../../../../../assets/img/icons_dynamo/icon_trash.svg';
import { ReactComponent as IconSearch } from '../../../../../../../assets/img/icons_dynamo/icon_search.svg';

// eslint-disable-next-line no-unused-vars
import { ReactComponent as goBackIcon } from '../../../../../../../assets/img/icons_dynamo/icon_back.svg';

import { UserSelect } from './components/UserSelect';

// eslint-disable-next-line no-unused-vars
import { IBaseStore, IUserItem, memberStatus } from '../models';

interface CleanFormStep2FunctionalComponentProps {
  currentMembers: IUserItem[];
  setCurrentMembers(currentMembers: IUserItem[] | undefined): void;
  deleteMemberFetch(id?: string): void;
  getGroupMembersFetch(idGroup: string): void;
  groupInfoStore: IBaseStore;
  className?: string;
  groupInfo: any;
  isUpdateGroupView: boolean;
  filterUsersFetch(filter: string): void;
  filteredUsers: any[];
  listRoles: any;
}
const initialGroupMemberInput: IUserItem = {
  uuid: '',
  owner: false,
  status: memberStatus.ADDED,
  user: {
    id: '',
    first_name: '',
    last_name: '',
    collaborator_id: '',
    username: '',
    email: '',
    active: false,
    partner_id: ''
  }
};

const CleanFormStep2 = (props: CleanFormStep2FunctionalComponentProps) => {
  const { t } = useTranslation();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = React.useState<boolean>(false);
  const [selectedMember, setSelectedMember] = React.useState<IUserItem>();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [result, setResult] = React.useState<IUserItem>(initialGroupMemberInput);

  // eslint-disable-next-line no-unused-vars
  const [showResult, setShowResult] = React.useState<boolean>(false);

  const {
    groupInfoStore,
    currentMembers,
    setCurrentMembers,
    deleteMemberFetch,
    getGroupMembersFetch,
    groupInfo,
    // eslint-disable-next-line no-unused-vars
    isUpdateGroupView,
    listRoles
  } = props;

  const addEmptyItem = () => {
    setCurrentMembers([initialGroupMemberInput, ...currentMembers]);
  };

  React.useEffect(() => {
    const isThereEmptyRow = currentMembers?.find((item) => !item.user || item.user?.id === '');
    isThereEmptyRow ? setIsAddButtonDisabled(true) : setIsAddButtonDisabled(false);
  }, [currentMembers]);

  React.useEffect(() => {
    result && setCurrentMembers([...currentMembers, result]);
  }, [result]);

  // eslint-disable-next-line no-unused-vars
  const handleChangePage = (selectedPage: number) => {
    //getMemberPageFetch(PAGE_LEN, selectedPage);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteModalConfirm = () => {
    if (selectedMember?.status === memberStatus.ADDED) {
      let membersUpdated: any[] = [];
      currentMembers.forEach((item: IUserItem) => {
        if (item.uuid !== selectedMember?.uuid) membersUpdated = [...membersUpdated, item];
      });
      setCurrentMembers(membersUpdated);
    } else {
      deleteMember();
    }
    toggleDeleteModal();
  };

  const deleteMember = () => {
    deleteMemberFetch(selectedMember?.uuid);
    getGroupMembersFetch(groupInfo.id);
  };

  const handleOnClickDelete = (formRow: IUserItem) => {
    setSelectedMember(formRow);
    setShowDeleteModal(true);
  };

  const renderModal = () => (
    <>
      <CancelModal
        open={showDeleteModal}
        handleClose={toggleDeleteModal}
        onClickConfirm={toggleDeleteModal}
        onClickCancel={handleDeleteModalConfirm}
        messages={{
          TITLE: t('GROUPS.EDIT_GROUP.REMOVE_MEMBERS_MODAL.TITLE'),
          CONTINUE_BUTTON: t('GROUPS.EDIT_GROUP.REMOVE_MEMBERS_MODAL.BUTTONS.CANCEL'),
          CANCEL_BUTTON: t('GROUPS.EDIT_GROUP.REMOVE_MEMBERS_MODAL.BUTTONS.CONFIRM')
        }}
        img={CancelIllustration}
      />
    </>
  );

  // eslint-disable-next-line no-unused-vars
  const searchBar = (
    <Box py={3}>
      <Grid container>
        <Grid item xs={5}>
          <UserSelect
            className='text-search'
            setResult={setResult}
            setShowResult={setShowResult}
            filterUsersFetch={props.filterUsersFetch}
            filteredUsers={props.filteredUsers}
          />
        </Grid>
        <IconButton Icon={IconSearch} className='search-icon' onClick={() => result && setShowResult(true)} />
      </Grid>
    </Box>
  );

  /*
  const searchResults = (
    <>
      <Grid className='row' direction='row' container>
        <Grid
          item
          container
          xs={3}
          direction='row'
          alignItems='center'
          onClick={() => {
            setShowResult(false);
          }}
        >
          <IconButton Icon={goBackIcon} />
          <Typography variant='h6'>{t('COMMONS.BUTTONS.GOBACK')}</Typography>
        </Grid>
      </Grid>
      <Grid className='row' direction='row' container spacing={2}>
        <Grid item xs={12} sm={5} className='form-input'>
          <Input defaultValue={`${result?.first_name} ${result?.last_name}`} testId='userResult' disabled={true} />
        </Grid>
        <Grid item xs={12} sm={5} className='form-input'>
          <Input
            defaultValue={''}
            onChange={(value: string): void => console.log(value)}
            testId='role'
            required={true}
            select={true}
          >
            {listRoles?.map((rol) => (
              <MenuItem key={rol.id} value={rol.name}>
                {rol.name}
              </MenuItem>
            ))}
          </Input>
        </Grid>
        <Grid item container xs={12} sm={1} className='form-input' direction='column' alignContent='center'>
          <IconButton className='trash-icon' Icon={iconTrash} onClick={() => handleOnClickDelete(result)} />
        </Grid>
      </Grid>
    </>
  );
  */

  const handleNewMemberOnChange = (value: any, index: number, field: string) => {
    const prevCurrentMembers = [...currentMembers];
    const prevCurrentMember: any = prevCurrentMembers[index];
    prevCurrentMember[field] = value;
    setCurrentMembers(prevCurrentMembers);
  };

  const formRows = currentMembers?.map((formRow: any, i) => {
    return (
      <Grid className='row' direction='row' container spacing={2} key={i}>
        <Grid item xs={12} sm={5} className='form-input'>
          {formRow?.status === memberStatus.ADDED ? (
            <Autocomplete
              options={currentMembers}
              className={props.className}
              //onInputChange={(event, value) => handleInputChange(event, value)}
              getOptionLabel={(option: any) => `${option.first_name} ${option.last_name}`}
              onChange={(e, value) => handleNewMemberOnChange(value, i, 'user')}
              renderInput={(params) => <TextField {...params} variant='standard' />}
              noOptionsText={t('GROUPS.EDIT_GROUP.SELECT_USER.NO_RESULTS')}
            ></Autocomplete>
          ) : (
            <Input
              defaultValue={`${formRow.first_name} ${formRow.last_name}`}
              testId='user'
              required={true}
              disabled={true}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={5} className='form-input'>
          <Input
            defaultValue={formRow.roles && formRow.roles[0]?.role?.name}
            onChange={(value: string): void => handleNewMemberOnChange(value, i, 'rol')}
            testId='role'
            required={true}
            select={true}
            disabled={formRow.user?.id === ''}
          >
            {listRoles?.map((rol: any) => (
              <MenuItem key={rol.id} value={rol.name}>
                {rol.name}
              </MenuItem>
            ))}
          </Input>
        </Grid>
        <Grid item container xs={12} sm={1} className='form-input' direction='column' alignContent='center'>
          <IconButton className='trash-icon' Icon={iconTrash} onClick={() => handleOnClickDelete(formRow)} />
        </Grid>
      </Grid>
    );
  });

  const buttonAddMember = (
    <Grid container item xs={12} direction='row' justify='flex-start' alignItems='center'>
      <IconButton Icon={addIcon} onClick={() => addEmptyItem()} disabled={isAddButtonDisabled} />
      <Typography className='icon-label'>{t('GROUPS.ADD_MEMBER')} </Typography>
    </Grid>
  );

  // eslint-disable-next-line no-unused-vars
  const pagination = (
    <Grid container justify='center'>
      <Pagination
        size='large'
        count={Number(groupInfoStore.groupMembers.members?.total_pages)}
        page={Number(groupInfoStore.groupMembers.members?.page)}
        onChange={(e, selectedPage) => handleChangePage(selectedPage)}
      />
    </Grid>
  );

  const paged_members = () => {
    return (
      <>
        {formRows}
        {buttonAddMember}
        {/* {isUpdateGroupView && pagination} */}
      </>
    );
  };

  return (
    <div className={`${props.className} group-form`}>
      {/* {isUpdateGroupView && searchBar} */}
      <Grid direction='row' container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant='caption'>{t('GROUPS.MEMBERS')}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant='caption'>{t('GROUPS.ROL_REPORTS')}</Typography>
        </Grid>
        {paged_members()}
      </Grid>
      {renderModal()}
    </div>
  );
};

export const FormStep2 = styled(({ ...props }) => <CleanFormStep2 {...props} />)`
  .trash-icon,
  .search-icon {
    padding: 0;
  }
  .row {
    padding-top: 15px;
  }
  .text-search {
    width: 100%;
  }
  .search-icon {
    padding-left: 0.5em;
  }
`;
