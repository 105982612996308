import { ACTIONS } from '../../../../ActionTypes';
/* eslint-disable no-unused-vars*/
import {
  IBillingModeParams,
  IPaymentMethodParams,
  IPaymentConditionParams,
  ICommercialPlanParams,
  IAccesoryParams,
  IContactsByPartial
} from './models';
/*eslint-enable no-unused-vars */

//get Steps
export const getStepsFetch = (text: string) => ({
  type: ACTIONS.QUOTATIONS.STEPS.FETCH,
  payload: { params: text }
});

export const getStepsSuccess = (data: any) => ({
  type: ACTIONS.QUOTATIONS.STEPS.SUCCESS,
  payload: { data: data }
});

export const getStepsFail = (error: object) => ({
  type: ACTIONS.QUOTATIONS.STEPS.FAIL,
  payload: { error: error }
});
//get SourceList
export const getListSourceFetch = (params: any) => ({
  type: ACTIONS.QUOTATIONS.LISTSOURCES.FETCH,
  payload: { params }
});

export const getListSourceSuccess = (data: any) => ({
  type: ACTIONS.QUOTATIONS.LISTSOURCES.SUCCESS,
  payload: data
});

export const getListSourceFail = (error: object) => ({
  type: ACTIONS.QUOTATIONS.LISTSOURCES.FAIL,
  payload: { error: error }
});
//get SourceData
export const getDataSourceFetch = (params: any) => ({
  type: ACTIONS.QUOTATIONS.DATASOURCES.FETCH,
  payload: { params }
});

export const getDataSourceSuccess = (data: {}) => ({
  type: ACTIONS.QUOTATIONS.DATASOURCES.SUCCESS,
  payload: data
});

export const getDataSourceFail = (error: object) => ({
  type: ACTIONS.QUOTATIONS.DATASOURCES.FAIL,
  payload: { error: error }
});
//get Contacts by partial text
export const getContactsByPartial = (params: IContactsByPartial) => ({
  type: ACTIONS.QUOTATIONS.CONTACTS.FETCH,
  payload: { params }
});

export const getContactsSuccess = (data: any) => ({
  type: ACTIONS.QUOTATIONS.CONTACTS.SUCCESS,
  payload: data
});

export const getContactsFail = (error: object) => ({
  type: ACTIONS.QUOTATIONS.CONTACTS.FAIL,
  payload: { error: error }
});
export const getContactsClear = () => ({
  type: ACTIONS.QUOTATIONS.CONTACTS.CLEAR
});
//create contact
export const createContactFetch = (params: any) => ({
  type: ACTIONS.QUOTATIONS.NEW_CONTACT.FETCH,
  payload: { params }
});

export const createContactSuccess = (data: any) => ({
  type: ACTIONS.QUOTATIONS.NEW_CONTACT.SUCCESS,
  payload: data
});

export const createContactFail = (error: object) => ({
  type: ACTIONS.QUOTATIONS.NEW_CONTACT.FAIL,
  payload: { error: error }
});
//get plans
export const getPlansFetch = (params: any) => ({
  type: ACTIONS.QUOTATIONS.PLANS.FETCH,
  payload: { params }
});

export const getPlansSuccess = (data: any) => ({
  type: ACTIONS.QUOTATIONS.PLANS.SUCCESS,
  payload: data
});

export const getPlansFail = (error: object) => ({
  type: ACTIONS.QUOTATIONS.PLANS.FAIL,
  payload: { error: error }
});

//put dynamo_items from inputter into store
export const setItemsToStore = (data: any) => ({
  type: ACTIONS.QUOTATIONS.LISTSOURCES.SET_ITEMS_FROM_INPUTTER,
  payload: data
});

//get commercialPlan
export const getCommercialPlanFetch = (params: ICommercialPlanParams) => ({
  type: ACTIONS.QUOTATIONS.COMMERCIAL_PLAN.FETCH,
  payload: { params }
});

export const getCommercialPlanSuccess = (data: any) => ({
  type: ACTIONS.QUOTATIONS.COMMERCIAL_PLAN.SUCCESS,
  payload: data
});

export const getCommercialPlanFail = (error: object) => ({
  type: ACTIONS.QUOTATIONS.COMMERCIAL_PLAN.FAIL,
  payload: { error: error }
});

//get billingMode
export const getBillingModeFetch = (params: IBillingModeParams) => ({
  type: ACTIONS.QUOTATIONS.BILLING_MODE.FETCH,
  payload: { params }
});

export const getBillingModeSuccess = (data: any) => ({
  type: ACTIONS.QUOTATIONS.BILLING_MODE.SUCCESS,
  payload: data
});

export const getBillingModeFail = (error: object) => ({
  type: ACTIONS.QUOTATIONS.BILLING_MODE.FAIL,
  payload: { error: error }
});

//get paymentMethod
export const getPaymentMethodFetch = (params: IPaymentMethodParams) => ({
  type: ACTIONS.QUOTATIONS.PAYMENT_METHOD.FETCH,
  payload: { params }
});

export const getPaymentMethodSuccess = (data: any) => ({
  type: ACTIONS.QUOTATIONS.PAYMENT_METHOD.SUCCESS,
  payload: data
});

export const getPaymentMethodFail = (error: object) => ({
  type: ACTIONS.QUOTATIONS.PAYMENT_METHOD.FAIL,
  payload: { error: error }
});

//get paymentCondition
export const getPaymentConditionFetch = (params: IPaymentConditionParams) => ({
  type: ACTIONS.QUOTATIONS.PAYMENT_CONDITION.FETCH,
  payload: { params }
});

export const getPaymentConditionSuccess = (data: any) => ({
  type: ACTIONS.QUOTATIONS.PAYMENT_CONDITION.SUCCESS,
  payload: data
});

export const getPaymentConditionFail = (error: object) => ({
  type: ACTIONS.QUOTATIONS.PAYMENT_CONDITION.FAIL,
  payload: { error: error }
});

//update Quotation
export const updateQuotationFetch = (quotationId: string, params: any) => ({
  type: ACTIONS.QUOTATIONS.UPDATE.FETCH,
  payload: { quotationId: quotationId, params }
});

export const updateQuotationSuccess = (data: any) => ({
  type: ACTIONS.QUOTATIONS.UPDATE.SUCCESS,
  payload: data
});

export const updateQuotationFail = (error: object) => ({
  type: ACTIONS.QUOTATIONS.UPDATE.FAIL,
  payload: { error: error }
});

//get producer
export const getProducerFetch = () => ({
  type: ACTIONS.QUOTATIONS.PRODUCER.FETCH
});

export const getProducerSuccess = (data: any) => ({
  type: ACTIONS.QUOTATIONS.PRODUCER.SUCCESS,
  payload: data
});

export const getProducerFail = (error: object) => ({
  type: ACTIONS.QUOTATIONS.PRODUCER.FAIL,
  payload: { error: error }
});

//get accesory
export const getAccessoryFetch = (params: IAccesoryParams) => ({
  type: ACTIONS.QUOTATIONS.ACCESORY.FETCH,
  payload: { params }
});

export const getAccessorySuccess = (data: any) => ({
  type: ACTIONS.QUOTATIONS.ACCESORY.SUCCESS,
  payload: data
});

export const getAccessoryFail = (error: object) => ({
  type: ACTIONS.QUOTATIONS.ACCESORY.FAIL,
  payload: { error: error }
});

//cleanStore
export const cleanStore = () => ({
  type: ACTIONS.QUOTATIONS.CLEAN
});
