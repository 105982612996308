/* eslint-disable no-unused-vars */
export interface IBaseStore {
  groupInfo: {
    loading: boolean;
    error: boolean;
    message?: string[];
  };
  groupMembers: {
    loading: boolean;
    error: boolean;
    members: IDataMember | undefined;
  };
  deleteMember: {
    loading: boolean;
    error: boolean;
    message?: string[];
  };
}

export interface IDataMember {
  items: IUserItem[];
  page: number;
  page_len: number;
  current_page_items: number;
  total_items: number;
  total_pages: number;
}
export interface IUserItem {
  uuid: string;
  owner: boolean;
  user?: IUser;
  status?: string;
}

export enum memberStatus {
  ADDED = 'added',
  EDITED = 'edited',
  EMPTY = 'empty'
}

export interface IUser {
  id: string;
  first_name: string;
  last_name: string;
  collaborator_id: string;
  username: string;
  email: string;
  active: boolean;
  partner_id: string;
}
export interface InputDefaultFields {
  value: string | boolean;
  error: boolean;
  isRequired: boolean;
}

export interface IGroupItem {
  id: number;
  uuid: string;
  name: string;
  partner_id: string;
  description: string;
  parent_name?: string;
  parent_id?: number;
}

export interface GroupInfoForm {
  id: string;
  groupName: string;
  description: string;
}

export interface IGroupInfoForm {
  [field: string]: InputDefaultFields;
}

export interface IGroup {
  id: string | undefined;
  name: string;
}
export interface AddMemberReq {
  collaborator_uuid: string;
  group_uuid: string;
  owner: boolean;
}

export interface IformRow {
  uuid: string;
  owner: string;
  fixedId?: boolean;
}

export interface IGroupCreateParam {
  id_parent_group?: string | undefined;
  name: string;
  description: string;
}
