import { call, put, takeLatest, all } from 'redux-saga/effects';

import Api from './Api';
import ApiMock from './ApiMock';
import {
  getMySalesSuccess,
  getMySalesFail,
  getMySalesDashboardSuccess,
  getMySalesDashboardFail,
  getGroupsFail,
  getGroupsSuccess
} from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasGetMySalesFetch(action: actionType) {
  try {
    let response;
    if (action.payload && action.payload.nextPage) response = yield call(api.getMySalesFetch, action.payload.nextPage);
    else response = yield call(api.getMySalesFetch);
    yield put(getMySalesSuccess(response.data));
  } catch (e) {
    yield put(getMySalesFail(e));
  }
}

function* sagasGetMySalesDashboardFetch() {
  try {
    const response = yield call(api.getMySalesDashboardFetch);
    yield put(getMySalesDashboardSuccess(response.data));
  } catch (e) {
    yield put(getMySalesDashboardFail(e));
  }
}
function* sagasGetGroupsFetch() {
  try {
    const response = yield call(api.getGroupsFetch);
    yield put(getGroupsSuccess(response.data.data.groups));
  } catch (e) {
    yield put(getGroupsFail(e));
  }
}

// Listen for redux actions
function* mySalesSaga() {
  yield all([takeLatest(ACTIONS.MY_SALES.TABLE.FETCH, sagasGetMySalesFetch)]);
  yield all([takeLatest(ACTIONS.MY_SALES.TABLE.NEXT_PAGE, sagasGetMySalesFetch)]);
  yield all([takeLatest(ACTIONS.MY_SALES.DASHBOARD.FETCH, sagasGetMySalesDashboardFetch)]);
  yield all([takeLatest(ACTIONS.MY_SALES.GROUPS.FETCH, sagasGetGroupsFetch)]);
}

export { mySalesSaga };
