import React from 'react';
import {
  Container,
  Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  Box
} from '@material-ui/core';
import { TextPoint, StyledButton, Input, SuccessModal, CancelModal } from '@wdynamo/common';
import { Analytics } from '@wdynamo/common/lib/services';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import SuccessIllustration from '../../../../../assets/img/illustrations/SuccessIllustration.jpg';
import CancelIllustration from '../../../../../assets/img/illustrations/CancelIllustration.jpg';
// eslint-disable-next-line no-unused-vars
import { IBaseStore, RoleInfoForm } from './models';
import styled from 'styled-components';
import { uris } from '../../../../../siteMap';

interface locationStateType {
  role: any;
}

interface RolesInfoFunctionalComponentProps {
  roleInfoStore: IBaseStore;
  getPermissionsFetch(): void;
  className?: string;
  roleInfoAddFetch(data: RoleInfoForm): void;
  roleInfoUpdateFetch(id: string, data: RoleInfoForm): void;
}

const initialRoleInfoForm: any = {
  // couldn't use RoleInfoForm
  name: { value: '', error: false, isRequired: true },
  permissions: { value: [], error: false, isRequired: true },
  description: { value: '', error: false, isRequired: false }
};

const CleanRoleInfoComponent: React.FC<RolesInfoFunctionalComponentProps> = (
  props: RolesInfoFunctionalComponentProps
) => {
  const analytics = Analytics();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const { roleInfoStore } = props;
  const [isUpdateRoleView, setIsUpdateRoleView] = React.useState<boolean>(false);
  const location = useLocation<locationStateType>();
  const { t } = useTranslation();
  const [roleInfoForm, setRoleInfoForm] = React.useState(initialRoleInfoForm);
  const [permissions, setPermissions] = React.useState<any>();
  const [showSuccessModal, setShowSuccessModal] = React.useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false);
  const [isValid, setIsValid] = React.useState<boolean>(true);

  React.useEffect(() => {
    analytics.pageview('role-info');
    if (location.state && location.state.role) {
      setIsUpdateRoleView(true);
      setRoleInfoForm((prevState: any) => {
        let newState = initialRoleInfoForm;
        Object.keys(prevState).forEach((input) => {
          newState = {
            ...newState,
            [input]: {
              ...newState[input],
              value: location.state.role[input]
            }
          };
        });
        return newState;
      });
    }
  }, []);

  const parsePermissions = () => {
    const permissionsObject: any = {};
    roleInfoStore?.permissions?.data?.map((storePermission: any) => {
      permissionsObject[storePermission.permission] = {
        name: storePermission.permission,
        id: storePermission.id,
        checked: false,
        label: storePermission.description
      };
    });
    if (isUpdateRoleView) {
      roleInfoForm.permissions.value.forEach((permissionName: any) => {
        const currentPermission = permissionsObject[permissionName];
        if (currentPermission) currentPermission.checked = true;
      });
    }
    setPermissions(permissionsObject);
  };

  React.useEffect(() => {
    if (keycloak?.authenticated) {
      setTimeout(() => props.getPermissionsFetch());
    }
  }, [keycloak?.authenticated]);

  React.useEffect(() => {
    parsePermissions();
  }, [roleInfoStore?.permissions.data]);

  // better validation
  React.useEffect(() => {
    if (roleInfoForm.name.value !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [roleInfoForm.name.value]);

  const handleOnChange = (fieldToUpdate: any, value: string | boolean) =>
    setRoleInfoForm({
      ...roleInfoForm,
      [fieldToUpdate]: { ...roleInfoForm[fieldToUpdate], value: value, error: false }
    });

  const handleCancelModalConfirm = () => {
    setRoleInfoForm(initialRoleInfoForm);
    history.push(uris.roles.uri);
  };

  const toggleSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
  };

  const toggleCancelModal = () => {
    setShowCancelModal(!showCancelModal);
  };

  const handleSuccessModalConfirm = () => {
    toggleSuccessModal();
  };

  const emtpyForm = () => {
    setRoleInfoForm(initialRoleInfoForm);
    handleUncheckAll();
  };

  const handleCreateUpdate = () => {
    if (isValid) {
      const checkedPermissions: any = Object.values(permissions)
        .filter((perm: any) => perm.checked === true)
        .map((perm: any) => perm.id);
      const data = {
        name: roleInfoForm.name.value,
        description: roleInfoForm.description.value,
        permissions_list: checkedPermissions
      };
      if (isUpdateRoleView) {
        props.roleInfoUpdateFetch(location.state.role.id, data);
      } else {
        props.roleInfoAddFetch(data);
      }
      emtpyForm();
      toggleSuccessModal();
      setTimeout(() => {
        history.push(uris.roles.uri);
      }, 2000);
    }
  };

  const renderModal = () => (
    <>
      <CancelModal
        open={showCancelModal}
        handleClose={toggleCancelModal}
        onClickConfirm={toggleCancelModal}
        onClickCancel={handleCancelModalConfirm}
        messages={{
          TITLE: t('COMMONS.MODALS.CANCEL.TITLE'),
          CANCEL_BUTTON: t('COMMONS.MODALS.CANCEL.CANCEL'),
          CONTINUE_BUTTON: t('COMMONS.MODALS.CANCEL.CONTINUE')
        }}
        img={CancelIllustration}
      />
      <SuccessModal
        open={showSuccessModal}
        handleClose={toggleSuccessModal}
        onClickConfirm={handleSuccessModalConfirm}
        description={''}
        img={SuccessIllustration}
        messages={{
          TITLE: isUpdateRoleView
            ? t('USERS.ROLES.ROLE_INFO.SUCCESSFUL_UPDATE')
            : t('USERS.ROLES.ROLE_INFO.SUCCESSFUL_CREATION'),
          CONTINUE_BUTTON: t('COMMONS.BUTTONS.CONTINUE')
        }}
      />
    </>
  );

  const handlePermissionsChange = (event: any) => {
    setPermissions({
      ...permissions,
      [event.target.name]: { ...permissions[event.target.name], checked: event.target.checked }
    });
  };

  const handleCheckAll = () => {
    const selected: any = {};
    Object.values(permissions).forEach((permission: any) => {
      selected[permission.name] = { ...permission, checked: true };
    });
    setPermissions(selected);
  };
  const handleUncheckAll = () => {
    const selected: any = {};
    Object.values(permissions).forEach((permission: any) => {
      selected[permission.name] = { ...permission, checked: false };
    });
    setPermissions(selected);
  };

  return (
    <Container className={props.className || 'role-info'} maxWidth='md'>
      {renderModal()}
      <Grid>
        <h2>
          {isUpdateRoleView ? t('USERS.ROLES.ROLE_INFO.UPDATE_ROLE') : t('USERS.ROLES.ROLE_INFO.NEW_ROLE')}
          <TextPoint />
        </h2>
      </Grid>
      <Grid xs={6} className={'role-name'}>
        <Input
          label={t('USERS.ROLES.NAME')}
          required={true}
          defaultValue={roleInfoForm.name.value as string}
          onChange={(value: string) => {
            handleOnChange('name', value);
          }}
        />
      </Grid>
      <Grid xs={12} className={'role-desc'}>
        <Input
          label={t('USERS.ROLES.DESCRIPTION')}
          defaultValue={roleInfoForm.description.value as string}
          onChange={(value: string) => {
            handleOnChange('description', value);
          }}
        />
      </Grid>
      <Grid className={'role-permissions'} xs={12}>
        <FormControl component='fieldset'>
          <Typography component='legend'>{t('USERS.ROLES.PERMISSIONS')}</Typography>
          <FormGroup>
            <Grid container>
              {permissions &&
                Object.values(permissions).map((permission: any, key) => (
                  <Grid item md={6} key={key}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={'permissions'}
                          checked={permission.checked}
                          onChange={handlePermissionsChange}
                          name={permission.name}
                        />
                      }
                      label={permission.label}
                    />
                  </Grid>
                ))}
            </Grid>
            <Box paddingTop={4}>
              <Grid direction='row' justify='flex-start' container spacing={2}>
                <Grid item>
                  <StyledButton onClick={handleUncheckAll} className='secondary'>
                    {t('USERS.ROLES.CLEAR')}
                  </StyledButton>
                </Grid>

                <Grid item>
                  <StyledButton onClick={handleCheckAll} className='default'>
                    {t('USERS.ROLES.SELECT_ALL')}
                  </StyledButton>
                </Grid>
              </Grid>
            </Box>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid className={'confirm-grid'}>
        <Grid direction='row' justify={'center'} container spacing={2}>
          <Grid item>
            <StyledButton
              className='secondary'
              onClick={() => {
                isUpdateRoleView ? toggleCancelModal() : history.push(uris.roles.uri);
              }}
            >
              {t('COMMONS.BUTTONS.CANCEL')}
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton className='default' disabled={!isValid} onClick={handleCreateUpdate}>
              {isUpdateRoleView ? t('COMMONS.BUTTONS.FINISH') : t('COMMONS.BUTTONS.CREATE')}
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export const RoleInfoComponent = styled(({ ...props }) => <CleanRoleInfoComponent {...props} />)`
  .role-name {
    padding-bottom: 20px;
  }

  .role-desc {
    padding-bottom: 60px;
  }

  .role-permissions {
    padding-bottom: 20px;
  }

  .confirm-grid {
    text-align: center;
    padding: 30px;
  }

  .permissions {
    font-weight: bold;
  }
`;
