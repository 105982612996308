import { Container, Switch, Typography, Box } from '@material-ui/core';
import React from 'react';
import { CheckBoxList } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { Analytics } from '@wdynamo/common/lib/services';

interface IPaymentsFormComponentProps {
  service: any;
  onChange(service: any): void;
  paymentsFormStore: any;
  getPaymentMethodsFetch(): void;
  getSaleChannelsFetch(): void;
}

export const PaymentsFormComponent: React.FC<IPaymentsFormComponentProps> = (props: IPaymentsFormComponentProps) => {
  const { getPaymentMethodsFetch, getSaleChannelsFetch, service, onChange } = props;
  const { paymentMethodsList, salesChannelsList } = props.paymentsFormStore.data;
  const { t } = useTranslation();

  React.useEffect(() => {
    getPaymentMethodsFetch();
    getSaleChannelsFetch();
    Analytics().pageview('update-service | payments');
  }, []);

  const handleOnChange = (field: string, value: any) => {
    const updatedState = { ...service };
    const currentField = updatedState[field];
    currentField.value = value;
    onChange(updatedState);
  };

  return (
    <Container maxWidth='sm'>
      <CheckBoxList
        title={'Medios de Pago'}
        list={paymentMethodsList}
        listChecked={service.paymentMethods.value || []}
        setListChecked={(newList: any) => {
          handleOnChange('paymentMethods', newList);
        }}
      />
      <CheckBoxList
        title={'Canales de Venta'}
        list={salesChannelsList}
        listChecked={service.salesChannels.value || []}
        setListChecked={(newList: any) => {
          handleOnChange('salesChannels', newList);
        }}
      />
      <Box py={2}>
        <Typography>{t('SERVICES.UPDATE.PAYMENTS.SERVICE_STATUS')}</Typography>
        <Switch
          checked={service.serviceState?.value}
          onChange={(): void => {
            handleOnChange('serviceState', !service.serviceState?.value);
          }}
          required={true}
          color='primary'
        />
        {service.serviceState?.value ? t('COMMONS.ACTIVE') : t('COMMONS.INACTIVE')}
      </Box>
    </Container>
  );
};
