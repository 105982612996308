import { PlansComponent } from './Plans';
import { connect } from 'react-redux';
import {
  getCommercialPlanFetch,
  getBillingModeFetch,
  getPaymentMethodFetch,
  getPaymentConditionFetch
} from '../actions';

const mapStateToProps = (state: any) => ({
  stepsStore: state.quotationState
});

const mapDispatchToProps = {
  getCommercialPlanFetch,
  getBillingModeFetch,
  getPaymentMethodFetch,
  getPaymentConditionFetch
};

export const Plans = connect(mapStateToProps, mapDispatchToProps)(PlansComponent);
