import { connect } from 'react-redux';
import { MySalesComponent } from './view';
import { getMySalesFetch, getMySalesNextPageFetch, getMySalesDashboardFetch, getGroupsFetch } from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  mySalesStore: state.mySalesState
});
const mapDispatchToProps = { getMySalesFetch, getMySalesNextPageFetch, getMySalesDashboardFetch, getGroupsFetch };

export const MySales = connect(mapStateToProps, mapDispatchToProps)(MySalesComponent);
export { mySalesSaga } from './sagas';
export { mySalesReducer } from './reducers';
export type { IMySalesProps } from './models';
