import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Analytics, substractTime, TextPoint, Loading, CustomTooltip, IconButton } from '@wdynamo/common';
import { IReportsProps, OrderStatus } from './models'; // eslint-disable-line no-unused-vars
import { SalesChart } from './components/Chart';
import { ReportsTabs } from './components/Tabs';
import { OrdersTable } from './components/Table';
import { Grid, Button } from '@material-ui/core';
import moment from 'moment';
import iconExcel from '../../assets/img/icons_dynamo/icon_excel.svg';
import { CSVLink } from 'react-csv'; // eslint-disable-line
import { ReactComponent as IconFilter } from '../../assets/img/icons_dynamo/icon_filter.svg';

import { DateFilterPopper } from './components/DateFilterPopper';

interface ReportsComponentProps {
  reportsStore: IReportsProps;
  getOrdersGroupedByDateFetch(from_date: string, to_date: string, include_children: boolean): void;
  getTopProductsFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    items_limit: number
  ): void;
  getTopSalesTeamsFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    items_limit: number
  ): void;
  getTopSalesmenFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    items_limit: number
  ): void;
  getOrdersFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    order_status?: string,
    pagelen?: number
  ): void;
  getOrdersToExportFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    pagelen?: number,
    order_status?: string,
    toExport?: boolean
  ): void;
  getOrdersNextPageFetch(nextPage: string): void;
}

const CleanReportsComponent: React.FC<ReportsComponentProps> = (props: ReportsComponentProps) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  const { getOrdersGroupedByDateFetch, getOrdersFetch, getTopProductsFetch, getOrdersToExportFetch } = props;
  const { ordersGroupedByDate, products, salesTeams, salesmen, orders } = props.reportsStore;
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [csvData, setCsvData] = useState<any>();
  const csvInstance = useRef<any>();

  useEffect(() => {
    Analytics().pageview('reports');
  }, []);

  useEffect(() => {
    setFirstLoading(false);
  }, [props.reportsStore]);

  //@TODO: this is a workaround because the react-csv isn't woking properyly.
  useEffect(() => {
    if (csvData && csvData.length > 0 && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(null);
        // setAnchorEl(null);
      });
    }
  }, [csvData]);

  useEffect(() => {
    setCsvData(orders.ordersToExport);
  }, [orders.ordersToExport]);

  useEffect(() => {
    if (keycloak?.authenticated) {
      setTimeout(() => {
        const to_date = moment().toISOString();
        const from_date = moment(substractTime(to_date, 'months', 6)).toISOString();
        getOrdersGroupedByDateFetch(from_date, to_date, true);
        getOrdersFetch(from_date, to_date, true, '-created_at');
        getTopProductsFetch(from_date, to_date, true, '-sales_amount', 4);
      });
    }
  }, [keycloak?.authenticated]);
  const generateDataToExport = (dateFrom: string, dateTo: string, filterOrderStatus: OrderStatus) => {
    getOrdersToExportFetch(
      moment(dateFrom, 'DD/MM/YYYY').toISOString(),
      moment(dateTo, 'DD/MM/YYYY').toISOString(),
      true,
      '-created_at',
      undefined,
      filterOrderStatus === OrderStatus.NONE ? undefined : filterOrderStatus
    );
  };

  const getOrdersFiltered = (dateFrom: string, dateTo: string, filterOrderStatus: OrderStatus) => {
    props.getOrdersFetch(
      moment(dateFrom).toISOString(),
      moment(dateTo).toISOString(),
      true,
      '-created_at',
      filterOrderStatus === OrderStatus.NONE ? undefined : filterOrderStatus
    );
  };

  const togglePopper = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const togglePopperFilter = (event: any) => {
    setAnchorElFilter(anchorElFilter ? null : event.currentTarget);
  };

  return (
    <Grid container>
      <Grid container justify='space-between' alignItems='flex-end'>
        <Grid item>
          <h1>
            {t('REPORTS.TITLE')}
            <TextPoint />
          </h1>
        </Grid>
        <Grid item>
          {csvData ? (
            <CSVLink
              data={csvData}
              filename={`WDynamo_Orders_Report_${moment().format('YYYYMMDD').toString()}.csv`}
              ref={csvInstance}
            />
          ) : undefined}
          <CustomTooltip title={t('REPORTS.EXPORT.CSV_BUTTON') as string} arrow placement='bottom'>
            <Button>
              {orders.loading ? (
                <Loading />
              ) : (
                <>
                  <img src={iconExcel} onClick={togglePopper} alt='' />
                  <DateFilterPopper
                    anchorEl={anchorEl}
                    actionTitle={t('REPORTS.EXPORT.BUTTON')}
                    onClick={(dateFrom: string, dateTo: string, orderStatus: OrderStatus) => {
                      generateDataToExport(dateFrom, dateTo, orderStatus);
                    }}
                    toggleOpen={togglePopper}
                  />
                </>
              )}
            </Button>
          </CustomTooltip>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <h3>
            {t('REPORTS.CHART.TITLE')}
            <TextPoint />
          </h3>
          <SalesChart ordersGroupedByDate={ordersGroupedByDate} />
        </Grid>
        <Grid item xs={12} md={6}>
          <h3>
            {t('REPORTS.TABS.TITLE')}
            <TextPoint />
          </h3>
          <ReportsTabs
            products={products}
            salesTeams={salesTeams}
            salesmen={salesmen}
            getTopProductsFetch={props.getTopProductsFetch}
            getTopSalesTeamsFetch={props.getTopSalesTeamsFetch}
            getTopSalesmenFetch={props.getTopSalesmenFetch}
          />
        </Grid>
        <Grid container>
          <Grid item xs={11}>
            <h3>
              {t('REPORTS.TABLE.TITLE')}
              <TextPoint />
            </h3>
          </Grid>
          <Grid item xs={1} onClick={togglePopperFilter}>
            <div style={{ paddingTop: '20px', padding: '10px' }}>
              <IconButton Icon={IconFilter} />
            </div>
            <DateFilterPopper
              actionTitle={t('REPORTS.EXPORT.FILTER')}
              anchorEl={anchorElFilter}
              toggleOpen={togglePopperFilter}
              onClick={(dateFrom: string, dateTo: string, orderStatus: OrderStatus) => {
                getOrdersFiltered(dateFrom, dateTo, orderStatus);
              }}
            />
          </Grid>
          <OrdersTable orders={orders} firstLoading={firstLoading} paginate={props.getOrdersNextPageFetch} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ReportsComponent = (props: ReportsComponentProps) => <CleanReportsComponent {...props} />;
