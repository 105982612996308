import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
import {
  Drawer,
  AppBar,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Grid,
  Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactComponent as ServiceIcon } from '../../assets/img/icons_dynamo/icon_service.svg';
import { ReactComponent as ContactIcon } from '../../assets/img/icons_dynamo/icon_contact.svg';
import { ReactComponent as MarketCarIcon } from '../../assets/img/icons_dynamo/icon_market_car.svg';
import { ReactComponent as SalesIcon } from '../../assets/img/icons_dynamo/icon_sales.svg';
import { ReactComponent as DashboardIcon } from '../../assets/img/icons_dynamo/icon_dashboard.svg';
import { ReactComponent as UsersIcon } from '../../assets/img/icons_dynamo/icon_users.svg';
import { ReactComponent as SettingIcon } from '../../assets/img/icons_dynamo/icon_setting.svg';

// eslint-disable-next-line no-unused-vars
import { SiteMap } from '../../../../common/lib/models';

interface SidebarProps {
  logoContent?: React.ReactNode;
  logoutContent?: React.ReactNode;
  hideSidebar?: boolean;
  className?: string;
  fastLinks?: SiteMap[];
  children?: React.ReactNode;
}

/**
 * CleanHeader.
 *
 * @param {SidebarProps} props header's props
 * @returns {React.FC<SidebarProps>} CleanHeader.
 */

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    link: {
      textDecoration: 'none'
    },
    active: {
      backgroundColor: 'var(--color-main)',
      color: 'var(--color-white)'
    },
    inactive: {
      color: 'var(--color-grey-text)'
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    toolbar: {
      minHeight: 85
    },
    drawerPaper: {
      width: drawerWidth,
      height: '100%',
      backgroundColor: 'var(--color-grey-background)'
    },
    content: {
      flexGrow: 1
    }
  })
);

export const CleanSidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();

  const checkIfUriIsSelected = (uri: string) => location.pathname === uri;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSelectIcon = (uri: string) => {
    const iconSize = '24';
    const iconClass = checkIfUriIsSelected(uri) ? 'seleted-icon' : 'unselected-icon';
    switch (uri) {
      case '/services':
        return <ServiceIcon className={iconClass} width={iconSize} height={iconSize} />;
      case '/my-sales':
        return <SalesIcon className={iconClass} width={iconSize} height={iconSize} />;
      case '/contacts':
        return <ContactIcon className={iconClass} width={iconSize} height={iconSize} />;
      case '/quotes':
        return <MarketCarIcon className={iconClass} width={iconSize} height={iconSize} />;
      case '/sales':
        return <SalesIcon className={iconClass} width={iconSize} height={iconSize} />;
      case '/reports':
        return <DashboardIcon className={iconClass} width={iconSize} height={iconSize} />;
      case '/users':
        return <UsersIcon className={iconClass} width={iconSize} height={iconSize} />;
      case '/settings':
        return <SettingIcon className={iconClass} width={iconSize} height={iconSize} />;
      default:
        return <ServiceIcon className={iconClass} width={iconSize} height={iconSize} />;
    }
  };

  const mobileDrawer = (
    <>
      <Grid container direction='column' justifyContent='space-between' style={{ height: '100vh' }}>
        <div>
          <List>
            {props.fastLinks?.map((fastLink: SiteMap) => (
              <Link className={classes.link} to={fastLink.uri} key={fastLink.uri}>
                <ListItem className={checkIfUriIsSelected(fastLink.uri) ? classes.active : classes.inactive} button>
                  <ListItemIcon>{handleSelectIcon(fastLink.uri)}</ListItemIcon>
                  <ListItemText disableTypography style={{ fontSize: '20px' }} primary={fastLink.label} />
                </ListItem>
              </Link>
            ))}
          </List>
        </div>
        {props.logoutContent}
      </Grid>
    </>
  );

  const drawer = (
    <Grid container direction='column' justifyContent='space-between' style={{ height: '100%' }}>
      <div>
        <div id='sidebar-logo'>{props.logoContent}</div>
        <List>
          {props.fastLinks?.map((fastLink: SiteMap) => (
            <Link
              className={classes.link}
              to={{ pathname: fastLink.uri, state: 'sideBar' /*location.pathname*/ }}
              key={fastLink.uri}
            >
              <ListItem className={checkIfUriIsSelected(fastLink.uri) ? classes.active : classes.inactive} button>
                <ListItemIcon>{handleSelectIcon(fastLink.uri)}</ListItemIcon>
                <ListItemText disableTypography style={{ fontSize: '20px' }} primary={fastLink.label} />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
      {props.logoutContent}
    </Grid>
  );

  return props.hideSidebar ? (
    <main className={classes.content}>{props.children}</main>
  ) : (
    <div className={`${classes.root} ${props.className}`}>
      <AppBar position='fixed' className={classes.appBar}>
        <Hidden smUp implementation='css'>
          <Toolbar style={{ backgroundColor: 'var(--color-grey-background)' }}>
            <IconButton
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Grid container justifyContent='center'>
              {props.logoContent}
            </Grid>
          </Toolbar>
        </Hidden>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            {mobileDrawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant='permanent'
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Hidden smUp implementation='css'>
          <div className={classes.toolbar} />
        </Hidden>
        {props.children}
      </main>
    </div>
  );
};

export const Sidebar = styled(({ ...props }) => <CleanSidebar {...props} />)`
  .seleted-icon > path {
    fill: var(--color-white);
  }
  .unselected-icon > path {
    fill: var(--color-grey-text);
  }
`;
