import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IconButton, Loading } from '@wdynamo/common';
import { Analytics } from '@wdynamo/common/lib/services';
import { uris } from '../../../../siteMap';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconArrowLeft } from '../../../../assets/img/icons_dynamo/arrow_left.svg';
import { ReactComponent as PlayIcon } from '../../../../assets/img/icons_dynamo/icon_more_info.svg';
import { ReactComponent as RejectIcon } from '../../../../assets/img/icons_dynamo/icon_cross_reject.svg';
import { ReactComponent as EditIcon } from '../../../../assets/img/icons_dynamo/icon_edit_new.svg';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
// eslint-disable-next-line no-unused-vars
import { IContact } from '../../models';
import { ENCRYPTION_KEY } from '../..';

interface locationStateType {
  contact: any;
  pathname: string;
  search: string;
  hash: string;
  key: string;
}

interface contactType {
  dni?: any;
  last_contact: any;
  name: string;
  last_name: string;
  phone: any;
  mail: string;
}

interface CleanContactDetailsProps {
  contactDetailsStore: any;
  contactDetailsFetch: (id: any) => {};
  className?: string;
  changeStatusFetch: (params: any) => {};
}

const CleanContactDetailsComponent: React.FC<CleanContactDetailsProps> = (props: CleanContactDetailsProps) => {
  const { contactDetailsStore, contactDetailsFetch, changeStatusFetch } = props;
  const { t } = useTranslation();
  //const location = useLocation<locationStateType>();
  // eslint-disable-next-line no-unused-vars
  const [contact, setContact] = useState<contactType>();
  const history = useHistory();
  const [hasContact, setHasContact] = useState<boolean>(false);
  const [data, setData] = useState<IContact>();

  useEffect(() => {
    Analytics().pageview('contact-details');

    const rawData = sessionStorage.getItem('contact') || '';
    if (rawData) {
      const decrypted = CryptoAES.decrypt(rawData, ENCRYPTION_KEY);
      const decryptedData: IContact = JSON.parse(decrypted.toString(CryptoENC));

      setData(decryptedData);
      contactDetailsFetch(decryptedData?.id);
      setHasContact(true);
      setContact(contact);
    }
  }, []);

  const getFormattedDate = (transactionDate: string) => {
    const formatedDate = new Date(transactionDate);
    const splittedDate = formatedDate.toISOString().split('T')[0];
    const [year, month, day] = splittedDate.split('-') as unknown[] as number[];
    return `${day}-${month}-${year}`;
  };
  const handleClickPlayIcon = (budget: any) => {
    const quotationInfo = {
      service: {
        product: budget.product,
        branch: budget.branch,
        policytype: budget.policiesType,
        id: budget.idProduct
      },
      //requote: budget.customer
      requote: {
        customer: {
          id: data?.id,
          name: data?.name,
          last_name: data?.last_name,
          mail: data?.mail,
          phone: data?.phone,
          insuranceType: ''
        }
      }
    };
    history.push(uris.quotationsAuto.uri, { ...quotationInfo });
    //if (!location?.state?.service?.id && !location?.state?.requote?.customer?.id) history.push(uris?.services.uri);
  };
  const handleClickReject = (budget: any) => {
    const params = { id: data?.id, idProduct: budget.idProduct };
    changeStatusFetch(params);
  };
  const handleClickEdit = () => {
    history.push(uris.contactInfo.uri);
  };
  const handleUndefinedString = (value: string | undefined) => {
    if (value) return value;
    return '';
  };

  const renderBudgetHistory = () => (
    <Grid container xs={12}>
      <>
        <Grid item xs={12}>
          <h3 className='quotation-history'>
            {t('CONTACTS.CONTACT_DETAILS.QUOTATION_HISTORY')}
            <span>:</span>
          </h3>
        </Grid>
        <Grid item xs={12}>
          <Grid container className='contact-grid'>
            <Grid item xs={12} className='header'>
              <p>
                {t('CONTACTS.CONTACT_DETAILS.NEW_QUOTATION')}{' '}
                <a className='link' onClick={() => history.push(uris.services.uri)}>
                  {t('CONTACTS.CONTACT_DETAILS.NEW_QUOTATION_LINK')}
                </a>
              </p>
              <Grid container>
                <Grid item xs={2}>
                  {t('CONTACTS.CONTACT_DETAILS.DATE')}
                </Grid>
                <Grid item xs={3}>
                  {t('CONTACTS.CONTACT_DETAILS.PRODUCT')}
                </Grid>
                <Grid item xs={5}>
                  {t('CONTACTS.CONTACT_DETAILS.STATUS')}
                </Grid>
                <Grid className='icon-grid' item xs={1}>
                  {t('CONTACTS.CONTACT_DETAILS.BUTTON_REJECT')}
                </Grid>
                <Grid className='icon-grid' item xs={1}>
                  {t('CONTACTS.CONTACT_DETAILS.CONTINUE_QUOTATION_ISSUE')}
                </Grid>
              </Grid>
            </Grid>
            {!contactDetailsStore.budgets ? (
              <Loading />
            ) : contactDetailsStore?.budgets.length ? (
              contactDetailsStore?.budgets
                ?.sort(function (c: any, b: any) {
                  if (c.product < b.product) {
                    return -1;
                  }
                  if (c.product > c.product) {
                    return 1;
                  }
                  return 0;
                })
                .map((budget: any, key: number) => {
                  return (
                    <Grid item xs={12} className='content' key={key}>
                      <Grid container className='budget'>
                        <Grid item xs={2}>
                          {budget?.date && getFormattedDate(budget?.date?.toString())}
                        </Grid>
                        <Grid item xs={3}>
                          {budget?.product}
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          className={
                            budget?.state === 'Cotizado'
                              ? 'colorGreen'
                              : budget?.state === 'Rechazado'
                              ? 'colorRed'
                              : 'colorGrey'
                          }
                        >
                          {budget?.state}
                        </Grid>
                        <Grid className='icon-grid' item xs={1}>
                          <RejectIcon
                            className={
                              budget?.state === 'Cotizado' || budget?.state === 'Rechazado' ? 'button-disabled' : ''
                            }
                            onClick={() => handleClickReject(budget)}
                          />
                        </Grid>
                        <Grid className='icon-grid' item xs={1}>
                          <PlayIcon
                            className={budget?.state === 'Cotizado' ? 'button-disabled' : ''}
                            onClick={() => handleClickPlayIcon(budget)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })
            ) : (
              <p>{t('CONTACTS.CONTACT_DETAILS.NO_PRODUCTS')}</p>
            )}
          </Grid>
        </Grid>
      </>
    </Grid>
  );

  const renderContactTable = () => (
    <Grid item className='header-main-container'>
      <Grid container className='contact-grid'>
        <Grid item xs={12} className='header'>
          <Grid container>
            <Grid item xs={3}>
              {t('CONTACTS.CONTACT_DETAILS.PERSON_IN_CHARGE')}
            </Grid>
            <Grid item xs={3}>
              {t('CONTACTS.CONTACT_DETAILS.NAME_LASTNAME')}
            </Grid>
            <Grid item xs={2}>
              {t('CONTACTS.CONTACT_DETAILS.PHONE')}
            </Grid>
            <Grid item xs={2}>
              {t('CONTACTS.CONTACT_DETAILS.EMAIL')}
            </Grid>
            <Grid item xs={2}>
              {t('CONTACTS.CONTACT_DETAILS.CHANNEL')}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className='content'>
          <Grid onClick={() => {}} container className='contact'>
            <Grid item xs={3}>
              {`${handleUndefinedString(data?.responsible?.first_name)} ${handleUndefinedString(
                data?.responsible?.last_name
              )}`}
            </Grid>
            <Grid item xs={3}>
              {`${handleUndefinedString(data?.name)} ${handleUndefinedString(data?.last_name)}`}
            </Grid>
            <Grid item xs={2}>
              {data?.phone}
            </Grid>
            <Grid item xs={2}>
              {data?.mail}
            </Grid>
            <Grid item xs={2}>
              {'Experta Basa'}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <div className={`${props.className}`}>
      <Grid container>
        <Grid
          item
          container
          xs={9}
          direction='row'
          alignItems='center'
          onClick={() => history.push(uris.contacts.uri)}
          className='go-back'
        >
          <IconArrowLeft className='icon' onClick={() => history.push(uris.contacts.uri)} />
          {/*  <Grid
          item
          container
          xs={1}
          direction='row'
          alignItems='center'
          onClick={() => history.push(uris.contacts.uri)}
          className='go-back'
      >
        <IconButton Icon={goBackIcon} />*/}

          <h3 className='main-title'>{t('CONTACTS.CONTACT_DETAILS.TITLE')}</h3>
        </Grid>
        <Grid item xs={3}>
          <div className='action-buttons'>
            {t('CONTACTS.CONTACT_DETAILS.EDIT_CONTACT')}
            <IconButton Icon={EditIcon} onClick={handleClickEdit} />
          </div>
        </Grid>

        {hasContact ? (
          [renderContactTable(), renderBudgetHistory()]
        ) : (
          <div> {t('CONTACTS.CONTACT_DETAILS.NO_CONTACT')}</div>
        )}
      </Grid>
    </div>
  );
};

export const ContactDetailsComponent = styled(({ ...props }) => <CleanContactDetailsComponent {...props} />)`
  .header-main-container {
    width: 100%;
    margin-top: 2rem;
  }
  .colorGreen {
    color: #3fa957;
  }
  .colorRed {
    color: #ec4b3f;
  }
  .colorGrey {
    color: #b1acac;
  }
  .quotation-history {
    font-size: 1.25rem;
    font-weight: 200;
    color: var(--color-black);
  }
  .main-title {
    font-size: 1.75rem;
    margin-left: 1rem;
  }
  .link {
    text-decoration: underline;
    color: var(--color-main);
    cursor: pointer;
  }
  .button-disabled {
    pointer-events: none;

    /* for "disabled" effect */
    opacity: 0.5;
    background: #fff;
  }
  .go-back {
    padding-right: 1rem;
  }
  .icon {
    fill: var(--color-main);

    cursor: pointer;
  }
  .cart-icon {
    text-align: center;
  }
  .colon {
    color: var(--color-main);
  }
  .trash-icon {
    fill: black;
  }
  .action-buttons {
    padding-top: 20px;
    text-align: right;
  }
  .cart {
    text-align: right;
  }
  .icon-grid {
    text-align: center;
  }
  .contact-grid {
    .header {
      border: none;
      font-weight: bold;
      text-align: left;
      div {
        padding: 5px 0;
      }
    }
    .content {
      .budget {
        padding: 10px 0;
        cursor: pointer;
        border-bottom: 1px solid var(--color-grey-border);
      }
      .contact {
        padding: 10px 0;
        cursor: pointer;
        g.main-color {
          fill: red;
        }
      }
    }
  }
`;
