import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { IUserParams } from './models';

const Api = () => {
  const getUsersFetch = (): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_KAISER_V3_BASE_URL}/users?usersPerPage=200`, {
      headers: { Origin: window.location.origin }
    });

  const getFilteredUsersByTextFetch = (text: string): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_KAISER_V3_BASE_URL}/users/group/search`, {
      params: { search: text }
    });

  return { getUsersFetch, getFilteredUsersByTextFetch };
};

export default Api;
