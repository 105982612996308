const handleCharacteristics = (item: string) => {
  const formatPrice = (number: number): string => {
    return number.toLocaleString('es-AR', { maximumFractionDigits: 0 });
  };

  const withNoDigits = item.replace(/[0-9]/g, '').replace('₲.', '');
  const lowercase = withNoDigits.toLowerCase();
  const capitalize = lowercase[0].toUpperCase() + lowercase.slice(1);
  const withNoLetters = item.replace(/,/g, '').replace(/^[^-0-9]*/, '');
  const price = formatPrice(Number(withNoLetters));

  return { capitalize, price };
};

export default handleCharacteristics;
