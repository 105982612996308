import { connect } from 'react-redux';

// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../../../store';
import { GeneralDataComponent } from './view';
import { getContactsFetch, getContactsClear } from './actions';
import { getCityFetch } from '../actions';

const mapStateToProps = (state: RootState) => ({
  stepsHomeStore: state.quotationHomeState,
  contactsStore: state.generalDataState
});
const mapDispatchToProps = {
  getContactsFetch,
  getContactsClear,
  getCityFetch
};

export const GeneralData = connect(mapStateToProps, mapDispatchToProps)(GeneralDataComponent);
export { homeQuotationGeneralDataSaga } from './sagas';
export { homeQuotationGeneralDataReducer } from './reducers';
export type { IGeneralDataBaseStore } from './models';
