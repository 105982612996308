import { call, put, takeLatest, all, takeEvery } from 'redux-saga/effects';

import Api from './Api';
import {
  getQouteManagementInfoSuccess,
  getQouteManagementInfoFail,
  getAllItemsSuccess,
  getAllItemsFail,
  getProductsSuccess,
  getProductsFail
} from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';

const api = new Api();

function* sagasGetQouteManagementInfoFetch(action: actionType) {
  try {
    const data: any = yield call(api.getQouteManagementInfoFetch, action.payload.params);
    yield put(getQouteManagementInfoSuccess(data));
  } catch (e) {
    yield put(getQouteManagementInfoFail(e.response));
  }
}

function* sagasgetAllItemsFetch(action: actionType) {
  try {
    const data: any = yield call(api.getAllItemsFetch, action.payload.params);
    yield put(getAllItemsSuccess(data));
  } catch (e) {
    yield put(getAllItemsFail(e.response));
  }
}

function* sagasGetProductsFetch() {
  try {
    const response: any = yield call(api.getProductsFetch);
    yield put(getProductsSuccess(response.data.data.items.items));
  } catch (e) {
    yield put(getProductsFail(e));
  }
}

// Listen for redux actions
function* qouteManagementSaga() {
  yield all([takeLatest(ACTIONS.QUOTE_MANAGEMENT.GET_QUOTES_INFO.FETCH, sagasGetQouteManagementInfoFetch)]);
  yield all([takeLatest(ACTIONS.QUOTE_MANAGEMENT.GET_ALL_QUOTES_INFO.FETCH, sagasgetAllItemsFetch)]);
  yield all([takeEvery(ACTIONS.QUOTE_MANAGEMENT.GET_PRODUCTS.FETCH, sagasGetProductsFetch)]);
}

export { qouteManagementSaga };
