import axios from 'axios';

const Api = () => {
  // eslint-disable-next-line no-unused-vars
  const getApStepsFetch = (id: string): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/inputs/3`);

  const createContactFetch = (params: any): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers`, params);

  const getContactsFetch = (params: any): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers`, { params });

  const getBillingModeFetch = (params: any): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/payment/billing`, {
      params: params
    });

  const getPaymentMethodFetch = (params: any): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/payment/method`, {
      params: params
    });

  const getPaymentConditionFetch = (params: any): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/payment_conditions`, {
      params: params
    });

  const getPlansFetch = (params: any): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/quotation/createApQuote`, params);

  const updateQuotationFetch = (quotationId: string, params: any): Promise<unknown> =>
    axios.patch(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/quotation/${quotationId}`, params);

  return {
    getApStepsFetch,
    createContactFetch,
    getContactsFetch,
    getBillingModeFetch,
    getPaymentMethodFetch,
    getPaymentConditionFetch,
    getPlansFetch,
    updateQuotationFetch
  };
};

export default Api;
