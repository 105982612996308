import { connect } from 'react-redux';
import { ServicesListComponent } from './view';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../store';
import { getServicesFetch } from './actions';

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state: RootState) => ({
  servicesStore: state.servicesState.services
});
const mapDispatchToProps = {
  getServicesFetch
};

export { servicesSaga } from './sagas';
export { servicesReducer } from './reducers';
export const ServicesList = connect(mapStateToProps, mapDispatchToProps)(ServicesListComponent);
