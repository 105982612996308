import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import { Box, Container, Grid, TextField } from '@material-ui/core';
import {
  Input,
  CustomAlert,
  AlertSeverities,
  CancelModal,
  SuccessModal,
  StyledButton,
  DatePicker
} from '@wdynamo/common';
import { Analytics } from '@wdynamo/common/lib/services';
import { Autocomplete } from '@material-ui/lab';
import CancelIllustration from '../../../../assets/img/illustrations/CancelIllustration.jpg';
import SuccessIllustration from '../../../../assets/img/illustrations/SuccessIllustration.jpg';

import { ReactComponent as IconArrowLeft } from '../../../../assets/img/icons_dynamo/arrow_left.svg';

import { IUsersProps, IUser } from '../../../Users/models'; // eslint-disable-line no-unused-vars
import { IContact, IContactInfoForm, IContactInfoStore } from './models'; // eslint-disable-line no-unused-vars

import { uris } from '../../../../siteMap';
import { useEffect } from 'react';
import { AssingUserPopper } from './components/AssignUserPopper';
import { ENCRYPTION_KEY } from '../..';
import PhoneMask from '../common/PhoneMask';
import AlphaMask from '../common/AlphaMask';
import phoneValidator from '../common/phoneValidator';
import emailValidator from '../../../Services/Quotations/components/common/emailValidator';

interface locationStateType {
  currentContact: any;
}

interface IContactInfoProps {
  contactInfoStore: IContactInfoStore;
  usersStore: IUsersProps;
  contactInfoFetch(contactInfoForm: IContact, contactId?: string): void;
  getUsersFetch(page_len: number, page: number): void;
  className?: string;
}

interface IListStatus {
  option_1: string;
  option_2: string;
  option_3: string;
}

const initialContactInfoForm: IContactInfoForm = {
  assigned_user: { value: '', error: false, isRequired: true },
  responsible: { value: '', error: false, isRequired: true },
  last_contact: { value: '', error: false, isRequired: false },
  dni: { value: '', error: false, isRequired: false },
  name: { value: '', error: false, isRequired: false },
  last_name: { value: '', error: false, isRequired: false },
  phone: { value: '', error: false, isRequired: false },
  mail: { value: '', error: false, isRequired: false },
  channel: { value: '', error: false, isRequired: false },
  status: { value: '', error: false, isRequired: false }
};

const usePrevious = (value: IContactInfoStore): IContactInfoStore => {
  const ref = React.useRef<IContactInfoStore>();
  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current as IContactInfoStore;
};

export const CleanContactInfoComponent: React.FC<IContactInfoProps> = (props: IContactInfoProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { contactInfoStore, contactInfoFetch, usersStore, getUsersFetch } = props;
  const [isUpdateContactView, setIsUpdateContactView] = React.useState<boolean>(false);
  const prevContactInfoStore = usePrevious(contactInfoStore);
  const [contactInfoForm, setContactInfoForm] = React.useState<IContactInfoForm>(initialContactInfoForm);
  const [contactInfoButtonDisabled, setContactInfoButtonDisabled] = React.useState(true);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [listUsers, setListUsers] = React.useState<any[]>([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [listStatus, setListStatus] = React.useState<any[]>([]);
  const [contactData, setContactData] = React.useState<IContact>();

  React.useEffect(() => {
    Analytics().pageview('contact-info');
    getUsersFetch(15, 1);

    setListStatus([{ option: 'NoContactado' }, { option: 'Rechazado' }, { option: 'Contactado' }]);

    let data: any;
    const rawData = sessionStorage.getItem('contact') || '';
    if (rawData) {
      const decrypted = CryptoAES.decrypt(rawData, ENCRYPTION_KEY);
      data = JSON.parse(decrypted.toString(CryptoENC));
    }
    if (data) {
      setIsUpdateContactView(true);
      setContactInfoForm((prevState: any) => {
        let newState: IContactInfoForm = initialContactInfoForm;
        Object.keys(prevState).forEach((input) => {
          if (input === 'last_contact')
            newState = {
              ...newState,
              [input]: {
                ...newState[input],
                value: data[input] && data[input].split(' ')[0].split('-').reverse().join('/')
              }
            };
          else if (input === 'responsible' || input === 'assigned_user') {
            // eslint-disable-next-line no-unused-vars
            const { id: _id_kaiser, ...rest } = data[input] ? data[input] : '';
            newState = {
              ...newState,
              [input]: {
                ...newState[input],
                value: rest
              }
            };
          } else
            newState = {
              ...newState,
              [input]: {
                ...newState[input],
                value: data[input]
              }
            };
        });
        return newState;
      });
      setContactData(data);
    }
  }, []);

  useEffect(() => {
    usersStore.users?.length &&
      setListUsers(
        usersStore.users.map((user) => {
          return {
            kaiser_username: user.username && user.username,
            first_name: user.first_name && user.first_name,
            last_name: user.last_name && user.last_name,
            partner_id: user.partner_id && user.partner_id
          };
        })
      );
  }, [usersStore.users]);

  React.useEffect(() => {
    setContactInfoButtonDisabled(!validateFormFields() || contactInfoStore.loading);
    if (prevContactInfoStore?.loading && !contactInfoStore!.loading && !contactInfoStore!.error) {
      setContactInfoForm(initialContactInfoForm);
      setShowSuccessModal((show) => !show);
      if (isUpdateContactView) {
        history.push(uris.contacts.uri);
      }
    }
  }, [contactInfoForm, contactInfoStore]);

  React.useEffect(() => {
    setAnchorEl(null);
  }, [contactInfoForm.assigned_user?.value]);

  const togglePopper = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const validateFormFields = () => {
    const phone = String(contactInfoForm.phone.value);
    const email = String(contactInfoForm.mail.value);
    const isPhoneValid = !phone || phoneValidator(phone);
    const isMailValid = !email || emailValidator(email);
    return (phone || email) && isPhoneValid && isMailValid;
  };

  const handleClickBack = () => {
    if (contactData) {
      history.push(uris.contactDetails.uri, contactData.id);
    } else {
      history.push(uris.contacts.uri);
    }
  };

  const handleOnChange = (fieldToUpdate: string, value: any) =>
    setContactInfoForm((prevState) => ({
      ...prevState,
      [fieldToUpdate]: { ...prevState[fieldToUpdate], value: value, error: false }
    }));

  const handleOnBlur = (fieldToUpdate: string, value: any) => {
    let error = false;
    if (fieldToUpdate === 'phone') {
      error = !phoneValidator(value);
    } else if (fieldToUpdate === 'mail') {
      error = !emailValidator(value);
    }
    setContactInfoForm((prevState) => ({
      ...prevState,
      [fieldToUpdate]: { ...prevState[fieldToUpdate], value: value, error }
    }));
  };

  const getFormattedDate = (dateString: string) => {
    const [day, month, year] = dateString.split('/') as unknown[] as number[];
    return new Date(year, month - 1, day);
  };
  const contactInfoConfirm = () => {
    if (validateFormFields()) {
      const rawLastContact = contactInfoForm.last_contact.value
        ? getFormattedDate(contactInfoForm.last_contact.value.toString())
        : new Date(Date.now());
      const [date, time] = rawLastContact.toISOString().split('T');
      const formattedLastContact = `${date} ${time.split('.')[0]}`;
      const data: IContact = {
        responsible: contactInfoForm.responsible.value,
        last_contact: formattedLastContact,
        name: contactInfoForm?.name?.value?.toString(),
        last_name: contactInfoForm?.last_name?.value?.toString(),
        phone: contactInfoForm?.phone?.value?.toString(),
        mail: contactInfoForm?.mail?.value?.toString(),
        assigned_user: contactInfoForm?.assigned_user?.value ? contactInfoForm?.assigned_user?.value : undefined
      };
      Analytics().trackEvent(
        'contact-info',
        isUpdateContactView ? 'update-contact' : 'add-contact',
        undefined,
        data.mail
      );
      isUpdateContactView ? contactInfoFetch(data, contactData?.id?.toString()) : contactInfoFetch(data);
    }
  };

  const toggleCancelModal = () => {
    setShowCancelModal(!showCancelModal);
  };
  const toggleSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
  };

  const handleCancelModalConfirm = () => {
    setContactInfoForm(initialContactInfoForm);
    toggleCancelModal();
    history.push(uris.contacts.uri);
  };
  const handleSuccessModalConfirm = () => {
    setContactInfoForm(initialContactInfoForm);
    toggleSuccessModal();
  };

  const renderModal = () => (
    <>
      <CancelModal
        open={showCancelModal}
        handleClose={toggleCancelModal}
        onClickConfirm={toggleCancelModal}
        onClickCancel={handleCancelModalConfirm}
        messages={{
          TITLE: t('COMMONS.MODALS.CANCEL.TITLE'),
          CANCEL_BUTTON: t('COMMONS.MODALS.CANCEL.CANCEL'),
          CONTINUE_BUTTON: t('COMMONS.MODALS.CANCEL.CONTINUE')
        }}
        img={CancelIllustration}
      />
      <SuccessModal
        open={showSuccessModal}
        handleClose={toggleSuccessModal}
        onClickConfirm={handleSuccessModalConfirm}
        img={SuccessIllustration}
        description=''
        messages={{
          TITLE: t('CONTACTS.CONTACT_INFO.ADD_CONTACT.SUCCESS.TITLE'),
          CONTINUE_BUTTON: t('COMMONS.BUTTONS.CONTINUE')
        }}
      />
    </>
  );

  return (
    <>
      {renderModal()}
      <Container maxWidth='lg' className={props.className}>
        <Box py={5}>
          <Grid container direction='row' className='icon-grid'>
            <IconArrowLeft className='icon' onClick={handleClickBack} />
            <h2>
              {isUpdateContactView
                ? t('CONTACTS.CONTACT_INFO.UPDATE_CONTACT.TITLE')
                : t('CONTACTS.CONTACT_INFO.ADD_CONTACT.TITLE')}
            </h2>
          </Grid>
        </Box>

        <Grid container>
          <AssingUserPopper
            assigned_user={contactInfoForm.assigned_user?.value}
            listUsers={listUsers}
            anchorEl={anchorEl}
            toggleOpen={togglePopper}
            handleOnChange={handleOnChange}
          />
        </Grid>
        <Grid container>
          <AssingUserPopper
            assigned_user={contactInfoForm.status?.value}
            listUsers={listStatus}
            anchorEl={anchorEl}
            toggleOpen={togglePopper}
            handleOnChange={handleOnChange}
          />
        </Grid>

        {contactInfoForm.assigned_user?.value && (
          <Grid container>
            <p className='confirm-note'>
              Al confirmar a
              <span className='seller-name-note'>{` ${contactInfoForm.assigned_user.value.first_name} ${contactInfoForm.assigned_user.value.last_name} `}</span>
              como vendedor asignado, se le trasladará el contacto y no podrás acceder al mismo.
            </p>
          </Grid>
        )}

        <Box py={3}>
          <Grid direction='row' container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disabled={isUpdateContactView}
                value={contactInfoForm.responsible.value}
                options={listUsers}
                onChange={(e, value) => handleOnChange('responsible', value)}
                getOptionLabel={(option: any) => `${option.first_name || ''} ${option.last_name || ''}`}
                renderInput={(params) => (
                  <TextField {...params} label={t('CONTACTS.CONTACT_INFO.RESPONSIBLE')} variant='standard' />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item xs={12} sm={6} style={{ width: '100%' }}>
              <DatePicker
                className='last-contact-field'
                format={process.env.REACT_APP_DATE_FORMAT!}
                label={t('CONTACTS.CONTACT_INFO.CONTACT_DATE')}
                value={contactInfoForm.last_contact.value as string}
                onChange={(value: string): void => handleOnChange('last_contact', value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label={t('CONTACTS.CONTACT_INFO.NAME')}
                //  disabled={isUpdateContactView}
                autoComplete={true}
                value={contactInfoForm.name.value as string}
                error={contactInfoForm.name.error}
                onChange={(value: string): void => handleOnChange('name', value)}
                subInputProps={{ maxLength: 30 }}
                testId='name'
                type='text'
                InputProps={{
                  inputComponent: AlphaMask
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label={t('CONTACTS.CONTACT_INFO.LAST_NAME')}
                //   disabled={isUpdateContactView}
                autoComplete={true}
                value={contactInfoForm?.last_name?.value ? contactInfoForm.last_name.value : ''}
                error={contactInfoForm.last_name.error}
                onChange={(value: string): void => handleOnChange('last_name', value)}
                subInputProps={{ maxLength: 30 }}
                testId='last_name'
                type='text'
                InputProps={{
                  inputComponent: AlphaMask
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Input
                label={t('CONTACTS.CONTACT_INFO.PHONE')}
                autoComplete={true}
                value={contactInfoForm.phone.value as string}
                error={contactInfoForm.phone.error}
                onChange={(value: string): void => handleOnChange('phone', value)}
                onBlur={(value: string): void => handleOnBlur('phone', value)}
                testId='phone'
                type='text'
                InputProps={{
                  inputComponent: PhoneMask
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Input
                label={t('CONTACTS.CONTACT_INFO.EMAIL')}
                autoComplete={true}
                value={contactInfoForm.mail.value as string}
                error={contactInfoForm.mail.error}
                onChange={(value: string): void => handleOnChange('mail', value)}
                onBlur={(value: string): void => handleOnBlur('mail', value)}
                subInputProps={{ maxLength: 30 }}
                testId='mail'
                type='email'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingTop: '0', paddingBottom: '0' }}>
              <Grid container direction='row'>
                <p style={{ marginTop: '0.2rem', marginBottom: '0.2rem', fontSize: '0.8rem' }}>
                  El teléfono o correo electrónico debe estar completado.
                </p>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label={t('CONTACTS.CONTACT_INFO.CHANNEL')}
                autoComplete={true}
                defaultValue={'Experta Basa' as string}
                error={contactInfoForm.channel.error}
                //  onChange={(value: string): void => handleOnChange('channel', 'Experta Basa')}
                testId='channel'
                type='channel'
              />
            </Grid>
            {/*   <Grid item xs={12} sm={6}>
              <Autocomplete
                //   disabled={isUpdateContactView}
                //value={contactInfoForm.status.value}
                options={listStatus}
                defaultValue={contactInfoForm?.status?.value ? contactInfoForm.status.value : ''}
                // defaultValue={contactInfoForm.status.value ? contactInfoForm.status.value : ''}
                onChange={(e, value) => handleOnChange('status', value)}
                getOptionLabel={(option: any) => `${option.option || ''}`}
                renderInput={(params) => (
                  <TextField {...params} label={t('CONTACTS.CONTACT_INFO.STATUS')} variant='standard' />
                )}
              ></Autocomplete>
                </Grid> */}
            {contactInfoStore.message &&
              contactInfoStore.message.map((error: string, index: number) => (
                <Grid key={index} item xs={12} sm={6}>
                  <CustomAlert key={index} severity={AlertSeverities.ERROR} className='alert'>
                    {error}
                  </CustomAlert>
                </Grid>
              ))}
          </Grid>
        </Box>

        <Grid direction='row' justify={'flex-end'} container spacing={2} style={{ marginTop: 40 }}>
          <Grid item xs={12} md={4} sm={6}>
            <StyledButton onClick={contactInfoConfirm} disabled={contactInfoButtonDisabled} className='button'>
              {isUpdateContactView ? t('CONTACTS.CONTACT_INFO.BUTTONS.UPDATE') : t('CONTACTS.CONTACT_INFO.BUTTONS.ADD')}
            </StyledButton>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export const ContactInfoComponent = styled(({ ...props }) => <CleanContactInfoComponent {...props} />)`
  * {
    font-family: 'Rubik';
  }
  h2 {
    margin: 0 1em;
    font-size: 28px;
    font-weight: 200;
  }
  h3 {
    margin: 0 0.5em;
    font-size: 20px;
    font-weight: 600;
  }
  .last-contact-field {
    width: 100%;
  }
  .icon > path {
    fill: var(--color-main);
  }
  .icon-grid {
    cursor: pointer;
  }
  .assing-icon {
    width: 20px;
  }
  .MuiInputLabel-formControl,
  .MuiInput-input {
    font-size: 16px;
    font-weight: 600;
  }
  .button {
    width: 100%;
  }
  .confirm-note {
    font-weight: 600;
  }
  .seller-name-note {
    color: var(--color-black);
  }
`;
