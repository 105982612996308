import React from 'react';
import styled from 'styled-components';
import { Grid, TextField, InputAdornment, Container } from '@material-ui/core';
import { TextPoint } from '@wdynamo/common/lib/components';
import { useTranslation } from 'react-i18next';
import { Analytics } from '@wdynamo/common/lib/services';

interface ComissionProps {
  handleOnChange(fieldToUpdate: any, value: any): void;
  service: any;
  className?: any;
}

const CleanComissionForm: React.FC<ComissionProps> = (props: ComissionProps) => {
  const { t } = useTranslation();
  const { service, handleOnChange } = props;

  React.useEffect(() => {
    Analytics().pageview('new-service | comission');
  }, []);

  const handleComissionChange = (e: any) => {
    const value = e.target.value;
    if (!Number.isNaN(value) || value < 0 || value > 100) {
      return;
    }
    if (value === '' || Number(value)) handleOnChange('comission', value);
  };

  return (
    <Container maxWidth='sm'>
      <Grid className={`${props.className}`}>
        <Grid item>
          <h3>
            {t('SERVICES.NEW_SERVICE.STEP_4.TITLE')}
            <TextPoint />
          </h3>
        </Grid>
        <Grid item>
          <p>{t('SERVICES.NEW_SERVICE.STEP_4.CLARIFICATION')} </p>
          <TextField
            type='number'
            className='input-comission'
            defaultValue={service.comission.value}
            onChange={handleComissionChange}
            label={t('SERVICES.NEW_SERVICE.STEP_4.TITLE')}
            name='comission'
            InputProps={{
              endAdornment: <InputAdornment position='end'>%</InputAdornment>
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
export const ComissionForm = styled(({ ...props }) => <CleanComissionForm {...props} />)``;
