import { connect } from 'react-redux';

// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../../../store';
import { HomePlansComponent } from './view';
import { getBillingModeFetch, getPaymentMethodFetch, getPaymentConditionFetch } from '../actions';

const mapStateToProps = (state: RootState) => ({
  stepsHomeStore: state.quotationHomeState
});
const mapDispatchToProps = {
  getBillingModeFetch,
  getPaymentMethodFetch,
  getPaymentConditionFetch
};

export const HomePlans = connect(mapStateToProps, mapDispatchToProps)(HomePlansComponent);
