import { connect } from 'react-redux';
import { ProductsComponent } from './view';
import { getProductsFetch, choiceProduct } from './actions';
import { resetCheckout } from '../Checkout';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  productsStore: state.productsState,
  checkoutStore: state.checkoutState
});
const mapDispatchToProps = { getProductsFetch, choiceProduct, resetCheckout };

export const Products = connect(mapStateToProps, mapDispatchToProps)(ProductsComponent);
export { productsSaga } from './sagas';
export { productsReducer } from './reducers';
