// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType, IBaseStateProps } from '../ActionTypes';

const initialState: IBaseStateProps = {
  loading: false,
  error: false,
  data: []
};

export const productsReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.PRODUCTS.LIST.FETCH:
      return {
        ...state,
        loading: true,
        error: false,
        data: []
      };
    case ACTIONS.PRODUCTS.LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data
      };
    case ACTIONS.PRODUCTS.LIST.FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
};
