// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType, IBaseStateProps } from '../../ActionTypes';

const initialState: IBaseStateProps = {
  loading: false,
  error: false,
  data: {}
};

export const serviceUpdateReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    // Contat info
    case ACTIONS.SERVICES.UPDATE.FETCH:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ACTIONS.SERVICES.UPDATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data
      };
    case ACTIONS.SERVICES.UPDATE.FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
};
