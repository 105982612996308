import { call, put, takeLatest, all } from 'redux-saga/effects';
import ApiMock from './ApiMock';
import { updateServiceSuccess, updateServiceFail } from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../ActionTypes';

const api = ApiMock();

function* sagasUpdateServiceFetch(action: actionType) {
  try {
    const response = yield call(api.updateServiceFetch, action.payload.data);
    yield put(updateServiceSuccess(response.data.data));
  } catch (e) {
    yield put(updateServiceFail(e));
  }
}

// Listen for redux actions
function* serviceUpdateSaga() {
  yield all([takeLatest(ACTIONS.SERVICES.UPDATE.FETCH, sagasUpdateServiceFetch)]);
}

export { serviceUpdateSaga };
