import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { IconButton } from '@wdynamo/common';

import { IconLabel, ModalComponent } from '../../../../../../../../components';
// eslint-disable-next-line no-unused-vars
import { ICustomDataForm, ICodeDescription } from '../../../models';
import { Loading } from '@wdynamo/common';
import { ReactComponent as trashIcon } from '../../../../../../../../assets/img/icons_dynamo/icon_trash.svg';

interface IAccessoriesModalProps {
  className: string;
  openModal: boolean;
  setOpenModal(value: boolean): void;
  dataForm: ICustomDataForm;
  setDataForm(dataForm: ICustomDataForm): void;
  loading: boolean;
  accessoriesList: ICodeDescription[];
  accessoryLimit: number;
}

const CleanAccessoriesModal: React.FC<IAccessoriesModalProps> = (props: IAccessoriesModalProps) => {
  const {
    className,
    openModal,
    setOpenModal,
    dataForm,
    setDataForm,
    loading,
    accessoriesList,
    accessoryLimit = 4
  } = props;
  const { t } = useTranslation();
  const accessoriesData = dataForm.custom_step.grupo1.accesories.value.description;
  const prevDataForm = { ...dataForm };
  const prevAccessoriesData = prevDataForm.custom_step.grupo1.accesories.value.description;

  const handleOnChange = (event: any, accesory: any, index: number) => {
    if (event.type === 'blur') prevAccessoriesData.splice(index, 1, { ...accesory, description: event.target.value });
    else prevAccessoriesData.splice(index, 1, { ...accesory, code: event.target.value });
    prevDataForm.custom_step.grupo1.accesories.value.description = prevAccessoriesData;
    setDataForm(prevDataForm);
  };

  const handleOnClickAddIcon = () => {
    prevDataForm.custom_step.grupo1.accesories.value.description.push({ code: '', description: '' });
    setDataForm(prevDataForm);
  };

  const handleOnClickTrashIcon = (index: number) => {
    prevDataForm.custom_step.grupo1.accesories.value.description.splice(index, 1);

    if (prevDataForm.custom_step.grupo1.accesories.value.description.length < 1) {
      prevDataForm.custom_step.grupo1.accesories.value.description.push({ code: '', description: '' });
    }
    setDataForm(prevDataForm);
  };

  const renderDescription = () => {
    if (Array.isArray(accessoriesData))
      return (
        <>
          {accessoriesData.map((rowAccesory: any, index: number) => (
            <Grid key={rowAccesory.description + index} className='row' container justifyContent='space-between'>
              <Grid item xs={11} md={5}>
                <TextField
                  value={rowAccesory.code}
                  onChange={(event) => handleOnChange(event, rowAccesory, index)}
                  label='Accesorios *'
                  placeholder='Seleccionar'
                  select
                  fullWidth
                >
                  {accessoriesList.map((accessory: any) => (
                    <MenuItem key={accessory.code} value={accessory.code}>
                      {accessory.description}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={11} md={5}>
                <TextField
                  defaultValue={rowAccesory.description}
                  onBlur={(event) => handleOnChange(event, rowAccesory, index)}
                  label='Valor ₲ *'
                  placeholder='Ej: 30.000'
                  fullWidth
                ></TextField>
              </Grid>
              {accessoriesData.length > 0 && accessoriesData[0].description && (
                <Grid item xs={12} md={1}>
                  <IconButton className='trash-button' Icon={trashIcon} onClick={() => handleOnClickTrashIcon(index)} />
                </Grid>
              )}
            </Grid>
          ))}
          {accessoriesData.length <= accessoryLimit && (
            <Grid className='row' container>
              <Grid className='icon-label' item>
                <IconLabel label='agregar' className='button-add' onClick={handleOnClickAddIcon} />
              </Grid>
            </Grid>
          )}
        </>
      );
  };

  const handleCloseCrossModal = () => {
    prevDataForm.custom_step.grupo1.accesories.value.description = { code: '', description: '' };
    setDataForm(prevDataForm);
    setOpenModal(false);
  };
  const handleCloseModal = () => {
    prevDataForm.custom_step.grupo1.accesories.value.description = JSON.stringify(prevAccessoriesData);
    setDataForm(prevDataForm);
    setOpenModal(false);
  };

  return (
    <ModalComponent
      className={className}
      open={openModal}
      title='Accesorios.'
      subtitle='Puede agregar como máximo 5 accesorios.'
      description={loading ? <Loading /> : renderDescription()}
      buttonColor='var(--color-main)'
      buttonText={t('COMMONS.BUTTONS.CONTINUE')}
      onClickButton={handleCloseModal}
      handleCloseCrossModal={handleCloseCrossModal}
    />
  );
};

export const AccesoriesModal = styled(({ ...props }) => <CleanAccessoriesModal {...props} />)`
  .row {
    width: 420px;
    .icon-label {
      margin-top: 1rem;
    }
  }
  .first-button {
    display: ${(props) => props.loading && 'none'};
  }
  .trash-button {
    width: 45px;
  }
  .button-add {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
`;
