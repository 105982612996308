import { ACTIONS } from '../ActionTypes';

// Search Users
export const getUsersFetch = (page_len: number, page: number) => ({
  type: ACTIONS.USERS.FETCH,
  payload: { page_len: page_len, page: page }
});

export const getUsersSuccess = (users: any[], total_pages?: number) => ({
  type: ACTIONS.USERS.SUCCESS,
  payload: { users: users, total_pages: total_pages }
});

export const getUsersFail = (error: object) => ({ type: ACTIONS.USERS.FAIL, payload: { error } });

// get filteredUsersByText
export const getFilteredUsersByTextFetch = (text: string) => ({
  type: ACTIONS.USERS.NON_PAGED_LIST.FETCH,
  payload: { text: text }
});

export const getFilteredUsersByTextSuccess = (data: any) => ({
  type: ACTIONS.USERS.NON_PAGED_LIST.SUCCESS,
  payload: { data: data }
});

export const getFilteredUsersByTextFail = (error: object) => ({
  type: ACTIONS.USERS.NON_PAGED_LIST.FAIL,
  payload: { error }
});
