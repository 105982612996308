const MOCK_PAYMENT_METHOD = {
  data: {
    status: 'success',
    status_code: 200,
    data: [
      {
        id: '7',
        name: 'est'
      },
      {
        id: '1',
        name: 'proident'
      },
      {
        id: '2',
        name: 'officia'
      },
      {
        id: '3',
        name: 'veniam'
      },
      {
        id: '4',
        name: 'sint'
      },
      {
        id: '5',
        name: 'sunt'
      },
      {
        id: '6',
        name: 'voluptate'
      }
    ]
  }
};

export default MOCK_PAYMENT_METHOD;
