import React from 'react';
import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Input } from '@wdynamo/common';

export const ListUnitMembers = (props: any) => {
  const {
    item, // member object, name, id, isAttending boolean
    // itemIdx, // index of the member object
    handleOrderListOnChange // on change function
  } = props;
  const useStyles = makeStyles({
    MuiInputBaseInput: {
      textAlign: 'start'
    }
  });

  /* const useStyles = makeStyles({
    switchTrack: {
      backgroundColor: '#000'
    },
    switchBase: {
      color: 'var(--color-main)',
      '&.Mui-checked': {
        color: 'var(--color-main)'
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: 'var(--color-main)'
      }
    }
  });*/
  const classes = useStyles();

  return (
    <div style={{ width: '100%', marginTop: '1rem' }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: '0', fontSize: '0.8rem' }}>
        {item.name}
      </div>
      <Input
        className={classes.MuiInputBaseInput}
        // defaultValue={selected /*dataForm?.plans?.billing_mode?.value as string */ /*|| ('12' as string)*/}
        onChange={(value: string): void =>
          handleOrderListOnChange(item.id, item.options[value].value, item.options[value].name)
        }
        // defaultValue={item.value}
        required
        select
      >
        {item.options.map((item: any) => (
          <MenuItem key={String(item.id)} value={String(item.id)}>
            {item.name}
          </MenuItem>
        ))}
      </Input>

      {/*   style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: '0', fontSize: '0.8rem' }} // Needed by MaterialUI to create labels, like Santa, Grinch
        control={
          <Switch
            checked={item.isActive} // boolean true/false
            onChange={() => {
              handleOrderListChange(itemIdx, !item.isActive);
            }} // "!" <-- lets the state flip, otherwise state flips once
            classes={{
              track: classes.switchTrack,
              switchBase: classes.switchBase
            }}
          />
        }
        label={item.name}
        labelPlacement='start'
      /> */}
    </div>
  );
};
