export const COLORS = {
  black: '#000000',
  wine: '#5c0f8b',
  greyText: '#666',
  greyBorder: '#b8b8b8',
  greyBackground: '#eaeaea',
  white: '#fff',
  orange: '#ff9800',
  red: '#ff2828'
};
