import axios from 'axios';

const Api = () => {
  // eslint-disable-next-line no-unused-vars
  const getContactsFetch = (params: any): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers/?pagelen=9`, {
      params
    });

  //&page=1&order=desc&sort=mail&search=edsab438

  return { getContactsFetch };
};

export default Api;
