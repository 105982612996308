import React, { useEffect } from 'react';
import styled from 'styled-components';
import { uris } from '../../../../../siteMap';
import { Box, Container, Grid, Step, StepLabel } from '@material-ui/core';
import { Redirect, useHistory } from 'react-router-dom';
import { Loading, Stepper, TextPoint } from '@wdynamo/common/lib/components';
import { useTranslation } from 'react-i18next';
import { GeneralInfo } from './GeneralInfo';
import { CustomInfo } from './CustomInfo';
import { Plans } from './Plans';
import { Summary } from './Summary';
//import { usePrevious } from '../../../../../hooks/usePrevius';

import { Analytics } from '@wdynamo/common/lib/services';
// eslint-disable-next-line no-unused-vars
import { ICustomDataForm, IPlansParams, ISourceParams, IContact, IContactsByPartial } from './models';
import { GeneralErrorComponent } from '../../../../../components/GeneralError';

interface QuotationsComponentProps {
  personStore: any;
  createPersonFetch(): void;
  getStepsFetch(text: string): void;
  className?: string;
  stepsStore: any;
  getListSourceFetch(listParams: ISourceParams): void;
  getDataSourceFetch(dataParams: ISourceParams): void;
  getContactsByPartial(params: IContactsByPartial): void;
  getContactsClear(): void;
  createContactFetch(data: any): void;
  getPlansFetch(params: IPlansParams): void;
  location: any;
  setItemsToStore(data: any): void;
  updateQuotationFetch(quotationId: string, params: any): void;
  getProducerFetch(): void;
  cleanStore(): void;
}

const initialState: any = {
  general_info: {
    name: { value: '', isRequired: false, error: false },
    last_name: { value: '', isRequired: false, error: false },
    email: { value: '', isRequired: false, error: false },
    phone_number: { value: '', isRequired: false, error: false }
  },
  plans: {
    producer_code: { value: '', isRequired: true, error: false },
    producer_code_description: { value: '', isRequired: true, error: false },
    commercial_plan: { value: '', isRequired: true, error: false },
    billing_mode: { value: '', isRequired: true, error: false },
    payment_method: { value: '', isRequired: true, error: false },
    payment_condition: { value: '', isRequired: true, error: false },
    name: { value: '', isRequired: true, error: false },
    code: { value: '', isRequired: true, error: false },
    // first_payment: { value: '', isRequired: true, error: false },
    price: { value: '', isRequired: true, error: false },
    remaining_payments: { value: '', isRequired: true, error: false },
    bonus: { value: '0', isRequired: true, error: false }
  }
};

let initialCustomState: any = {};
let productId: string;
let currentQuoteParams: any;

const CleanQuotationsComponent: React.FC<QuotationsComponentProps> = (props: QuotationsComponentProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const fixedSteps: string[] = [
    '',
    t('QUOTATIONS.STEP_2.LABEL'),
    t('QUOTATIONS.STEP_4.LABEL'),
    t('QUOTATIONS.STEP_5.LABEL')
  ];
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [dataForm, setDataForm] = React.useState<ICustomDataForm>(initialState);
  const [steps, setSteps] = React.useState<string[]>(fixedSteps);
  const [currentId, setCurrentId] = React.useState<number>();
  const [summaryData, setSummaryData] = React.useState([]);
  const [currentContact, setCurrentContact] = React.useState<IContact>();
  const [step3Reached, setStep3Reached] = React.useState<boolean>(false);
  const [producer, setProducer] = React.useState<any>();

  useEffect(() => {
    //assign the ref's current value to the count Hook
    if (currentStep === 2) {
      setStep3Reached(true);
    }
  }, [currentStep]); //run this code when the value of count changes

  const {
    createPersonFetch,
    getStepsFetch,
    stepsStore,
    getListSourceFetch,
    getDataSourceFetch,
    getContactsByPartial,
    getContactsClear,
    createContactFetch,
    getPlansFetch,
    location,
    setItemsToStore,
    updateQuotationFetch,
    getProducerFetch,
    cleanStore
  } = props;

  useEffect(() => {
    Analytics().pageview('car-badget');
    if (!location?.state?.service?.id && !location?.state?.requote?.customer?.id) history.push(uris?.services.uri);
    return () => {
      cleanStore();
      initialState.general_info.name.value = location?.state?.requote?.customer?.name
        ? location?.state?.requote?.customer?.name
        : '';
      initialState.general_info.last_name.value = location?.state?.requote?.customer?.last_name
        ? location?.state?.requote?.customer?.last_name
        : '';
      initialState.general_info.email.value = location?.state?.requote?.customer?.email
        ? location?.state?.requote?.customer?.email
        : '';
      initialState.general_info.phone_number.value = location?.state?.requote?.customer?.phone
        ? location?.state?.requote?.customer?.phone
        : '';
      setDataForm(initialState);
    };
  }, []);

  useEffect(() => {
    if (location?.state?.service?.id || location?.state?.requote?.customer?.id) {
      productId = location?.state?.service?.id ? location.state.service.id : '1';
      getStepsFetch(productId);
      setCurrentStep(0);
      getProducerFetch();
      if (location?.state?.requote?.customer?.id) {
        const requote = location.state.requote;
        const customer = location.state.requote.customer;
        setCurrentContact(customer);
        if (location?.state?.requote?.planName) setCurrentStep(3);
        else setCurrentStep(0);
        setDataForm((prevState) => ({
          ...prevState,
          general_info: {
            ...prevState.general_info,
            name: {
              ...prevState.general_info.name,
              value: customer.name
            },
            last_name: {
              ...prevState.general_info.last_name,
              value: customer.last_name
            },
            email: {
              ...prevState.general_info.email,
              value: customer.mail
            },
            phone_number: {
              ...prevState.general_info.phone_number,
              value: customer.phone
            }
          },
          plans: {
            producer_code: { ...prevState.plans.producer_code, value: requote.producer },
            producer_code_description: {
              ...prevState.plans.producer_code_description,
              value: requote.producerDescription
            },
            commercial_plan: { ...prevState.plans.commercial_plan, value: requote.commercialPlan },
            billing_mode: { ...prevState.plans.billing_mode, value: requote.billingMethod },
            payment_method: { ...prevState.plans.payment_method, value: requote.paymentMethod },
            payment_condition: { ...prevState.plans.payment_condition, value: requote.paymentCondition },
            name: { ...prevState.plans.bonus, value: '' },
            code: { ...prevState.plans.code, value: '' },
            // first_payment: { ...prevState.plans.bonus, value: '' },
            price: { ...prevState.plans.price, value: '' },
            remaining_payments: { ...prevState.plans.bonus, value: '' },
            bonus: { ...prevState.plans.bonus, value: '0' }
          }
        }));
      }
    }
  }, [location.state]);

  useEffect(() => {
    let hasError = false;
    //let timeout = false;
    Object.keys(stepsStore).forEach((service) => {
      if (stepsStore[service].error) {
        hasError = true;
        //timeout = timeout || stepsStore[service].timeout;
        return;
      }
    });
    if (hasError) {
      setCurrentStep(-1);
    }
  }, [stepsStore]);

  useEffect(() => {
    if (stepsStore.steps.error) {
      alert('Ocurrio un error');
      history.push(uris.services.uri);
    }
  }, [stepsStore.steps.loading]);

  useEffect(() => {
    if (stepsStore.steps.data?.label) {
      const stepsLabels = steps;
      stepsLabels[0] = stepsStore.steps.data.label;
      setSteps(stepsLabels);
      const loadedInputState: any = {};
      let summaryInputs: any = [];
      const loadedInputsFromContact = location?.state?.requote?.inputs && location.state.requote.inputs;
      //Generate custom_step State
      stepsStore.steps.data.input_groups.forEach((group: any) => {
        loadedInputState[group.name] = {};
        group.inputs.forEach((field: any) => {
          const loadedStateGroup = loadedInputState[group.name];
          //generate clean custom State for requoting
          initialCustomState = {
            ...initialCustomState,
            [group.name]: {
              ...initialCustomState[group.name],
              [field.name]: {
                error: false,
                isRequired: field.is_required,
                value: {
                  code: field.default_value ? field.default_value.toString() : '',
                  description: field.default_value ? field.default_value.toString() : ''
                }
              }
            }
          };
          loadedStateGroup[field.name] = {
            value: loadedInputsFromContact
              ? loadedInputsFromContact[field.name]
              : field.default_value && field.default_value,
            error: false,
            isRequired: field.is_required
          };
          summaryInputs = [
            ...summaryInputs,
            {
              name: field.name,
              label: field.label,
              type: field.type,
              groupName: group.name,
              true: field.text_true,
              false: field.text_false,
              isRequired: field.is_required,
              span: field.span
            }
          ];
        });
      });
      setSummaryData(summaryInputs);
      setDataForm({ ...dataForm, custom_step: loadedInputsFromContact ? loadedInputState : initialCustomState });
    }
  }, [stepsStore.steps.data]);

  useEffect(() => {
    if (stepsStore.newContact.data?.data?.clientRepresentation[0]?.id) {
      setDataForm((prevState) => ({
        ...prevState,
        general_info: {
          ...prevState.general_info,
          name: {
            ...prevState.general_info.name,
            value: stepsStore?.newContact?.data?.data?.clientRepresentation[0]?.name
          },
          last_name: {
            ...prevState.general_info.last_name,
            value: stepsStore?.newContact?.data?.data?.clientRepresentation[0]?.last_name
          },
          email: {
            ...prevState.general_info.email,
            value: stepsStore?.newContact?.data?.data?.clientRepresentation[0]?.mail
          },
          phone_number: {
            ...prevState.general_info.phone_number,
            value: stepsStore?.newContact?.data?.data?.clientRepresentation[0]?.phone
          }
        }
      }));
      setCurrentId(stepsStore?.newContact?.data?.data?.clientRepresentation[0]?.id);
      handleNext();
    }
  }, [stepsStore?.newContact?.data?.data?.clientRepresentation[0].id]);

  const defaultRedirect = <Redirect to={uris.services.uri} />;

  const savePlan = (planParams: any): void => {
    updateQuotationFetch(stepsStore.plans.data.quote.id, { ...currentQuoteParams, ...planParams });
  };

  const handleContactIsDone = (currentContact?: any) => {
    const name = dataForm?.general_info?.name?.value ?? '';
    const last_name = dataForm?.general_info?.last_name?.value ?? '';
    const phone = dataForm?.general_info?.phone_number?.value;
    const email = dataForm?.general_info?.email?.value;
    if (currentContact && step3Reached === false) {
      setCurrentId(currentContact?.id);
      setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
    } else if (step3Reached === true) {
      setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
    } else if (phone === '' && email === '') {
      setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
    } else {
      createContactFetch([
        {
          name: name as string,
          last_name: last_name as string,
          phone: phone as string,
          mail: email as string
        }
      ]);
    }
  };

  const getPlans = (requoteParams: any) => {
    const subProductId = 1;
    const inputs: { [key: string]: string } = {};
    Object.keys(dataForm.custom_step).forEach((group) => {
      Object.keys(dataForm.custom_step[group]).forEach((field_name: string) => {
        const currentGroup = dataForm.custom_step[group];
        inputs[field_name] = currentGroup[field_name].value;
      });
    });
    const producerDesc = producer.value.name;
    currentQuoteParams = requoteParams
      ? {
          product_id: productId,
          customer_id: currentId ? currentId : location?.state?.requote?.customer?.id,
          inputs: inputs,
          ...requoteParams
        }
      : {
          producer_code: dataForm.plans.producer_code.value,
          producer_code_description: producerDesc,
          commercial_plan: dataForm.plans.commercial_plan.value,
          bonus_percentage: 0,
          fop_changed: false,
          product_id: productId,
          sub_product_id: subProductId,
          customer_id: currentId ? currentId : location?.state?.requote?.customer?.id,
          inputs: inputs,
          billing_method: dataForm.plans.billing_mode.value,
          payment_method: dataForm.plans.payment_method.value,
          payment_condition: dataForm.plans.payment_condition.value,
          request: location?.state?.requote?.quotationExternalId
            ? Number(location.state.requote.quotationExternalId)
            : undefined
        };
    getPlansFetch(currentQuoteParams);
  };

  const handleNext = () => setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);

  const handleBack = () => setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);

  const formatPrice = (number: number): string => {
    return number.toLocaleString('es-AR', { maximumFractionDigits: 0 });
  };

  const isValid = (stepName: string): boolean => {
    let valid = true;
    const stepData = dataForm[stepName];
    if (stepData) {
      // eslint-disable-next-line no-prototype-builtins
      if (stepData.hasOwnProperty('grupo1')) {
        Object.keys(stepData).forEach((group) => {
          const currentGroup = stepData[group];
          Object.keys(currentGroup).forEach((field) => {
            if (
              (currentGroup[field].isRequired &&
                (currentGroup[field].value?.description === undefined ||
                  currentGroup[field].value?.description === '')) ||
              currentGroup[field].error
            )
              valid = false;
          });
        });
      } else {
        Object.keys(stepData).forEach((field) => {
          if (stepData[field].isRequired && (stepData[field].value === '' || stepData[field].error)) valid = false;
        });
      }
    }

    return valid;
  };

  const cleanState = () => {
    setDataForm((prevState) => ({
      ...prevState,
      general_info: {
        ...Object.fromEntries(
          Object.entries(prevState.general_info).map(([k, v]) => {
            const obj: any = v;
            return [k, { ...obj, value: '' }];
          })
        )
      },
      plans: {
        ...Object.fromEntries(
          Object.entries(prevState.plans).map(([k, v]) => {
            const obj: any = v;
            return [k, { ...obj, value: '' }];
          })
        ),
        bonus: { ...prevState.plans.bonus, value: '0' }
      }
    }));
    cleanStore();
    setCurrentContact(undefined);
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <CustomInfo
            isFirstStep={true}
            setItemsToStore={setItemsToStore}
            getListSourceFetch={getListSourceFetch}
            getDataSourceFetch={getDataSourceFetch}
            data={stepsStore.steps.data}
            listSources={stepsStore.listSources}
            dataSources={stepsStore.dataSources}
            isNextButtonDisabled={!isValid('custom_step')}
            prevStep={handleBack}
            nextStep={handleNext}
            dataForm={dataForm}
            onChange={setDataForm}
            productInfo={location.state?.service ? location.state.service : location.state.requote.product}
          />
        );
      case 1:
        return (
          <GeneralInfo
            isNextButtonDisabled={!isValid('general_info')}
            nextStep={handleContactIsDone}
            prevStep={handleBack}
            dataForm={dataForm}
            productInfo={location.state?.service ? location.state.service : location.state.requote.product}
            onChange={setDataForm}
            createPersonFetch={createPersonFetch}
            getContactsByPartial={getContactsByPartial}
            dataContacts={stepsStore.contacts}
            getContactsClear={getContactsClear}
            currentContact={currentContact}
            setCurrentContact={setCurrentContact}
            //            previousStep={previousStep}
          />
        );
      case 2:
        return (
          <Plans
            getPlans={getPlans}
            nextStep={handleNext}
            prevStep={handleBack}
            isNextButtonHide={true}
            dataForm={dataForm}
            onChange={setDataForm}
            plansData={stepsStore.plans}
            sub_product_id={1}
            productInfo={location.state.requote?.product ? location.state.requote.product : location.state.service}
            goToCustomForm={() => setCurrentStep(1)}
            formatPrice={formatPrice}
            savePlan={savePlan}
            producerList={stepsStore.producer.data}
            // producerList={mockListProducers}
            setProducer={setProducer}
            producer={producer}
          />
        );
      case 3:
        return (
          <Summary
            prevStep={handleBack}
            setStep={setCurrentStep}
            dataForm={dataForm}
            cleanState={cleanState}
            formatPrice={formatPrice}
            summaryData={summaryData}
            loadedQuote={location.state.requote}
            productInfo={location.state.requote?.product ? location.state.requote.product : location.state.service}
            stepsStore={stepsStore?.plans?.data}
            // producerList={stepsStore.producer.data}
            producer={producer}
          />
        );

      default:
        return defaultRedirect;
    }
  };

  const renderForm = () => {
    return (
      <Container className={props.className}>
        {currentStep === -1 ? (
          <GeneralErrorComponent className='general-error' />
        ) : (
          <>
            <Grid item container direction='row' alignItems='center'>
              <h1>
                {t('QUOTATIONS.TITLE')}
                <TextPoint />
              </h1>
            </Grid>

            {stepsStore.steps.loading ? (
              <Loading />
            ) : (
              <>
                <Box className='labels-root'>
                  <Grid container>
                    {steps.map((label, i) => (
                      <Box key={label} className={`${currentStep === i && 'label-active'} label-item`}>
                        <Grid item>{label}</Grid>
                      </Box>
                    ))}
                  </Grid>
                </Box>
                <Stepper activeStep={currentStep} alternativeLabel steps={steps}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div>{getStepContent(currentStep)}</div>
              </>
            )}
          </>
        )}
      </Container>
    );
  };
  return <Container>{renderForm()}</Container>;
};

export const QuotationsComponent = styled(({ ...props }) => <CleanQuotationsComponent {...props} />)`
  .labels-root {
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
  }
  .label-item {
    padding-left: 8px;
    padding-right: 8px;
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .label-active {
    font-weight: bolder;
  }

  .colored-mark {
    color: var(--color-main);
    padding-left: 0.3em;
  }
  .colored-text {
    color: var(--color-main);
  }
  .MuiSwitch-track {
    border-radius: 20px;
  }
  .MuiSwitch-root {
    width: 64px;
    height: 47px;
  }
  .MuiSwitch-switchBase {
    top: 4px;
    left: 5px;
  }
  .goBackButton {
    cursor: pointer;
  }

  .general-error {
    margin: 140px 0;
  }
`;
