import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as IconArrowLeft } from '../../../../../../assets/img/icons_dynamo/arrow_left.svg';
import { ReactComponent as CartIcon } from '../../../../../../assets/img/icons_dynamo/icon_cart.svg';
import { ReactComponent as RestartIcon } from '../../../../../../assets/img/icons_dynamo/icon_restart.svg';
import { ReactComponent as RightArrowIcon } from '../../../../../../assets/img/icons_dynamo/icon_right_arrow.svg';
import { ReactComponent as DownloadArrowIcon } from '../../../../../../assets/img/icons_dynamo/icon_arrow_download.svg';
import { useTranslation } from 'react-i18next';
import { uris } from '../../../../../../siteMap';
/* eslint-disable no-unused-vars */
import { IBillingModeParams, ICustomDataForm, IPaymentMethodParams, IPaymentConditionParams } from '../models';
/* eslint-enable no-unused-vars */
import moment from 'moment';
import { Analytics } from '@wdynamo/common/lib/services';
import { StyledButton } from '@wdynamo/common';
import { Box, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { BlueBoxLabel } from '../../common/BlueBoxLabel/BlueBoxLabel';
import { AccessoriesTable } from '../AccessoriesTable';
import Api from '../Api';
import { Spinner } from '../../common/Spinner';

interface ISummaryProp {
  className?: string;
  dataForm: ICustomDataForm;
  setStep(step: number): void;
  cleanState(): void;
  formatPrice(number: number): string;
  summaryData: any;
  service: any;
  getBillingModeFetch(params: IBillingModeParams): void;
  getPaymentMethodFetch(params: IPaymentMethodParams): void;
  getPaymentConditionFetch(params: IPaymentConditionParams): void;
  getQuotationByIdFetch(id: number): any;
  loadedQuote: any;
  productInfo: any;
  stepsStore: any;
  // producerList: any;
  producer: any;
}

const CleanSummaryComponent: React.FC<ISummaryProp> = (props: ISummaryProp) => {
  const {
    dataForm,
    setStep,
    cleanState,
    formatPrice,
    loadedQuote,
    productInfo,
    summaryData,
    getBillingModeFetch,
    getPaymentMethodFetch,
    getPaymentConditionFetch,
    getQuotationByIdFetch,
    stepsStore,
    // producerList,
    producer
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const quoteData = stepsStore.plans?.data?.quote ?? loadedQuote;
  const characteristics = stepsStore.plans?.data?.quote ? stepsStore.plans.data.quote.planList : '';
  const planList = stepsStore.plans?.data?.quote?.planList ?? '';
  const billingModeList = stepsStore.billingMode.data;
  const paymentMethodList = stepsStore.paymentMethod.data;
  const paymentConditionList = stepsStore.paymentCondition.data;
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [planId, setPlanId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!billingModeList.length || !paymentMethodList.length) handleGetList();
    Analytics().pageview('car-badget | summary');
    getQuotationByIdFetch(quoteData.id);
    getPlanSelected(planList, dataForm?.plans?.code?.value, 'id');
  }, []);

  const handleGetList = () => {
    const productId = productInfo.id;
    const producer = quoteData.producer;
    const commercialPlan = quoteData.commercialPlan;
    const billingMode = quoteData.billingMethod;
    const currency = '';
    const policyType = quoteData.product?.sub_product_list[0]?.subBranchId;
    const paymentMethod = quoteData.paymentMethod;
    const company = '1';
    const branch = quoteData.product.branch;
    const date = moment(quoteData.createdAt).format('YYYYMMDD');

    getBillingModeFetch({
      subProductId: '1',
      productId: '1',
      producer: producer,
      commercialPlan: commercialPlan,
      currency: currency
    });
    getPaymentMethodFetch({
      productId: productId,
      subProductId: '1',
      commercialPlan: commercialPlan,
      billingMode: billingMode,
      currency: currency
    });
    getPaymentConditionFetch({
      company: company,
      branch: branch,
      date: date,
      producer: producer,
      commercialPlan: commercialPlan,
      billingMode: billingMode,
      currency: currency,
      policyType: policyType,
      paymentMethod: paymentMethod
    });
  };

  const getDescription = (list: any[], code: string) => {
    return list ? list.filter((item: any) => item.code === code)[0]?.description : '';
  };

  // const getName = (list: any[], code: string) => {
  //   return list ? list.filter((item: any) => item.producerCode === +code)[0]?.name : '';
  // };

  const getPlanSelected = (list: any[], code: string, type: string) => {
    if (list) {
      const planSelected = list.filter((item: any) => item.plan_code === code);
      if (type === 'charact') return planSelected[0]?.characteristics;
      if (type === 'id') return setPlanId(planSelected[0]?.id);
    }
  };

  const getCustomComponents = () => {
    return summaryData.map((field: any) => {
      const currentGroup = dataForm.custom_step[field.groupName];
      const value =
        field.type === 'SWITCH'
          ? field[currentGroup[field.name].value?.description]
          : field.name === 'value'
          ? formatPrice(Number(currentGroup.value?.value?.description))
          : currentGroup[field.name].value?.description;

      if (field.name !== 'accesories')
        return (
          <Typography key={field.name} className={field.name === 'year' ? '' : 'mt-txt'}>
            {field.label === '0 KM' ? 'Cero KM' : field.label}: <strong>{value}</strong>
          </Typography>
        );
    });
  };

  const handleClickIssue = () => {
    const quotationById = stepsStore?.quotationById?.data?.data?.data?.quote;
    history.push(uris.emissions.uri, {
      quotation: quotationById
    });
  };

  const handleClickNewQuote = () => {
    const serviceAuto = {
      ...productInfo
    };
    history.push(uris.quotationsAuto.uri, { service: serviceAuto });
    cleanState();
  };

  const handleDownloadPdf = () => {
    const quotationId = quoteData.id;
    setIsLoading(true);
    Api()
      .getDownloadPlanSelected(quotationId, planId)
      .then((response: any) => {
        if (response) {
          const url = window.URL.createObjectURL(response?.data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'plan.pdf');
          document.body.appendChild(link);
          link.click();
          setIsLoading(false);
        }
        setIsLoading(false);
      });
  };

  const showInfoCombo = () => {
    if (!showInfo) setShowInfo(true);
    else setShowInfo(false);
  };

  const navButtons: React.ReactNode = (
    <Grid container className='bttns-container'>
      <Grid item md={2} className='button-container mr'>
        <StyledButton onClick={() => setStep(2)} className='secondary go-back-button'>
          <Typography>{t('COMMONS.BUTTONS.GOBACK')}</Typography>
        </StyledButton>
      </Grid>
      <Grid item md={3} className='button-container'>
        <StyledButton onClick={() => handleClickNewQuote()} className='secondary restart-button'>
          <RestartIcon />
          <Typography className='ml'>{t('QUOTATIONS.STEP_5.BUTTONS.RESTART')}</Typography>
        </StyledButton>
      </Grid>
      <Grid item md={3} className='button-container'>
        <StyledButton onClick={handleDownloadPdf} className='secondary download-button'>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <DownloadArrowIcon />
              <Typography className='ml'>{t('QUOTATIONS.STEP_5.BUTTONS.DOWNLOAD')}</Typography>
            </>
          )}
        </StyledButton>
      </Grid>
      {/* <Grid item md={3} className='button-container hide-pdf'>
        <StyledButton onClick={() => {}} className='default '>
          {t('QUOTATIONS.STEP_5.BUTTONS.SEND')}
        </StyledButton>
      </Grid> */}
      <Grid item md={3} className='button-container'>
        <StyledButton onClick={handleClickIssue} className='default'>
          <CartIcon />
          <Typography className='ml'>{t('QUOTATIONS.STEP_5.BUTTONS.REQUEST')}</Typography>
        </StyledButton>
      </Grid>
    </Grid>
  );

  return (
    <Box className={props.className} id='printPdf' style={{ maxWidth: '1135px' }}>
      <IconArrowLeft className='icon goBackButton' width={37} height={37} onClick={() => setStep(2)} />
      <Box className='header-box'>
        <Typography className='success-label'>
          <strong>{t('QUOTATIONS.STEP_5.SUCCESS_LABEL')}</strong>
        </Typography>
        <Typography>
          {t('QUOTATIONS.STEP_5.QUOTATION_EXTERNAL_ID')}: <strong>{quoteData?.quotationExternalId}</strong>
        </Typography>
        {/* DATOS DE AUTOMOVIL */}
      </Box>
      <Grid container>
        <BlueBoxLabel label={'Datos automóvil.'} onClick={() => setStep(0)} />
        <Grid item xs={6} className='grid-padding'>
          {getCustomComponents()}
        </Grid>
        <Grid item xs={6} style={{ padding: '6px 0' }}>
          <AccessoriesTable
            accessoriesListData={stepsStore.accesory?.data}
            accessoriesValue={dataForm.custom_step?.grupo1.accesories.value.description}
            showInSummary={true}
          />
        </Grid>
      </Grid>
      {/* DATOS GENERALES */}
      {dataForm?.general_info?.email?.value !== '' && (
        <Grid container>
          <BlueBoxLabel label={'Datos generales.'} editIcon={false} />
          <Grid item xs={6} className='grid-padding'>
            {dataForm?.general_info?.name?.value !== '' && (
              <Typography>
                {t('QUOTATIONS.STEP_2.NAME')}: <strong>{dataForm?.general_info?.name?.value}</strong>
              </Typography>
            )}
            {dataForm?.general_info?.last_name?.value !== '' && (
              <Typography className={dataForm?.general_info?.name?.value && 'mt-txt'}>
                {t('QUOTATIONS.STEP_2.LAST_NAME')}: <strong>{dataForm?.general_info?.last_name?.value}</strong>
              </Typography>
            )}
            <Typography
              className={
                dataForm?.general_info?.name?.value || dataForm?.general_info?.last_name?.value ? 'mt-txt' : ''
              }
            >
              {t('HOME_QUOTATIONS.STEP_3.GENERAL_DATA.PHONE')}:{' '}
              <strong>{dataForm?.general_info?.phone_number?.value}</strong>
            </Typography>
            <Typography className='mt-txt'>
              {t('HOME_QUOTATIONS.STEP_3.GENERAL_DATA.EMAIL')}: <strong>{dataForm?.general_info?.email?.value}</strong>
            </Typography>
          </Grid>
        </Grid>
      )}
      {/* PLANES */}
      <Grid container>
        <BlueBoxLabel label={'Planes.'} onClick={() => setStep(2)} />
        <Grid item className='grid-padding'>
          <Typography>
            {t('QUOTATIONS.STEP_2.CHANNEL')}:{' '}
            {/* <strong>{getName(producerList, dataForm?.plans?.producer_code?.value)}</strong> */}
            <strong>{producer.value.name}</strong>
          </Typography>
          <Typography className='mt-txt'>
            {t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.VALIDITY')}: <strong>FACTURACIÓN ANUAL</strong>
          </Typography>
          <Typography className='mt-txt'>
            {t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.PAYMENT_METHOD')}:{' '}
            <strong>{getDescription(paymentMethodList, dataForm?.plans?.payment_method?.value)}</strong>
          </Typography>
          <Typography className='mt-txt'>
            {t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.PAYMENT_CONDITION')}:{' '}
            <strong>{getDescription(paymentConditionList, dataForm?.plans?.payment_condition?.value)}</strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid container className='mt-plans'>
        <Grid item xs={8}>
          <strong>{dataForm?.plans?.name?.value}</strong>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={4} className='plans-style'>
              <Typography>{t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.PRIZE')}</Typography>
              <strong>{formatPrice(Number(dataForm?.plans?.price?.value))}</strong>
            </Grid>
            <Grid item xs={4} className='plans-style'>
              <Typography>{t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.DUES')}</Typography>
              <strong>{+dataForm?.plans?.payment_condition?.value}</strong>
            </Grid>
            <Grid item xs={4} className='plans-style'>
              <Typography>{t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.VALUES')}</Typography>
              <strong>{formatPrice(Number(dataForm?.plans?.remaining_payments?.value))}</strong>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* DETALLES PLAN */}
      <Grid container>
        <Typography className='blue-text' onClick={showInfoCombo}>
          {t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.DETAILS')}
          <RightArrowIcon className={!showInfo ? 'blue-icon' : 'blue-icon rotate'} />
        </Typography>
      </Grid>
      {showInfo && (
        <Box>
          {getPlanSelected(characteristics, dataForm?.plans?.code?.value, 'charact').map((charact: any, index: any) => {
            return <Typography key={index}>{charact}</Typography>;
          })}
        </Box>
      )}
      {navButtons}
    </Box>
  );
};
export const SummaryComponent = styled(({ ...props }) => <CleanSummaryComponent {...props} />)`
  .goBackButton {
    position: relative;
    top: -186px;
    left: -5%;
    cursor: pointer;
  }
  .header-box {
    width: 100%;
    display: flex;
    align-items: end;
  }
  .success-label {
    flex-grow: 1;
    font-size: 20px;
  }
  svg {
    cursor: pointer;
  }
  .bttns-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10em 0 6em;
    .mr {
      margin-right: 5.8em;
    }
  }
  .button-container {
    text-align: center;
    .ml {
      margin-left: 1rem;
    }
    button {
      width: 95%;
      height: 52px;
    }
    .restart-button,
    .go-back-button,
    .download-button {
      color: var(--color-main);
      background-color: var(--color-white);
      border: 1px solid var(--color-main);
    }
    .restart-button {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .download-button {
      padding: 13px;
    }
  }
  .grid-padding {
    padding: 10px 0;
  }
  .mt-txt {
    margin-top: 13px;
  }
  .blue-text {
    color: var(--color-main);
    cursor: pointer;
    display: flex;
    align-items: center;
    .blue-icon {
      margin-left: 3px;
      fill: #fff;
      border-radius: 50%;
      background-color: var(--color-main);
    }
    .rotate {
      transform: rotate(90deg);
    }
  }
  .mt-plans {
    margin-top: 3em;
    .plans-style {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
