// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IBaseStore } from './models';

const initialState: IBaseStore = {
  userInfo: {
    loading: false,
    error: false,
    message: undefined
  }
};

export const userInfoReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    // User info
    case ACTIONS.USERS.USER_INFO.FETCH:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          loading: true,
          error: false,
          message: undefined
        }
      };
    case ACTIONS.USERS.USER_INFO.SUCCESS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          loading: false,
          error: false
        }
      };
    case ACTIONS.USERS.USER_INFO.FAIL:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          loading: false,
          error: true,
          message: action.payload?.error?.data?.errors
        }
      };

    default:
      return state;
  }
};
