import React from 'react';
import styled from 'styled-components';
import { Box, Grid, List } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface ILoadingPlanBoxProp {
  className?: string;
}

const features = [
  { hasFeature: true },
  { hasFeature: true },
  { hasFeature: true },
  { hasFeature: true },
  { hasFeature: true },
  { hasFeature: true },
  { hasFeature: false },
  { hasFeature: false }
];

const CleanLoadingPlanBox: React.FC<ILoadingPlanBoxProp> = (props: ILoadingPlanBoxProp) => {
  const style = { className: 'regular-card' };

  return (
    <Grid item sm={4} className={props.className}>
      <Box className={`${style.className} card`}>
        <Skeleton variant='rect' height={60} width='100%' animation='wave' />
        <Grid direction='column' container>
          <Grid className='card-header'>
            <Skeleton variant='rect' width='100%' height={70} />
          </Grid>
          <Grid item className='card-content'>
            <Skeleton height={25} width='50%' animation='wave' />
            <Skeleton height={25} width='95%' animation='wave' />
            <Skeleton height={25} width='90%' animation='wave' />

            <List dense={true}>
              {features.map((_item, i) => (
                <Skeleton key={i} height={25} width='80%' animation='wave' />
              ))}
            </List>
          </Grid>
          <Grid item container className='card-action' justify='center'>
            <Skeleton variant='rect' height={35} width='60%' animation='wave' />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
export const LoadingPlanBox = styled(({ ...props }) => <CleanLoadingPlanBox {...props} />)`
  .MuiSkeleton-text {
    padding: 4px;
  }
  .card-content {
    padding: 0.5em 1.5em;
  }
  .card-action {
    padding: 1.3em 1em;
  }
  .card {
    border-radius: 15px;
  }
  button {
    padding: 5px 50px;
  }
  .first-payment {
    color: var(--color-grey-border);
    font-size: x-large;
    font-weight: bold;
    padding-right: 5px;
  }
  .remaining-payment {
    color: var(--color-grey-border);
    font-size: larger;
    font-weight: bold;
    padding-right: 5px;
  }

  .recommended-card {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;

    .card-header {
      padding: 1em;
      background-color: var(--color-grey-border);
      color: var(--color-white);
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      text-align: center;
    }
    button {
      background-color: var(--color-grey-border);
    }
  }
  .regular-card {
    background-color: rgb(0, 0, 0, 2%);

    .card-header {
      display: none;
    }
    button {
      background-color: var(--color-white);
      color: var(--color-grey-border);
      border: solid 1px var(--color-grey-border);
    }
  }
  #Group {
    fill: var(--color-grey-border);
  }
  svg {
    width: 15px;
  }
  .MuiListItem-gutters {
    padding-left: 0;
  }
  .MuiListItemIcon-root {
    min-width: 25px;
  }
  .light {
    opacity: 0.2;
  }
`;
