import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormControl, Grid, Select, MenuItem, Typography } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import Pagination from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';
import { CustomInfiniteScroll, Loading } from '@wdynamo/common';
import { ModalComponent } from '../../components';
/* eslint-disable no-unused-vars */
import { History } from 'history';
import { IgetQouteManagementParams, IInitialState } from './models';
import { uris } from '../../siteMap';
import { IconLabel } from '../../components/IconLabel/IconLabel';
import { ReactComponent as PlayIcon } from '../../assets/img/icons_dynamo/play.svg';
import { ReactComponent as ExcelIcon } from '../../assets/img/icons_dynamo/icon_excel.svg';
import { ReactComponent as DocumentIcon } from '../../assets/img/icons_dynamo/icon_document_exclamation.svg';
import { ReactComponent as RestartIcon } from '../../assets/img/icons_dynamo/icon_restart_circle.svg';
import SummaryModal from './components/SummaryModal/SummaryModal';
import { ReactComponent as DownloadIcon } from '../../assets/img/icons_dynamo/icon_download_circle.svg';
import { ReactComponent as SignatureIcon } from '../../assets/img/icons_dynamo/aceptacion.svg';
import XLSX from 'xlsx';
import Api from './Api';
import moment from 'moment';
import { IAccesoryParams, IPaymentMethodParams } from '../Services/Quotations/components/AutoQuotation/models';
import TransparentTooltip from '../../components/Tooltip/Tooltip';

interface QuoteManagementProps {
  className: string;
  history: History;
  location: Location;
  getQouteManagementInfoFetch(params: IgetQouteManagementParams): void;
  getAllItemsFetch(params: IgetQouteManagementParams): void;
  getPaymentMethodFetch(params: IPaymentMethodParams): void;
  getAccessoryFetch(params: IAccesoryParams): void;
  quoteManagementStore: IInitialState;
  quoteManagementeAllStore: IInitialState;
  getClearAllItemsFetch(): any;
  getQuotationByIdFetch(id: number): any;
  getQuotationByIdFetchClean(): any;
  stepsStore: any;
  cleanStore: any;
  getProductsFetch(): void;
  getProducerFetch(): void;
}

const CleanQuoteManagementComponent: React.FC<QuoteManagementProps> = (props: QuoteManagementProps) => {
  const {
    className,
    history,
    location,
    getQouteManagementInfoFetch,
    quoteManagementStore,
    getAllItemsFetch,
    getPaymentMethodFetch,
    getAccessoryFetch,
    quoteManagementeAllStore,
    getClearAllItemsFetch,
    getQuotationByIdFetch,
    getQuotationByIdFetchClean,
    stepsStore,
    getProductsFetch,
    getProducerFetch
  } = props;
  const { t } = useTranslation();
  const [loader, setLoader] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [searched, setSearched] = useState<string>('');
  const [redirectedFromMenu, setRedirectedFromMenu] = useState<boolean>(false);
  const [summaryModalOpen, setSummaryModalOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [productList, setProductList] = useState<any>([]);
  const [product, setProduct] = useState(1);

  const salesPath = '/sales';
  const showSales = location.pathname === salesPath;
  const sort = 'customer.lastName';
  const quoteList = quoteManagementStore.quotesInfo.data?.items ? quoteManagementStore.quotesInfo.data.items : [];
  const allSalesList = quoteManagementeAllStore?.quotesInfo?.data?.items
    ? quoteManagementeAllStore.quotesInfo.data.items
    : [];
  const statusPreEmit = 'Pre-emitir';
  const statusEmit = 'Emitido';
  const statusError = 'Error';
  const quoteStatus = 'QUOTE,RE_QUOTE,PRE_ISSUE';
  const salesSatus =
    'ISSUE,ISSUE_REJECTED,PENDING_INSPECTION,INSPECTION_REJECTED,PENDING_SIGNATURE,SIGNATURE_REJECTED,PAYMENT_REJECTED,EMITTED';

  const getFormattedDate = (transactionDate: string) => {
    const formatedDate = new Date(transactionDate);
    const splittedDate = formatedDate.toISOString().split('T')[0];
    const [year, month, day] = splittedDate.split('-') as unknown[] as number[];
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    handleGetQuoteManagementeFetch();
    getProductsFetch();
  }, []);

  useEffect(() => {
    handleGetQuoteManagementeFetch();
  }, [product]);

  useEffect(() => {
    if (
      quoteManagementStore.quotesInfo.data &&
      !quoteManagementStore.quotesInfo.loading &&
      !quoteManagementStore.quotesInfo.error
    ) {
      setLoader(false);
      setTotalPages(quoteManagementStore.quotesInfo.data.total_pages);
    }
    if (quoteManagementStore.quotesInfo.loading) setLoader(true);
  }, [quoteManagementStore.quotesInfo.loading]);

  useEffect(() => {
    if (allSalesList.length) {
      downloadExcel();
    }
  }, [quoteManagementeAllStore]);

  useEffect(() => {
    setProductList(quoteManagementStore.products?.data);
  }, [quoteManagementStore]);

  // const handleChangeTab = () => {
  //   console.log('modificar por logica');
  // };

  useEffect(() => {
    if (!redirectedFromMenu) {
      getQuotationByIdFetchClean();
    }
  }, [stepsStore?.quotationById?.data?.data?.data?.quote]);

  useEffect(() => {
    const objQuotation = stepsStore?.quotationById?.data?.data?.data?.quote;
    if (stepsStore?.quotationById?.data?.data?.data?.quote && redirectedFromMenu) {
      history.push(uris.emissions.uri, { quotation: objQuotation });
      setRedirectedFromMenu(false);
    }
  }, [stepsStore?.quotationById?.data?.data?.data?.quote]);

  const handleGetQuoteManagementeFetch = (search = '', page = 1, pagelen = 10) => {
    const params: IgetQouteManagementParams = {
      product,
      page,
      pagelen,
      search,
      sort,
      status: quoteStatus
    };
    if (showSales) {
      getQouteManagementInfoFetch({
        ...params,
        status: salesSatus
      });
    } else {
      getQouteManagementInfoFetch(params);
    }
  };

  const handlegetAllItemsFetch = (search = '', page = 1, pagelen = 100) => {
    const params: IgetQouteManagementParams = {
      product,
      page,
      pagelen,
      search,
      sort,
      status: salesSatus
    };
    getAllItemsFetch(params);
  };

  const handleSearch = (value: string) => {
    setSearched(value);
    if (value.length > 5) {
      handleGetQuoteManagementeFetch(value);
    } else if (!value) {
      setSearched('');
      handleGetQuoteManagementeFetch(value);
    }
  };

  const handleCancelSearch = () => {
    handleSearch('');
  };

  const handleSummaryClick = (quoteId: number) => {
    getQuotationByIdFetch(quoteId);
    setSummaryModalOpen(true);
  };

  const handleRequoteClick = (quote: any) => {
    if (!quote) return;
    const product = productList.find((prod: any) => prod.id === quote.productId);
    const fullName = quote.clientName.split(', ');
    const firstName = fullName[1];
    const lastName = fullName[0];
    if (product) {
      const quotationInfo = {
        service: {
          product: quote.service,
          branch: product.branch,
          policytype: product.sub_product_list.map((subProd: any) => subProd.sub_branch_id)
        },
        requote: {
          customer: {
            id: quote.clientId,
            mail: quote.clientMail,
            phone: quote.clientPhone,
            name: firstName,
            last_name: lastName,
            insuranceType: ''
          }
        }
      };
      history.push(uris.quotationsAuto.uri, { ...quotationInfo });
    }
  };

  const onClickDownloadExcel = () => {
    handlegetAllItemsFetch();
  };

  const downloadExcel = () => {
    const headers = [
      [
        'Id de cliente',
        'Email',
        'Contacto',
        'Teléfono',
        'Fecha',
        'Rol',
        'Prima/ Precio de venta',
        'Estado',
        'Solicitud N°',
        'Producto',
        'Vendedor'
      ]
    ];

    const newAllSalesList = allSalesList?.map((sale: any) => {
      if (sale.clientName === 'null, null' || sale.clientName === ', ') {
        return { ...sale, clientName: '', createdAt: moment(sale.createdAt).format('YYYY-MM-DD') };
      }
      return { ...sale, createdAt: moment(sale.createdAt).format('YYYY-MM-DD') };
    });

    if (newAllSalesList.length) {
      const quoteListForExport = newAllSalesList?.map((quote: any) => {
        delete quote.codSeller;
        delete quote.fullName;
        delete quote.id;
        delete quote.planCode;
        delete quote.productId;
        return quote;
      });
      const wb = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

      XLSX.utils.sheet_add_aoa(ws, headers);
      XLSX.utils.sheet_add_json(ws, quoteListForExport, { origin: 'A2', skipHeader: true });
      XLSX.utils.book_append_sheet(wb, ws, 'cotizaciones');

      const colNum = XLSX.utils.decode_col('G');
      const fmt = '0.00 ₲';
      const range = XLSX.utils.decode_range(ws['!ref'] ? ws['!ref'] : '');
      for (let i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        const ref = XLSX.utils.encode_cell({ r: i, c: colNum });
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!ws[ref]) continue;
        /* `.t == "n"` for number cells */
        if (ws[ref].t !== 'n') continue;
        /* assign the `.z` number format */
        ws[ref].z = fmt;
      }
      XLSX.writeFile(wb, 'Cotizaciones.xlsx');
      getClearAllItemsFetch();
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
    handleGetQuoteManagementeFetch(searched, value);
  };

  const handleDownloadPDF = (id: any) => {
    setOpenModal(true);
    new Api().getDownloadPDF(id).then((response: any) => {
      if (response) {
        const url = window.URL.createObjectURL(response?.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'solicitud-de-emision.pdf');
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSignatureModal = () => {
    return (
      <ModalComponent
        open={openModal}
        handleCloseCrossModal={handleCloseModal}
        onClickButton={handleCloseModal}
        title={t('QUOTE_MANAGEMENT.SIGNATURE_MODAL.TITLE')}
        icon={<SignatureIcon width={200} height={200} />}
        description={
          <div>
            {t('QUOTE_MANAGEMENT.SIGNATURE_MODAL.DESCRIPTION')}
            <br></br>
            {t('QUOTE_MANAGEMENT.SIGNATURE_MODAL.DESCRIPTION_')}
          </div>
        }
      />
    );
  };

  const handleChange = (value: any) => {
    setProduct(value);
  };

  return (
    <div className={className}>
      {/* HEADER CONTAINER START */}
      <Grid container className='header-container'>
        <Grid item xs={6}>
          {showSales ? (
            <h3 className='main-title'>{t('QUOTE_MANAGEMENT.SALE.TITLE')}</h3>
          ) : (
            <h3 className='main-title'>{t('QUOTE_MANAGEMENT.QUOTE.TITLE')}</h3>
          )}
        </Grid>
        <Grid item container xs={6} direction='row' justifyContent='flex-end'>
          <Grid item xs={10} className='div-searcher'>
            <SearchBar
              value={searched}
              onChange={handleSearch}
              onCancelSearch={handleCancelSearch}
              placeholder={t('CONTACTS.PLACEHOLDER_SEARCHER')}
              className='search-bar'
            />
            <FormControl variant='standard' className='form-control'>
              <Select
                labelId='demo-multiple-name-label'
                id='demo-multiple-name'
                type={'select'}
                value={product}
                className='select-product'
                onChange={(e) => handleChange(e.target.value)}
              >
                {productList?.map((product: any, index: number) => (
                  <MenuItem key={index} value={product.id} style={{ color: 'black', paddingRight: '0.3rem' }}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* <Grid item xs={6}>
          {showSales ? (
            <Tabs value={value} onChange={handleChangeTab}>
              <Tab label='Mis ventas' />
              <Tab label='Ventas grupales' />
            </Tabs>
          ) : (
            <Tabs value={value} onChange={handleChangeTab}>
              <Tab label='Mis cotizaciones' />
              <Tab label='Cotizaciones grupales' />
            </Tabs>
          )}
        </Grid> */}
        <Grid item xs={12} container direction='row' justifyContent='flex-end'>
          {showSales ? (
            <IconLabel
              onClick={() => onClickDownloadExcel()}
              className='icon-container'
              icon={ExcelIcon}
              label={t('QUOTE_MANAGEMENT.EXPORT_EXCEL')}
            />
          ) : (
            <IconLabel
              onClick={() => history.push(uris.services.uri)}
              className='icon-container'
              label={t('QUOTE_MANAGEMENT.QUOTE.NEW_QUOTE')}
            />
          )}
        </Grid>
      </Grid>
      {/* HEADER CONTAINER END */}

      {/* MIS COTIZACIONES START */}
      <Grid container className='quotes-grid'>
        {quoteManagementStore.quotesInfo.error ? (
          <Typography>
            {t('QUOTE_MANAGEMENT.ERROR.GENERAL')}{' '}
            {!showSales ? (
              <>
                {t('QUOTE_MANAGEMENT.ERROR.QUOTE')}{' '}
                <a className='link' onClick={() => history.push(uris.services.uri)}>
                  {t('QUOTE_MANAGEMENT.ERROR.QUOTE_LINK')}
                </a>
              </>
            ) : (
              <span>{t('QUOTE_MANAGEMENT.ERROR.SALES')}</span>
            )}
          </Typography>
        ) : (
          <Grid item xs={12} className='header'>
            <Grid container wrap='nowrap'>
              <Grid item xs={2}>
                {t('QUOTE_MANAGEMENT.TABLE.DATE')}
              </Grid>
              <Grid item xs={2}>
                {t('QUOTE_MANAGEMENT.TABLE.REQUEST')}
              </Grid>
              <Grid item xs={3}>
                {t('QUOTE_MANAGEMENT.TABLE.CONTACT')}
              </Grid>
              <Grid item xs={2}>
                {t('QUOTE_MANAGEMENT.TABLE.PRODUCT')}
              </Grid>
              <Grid item xs={2}>
                {t('QUOTE_MANAGEMENT.TABLE.STATE')}
              </Grid>
              <Grid item xs={2} />
            </Grid>
          </Grid>
        )}
        {loader ? (
          <Loading />
        ) : searched && !quoteList?.length ? (
          `${t('QUOTE_MANAGEMENT.TABLE.FIRST_ERROR_WORD_NOT_FOUND')} ${searched}. ${t(
            'QUOTE_MANAGEMENT.TABLE.SECOND_ERROR_WORD_NOT_FOUND'
          )}`
        ) : (
          <Grid item xs={12} className='content'>
            <CustomInfiniteScroll
              //  nextUrl={nextPage}
              // paginate={handleChangePage}
              paginate={() => {}}
              firstLoading={false}
            >
              {quoteList.map((quote: any, i: number) => (
                <Grid
                  onClick={() => {
                    // getQuotationByIdFetch(quote.quotationExternalId);
                    // history.push(uris.emissions.uri, { quotation: quotationMock });
                  }}
                  container
                  key={i}
                  className='quote'
                  wrap='nowrap'
                >
                  <Grid item xs={2}>
                    {moment(quote?.createdAt).format('YYYY-MM-DD')}
                  </Grid>
                  <Grid item xs={2}>
                    {quote?.request}
                  </Grid>
                  <Grid item xs={3}>
                    {quote?.fullName?.trim() === '' || quote?.fullName?.includes('null')
                      ? quote?.clientMail
                      : quote?.fullName}
                  </Grid>
                  <Grid item xs={2}>
                    {quote?.service}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={
                      quote?.quotationStatus?.includes(statusEmit)
                        ? 'green-text'
                        : quote?.quotationStatus?.includes(statusError)
                        ? 'red-text'
                        : ''
                    }
                  >
                    {quote?.quotationStatus === statusPreEmit ? 'Pendiente de emisión' : quote?.quotationStatus}
                  </Grid>
                  <Grid item xs={2} style={{ padding: 0 }}>
                    <Grid container wrap='nowrap' style={{ justifyContent: 'center', padding: 0 }}>
                      {!showSales ? (
                        <>
                          <Grid item xs={2} className='table-icon'>
                            <TransparentTooltip title={t('QUOTE_MANAGEMENT.TABLE.SUMMARY')}>
                              <DocumentIcon onClick={() => handleSummaryClick(quote.id)} />
                            </TransparentTooltip>
                          </Grid>
                          <Grid item xs={2} className='table-icon'>
                            <TransparentTooltip title={t('QUOTE_MANAGEMENT.TABLE.RESTART')}>
                              <RestartIcon onClick={() => handleRequoteClick(quote)} />
                            </TransparentTooltip>
                          </Grid>
                          <Grid item xs={2} className='table-icon'>
                            <TransparentTooltip title={t('QUOTE_MANAGEMENT.TABLE.ISSUE')}>
                              <PlayIcon
                                className={quote.quotationStatus === statusEmit ? 'disabled icon' : 'icon'}
                                onClick={() => {
                                  getQuotationByIdFetch(quote.id);
                                  setRedirectedFromMenu(true);
                                  /*history.push(uris.emissions.uri, { quotation: quote })*/
                                }}
                              />
                            </TransparentTooltip>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={2} className='table-icon'>
                            <TransparentTooltip title={t('QUOTE_MANAGEMENT.TABLE.SIGNATURE')}>
                              <DownloadIcon
                                width={25}
                                height={25}
                                onClick={() => {
                                  handleDownloadPDF(quote.id);
                                }}
                              />
                            </TransparentTooltip>
                          </Grid>
                          <Grid item xs={2} className='table-icon'>
                            <TransparentTooltip title={t('QUOTE_MANAGEMENT.TABLE.ISSUE')}>
                              <PlayIcon
                                className={quote.quotationStatus === statusEmit ? 'disabled icon' : 'icon'}
                                onClick={() => {
                                  getQuotationByIdFetch(quote.id);
                                  setRedirectedFromMenu(true);
                                  /*history.push(uris.emissions.uri, { quotation: quote })*/
                                }}
                              />
                            </TransparentTooltip>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </CustomInfiniteScroll>
          </Grid>
        )}
        {openModal && handleSignatureModal()}
      </Grid>
      {/* MIS COTIZACIONES END */}

      {/* {PAGINATION START} */}
      {Boolean(quoteList.length) && (
        <Grid item className='paginator-cont'>
          <Pagination
            count={totalPages}
            page={pageNumber}
            boundaryCount={1}
            variant='outlined'
            onChange={handlePageChange}
          />
        </Grid>
      )}

      {/* SUMMARY MODAL */}
      {!showSales && (
        <SummaryModal
          open={summaryModalOpen}
          onClose={() => {
            setSummaryModalOpen(false);
          }}
          quoteData={stepsStore.quotationById?.data?.data?.data?.quote}
          loading={stepsStore.quotationById?.loading ?? true}
          getPaymentMethodFetch={getPaymentMethodFetch}
          getAccessoryFetch={getAccessoryFetch}
          stepsStore={stepsStore}
          // getProducerFetch={getProducerFetch}
        />
      )}
    </div>
  );
};

export const QuoteManagementComponent = styled(({ ...props }) => <CleanQuoteManagementComponent {...props} />)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .header-container {
    margin-bottom: 2em;
  }
  .div-searcher {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
  }
  .search-bar {
    border: 1px solid #b1acac;
    box-sizing: border-box;
    border-radius: 10px 1px 1px 10px;
    box-shadow: none;
    height: 2.5rem;
    width: 15rem;
  }
  .select-product {
    background-color: var(--color-main);
    color: white;
    width: 15rem;
    height: 2.5rem;
    border: none;
    border-style: none;
    text-align: center;
    border-radius: 0 10px 10px 0;
  }
  .link {
    cursor: pointer;
    border-bottom: 1px solid var(--color-main);
    color: var(--color-main);
  }
  .form-control {
    padding: 0 inherit;
    min-width: 15rem;
    height: 2.5rem;
    border-style: none;
    border-radius: 0 10px 10px 0;
  }
  .icon-container {
    padding: 0 2em;
  }
  .quotes-grid {
    .header {
      font-weight: bold;
      text-align: left;
      .icon-title {
        text-align: center;
      }
    }
    div {
      padding: 5px 0;
    }
    .content {
      padding: 0;
      .quote {
        padding: 10px 0;
        border-bottom: 1px solid var(--color-grey-border);
        cursor: pointer;
      }
      .green-text {
        color: var(--color-green);
      }
      .red-text {
        color: red;
      }
    }
  }
  .table-icon {
    margin-right: 8px;
  }
  .paginator-cont {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    margin: 2rem 0;
    width: 100%;
    height: 100%;
  }
`;
