import React, { useRef } from 'react';
import { StyledButton } from '@wdynamo/common';

import { useTranslation } from 'react-i18next';

interface ImageUploaderProps {
  onImageSelected(imageSelected: string): void;
}

export const ImageUploader = (props: ImageUploaderProps) => {
  const { t } = useTranslation();
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileInput = (e: any) => {
    props.onImageSelected(e.target.files[0]);
  };

  return (
    <>
      <input type='file' onChange={handleFileInput} ref={fileInput} style={{ display: 'none' }} />
      <StyledButton className='small fullWidth' onClick={() => fileInput.current && fileInput.current.click()}>
        {t('SETTINGS.LOGO.UPLOAD_IMAGE')}
      </StyledButton>
    </>
  );
};

export default ImageUploader;
