import { connect } from 'react-redux';
import { UserInfoComponent } from './view';
import { userInfoFetch } from './actions';
import { getRolesFetch } from '../Roles/RolesList/actions';
import { getParentsFetch } from '../Groups/actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../store';

const mapStateToProps = (state: RootState) => ({
  userInfoStore: state.userInfoState,
  groupsStore: state.groupsState,
  rolesStore: state.rolesState
});
const mapDispatchToProps = { userInfoFetch, getRolesFetch, getParentsFetch };

export const UserInfo = connect(mapStateToProps, mapDispatchToProps)(UserInfoComponent);
export { userInfoSaga } from './sagas';
export { userInfoReducer } from './reducers';
export type { IBaseStore } from './models';
