// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IAuthProps } from './models';

const initialState: IAuthProps = {
  currentUserInfo: {
    userJustLoggedIn: false
  }
};

export const authReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.AUTH.UPDATE_CURRENT_USER_INFO: {
      return {
        ...state,
        currentUserInfo: {
          ...state.currentUserInfo,
          ...action.payload
        }
      };
    }

    default:
      return state;
  }
};
