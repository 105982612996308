import { ACTIONS } from '../ActionTypes';

// Search Products
export const getProductsFetch = () => ({ type: ACTIONS.PRODUCTS.LIST.FETCH });

export const getProductsSuccess = (data: any) => ({ type: ACTIONS.PRODUCTS.LIST.SUCCESS, payload: { data } });

export const getProductsFail = (error: object) => ({ type: ACTIONS.PRODUCTS.LIST.FAIL, payload: { error } });

// Choice a product
export const choiceProduct = (product: object) => ({ type: ACTIONS.PRODUCTS.CHOICE, payload: { data: product } });
