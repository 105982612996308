// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../../ActionTypes';

// eslint-disable-next-line no-unused-vars
import { IBaseHomeStore } from './model';

const initialState: IBaseHomeStore = {
  steps: {
    loading: false,
    error: false,
    message: '',
    data: {}
  },
  listSources: {
    loading: false,
    error: false,
    data: {}
  },
  dataSources: {
    loading: false,
    error: false,
    data: {}
  },
  createContact: {
    loading: false,
    error: false,
    data: {}
  },
  cities: {
    loading: false,
    error: false,
    data: []
  },
  plans: {
    loading: false,
    error: false,
    data: {}
  },
  billingMode: {
    loading: false,
    error: false,
    data: []
  },
  paymentMethod: {
    loading: false,
    error: false,
    data: []
  },
  paymentCondition: {
    loading: false,
    error: false,
    data: []
  },
  update: {
    loading: false,
    error: false,
    data: {}
  }
};

export const quotationHomeStepsReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.HOME_QUOTATIONS.STEPS.FETCH:
      return {
        ...state,
        steps: {
          ...state.steps,
          loading: true,
          error: false
        }
      };
    case ACTIONS.HOME_QUOTATIONS.STEPS.SUCCESS:
      return {
        ...state,
        steps: {
          ...state.steps,
          loading: false,
          error: false,
          data: { ...state.steps.data, ...action.payload.data.input_flow[0] }
          // data: { ...state.steps.data, ...action.payload.data }
        }
      };
    case ACTIONS.HOME_QUOTATIONS.STEPS.FAIL:
      return {
        ...state,
        steps: {
          ...state.steps,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };

    case ACTIONS.HOME_QUOTATIONS.LISTSOURCES.FETCH:
      return {
        ...state,
        listSources: {
          ...state.listSources,
          loading: true,
          error: false
        }
      };
    case ACTIONS.HOME_QUOTATIONS.LISTSOURCES.SUCCESS:
      return {
        ...state,
        listSources: {
          ...state.listSources,
          loading: false,
          error: false,
          data: { ...state.listSources.data, ...action.payload }
        }
      };
    case ACTIONS.HOME_QUOTATIONS.LISTSOURCES.FAIL:
      return {
        ...state,
        listSources: {
          ...state.listSources,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.HOME_QUOTATIONS.LISTSOURCES.SET_ITEMS_FROM_INPUTTER:
      return {
        ...state,
        listSources: {
          ...state.listSources,
          loading: false,
          error: false,
          data: { ...state.listSources.data, ...action.payload }
        }
      };
    case ACTIONS.HOME_QUOTATIONS.DATASOURCES.FETCH:
      return {
        ...state,
        dataSources: {
          ...state.dataSources,
          loading: true,
          error: false
        }
      };
    case ACTIONS.HOME_QUOTATIONS.DATASOURCES.SUCCESS:
      return {
        ...state,
        dataSources: {
          ...state.listSources,
          loading: false,
          error: false,
          data: { ...state.dataSources.data, ...action.payload }
        }
      };
    case ACTIONS.HOME_QUOTATIONS.DATASOURCES.FAIL:
      return {
        ...state,
        dataSources: {
          ...state.dataSources,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.HOME_QUOTATIONS.CREATE_CONTACT.FETCH:
      return {
        ...state,
        createContact: {
          ...state.createContact,
          loading: false,
          error: false
        }
      };
    case ACTIONS.HOME_QUOTATIONS.CREATE_CONTACT.SUCCESS:
      return {
        ...state,
        createContact: {
          ...state.createContact,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.HOME_QUOTATIONS.CREATE_CONTACT.FAIL:
      return {
        ...state,
        createContact: {
          ...state.createContact,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.HOME_QUOTATIONS.CITIES.FETCH:
      return {
        ...state,
        cities: {
          ...state.cities,
          loading: true,
          error: false
        }
      };
    case ACTIONS.HOME_QUOTATIONS.CITIES.SUCCESS:
      return {
        ...state,
        cities: {
          ...state.cities,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.HOME_QUOTATIONS.CITIES.FAIL:
      return {
        ...state,
        cities: {
          ...state.cities,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.HOME_QUOTATIONS.PLANS.FETCH:
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: true,
          error: false
        }
      };
    case ACTIONS.HOME_QUOTATIONS.PLANS.SUCCESS:
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: false,
          error: false,
          data: action.payload.data
        }
      };
    case ACTIONS.HOME_QUOTATIONS.PLANS.FAIL:
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.HOME_QUOTATIONS.BILLING_MODE.FETCH:
      return {
        ...state,
        billingMode: {
          ...state.billingMode,
          loading: true,
          error: false
        }
      };
    case ACTIONS.HOME_QUOTATIONS.BILLING_MODE.SUCCESS:
      return {
        ...state,
        billingMode: {
          ...state.billingMode,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.HOME_QUOTATIONS.BILLING_MODE.FAIL:
      return {
        ...state,
        billingMode: {
          ...state.billingMode,
          loadind: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.HOME_QUOTATIONS.PAYMENT_METHOD.FETCH:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          loading: true,
          error: false
        }
      };
    case ACTIONS.HOME_QUOTATIONS.PAYMENT_METHOD.SUCCESS:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.HOME_QUOTATIONS.PAYMENT_METHOD.FAIL:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.HOME_QUOTATIONS.PAYMENT_CONDITION.FETCH:
      return {
        ...state,
        paymentCondition: {
          ...state.paymentCondition,
          loading: true,
          error: false
        }
      };
    case ACTIONS.HOME_QUOTATIONS.PAYMENT_CONDITION.SUCCESS:
      return {
        ...state,
        paymentCondition: {
          ...state.paymentCondition,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.HOME_QUOTATIONS.PAYMENT_CONDITION.FAIL:
      return {
        ...state,
        paymentCondition: {
          ...state.paymentCondition,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };

    case ACTIONS.HOME_QUOTATIONS.UPDATE.FETCH:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
          error: false
        }
      };
    case ACTIONS.HOME_QUOTATIONS.UPDATE.SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.HOME_QUOTATIONS.UPDATE.FAIL:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    //LIMPIAR EL STORE
    case ACTIONS.HOME_QUOTATIONS.CLEAN:
      return { ...initialState };

    default:
      return state;
  }
};
