import { connect } from 'react-redux';
import { GroupInfoComponent } from './view';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../../../store';
import { groupInfoFetch, getGroupMembersFetch, deleteMemberFetch } from './actions';
import { getFilteredUsersByTextFetch } from '../../../../actions';
import { getRolesFetch } from '../../../Roles/RolesList/actions';

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state: RootState) => ({
  groupInfoStore: state.groupInfoState,
  groupsStore: state.groupsState,
  usersStore: state.usersState,
  rolesStore: state.rolesState
});
const mapDispatchToProps = {
  groupInfoFetch,
  getGroupMembersFetch,
  getFilteredUsersByTextFetch,
  deleteMemberFetch,
  getRolesFetch
};
export { groupInfoReducer } from './reducers';
export const GroupInfo = connect(mapStateToProps, mapDispatchToProps)(GroupInfoComponent);
export { groupSaga } from './sagas';
export type { IBaseStore } from './models';
