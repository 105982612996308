import { connect } from 'react-redux';

import { EmissionsComponent } from './view';
import {
  getBillingModeFetch,
  getPaymentMethodFetch,
  getPaymentConditionFetch
} from '../Quotations/components/AutoQuotation/actions';
import {
  getStepsFetch,
  getBankFetch,
  getCardFetch,
  getNationalityFetch,
  getProvinceFetch,
  getCityFetch,
  getNeighborhoodFetch,
  getIdTypeFetch,
  getMaritalStatusFetch,
  getActivityFetch,
  issueAddFetch,
  cleanStore,
  clearError,
  getListSourceFetch,
  initializePersistenceFetch,
  saveFirstStepFetch,
  saveSecondStepFetch,
  // saveThirdStepFetch,
  saveFourthStepFetch,
  getQuotationByIdFetch,
  getSecondInstallmentDateFetch,
  getSourceFundFetch
} from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../store';

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state: RootState) => ({
  stepsStore: {
    ...state.emissionState,
    billingMode: state.quotationState.billingMode,
    paymentMethod: state.quotationState.paymentMethod,
    paymentCondition: state.quotationState.paymentCondition
  }
});
const mapDispatchToProps = {
  getStepsFetch,
  getBillingModeFetch,
  getPaymentMethodFetch,
  getPaymentConditionFetch,
  getBankFetch,
  getCardFetch,
  getNationalityFetch,
  getProvinceFetch,
  getCityFetch,
  getNeighborhoodFetch,
  getIdTypeFetch,
  getMaritalStatusFetch,
  getActivityFetch,
  issueAddFetch,
  cleanStore,
  clearError,
  getListSourceFetch,
  initializePersistenceFetch,
  saveFirstStepFetch,
  saveSecondStepFetch,
  // saveThirdStepFetch,
  saveFourthStepFetch,
  getQuotationByIdFetch,
  getSecondInstallmentDateFetch,
  getSourceFundFetch
};

export { emissionsSaga } from './sagas';
export { emissionStepsReducer } from './reducers';
export const Emissions = connect(mapStateToProps, mapDispatchToProps)(EmissionsComponent);
