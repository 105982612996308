import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Grid } from '@material-ui/core';
import { Date, Price, Loading, CustomInfiniteScroll, Modal, TextPoint } from '@wdynamo/common';
import { OrderStatus } from '../../../../components';
// eslint-disable-next-line no-unused-vars
import { IReportsOrders, IReportsCollaborator, IReportsOrdersStore, IReportsItem } from '../../models';
interface IOrdersTableProps {
  orders: IReportsOrdersStore;
  firstLoading: boolean;
  paginate(nextPage: string): void;
  className?: string;
  [key: string]: any;
}

const initialOrder = {
  id: 0,
  created_at: '',
  items: [
    {
      title: '',
      price: { amount: 0, currency: { iso_code: '', name: '', symbol: '' } },
      amount: 0
    }
  ],
  customer: {
    legal_name: '',
    first_name: '',
    last_name: '',
    person_type: '',
    email: '',
    identity: { value: '', type: '' }
  },
  collaborator: {
    id: '',
    first_name: '',
    last_name: '',
    group_name: '',
    username: '',
    parent_collaborators: [{ id: '', first_name: '', last_name: '', group_name: '', username: '' }]
  },
  status: '',
  payment_current_status: {
    status: ''
  }
};

const CleanOrdersTable: React.FC<IOrdersTableProps> = (props: IOrdersTableProps) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { loading, orders, nextPage } = props.orders;
  const [isPaginating, setIsPaginating] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<IReportsOrders>(initialOrder);

  useEffect(() => {
    setIsPaginating(loading);
  }, [loading]);

  const handleChangePage = (): void => {
    if (nextPage && !loading && !isPaginating) {
      setIsPaginating(true);
      props.paginate(nextPage);
    }
  };

  const SalesGrid = styled(Grid)`
    font-size: 18px;
    .headers {
      font-weight: 600;
      border: none;
    }
    .row {
      margin-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--color-grey-border);
      &:last-child {
        border: none;
      }
    }
    .total {
      text-align: end;
      &.field {
        font-size: 26px;
        color: var(--color-black);
      }
    }
    .created-at {
      margin-right: 15px;
    }
  `;

  const renderModal = (): React.ReactNode => (
    <Modal
      open={openModal}
      handleClose={() => {
        setSelectedOrder(initialOrder);
        setOpenModal(false);
      }}
      className='order-modal padding'
      title={
        <h2>
          {t('MY_SALES.TABLE.SOLD_MODAL.TITLE')}
          <TextPoint />
        </h2>
      }
      body={
        <>
          {selectedOrder.items && (
            <Grid container>
              <Grid item xs={12} sm={6} className='item-container'>
                <div className='item-label'>{t('MY_SALES.TABLE.SOLD_MODAL.ORDER')}</div>
                <div className='item-value'>{selectedOrder.id}</div>
              </Grid>
              <Grid item xs={12} sm={6} className='item-container'>
                <div className='item-label'>{t('MY_SALES.TABLE.SOLD_MODAL.CREATED_AT')}</div>
                <Date date={selectedOrder.created_at} format={process.env.REACT_APP_DATE_FORMAT!} />
              </Grid>
              <Grid item xs={12} sm={6} className='item-container'>
                <div className='item-label'>{t('MY_SALES.TABLE.SOLD_MODAL.PRODUCT')}</div>
                {selectedOrder.items.map(
                  (item: IReportsItem, index: number): React.ReactNode => (
                    <div key={index} className='item-value'>
                      {item.title}
                    </div>
                  )
                )}
              </Grid>
              <Grid item xs={12} sm={6} className='item-container'>
                <div className='item-label'>{t('MY_SALES.TABLE.SOLD_MODAL.AMOUNT')}</div>
                <div className='item-value'>{selectedOrder.items.length}</div>
              </Grid>
              <Grid item xs={12} sm={6} className='item-container'>
                <div className='item-label'>{t('MY_SALES.TABLE.SOLD_MODAL.EMAIL')}</div>
                <div className='item-value'>{selectedOrder.customer?.email}</div>
              </Grid>
              <Grid item xs={12} sm={6} className='item-container'>
                <div className='item-label'>{t('MY_SALES.TABLE.SOLD_MODAL.IDENTITY')}</div>
                <div className='item-value'>{selectedOrder.customer?.identity.value}</div>
              </Grid>
              <Grid item xs={12} sm={6} className='item-container'>
                <div className='item-label'>{t('MY_SALES.TABLE.PURCHASE')}</div>
                <div className='item-value'>
                  <OrderStatus status={selectedOrder.status} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} className='item-container'>
                <div className='item-label'>{t('MY_SALES.TABLE.PAYMENT')}</div>
                <div className='item-value'>
                  <OrderStatus status={selectedOrder.payment_current_status?.status} payment />
                </div>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6} className='price'>
                  <Price
                    amount={selectedOrder.items.reduce((total, item) => (total += item.amount * item.price.amount), 0)}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      }
    />
  );

  const renderTable = (): React.ReactNode => (
    <SalesGrid container>
      <Grid item xs={12} className='headers'>
        <Grid container>
          <Grid item xs={4} md={1}>
            {t('REPORTS.TABLE.HEADERS.ORDER_ID')}
          </Grid>
          <Grid item xs={8} md={1} className='created-at'>
            {t('REPORTS.TABLE.HEADERS.CREATED_AT')}
          </Grid>
          <Grid item xs={12} md={3}>
            {keycloak?.realmAccess?.roles.includes(process.env.REACT_APP_KEYCLOAK_PARTNER_ADMIN_ROL!) &&
              t('REPORTS.TABLE.HEADERS.TEAM_ID')}
          </Grid>
          <Grid item xs={12} md={3}>
            {t('REPORTS.TABLE.HEADERS.SALESMAN_NAME')}
          </Grid>
          <Grid item xs={1}>
            {t('REPORTS.TABLE.HEADERS.ORDER_STATUS')}
          </Grid>
          <Grid item xs={1}>
            {t('REPORTS.TABLE.HEADERS.PAYMENT_STATUS')}
          </Grid>
          <Grid item xs={10} md className='total'>
            {t('REPORTS.TABLE.HEADERS.ORDER_TOTAL')}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className='content'>
        <CustomInfiniteScroll nextUrl={nextPage} paginate={handleChangePage} firstLoading={props.firstLoading}>
          {orders.map((order: IReportsOrders, index: number) => (
            <Grid
              key={index}
              container
              onClick={() => {
                setSelectedOrder(order);
                setOpenModal(true);
              }}
              className='row'
            >
              <Grid item xs={4} md={1}>
                {order.id}
              </Grid>
              <Grid item xs={8} md={1} className='created-at'>
                <Date date={order.created_at} format={process.env.REACT_APP_DATE_FORMAT!} />
              </Grid>
              <Grid item xs={12} md={3}>
                {keycloak?.realmAccess?.roles.includes(process.env.REACT_APP_KEYCLOAK_PARTNER_ADMIN_ROL!) &&
                  (order.collaborator.parent_collaborators.length > 0
                    ? order.collaborator.parent_collaborators.map((collabor: IReportsCollaborator) => (
                        <>{collabor.username}</>
                      ))
                    : '-')}
              </Grid>
              <Grid item xs={12} md={3}>
                {order.collaborator.username}
              </Grid>
              <Grid item xs={2} md={1}>
                <OrderStatus status={order.status} />
              </Grid>
              <Grid item xs={2} md={1}>
                <OrderStatus status={order.payment_current_status?.status} payment />
              </Grid>
              <Grid item xs={10} md className='total field'>
                <Price amount={order.items.reduce((total, item) => (total += item.amount * item.price.amount), 0)} />
              </Grid>
            </Grid>
          ))}
        </CustomInfiniteScroll>
      </Grid>
    </SalesGrid>
  );

  if (!orders.length && loading) return <Loading />;
  else if (!orders.length) return <></>;
  else
    return (
      <>
        {renderModal()}
        {renderTable()}
      </>
    );
};

export const OrdersTable = (props: IOrdersTableProps) => <CleanOrdersTable {...props} />;
