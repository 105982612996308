// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../../ActionTypes';

const initialState = {
  permissions: {
    loading: false,
    error: false,
    data: undefined
  }
};

export const roleInfoReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    // Get permissions
    case ACTIONS.USERS.ROLE_INFO.GET_PERMISSIONS.FETCH:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          loading: true,
          error: false,
          data: undefined
        }
      };
    case ACTIONS.USERS.ROLE_INFO.GET_PERMISSIONS.SUCCESS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          loading: false,
          data: action.payload.data
        }
      };
    case ACTIONS.USERS.ROLE_INFO.GET_PERMISSIONS.FAIL:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          loading: false,
          error: true
        }
      };
    default:
      return state;
  }
};
