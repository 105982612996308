export enum IChartPeriods {
  // eslint-disable-next-line no-unused-vars
  MONTHLY = 'monthly',
  // eslint-disable-next-line no-unused-vars
  WEEKLY = 'weekle',
  // eslint-disable-next-line no-unused-vars
  DAILY = 'daily'
}

export enum FilterOrders {
  // eslint-disable-next-line no-unused-vars
  ORDERS = 'orders',
  // eslint-disable-next-line no-unused-vars
  SELLERS = 'sellers',
  // eslint-disable-next-line no-unused-vars
  PRODUCTS = 'products'
}

export enum OrderStatus {
  // eslint-disable-next-line no-unused-vars
  NONE = 'NONE',
  // eslint-disable-next-line no-unused-vars
  APPROVED = 'APPROVED',
  // eslint-disable-next-line no-unused-vars
  PENDING = 'PENDING',
  // eslint-disable-next-line no-unused-vars
  DECLINED = 'DECLINED',
  // eslint-disable-next-line no-unused-vars
  ENTERED = 'ENTERED',
  // eslint-disable-next-line no-unused-vars
  EXPIRED = 'EXPIRED'
}

export interface IBaseStoreProps {
  loading: boolean;
  error: boolean;
  data?: Array<any>;
}
export interface IReportsCollaborator {
  id: string;
  first_name: string;
  last_name: string;
  group_name: string;
  username: string;
}

export interface IReportsCustomer {
  legal_name?: string;
  first_name: string;
  last_name: string;
  person_type: string;
  email: string;
  identity: { value: string; type: string };
}

export interface IReportsCollaboratorWithParent extends IReportsCollaborator {
  parent_collaborators: Array<IReportsCollaborator>;
}

export interface IReportsCurrency {
  iso_code: string;
  name: string;
  symbol: string;
}

export interface IReportsItem {
  title: string;
  price: { amount: number; currency: IReportsCurrency };
  amount: number;
}

export interface IReportsOrdersGroupedByDate {
  currency: IReportsCurrency;
  date: string;
  total_sales: number;
  total_sales_money: number;
}

export interface IReportsOrdersGroupedByDateStore extends IBaseStoreProps {
  ordersGroupedByDate: Array<IReportsOrdersGroupedByDate>;
}

export interface IReportsProducts {
  price: number;
  title: string;
  total_sales: number;
  total_sales_money: number;
  short_description: string;
  description: string;
  id: number;
  code: string;
}

export interface IReportsProductsStore extends IBaseStoreProps {
  products: Array<IReportsProducts>;
}

export interface IReportsSalesTeams {
  collaborator: IReportsCollaborator;
  currency: IReportsCurrency;
  total_sales: number;
  total_sales_money: number;
}

export interface IReportsSalesTeamsStore extends IBaseStoreProps {
  salesTeams: Array<IReportsSalesTeams>;
}

export interface IReportsSalesmen {
  collaborator: IReportsCollaborator;
  currency: IReportsCurrency;
  total_sales: number;
  total_sales_money: number;
}

export interface IReportsSalesmenStore extends IBaseStoreProps {
  salesmen: Array<IReportsSalesmen>;
}

export interface IReportsOrderStatus {
  status: string;
}

export interface IReportsOrders {
  id: number;
  created_at: string;
  items: Array<IReportsItem>;
  customer: IReportsCustomer;
  collaborator: IReportsCollaboratorWithParent;
  status: string;
  payment_current_status: IReportsOrderStatus;
}

export interface IReportsOrdersStore extends IBaseStoreProps {
  nextPage: string;
  orders: Array<IReportsOrders>;
  ordersToExport: Array<IReportsOrders>;
}

export interface IReportsProps {
  ordersGroupedByDate: IReportsOrdersGroupedByDateStore;
  products: IReportsProductsStore;
  salesTeams: IReportsSalesTeamsStore;
  salesmen: IReportsSalesmenStore;
  orders: IReportsOrdersStore;
}
