import React from 'react';

import { CustomersList } from './components/CustomersList';
// import { ContactsList } from './components';
//import { IconButton } from '@wdynamo/common';
import { Grid } from '@material-ui/core';

//import { ReactComponent as AddIcon } from '../../assets/img/icons_dynamo/icon_add.svg';

//import { uris } from '../../siteMap';
import { useTranslation } from 'react-i18next';

/* eslint-disable no-unused-vars */
import { IContactsStore } from './models';
import { History } from 'history';
/* eslint-disable no-unused-vars */

interface IContactsProps {
  contactsStore: IContactsStore;

  getContactsNextPageFetch(nextPage?: string): void;
  history: History;
  firstLoading: boolean;
  [key: string]: any;
}
export const ContactsComponent: React.FC<IContactsProps> = (props: IContactsProps) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems='center'>
      <Grid item xs={12}>
        <CustomersList {...props} />
      </Grid>
    </Grid>
  );
};
