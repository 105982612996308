export type { actionType, IBaseStateProps } from '../../../ActionTypes';

export const PAYMENT_METHOD = {
  FETCH: 'PAYMENT_METHOD_GET',
  SUCCESS: 'PAYMENT_METHOD_SUCCESS',
  FAIL: 'PAYMENT_METHOD_FAIL'
};

export const SALE_CHANNELS = {
  FETCH: 'SALE_CHANNELS_GET',
  SUCCESS: 'SALE_CHANNELS_SUCCESS',
  FAIL: 'SALE_CHANNELS_FAIL'
};
