import React from 'react';
import styled from 'styled-components';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TextPoint, Input } from '@wdynamo/common';
import { Analytics } from '@wdynamo/common/lib/services';
import { Logo } from '../LogoComponent';

interface PriceFormProps {
  currencies: any;
  className?: string;
  service: any;
  handleOnChange(fieldToUpdate: any, value: any): void;
}

const CleanPriceForm: React.FC<PriceFormProps> = (props: PriceFormProps) => {
  const { t } = useTranslation();
  const { service, handleOnChange } = props;

  React.useEffect(() => {
    Analytics().pageview('new-service | form-price');
  }, []);

  const handleClarificationChange = (value: any) => {
    handleOnChange('clarification', value.toString().slice(0, service.clarification.maxLength));
  };
  const handleCurrencyChange = (e: any) => {
    handleOnChange('currency', e.target.value);
  };

  const handlePriceChange = (value: any) => {
    if (value === '' || Number(value)) handleOnChange('price', value);
  };

  const letterCounter = (value: string, max: number) => {
    return (
      <span>
        {value?.length === 0
          ? t('SERVICES.MAX_LENGTH', { max: max })
          : t('SERVICES.CURRENT_LENGTH', { max: max, current: value.length })}
      </span>
    );
  };

  return (
    <div className={`${props.className} `}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>
            {t('SERVICES.NEW_SERVICE.STEP_2.TITLE')}
            <TextPoint />
          </h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            onChange={handlePriceChange}
            defaultValue={service.price.value}
            label={t('SERVICES.NEW_SERVICE.STEP_2.PRICE')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>{t('SERVICES.NEW_SERVICE.STEP_2.CURRENCY')}</InputLabel>
            <Select value={service.currency.value} onChange={handleCurrencyChange}>
              {props.currencies.map((currency: any, key: number) => (
                <MenuItem key={key} value={currency.name}>
                  {currency.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Input
            defaultValue={service.clarification.value}
            onChange={handleClarificationChange}
            label={t('SERVICES.NEW_SERVICE.STEP_2.CLARIFICATION')}
          />
          {letterCounter(service.clarification.value, service.clarification.maxLength)}
        </Grid>
        <Grid item xs={12}>
          <Logo
            logoFilename={service.logo_filename.value}
            uploadImageFetch={() => {}}
            onImageUploaded={(location: any) => handleOnChange('logo_filename', location)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const PriceForm = styled(({ ...props }) => <CleanPriceForm {...props} />)``;
