import React from 'react';
import {
  Radio,
  RadioGroup,
  Grid,
  MenuItem,
  TextField,
  FormGroup,
  FormControlLabel,
  FormLabel
} from '@material-ui/core';
import styled from 'styled-components';
//import { DatePicker } from '@wdynamo/common';
import { FormAddress } from './FormAddress';
import { FormSpouse } from './FormSpouse';
import { Autocomplete } from '@material-ui/lab';
// eslint-disable-next-line no-unused-vars
import { IQuotationInput } from '../../../../models';
import RucMask from '../common/RucMask';
import TextMask from '../common/TextMask';
import { IMaskInput } from 'react-imask';

interface IFormNPProp {
  className?: string;
  dataForm: any;
  handleOnChange(value: string | number, name: string): void;
  handleOnChangeRuc(e: any, path: string): void;
  statePath: string;
  nationalityList: IQuotationInput[];
  provinceList: IQuotationInput[];
  cityList: IQuotationInput[];
  neighborhoodList: IQuotationInput[];
  idTypeList: IQuotationInput[];
  maritalStatusList: IQuotationInput[];
  activityList: IQuotationInput[];
  sourceFundList: IQuotationInput[];
}

const DocMask = React.forwardRef<HTMLElement, any>(function numbMask(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;
  const regex = /^[0-9]*$/;

  return (
    <IMaskInput
      {...other}
      mask={regex}
      inputRef={ref}
      maxLength={9}
      onAccept={(value: any) => onChange({ target: { value, name: props.name } })}
    />
  );
});

const CleanformCommonNaturalPerson: React.FC<IFormNPProp> = (props: IFormNPProp) => {
  const {
    dataForm,
    handleOnChange,
    handleOnChangeRuc,
    statePath,
    nationalityList,
    provinceList,
    cityList,
    neighborhoodList,
    idTypeList,
    maritalStatusList,
    activityList,
    sourceFundList
  } = props;

  const isRuc: string = '80';
  const invalidRuc: any = dataForm.id.error;
  const invalidRucMssg: string = 'Ruc inválido';

  return (
    <>
      <Grid item md={2} style={{ height: '80px' }}>
        <TextField
          name={`${statePath}.name`}
          value={dataForm.name.value}
          error={dataForm.name.error}
          InputProps={{
            inputComponent: TextMask as any
          }}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.name.isRequired}
          label={'Nombre'}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item md={2} style={{ height: '80px' }}>
        <TextField
          name={`${statePath}.last_name`}
          value={dataForm.last_name.value}
          error={dataForm.last_name.error}
          InputProps={{
            inputComponent: TextMask as any
          }}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.last_name.isRequired}
          label={'Apellido'}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {statePath === 'insured' && (
        <Grid item md={2} style={{ height: '80px' }}>
          <TextField
            name={`${statePath}.phone`}
            value={dataForm.phone?.value}
            error={dataForm.phone?.error}
            onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
            fullWidth
            required={dataForm.phone?.isRequired}
            type='tel'
            label={'Teléfono'}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      )}
      <Grid item md={statePath === 'contractor' ? 2 : 1} style={{ height: '80px' }}>
        <TextField
          name={`${statePath}.id_type`}
          value={dataForm.id_type.value}
          error={dataForm.id_type.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.id_type.isRequired}
          label={'Tipo'}
          select={true}
        >
          {idTypeList.map((item: any, i: number) => (
            <MenuItem key={i} value={item.code}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={statePath === 'contractor' ? 3 : 2} style={{ height: '80px' }}>
        {dataForm.id_type.value === isRuc ? (
          <TextField
            name={`${statePath}.id`}
            value={dataForm.id.value}
            error={dataForm.id.error}
            onChange={(e) => handleOnChangeRuc(e, statePath)}
            fullWidth
            required={dataForm.id.isRequired}
            label={'Documento'}
            type={'text'}
            InputProps={{
              inputComponent: RucMask as any
            }}
            InputLabelProps={{ shrink: true }}
            helperText={invalidRuc ? invalidRucMssg : ''}
          />
        ) : (
          <TextField
            name={`${statePath}.id`}
            value={dataForm.id.value}
            error={dataForm.id.error}
            onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
            fullWidth
            required={dataForm.id.isRequired}
            label={'Documento'}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: DocMask as any
            }}
          />
        )}
      </Grid>
      <Grid item md={3} style={{ height: '80px' }}>
        <FormLabel className='radio-label'>Sexo</FormLabel>
        <FormGroup>
          <RadioGroup
            name='stateFilter'
            value={Number(dataForm.sex.value)}
            onChange={(event): void => handleOnChange(Number(event.target.value), `${statePath}.sex`)}
            row={true}
          >
            <FormControlLabel value={2} control={<Radio />} label={'Femenino'} />
            <FormControlLabel value={1} control={<Radio />} label={'Masculino'} />
          </RadioGroup>
        </FormGroup>
      </Grid>
      {/* <Grid item md={2}>
        <DatePicker
          format={process.env.REACT_APP_DATE_FORMAT!}
          label={'Fecha de nacimi.'}
          value={dataForm.date_of_birth.value as string}
          error={dataForm.date_of_birth.error}
          onChange={(value: string): void => handleOnChange(value, `${statePath}.date_of_birth`)}
          required={dataForm.birth_place.isRequired}
          minDate={moment().subtract(500, 'years')}
          maxDate={moment().subtract(18, 'years')}
        />
          </Grid> */}
      {/* <Grid item md={2}>
        <TextField
          name={`${statePath}.birth_place`}
          value={dataForm.birth_place.value}
          error={dataForm.birth_place.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.birth_place.isRequired}
          label={'Lugar de nac.'}
        ></TextField>
          </Grid> */}
      <Grid item md={3} style={{ height: '80px' }}>
        <TextField
          name={`${statePath}.province`}
          value={dataForm?.province?.value}
          error={dataForm?.province?.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm?.province?.isRequired}
          label={'Departamento'}
          select={true}
        >
          {provinceList.map((item: any, i: number) => (
            <MenuItem key={i} value={item.code}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} style={{ height: '80px' }}>
        <TextField
          name={`${statePath}.city`}
          value={dataForm?.city?.value}
          error={dataForm?.city?.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm?.city?.isRequired}
          label={'Ciudad'}
          disabled={!dataForm?.province?.value}
          select={true}
        >
          {cityList?.map((item: any, i: number) => (
            <MenuItem key={i} value={item.code}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} style={{ height: '80px' }}>
        <TextField
          name={`${statePath}.postal_code`}
          value={dataForm.postal_code.value}
          error={dataForm.postal_code.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.postal_code.isRequired}
          disabled={!dataForm?.city?.value}
          label={'Barrio'}
          select={true}
        >
          {neighborhoodList?.map((item: any, i: number) => (
            <MenuItem key={i} value={item.code}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} style={{ height: '80px' }}>
        <TextField
          name={`${statePath}.nationality`}
          value={dataForm.nationality.value}
          error={dataForm.nationality.error}
          onChange={(event): void => handleOnChange(Number(event.target.value), event.target.name)}
          fullWidth
          required={dataForm.nationality.isRequired}
          label={'Nacionalidad'}
          select={true}
        >
          {nationalityList.map((item: any, i: number) => (
            <MenuItem key={i} value={item.code}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <FormAddress statePath={`${statePath}.address`} handleOnChange={handleOnChange} dataForm={dataForm.address} />

      {/* <Grid item md={3}>
        <TextField
          name={`${statePath}.phone`}
          value={dataForm.phone.value}
          error={dataForm.phone.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.phone.isRequired}
          type='number'
          label={'Teléfono'}
        ></TextField>
          </Grid> 
      <Grid item md={3}>
        <TextField
          name={`${statePath}.mail`}
          value={dataForm.mail.value}
          error={dataForm.mail.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.mail.isRequired}
          type='email'
          label={'Correo electrónico'}
        ></TextField>
      </Grid>*/}
      <Grid item md={2} style={{ height: '80px' }}>
        <TextField
          name={`${statePath}.marital_status`}
          value={dataForm.marital_status.value}
          error={dataForm.marital_status.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.marital_status.isRequired}
          label={'Estado civil'}
          select={true}
        >
          {maritalStatusList.map((item: any, i: number) => (
            <MenuItem key={i} value={item.code}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {dataForm.marital_status.value === '1' && (
        <FormSpouse
          statePath={`${statePath}.spouse`}
          handleOnChange={handleOnChange}
          dataForm={dataForm.spouse}
          idTypeList={idTypeList}
        />
      )}
      <Grid item md={5}>
        <Autocomplete
          value={dataForm.activity?.value}
          options={activityList}
          noOptionsText={'Sin coincidencias'}
          getOptionLabel={(option: any) => option.description || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              required={dataForm.activity.isRequired}
              error={dataForm.activity.error}
              label={'Actividad'}
            />
          )}
          onChange={(e, value) => handleOnChange(value, `${statePath}.activity`)}
          autoComplete
          autoHighlight
        ></Autocomplete>
      </Grid>
      {statePath !== 'insured' && (
        <Grid item md={5}>
          <Autocomplete
            value={dataForm.source_fund?.value}
            options={sourceFundList ?? []}
            noOptionsText={'Sin coincidencias'}
            getOptionLabel={(option: any) => option.description || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                required={dataForm.source_fund.isRequired}
                error={dataForm.source_fund.error}
                label={'Origen de fondos'}
              />
            )}
            onChange={(e, value) => handleOnChange(value, `${statePath}.source_fund`)}
            autoComplete
            autoHighlight
          ></Autocomplete>
        </Grid>
      )}
    </>
  );
};
export const FormCommonNaturalPerson = styled(({ ...props }) => <CleanformCommonNaturalPerson {...props} />)`
  .radio-label {
    font-size: 13px;
  }
`;
