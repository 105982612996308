import axios from 'axios';
/* eslint-disable no-unused-vars */
import {
  ISourceParams,
  IContactParams,
  IPlansParams,
  ICommercialPlanParams,
  IBillingModeParams,
  IPaymentMethodParams,
  IPaymentConditionParams,
  IAccesoryParams,
  IContactsByPartial
} from './models';
/* eslint-enable no-unused-vars */

const Api = () => {
  const createPersonFetch = (createPersonForm: any): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_BOOKING_BASE_URL}/person`, createPersonForm);
  //axios.post('https://run.mocky.io/v3/bf017f1e-69d3-40ed-8443-61fafdf41942/person', createPersonForm);

  // eslint-disable-next-line no-unused-vars
  const getStepsFetch = (id: string): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/inputs/1`);
  // axios.get('http://localhost:3005/inputs');

  const getSourceList = (listParams: ISourceParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}${listParams.url}`, { params: listParams.params });
  //axios.get(`http://localhost:3005/${listParams.url}`, { params: listParams.params });

  const getSourceData = (dataParams: ISourceParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}${dataParams.url}`, { params: dataParams.params });
  //axios.get(`http://localhost:3005${dataParams.url}`, { params: dataParams.params });

  const getContactsByPartial = (params: IContactsByPartial): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers`, { params });
  //axios.get('http://localhost:3005/contacts');

  const createContact = (params: IContactParams): Promise<unknown> => {
    return axios.post(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers`, params);
    //return axios.post('http://localhost:3005/contacts', params);
  };

  const getPlansFetch = (params: IPlansParams): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/quotation`, params);
  // axios.post('http://localhost:3005/quotation', params);

  const updatePlansFetch = (quotationId: string, params: any): Promise<unknown> =>
    axios.patch(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/quotation/${quotationId}`, params);
  //axios.patch(`http://localhost:3005/b2b/quotation/${quotationId}`, params);

  const getCommercialPlanFetch = (params: ICommercialPlanParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/commercialPlan`, {
      params: params
    });
  // axios.get('http://localhost:3005/commercialPlan', {
  //   params: params
  // });

  const getBillingModeFetch = (params: IBillingModeParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/payment/billing`, {
      params: params
    });
  // axios.get('http://localhost:3005/billingMode', {
  //   params: params
  // });

  const getPaymentMethodFetch = (params: IPaymentMethodParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/payment/method`, {
      params: params
    });
  // axios.get('http://localhost:3005/paymentMethod', {
  //   params: params
  // });

  const getPaymentConditionFetch = (params: IPaymentConditionParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/payment_conditions`, {
      params: params
    });
  // axios.get('http://localhost:3005/paymentCondition', {
  //   params: params
  // });

  // const getProducerFetch = (): Promise<unknown> =>
  //   axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/producers`);
  // axios.get('http://localhost:3005/producers');

  const getProducerFetch = (params: any): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/producers`, { params: params });

  // eslint-disable-next-line no-unused-vars
  const getAccessoryFetch = (params: IAccesoryParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/accesories`, { params: params });

  const getDownloadPlans = (quotationId: string): Promise<unknown> =>
    axios({
      url: `${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/quotation/${quotationId}/print`,
      method: 'POST',
      responseType: 'blob' // important
    }).then((response) => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'planes.pdf');
      document.body.appendChild(link);
      link.click();
    });

  const getDownloadPlanSelected = (quotationId: any, planId: any): Promise<unknown> =>
    axios({
      url: `${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/quotation/${quotationId}/print/item/${planId}`,
      method: 'POST',
      responseType: 'blob' // important
    }).then((response) => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'cotizacion.pdf');
      document.body.appendChild(link);
      link.click();
    });

  return {
    createPersonFetch,
    getStepsFetch,
    getSourceList,
    getSourceData,
    getContactsByPartial,
    createContact,
    getPlansFetch,
    updatePlansFetch,
    getCommercialPlanFetch,
    getBillingModeFetch,
    getPaymentMethodFetch,
    getPaymentConditionFetch,
    getProducerFetch,
    getAccessoryFetch,
    getDownloadPlans,
    getDownloadPlanSelected
  };
};

export default Api;
