import moment from 'moment';

// eslint-disable-next-line no-unused-vars
import { ILocation } from './models';

export class StepsRequest {
  dataForm: any;
  location: ILocation;
  constructor(dataForm: any, location: ILocation) {
    this.dataForm = dataForm;
    this.location = location;
  }

  step1 = (riskHasPledgee: any, isContractorInsured: any) => {
    const { dataForm } = this;

    const contractorDateOfBirth = dataForm.person_data.contractor.date_of_birth.value
      ? moment(dataForm.person_data.contractor.date_of_birth.value, process.env.REACT_APP_DATE_FORMAT!).format(
          'YYYY-MM-DD'
        )
      : '';
    const insuredDateOfBirth = dataForm.person_data.insured.date_of_birth.value
      ? moment(dataForm.person_data.insured.date_of_birth.value, process.env.REACT_APP_DATE_FORMAT!).format(
          'YYYY-MM-DD'
        )
      : '';
    return {
      contractor: {
        IVA_category: '1',
        type: dataForm.person_data.contractor.type.value,
        name: dataForm.person_data.contractor.name.value,
        last_name: dataForm.person_data.contractor.last_name.value,
        province: dataForm.person_data.contractor.province.value,
        city: dataForm.person_data.contractor.city.value,
        postal_code: dataForm.person_data.contractor.postal_code.value,
        business_name: dataForm.person_data.contractor.business_name.value,
        // ruc: dataForm.person_data.contractor.ruc.value,
        birth_place: dataForm.person_data.contractor.birth_place.value,
        date_of_birth: contractorDateOfBirth,
        nationality: dataForm.person_data.contractor.nationality.value,
        sex: dataForm.person_data.contractor.sex.value,
        doc_type: dataForm.person_data.contractor.id_type.value,
        doc_number: dataForm.person_data.contractor.id.value,
        address: {
          street: dataForm.person_data.contractor.address.street.value,
          street_number: Number(dataForm.person_data.contractor.address.street_number.value || 1),
          without_street_number: dataForm.person_data.contractor.address.without_street_number.value,
          floor: Number(dataForm.person_data.contractor.address.floor.value),
          apartment: dataForm.person_data.contractor.address.apartment.value
        },
        marital_status: dataForm.person_data.contractor.marital_status.value,
        mail: dataForm.person_data.contractor.mail.value,
        phone: dataForm.person_data.contractor.phone.value,
        spouse: {
          name: dataForm.person_data.contractor.spouse.name.value,
          last_name: dataForm.person_data.contractor.spouse.last_name.value,
          doc_type: dataForm.person_data.contractor.spouse.id_type.value,
          doc_number: dataForm.person_data.contractor.spouse.id.value,
          phone: dataForm.person_data.contractor.spouse.phone.value,
          mail: dataForm.person_data.contractor.spouse.mail.value,
          is_pep: dataForm.person_data.contractor.spouse.mail.value
        },
        is_pep: dataForm.person_data.contractor.is_pep.value,
        pep: {
          position: dataForm.person_data.contractor.pep.position.value,
          agency: dataForm.person_data.contractor.pep.agency.value,
          character: dataForm.person_data.contractor.pep.character.value
        },
        is_holder: dataForm.person_data.contractor.is_holder.value,
        holder: {
          name: dataForm.person_data.contractor.holder.name.value,
          last_name: dataForm.person_data.contractor.holder.last_name.value,
          doc_type: dataForm.person_data.contractor.holder.id_type.value,
          doc_number: dataForm.person_data.contractor.holder.id.value,
          character: dataForm.person_data.contractor.holder.character.value,
          phone: dataForm.person_data.contractor.holder.value
        },
        activity: dataForm.person_data.contractor.activity.value,
        source_fund: dataForm.person_data.contractor.source_fund.value
      },
      risk_has_pledgee: riskHasPledgee,
      creditor: {
        business_name: dataForm.person_data.creditor.business_name.value,
        // ruc: dataForm.person_data.creditor.ruc.value,
        doc_number: dataForm.person_data.creditor.id.value,
        address: {
          street: dataForm.person_data.creditor.address.street.value,
          street_number: Number(dataForm.person_data.creditor.address.street_number.value || 1),
          without_street_number: dataForm.person_data.creditor.address.without_street_number.value,
          floor: Number(dataForm.person_data.creditor.address.floor.value),
          apartment: dataForm.person_data.creditor.address.apartment.value
        },
        city: dataForm.person_data.creditor.city.value,
        postal_code: dataForm.person_data.creditor.postal_code.value,
        province: dataForm.person_data.creditor.province.value,
        phone: dataForm.person_data.creditor.phone.value,
        mail: dataForm.person_data.creditor.mail.value,
        is_holder: dataForm.person_data.creditor.is_holder.value,
        holder: {
          name: dataForm.person_data.creditor.holder.name.value,
          last_name: dataForm.person_data.creditor.holder.last_name.value,
          doc_type: dataForm.person_data.creditor.holder.id_type.value,
          doc_number: dataForm.person_data.creditor.holder.id.value,
          character: dataForm.person_data.creditor.holder.character.value
        },
        activity: dataForm.person_data.creditor.activity.value
      },
      is_contractor_insured: isContractorInsured,
      insured: {
        IVA_category: '1',
        name: dataForm.person_data.insured.name.value,
        last_name: dataForm.person_data.insured.last_name.value,
        date_of_birth: isContractorInsured ? contractorDateOfBirth : insuredDateOfBirth,
        birth_place: dataForm.person_data.insured.birth_place.value,
        nationality: dataForm.person_data.insured.nationality.value,
        sex: dataForm.person_data.insured.sex.value,
        doc_type: dataForm.person_data.insured.id_type.value,
        doc_number: dataForm.person_data.insured.id.value,
        province: dataForm.person_data.insured.province.value,
        city: dataForm.person_data.insured.city.value,
        postal_code: dataForm.person_data.insured.postal_code.value,
        address: {
          street: dataForm.person_data.insured.address.street.value,
          street_number: Number(dataForm.person_data.insured.address.street_number.value || 1),
          without_street_number: dataForm.person_data.insured.address.without_street_number.value,
          floor: Number(dataForm.person_data.insured.address.floor.value),
          apartment: dataForm.person_data.insured.address.apartment.value
        },
        marital_status: dataForm.person_data.insured.marital_status.value,
        spouse: {
          name: dataForm.person_data.insured.spouse.name.value,
          last_name: dataForm.person_data.insured.spouse.last_name.value,
          doc_type: dataForm.person_data.insured.spouse.id_type.value,
          doc_number: dataForm.person_data.insured.spouse.id.value,
          phone: dataForm.person_data.contractor.spouse.phone.value,
          mail: dataForm.person_data.contractor.spouse.mail.value,
          is_pep: dataForm.person_data.contractor.spouse.mail.value
        },
        is_pep: dataForm.person_data.insured.is_pep.value,
        pep: {
          position: dataForm.person_data.insured.pep.position.value,
          agency: dataForm.person_data.insured.pep.agency.value,
          character: dataForm.person_data.insured.pep.character.value
        },
        is_holder: dataForm.person_data.insured.is_holder.value,
        holder: {
          name: dataForm.person_data.insured.holder.name.value,
          last_name: dataForm.person_data.insured.holder.last_name.value,
          doc_type: dataForm.person_data.insured.holder.id_type.value,
          doc_number: dataForm.person_data.insured.holder.id.value,
          character: dataForm.person_data.insured.holder.character.value
        },
        activity: dataForm.person_data.insured.activity.value.code,
        phone: dataForm.person_data.insured.phone.value
      }
    };
  };

  step2 = (stepsStore: any) => {
    const { dataForm } = this;
    const stateName = stepsStore.steps.data.state_name;
    const field: any = {};
    Object.keys(dataForm.custom_data).forEach(
      (element: string) => (field[element] = dataForm.custom_data[element].value)
    );
    return {
      [stateName]: field
    };
  };

  individualStep2 = () => {
    const { dataForm } = this;
    const field: any = {};
    Object.keys(dataForm.custom_data).forEach((element: string) => {
      /* eslint-disable no-prototype-builtins */
      if (
        dataForm.custom_data[element].value.hasOwnProperty('description') &&
        !dataForm.custom_data[element].value.hasOwnProperty('code')
      )
        return (field[element] = {
          ...dataForm.custom_data[element].value,
          code: dataForm.custom_data[element].value.description
        });
      else if (dataForm.custom_data[element].value.hasOwnProperty('file_name'))
        return (field[element] = {
          code: dataForm.custom_data[element].value.file_name,
          description: dataForm.custom_data[element].value.string_base_64
        });
      else if (dataForm.custom_data[element].value === '')
        return {
          description: dataForm.custom_data[element].value,
          code: dataForm.custom_data[element].value
        };
      else return (field[element] = dataForm.custom_data[element].value);
      /* eslint-enable no-prototype-builtins */
    });
    return {
      stage: 2,
      inputs: field
    };
  };

  // step3 = () => {
  //   const { dataForm } = this;
  //   return {
  //     id_info: {
  //       id_document_front: dataForm.id_info_data.id_document_front.value,
  //       id_document_back: dataForm.id_info_data.id_document_back.value,
  //       signature: dataForm.id_info_data.signature.value
  //     }
  //   };
  // };

  // individualStep3 = () => {
  //   const { dataForm } = this;
  //   return {
  //     stage: 3,
  //     documents: {
  //       id_document_front: { ...dataForm.id_info_data.id_document_front.value, description: 'id_document_front' },
  //       id_document_back: { ...dataForm.id_info_data.id_document_back.value, description: 'id_document_back' },
  //       signature: { ...dataForm.id_info_data.signature.value, description: 'signature' }
  //     }
  //   };
  // };

  step4 = () => {
    const { dataForm, location } = this;
    //CREDIT CARD & MANUAL
    if (location.state.quotation.paymentMethod === '3' || location.state.quotation.paymentMethod === '0') {
      return {
        payment: {
          amount_of_payments: location.state.quotation.billingMethod,
          payment_method: location.state.quotation.paymentMethod,
          second_payment_expiration: moment(
            dataForm.payment_data.second_payment_expiration.value,
            process.env.REACT_APP_DATE_FORMAT!
          ).format('YYYY-MM-DD'),
          bank: dataForm.payment_data.bank.value,
          card: dataForm.payment_data.card.value,
          card_number: location.state.quotation.paymentMethod === '0' ? null : dataForm.payment_data.card_number.value,
          due_date:
            dataForm.payment_data.due_date.value === ''
              ? ''
              : moment(dataForm.payment_data.due_date.value, 'MM/YY').format('YYYY-MM-01')
        }
      };
    } else {
      //DEBIT CARD
      return {
        payment: {
          amount_of_payments: location.state.quotation.billingMethod,
          payment_method: location.state.quotation.paymentMethod,
          second_payment_expiration: moment(
            dataForm.payment_data.second_payment_expiration.value,
            process.env.REACT_APP_DATE_FORMAT!
          ).format('YYYY-MM-DD'),
          bank: dataForm.payment_data.bank.value,
          card: dataForm.payment_data.card.value,
          bank_ob_account_type: dataForm.payment_data.bank_ob_account_type.value,
          account_number: dataForm.payment_data.account_number.value
        }
      };
    }
  };

  individualStep4 = () => {
    const { dataForm, location } = this;
    //CREDIT CARD & MANUAL
    if (location.state.quotation.paymentMethod === '3' || location.state.quotation.paymentMethod === '0') {
      return {
        stage: 4,
        amount_of_payments: location.state.quotation.billingMethod,
        payment_method: location.state.quotation.paymentMethod,
        second_payment_expiration: moment(
          dataForm.payment_data.second_payment_expiration.value,
          process.env.REACT_APP_DATE_FORMAT!
        ).format('YYYY-MM-DD'),
        bank: dataForm.payment_data.bank.value,
        card: dataForm.payment_data.card.value,
        card_number: location.state.quotation.paymentMethod === '0' ? null : dataForm.payment_data.card_number.value,
        due_date:
          dataForm.payment_data.due_date.value === ''
            ? ''
            : moment(dataForm.payment_data.due_date.value, 'MM/YY').format('YYYY-MM-01')
      };
    } else {
      //DEBIT CARD
      return {
        stage: 4,
        amount_of_payments: location.state.quotation.billingMethod,
        payment_method: location.state.quotation.paymentMethod,
        second_payment_expiration: moment(
          dataForm.payment_data.second_payment_expiration.value,
          process.env.REACT_APP_DATE_FORMAT!
        ).format('YYYY-MM-DD'),
        bank: dataForm.payment_data.bank.value,
        card: dataForm.payment_data.card.value,
        bank_ob_account_type: dataForm.payment_data.bank_ob_account_type.value,
        account_number: dataForm.payment_data.account_number.value
      };
    }
  };
}
