import { ACTIONS } from '../../../ActionTypes';

export const contactDetailsFetch = (contactId?: number) => ({
  type: ACTIONS.CONTACTS.CONTACT_DETAILS.FETCH,
  payload: { contactId: contactId }
});

export const contactDetailsSuccess = (budgets: any) => ({
  type: ACTIONS.CONTACTS.CONTACT_DETAILS.SUCCESS,
  payload: { budgets }
});

export const contactDetailsFail = (error: object) => ({
  type: ACTIONS.CONTACTS.CONTACT_DETAILS.FAIL,
  payload: { error }
});

export const changeStatusFetch = (params?: any) => ({
  type: ACTIONS.CONTACTS.CHANGE_STATUS.FETCH,
  payload: { params }
});

export const changeStatusSuccess = (budgets: any) => ({
  type: ACTIONS.CONTACTS.CHANGE_STATUS.SUCCESS,
  payload: { budgets }
});

export const changeStatusFail = (error: object) => ({
  type: ACTIONS.CONTACTS.CHANGE_STATUS.FAIL,
  payload: { error }
});
