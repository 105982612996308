import { call, put, takeLatest, all } from 'redux-saga/effects';

import Api from './Api';
import { exampleSuccess, exampleFail } from './actions';
import { ACTIONS } from '../ActionTypes';

const api = new Api();

function* sagasExampleFetch() {
  try {
    const data = yield call(api.exampleFetch);
    yield put(exampleSuccess(data));
  } catch (e) {
    yield put(exampleFail(e));
  }
}

// Listen for redux actions
function* exampleSaga() {
  yield all([takeLatest(ACTIONS.EXAMPLE.FETCH, sagasExampleFetch)]);
}

export { exampleSaga };
