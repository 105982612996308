import { ACTIONS } from '../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IGetInspectionFormParams } from './models';

//get form
export const getInspectionFormFetch = (params: IGetInspectionFormParams) => ({
  type: ACTIONS.INSPECTION.GET_FORM.FETCH,
  payload: { params: params }
});

export const getInspectionFormSuccess = (data: any) => ({ type: ACTIONS.INSPECTION.GET_FORM.SUCCESS, payload: data });

export const getInspectionFormFail = (error: object) => ({ type: ACTIONS.INSPECTION.GET_FORM.FAIL, payload: error });

//send form
export const sendInspectionFormFetch = (params: IGetInspectionFormParams) => ({
  type: ACTIONS.INSPECTION.SEND_FORM.FETCH,
  payload: { params: params }
});

export const sendInspectionFormSuccess = (data: any) => ({ type: ACTIONS.INSPECTION.SEND_FORM.SUCCESS, payload: data });

export const sendInspectionFormFail = (error: object) => ({ type: ACTIONS.INSPECTION.SEND_FORM.FAIL, payload: error });
