import { CUSTOM_FIELDS_TYPES } from './types';

export const getTypesFetch = () => ({
  type: CUSTOM_FIELDS_TYPES.FETCH
});

export const getTypesSuccess = (data: any) => ({
  type: CUSTOM_FIELDS_TYPES.SUCCESS,
  payload: { data: data }
});

export const getTypesFail = (error: object) => ({ type: CUSTOM_FIELDS_TYPES.FAIL, payload: { error } });
