import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { StyledButton } from '@wdynamo/common';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';

interface ColorComponentProps {
  color: string;
  onColorSelected(color: string): void;
}

export const ColorComponent = (props: ColorComponentProps) => {
  const { t } = useTranslation();
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const [color, setColor] = React.useState('');
  const { onColorSelected } = props;

  React.useEffect(() => {
    onColorSelected(color);
  }, [color]);

  const toggleColorPicker = () => {
    setColorPickerOpen(!colorPickerOpen);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={4} container spacing={3} direction='column'>
        <Grid item>
          <Typography variant='caption'>{t('SETTINGS.COLOR.TITLE')}</Typography>
        </Grid>
        <Grid item>
          {colorPickerOpen ? (
            <>
              <SketchPicker color={props.color || color} onChangeComplete={(color: any) => setColor(color.hex)} />
              <br />
              <StyledButton className='small fullWidth' onClick={toggleColorPicker}>
                {t('SETTINGS.COLOR.COLOR_PICK')}
              </StyledButton>
            </>
          ) : (
            <StyledButton className='small fullWidth' onClick={toggleColorPicker}>
              {props.color || color ? (
                <div style={{ display: 'contents' }}>
                  <div>{props.color || color}</div>
                  <div
                    style={{ width: '15px', height: '15px', background: props.color || color, marginLeft: '10px' }}
                  ></div>
                </div>
              ) : (
                t('SETTINGS.COLOR.COLOR_SELECTOR')
              )}
            </StyledButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ColorComponent;
