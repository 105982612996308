import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ImageUploader } from './ImageUploader';

import { useTranslation } from 'react-i18next';
interface LogoComponentProps {
  partnerId: string;
  logoFilename: string;
  uploadImageFetch(imageData: any, partnerId: string): void;
  onImageUploaded(location: string): void;
}

export const LogoComponent = (props: LogoComponentProps) => {
  const { t } = useTranslation();

  const uploadLogo = (logo_filename: any) => {
    const formData = new FormData();
    formData.append('image', logo_filename);
    props.uploadImageFetch(formData, props.partnerId);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='caption'>{t('SETTINGS.LOGO.TITLE')}</Typography>
      </Grid>
      <Grid item xs={6} container spacing={3} direction='column'>
        <Grid item>
          <Typography variant='body2'>{t('SETTINGS.LOGO.TYPE.DESCRIPTION')}</Typography>
          <Typography variant='body1' style={{ fontWeight: 'bold' }}>
            {t('SETTINGS.LOGO.TYPE.VALUE')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body2'>{t('SETTINGS.LOGO.SIZE.DESCRIPTION')}</Typography>
          <Typography variant='body1' style={{ fontWeight: 'bold' }}>
            {t('SETTINGS.LOGO.SIZE.VALUE')}
          </Typography>
        </Grid>
        <Grid item>
          <ImageUploader onImageSelected={(selectedImage) => uploadLogo(selectedImage)} />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <div style={{ border: '1px solid black', width: '264px', height: '117px' }}>
          {props.logoFilename && <img src={props.logoFilename} style={{ width: '100%', height: '100%' }} alt='' />}
        </div>
      </Grid>
    </Grid>
  );
};

export default LogoComponent;
