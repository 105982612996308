/* eslint-disable no-unused-vars */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, CustomTabs, CancelModal, SuccessModal, TextPoint } from '@wdynamo/common';
import { IGroupInfoForm, IBaseStore, IGroupCreateParam, IUserItem, memberStatus } from './models';
import { Box, Container, Grid } from '@material-ui/core';
import { Stepper, UnclosableModal } from '@wdynamo/common/lib/components';
import { FormStep1 } from './components/FormStep1';
import { FormStep2 } from './components/FormStep2';
import { Analytics } from '@wdynamo/common/lib/services';
import CancelIllustration from '../../../../../../assets/img/illustrations/CancelIllustration.jpg';
import SuccessIllustration from '../../../../../../assets/img/illustrations/SuccessIllustration.jpg';
import CreatingOrderIllustration from '../../../../../../assets/img/illustrations/CreatingOrderIllustration.svg';
import { uris } from '../../../../../../siteMap';
import { IGroupsProps } from '../../models';
import { IUsersProps } from '../../../../models';

interface locationStateType {
  group: any;
}

interface GroupInfoFunctionalComponentProps {
  [key: string]: any;
  groupsStore: IGroupsProps;
  groupInfoStore: IBaseStore;
  usersStore: IUsersProps;
  rolesStore: any;
  groupInfoFetch(data: IGroupCreateParam, id?: string): void;
  getGroupMembersFetch(idGroup: string): void;
  getFilteredUsersByTextFetch(text: string, params: any): void;
  deleteMemberFetch(id: string): void;
  getRolesFetch(): void;
  className?: string;
}
const usePrevious = (value: IBaseStore): IBaseStore => {
  const ref = React.useRef<IBaseStore>();
  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current as IBaseStore;
};

const initialGroupInfoForm: IGroupInfoForm = {
  id_parent_group: { value: '', error: false, isRequired: true },
  name: { value: '', error: false, isRequired: true },
  description: { value: '', error: false, isRequired: true }
};

const CleanGroupInfoComponent: React.FC<GroupInfoFunctionalComponentProps> = (
  props: GroupInfoFunctionalComponentProps
) => {
  const {
    groupInfoStore,
    groupInfoFetch,
    groupsStore,
    getGroupMembersFetch,
    getFilteredUsersByTextFetch,
    usersStore,
    rolesStore,
    getRolesFetch
  } = props;

  const analytics = Analytics();
  const [isUpdateGroupView, setIsUpdateGroupView] = React.useState<boolean>(false);
  const [currentStep, setCurrentStep] = React.useState<number>(0); //testMember: pasar a 0
  const [groupInfoForm, setGroupInfoForm] = React.useState<IGroupInfoForm>(initialGroupInfoForm);
  const { keycloak } = useKeycloak();
  const prevGroupInfoStore = usePrevious(groupInfoStore);
  const history = useHistory();
  const location = useLocation<locationStateType>();
  const { t } = useTranslation();
  const steps = [t('GROUPS.STEP_1.STEPPER'), t('GROUPS.STEP_2.STEPPER')];
  const [currentMembers, setCurrentMembers] = React.useState<IUserItem[]>([]);
  const [infoButtonDisabled, setInfoButtonDisabled] = React.useState(false);
  const [finishButtonDisabled, setFinishButtonDisabled] = React.useState(false);
  //modals (para esto se puede usar un "state machine")
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = React.useState(false);
  const [showErrorCreatingModal, setShowErrorCreatingModal] = React.useState(false);
  const [previousSaveModalAction, setPreviousSaveModalAction] = React.useState<Function>(() => {});
  const [showDeleteErrorModal, setShowDeleteErrorModal] = React.useState(false);
  const [showSaveGroupErrorModal, setShowSaveGroupErrorModal] = React.useState(false);
  const [showSaveGroupSuccessModal, setShowSaveGroupSuccessModal] = React.useState(false);

  const groupId = location.state?.group.id;

  React.useEffect(() => {
    if (keycloak?.authenticated) {
      keycloak?.loadUserInfo().then((groupInfo: any) => {});
    }
  }, [keycloak?.authenticated]);

  React.useEffect(() => {
    if (currentStep === 1 && !isUpdateGroupView) {
      groupInfoStore.groupInfo?.error && setShowErrorCreatingModal(true);
    }
  }, [groupInfoStore.groupInfo?.error]);

  React.useEffect(() => {
    const items: IUserItem[] | any = groupInfoStore.groupMembers.members;
    if (items) setCurrentMembers(items);
  }, [groupInfoStore.groupMembers.members]);

  React.useEffect(() => {
    if (groupInfoStore.deleteMember.error) {
      setShowDeleteErrorModal(true);
      getGroupMembersFetch(groupId); //TODO
    }
  }, [groupInfoStore.deleteMember.error]);

  React.useEffect(() => {
    analytics.pageview('group-info');
    if (location.state && location.state.group) {
      setIsUpdateGroupView(true);
      if (currentStep === 0) getGroupMembersFetch(groupId); //pasar id y page
      Object.keys(groupInfoForm).forEach((input) => {
        setGroupInfoForm((prevState) => ({
          ...prevState,
          [input]: {
            ...prevState[input],
            value: input === 'groups' ? [location.state.group[input][0].id] : location.state.group[input]
          }
        }));
      });
    }
    getRolesFetch();
  }, []);

  const toggleCancelModal = () => {
    setShowCancelModal(!showCancelModal);
  };
  const toggleSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
  };

  const handleCancelModalConfirm = () => {
    //setUserInfoForm(initialUserInfoForm);
    toggleCancelModal();
    history.push(uris.groups.uri);
  };
  const handleSuccessModalConfirm = () => {
    toggleSuccessModal();
  };

  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };
  const handleCreateModalConfirm = () => {
    updateGroup();
    toggleCreateModal();
  };
  const handleCreateModalCancel = () => {
    toggleCreateModal();
  };

  const toggleErrorCreatingModal = () => {
    setShowErrorCreatingModal(!showErrorCreatingModal);
  };

  const toggleSaveChangesModal = () => {
    setShowSaveChangesModal(!showSaveChangesModal);
  };
  const handleSaveChangesConfirm = () => {
    if (currentStep === 0) {
      updateGroup();
      setCurrentStep(1);
    }
    //previousSaveModalAction();
    toggleSaveChangesModal();
  };
  const handleSaveChangesCancel = () => {
    setCurrentStep(1);
    toggleSaveChangesModal();
  };

  const saveGroup = () => {
    //Falta desarrollar la actualización de miembros. Falta definicion API
    toggleSaveGroupErrorModal();
  };

  const toggleDeleteErrorModal = () => {
    setShowDeleteErrorModal(!showDeleteErrorModal);
  };
  const toggleSaveGroupErrorModal = () => {
    setShowSaveGroupErrorModal(!showSaveGroupErrorModal);
  };
  const toggleSaveGroupSuccessModal = () => {
    setShowSaveGroupSuccessModal(!showSaveGroupSuccessModal);
  };

  const renderModal = () => (
    <>
      <CancelModal
        open={showCancelModal}
        handleClose={toggleCancelModal}
        onClickConfirm={toggleCancelModal}
        onClickCancel={handleCancelModalConfirm}
        messages={{
          TITLE: t('COMMONS.MODALS.CANCEL.TITLE'),
          CANCEL_BUTTON: t('COMMONS.MODALS.CANCEL.CANCEL'),
          CONTINUE_BUTTON: t('COMMONS.MODALS.CANCEL.CONTINUE')
        }}
        img={CancelIllustration}
      />
      <SuccessModal
        open={showSuccessModal}
        handleClose={toggleSuccessModal}
        onClickConfirm={handleSuccessModalConfirm}
        img={SuccessIllustration}
        description={''}
        messages={{
          TITLE: t('GROUPS.NEW_GROUP.MODAL.TITLE_SUCCESS'),
          CONTINUE_BUTTON: t('COMMONS.BUTTONS.CONTINUE')
        }}
      />
      <CancelModal
        open={showCreateModal}
        handleClose={toggleCreateModal}
        onClickConfirm={handleCreateModalConfirm}
        onClickCancel={handleCreateModalCancel}
        messages={{
          TITLE: t('GROUPS.NEW_GROUP.MODAL.TITLE'),
          CANCEL_BUTTON: t('GROUPS.NEW_GROUP.MODAL.NO'),
          CONTINUE_BUTTON: t('GROUPS.NEW_GROUP.MODAL.YES')
        }}
        img={CancelIllustration}
      />
      <CancelModal
        open={showSaveChangesModal}
        handleClose={toggleSaveChangesModal}
        onClickConfirm={handleSaveChangesConfirm}
        onClickCancel={handleSaveChangesCancel}
        messages={{
          TITLE: t('GROUPS.SAVE_CHANGES.MODAL.TITLE'),
          CANCEL_BUTTON: t('GROUPS.SAVE_CHANGES.MODAL.NO'),
          CONTINUE_BUTTON: t('GROUPS.SAVE_CHANGES.MODAL.YES')
        }}
        img={CancelIllustration}
      />
      <SuccessModal
        open={showErrorCreatingModal}
        handleClose={toggleErrorCreatingModal}
        onClickConfirm={() => {
          setCurrentStep(0);
          toggleErrorCreatingModal();
        }}
        img={SuccessIllustration}
        description={t('GROUPS.NEW_GROUP.MODAL.DESCRIPTION_ERROR')}
        messages={{
          TITLE: t('GROUPS.NEW_GROUP.MODAL.TITLE_ERROR'),
          CONTINUE_BUTTON: t('COMMONS.BUTTONS.TRY_AGAIN')
        }}
      />
      <SuccessModal
        open={showDeleteErrorModal}
        handleClose={toggleDeleteErrorModal}
        onClickConfirm={toggleDeleteErrorModal}
        img={CancelIllustration}
        description={t('GROUPS.EDIT_GROUP.REMOVE_MEMBERS_MODAL.ERROR.DESCRIPTION')}
        messages={{
          TITLE: t('GROUPS.EDIT_GROUP.REMOVE_MEMBERS_MODAL.ERROR.TITLE'),
          CONTINUE_BUTTON: t('GROUPS.EDIT_GROUP.REMOVE_MEMBERS_MODAL.ERROR.BUTTONS.TRY_AGAIN')
        }}
      />
      <SuccessModal
        open={showSaveGroupErrorModal}
        handleClose={toggleSaveGroupErrorModal}
        onClickConfirm={() => history.push(uris.groups.uri)}
        img={CancelIllustration}
        description={t('GROUPS.EDIT_GROUP.ERROR_MODAL.DESCRIPTION')}
        messages={{
          TITLE: t('GROUPS.EDIT_GROUP.ERROR_MODAL.TITLE'),
          CONTINUE_BUTTON: t('GROUPS.EDIT_GROUP.ERROR_MODAL.BUTTONS.TRY_AGAIN')
        }}
      />
      <SuccessModal
        open={showSaveGroupSuccessModal}
        handleClose={toggleSaveGroupSuccessModal}
        onClickConfirm={toggleSaveGroupSuccessModal}
        img={CancelIllustration}
        description={''}
        messages={{
          TITLE: t('GROUPS.EDIT_GROUP.SUCCESS_MODAL.TITLE'),
          CONTINUE_BUTTON: t('GROUPS.EDIT_GROUP.RSUCCESS_MODAL.BUTTONS.CONTINUE')
        }}
      />
    </>
  );

  const tabsHeaders = [
    {
      index: 0,
      label: t('GROUPS.EDIT_GROUP.INFO')
    },

    {
      index: 1,
      label: t('GROUPS.EDIT_GROUP.MEMBERS')
    }
  ];

  const getBackButtonsName = () => {
    return currentStep === 0 || isUpdateGroupView ? t('COMMONS.BUTTONS.CANCEL') : t('COMMONS.BUTTONS.ADD_LATER');
  };

  const getNextButtonsName = () => {
    if (isUpdateGroupView) return t('COMMONS.BUTTONS.FINISH');
    else if (currentStep === 0) return t('COMMONS.BUTTONS.CREATE');
    else return t('COMMONS.BUTTONS.CONFIRMATION');
  };

  const handleOnClickBackButton = () => {
    currentStep === 0 ? toggleCancelModal() : history.push(uris.groups.uri);
  };

  const handleOnClickNextButton = () => {
    if (isUpdateGroupView) {
      saveGroup();
    } else if (currentStep === 0) toggleCreateModal();
    else setShowSuccessModal(true);
  };

  const updateGroup = () => {
    const params: IGroupCreateParam = {
      name: groupInfoForm.name?.value.toString(),
      description: groupInfoForm.description?.value.toString(),
      id_parent_group: groupInfoForm.id_parent_group?.value?.toString()
    };
    const idGroup = isUpdateGroupView && location.state.group.id;
    groupInfoFetch(params, idGroup);
    setCurrentStep(1);
  };

  const handleOnChange = (fieldToUpdate: string, value: string) =>
    setGroupInfoForm({
      ...groupInfoForm,
      [fieldToUpdate]: { ...groupInfoForm[fieldToUpdate], value: value, error: false }
    });

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (currentStep === 0 && validateInfoFormFields())
      infoFormIsEdited() ? setShowSaveChangesModal(true) : setCurrentStep(newValue);
    if (currentStep === 1) setCurrentStep(newValue);
  };

  const infoFormIsEdited = (): boolean => {
    let isEdited: boolean = false;
    Object.keys(groupInfoForm).forEach((field) => {
      if (groupInfoForm[field].value !== location.state.group[field]) isEdited = true;
    });
    return isEdited;
  };

  const pageIsEdited = (): boolean => {
    let isEdited: boolean = false;
    currentMembers.forEach((item) => {
      if (item.status === memberStatus.ADDED || item.status === memberStatus.EMPTY) isEdited = true; //cambiar al comentado
      //if (item.status === memberStatus.ADDED || item.status === memberStatus.EDITED) isEdited = true;
    });
    return isEdited;
  };
  const askForChanges = (onCancel: Function) => {
    if (pageIsEdited()) {
      setPreviousSaveModalAction(() => {
        saveGroup();
        getGroupMembersFetch(groupId);
        onCancel();
      });
      setShowSaveChangesModal(true);
    } else {
      onCancel();
    }
  };

  React.useEffect(() => {
    setInfoButtonDisabled(!validateInfoFormFields() || groupInfoStore?.groupInfo?.loading);
    if (
      prevGroupInfoStore?.groupInfo?.loading &&
      !groupInfoStore!.groupInfo?.loading &&
      !groupInfoStore!.groupInfo?.error
    ) {
      setGroupInfoForm(initialGroupInfoForm);
      toggleSuccessModal();
    }
  }, [groupInfoForm, groupInfoStore]);

  const validateInfoFormFields = () => {
    let isValidForm: boolean = true;
    if (groupInfoForm.name.value === '') isValidForm = false;
    if (!isUpdateGroupView && groupInfoForm.id_parent_group.value === '') isValidForm = false;
    return isValidForm;
  };

  const header = (): React.ReactNode => {
    return (
      <>
        {isUpdateGroupView ? (
          <>
            <h2>
              {t('GROUPS.EDIT_GROUP.TITLE')}
              <TextPoint />
            </h2>
            <Box pb={5}>
              <CustomTabs tabHeaders={tabsHeaders} tabPanels={[]} value={currentStep} handleChange={handleChangeTab} />
            </Box>
          </>
        ) : (
          <>
            <Stepper activeStep={currentStep} steps={steps} className='checkout-stepper' />
            <h2>
              {currentStep === 0 ? t('GROUPS.NEW_GROUP.TITLE') : t('GROUPS.NEW_GROUP.ADD_MEMBERS_TITLE')}
              <TextPoint />
            </h2>
          </>
        )}
      </>
    );
  };

  const buttonNextDisabled = (): boolean => {
    if (currentStep === 0) return infoButtonDisabled;
    else return finishButtonDisabled;
  };

  return (
    <Container className={props.className} maxWidth='sm'>
      {header()}
      {(groupInfoStore.groupInfo?.loading ||
        groupInfoStore.groupMembers?.loading ||
        groupInfoStore.deleteMember.loading) && (
        <UnclosableModal img={CreatingOrderIllustration} messages={{ TITLE: t('COMMONS.MODALS.LOADING.TITLE') }} />
      )}
      {currentStep === 0 ? (
        <FormStep1
          groups={groupsStore.groups}
          handleOnChange={handleOnChange}
          groupInfoForm={groupInfoForm}
          isUpdateGroupView={isUpdateGroupView}
        />
      ) : (
        <FormStep2
          currentMembers={currentMembers}
          setCurrentMembers={setCurrentMembers}
          groupInfo={location.state?.group}
          isUpdateGroupView={isUpdateGroupView}
          filterUsersFetch={getFilteredUsersByTextFetch}
          filteredUsers={usersStore.filteredUsers}
          listRoles={rolesStore.roles}
          {...props}
        />
      )}
      <Grid className='add-group-button' direction='row' justify={'center'} container spacing={2}>
        <Grid item>
          <Button className='back' onClick={handleOnClickBackButton}>
            {getBackButtonsName()}
          </Button>
        </Grid>
        <Grid item>
          <Button className='next' onClick={handleOnClickNextButton} disabled={buttonNextDisabled()}>
            {getNextButtonsName()}
          </Button>
        </Grid>
      </Grid>
      {renderModal()}
    </Container>
  );
};

export const GroupInfoComponent = styled(({ ...props }) => <CleanGroupInfoComponent {...props} />)`
  .add-group-button {
    margin-top: 40px;
    .back,
    .next {
      padding: 10px 50px;
      border-radius: 25px;
      text-transform: none;
      font-size: 16px;
    }
    .back {
      background-color: transparent;
      box-shadow: none;
      color: var(--color-grey-text);
      border: 1px solid transparent;
      &:hover {
        border: 1px solid var(--color-grey-border);
      }
    }
    .next {
      color: var(--color-white);
    }
  }
`;
