import React, { useState } from 'react';
import styled from 'styled-components';

import { UsersListComponent } from './components';
import { IconButton, Input } from '@wdynamo/common';
import { ClickAwayListener, Grid, Typography } from '@material-ui/core';
import { ReactComponent as AddIcon } from '../../assets/img/icons_dynamo/icon_add.svg';
import { ReactComponent as IconGroup } from '../../assets/img/icons_dynamo/icon_group.svg';
import { ReactComponent as RoleIcon } from '../../assets/img/icons_dynamo/icon_role.svg';
import { ReactComponent as IconFilter } from '../../assets/img/icons_dynamo/icon_filter.svg';
import { ReactComponent as IconSearch } from '../../assets/img/icons_dynamo/icon_search.svg';
import { uris } from '../../siteMap';
import { useTranslation } from 'react-i18next';
import { UsersFilterPopper } from './components/UsersFilterPopper';

/* eslint-disable no-unused-vars */
import { IUsersProps } from './models';
import { IGroupsProps } from './components/Groups/models';
import { History } from 'history';
/* eslint-disable no-unused-vars */

interface IPagedUsersProps {
  [key: string]: any;
  usersStore: IUsersProps;
  groupsStore: IGroupsProps;
  rolesStore: any;
  getUsersFetch(page_len: number, page: number): void;
  getParentsFetch(parent?: string): void;
  getRolesFetch(): void;
  history: History;
  firstLoading: boolean;
  className?: string;
}

const CleanUsersComponent: React.FC<IPagedUsersProps> = (props: IPagedUsersProps) => {
  const PAGE_LEN: number = 15;
  const { t } = useTranslation();
  const [isSearchBarVisible, setIsSearchBarVisible] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState<string>('');
  const { history, getUsersFetch, groupsStore, rolesStore } = props;

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchText.trim() !== '') {
      //TODO: Falta que este definido el param
      console.log(`filtro: "${searchText}"`);
    }
  };

  const togglePopper = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const toggleSearchBar = () => {
    setIsSearchBarVisible(!isSearchBarVisible);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container alignItems='center' className={props.className}>
      <Grid item xs={6}>
        <h1></h1>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'end' }}>
        <IconButton onClick={() => history.push(uris.userInfo.uri)} Icon={AddIcon} />
      </Grid>

      <Grid item container xs={12} justify='space-between' direction='row'>
        <Grid item container xs={4} direction='row' alignItems='center'>
          <Grid
            item
            container
            xs={4}
            direction='row'
            alignItems='center'
            className='menu-button'
            onClick={() => history.push(uris.groups.uri)}
          >
            <div>
              <IconButton Icon={IconGroup} />
            </div>
            <Typography variant='h6'>{t('USERS.ICON_GROUPS')}</Typography>
          </Grid>
          <Grid
            item
            container
            xs={4}
            direction='row'
            alignItems='center'
            className='menu-button'
            onClick={() => history.push(uris.roles.uri)}
          >
            <IconButton Icon={RoleIcon} />
            <Typography variant='h6'>{t('USERS.ICON_ROLES')}</Typography>
          </Grid>
        </Grid>
        <ClickAwayListener mouseEvent='onMouseDown' touchEvent='onTouchStart' onClickAway={handleClickAway}>
          <Grid item container xs={3} direction='row' justify='flex-end'>
            <div onClick={togglePopper}>
              <IconButton Icon={IconFilter} className='filter-icons'></IconButton>
            </div>

            <UsersFilterPopper
              anchorEl={anchorEl}
              toggleOpen={togglePopper}
              onClick={() => {}}
              onClickReset={() => getUsersFetch(PAGE_LEN, 1)}
              groups={groupsStore?.parents?.groups}
              roles={rolesStore?.roles}
            />

            {isSearchBarVisible && (
              <Input
                label={t('COMMONS.SEARCH_BAR.PLACEHOLD')}
                onChange={(value: string): void => setSearchText(value)}
                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>): void => handleKeyPress(event)}
                testId='Search'
                fullWidth={true}
              ></Input>
            )}
            <IconButton Icon={IconSearch} className='filter-icons' onClick={toggleSearchBar} />
          </Grid>
        </ClickAwayListener>
      </Grid>
      <Grid item xs={12}>
        <UsersListComponent {...props} />
      </Grid>
    </Grid>
  );
};

export const UsersComponent = styled(({ ...props }) => <CleanUsersComponent {...props} />)`
  .menu-button {
    cursor: pointer;
  }
`;
