import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Loading, Price, substractTime } from '@wdynamo/common';
import { Grid } from '@material-ui/core';
// eslint-disable-next-line no-unused-vars
import { IReportsSalesTeams, IReportsSalesTeamsStore } from '../../../models';
import moment from 'moment';

interface ITopSalesTeamsProps {
  salesTeams: IReportsSalesTeamsStore;
  className?: string;
  [key: string]: any;
  getTopSalesTeamsFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    items_limit: number
  ): void;
}

export const TopSalesTeamsGrid = styled(Grid)`
  border: 1px solid var(--color-main);
  border-radius: 20px;
  margin: 20px 0px 20px 0px;
  padding: 20px;
  font-size: 16px;
  .headers {
    font-weight: 600;
    border: none;
  }
  .team {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-grey-border);
    &:last-child {
      border: none;
    }
  }
  .amount {
    text-align: end;
  }
`;

const CleanTopSalesTeams: React.FC<ITopSalesTeamsProps> = (props: ITopSalesTeamsProps) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { salesTeams, loading } = props.salesTeams;
  const { getTopSalesTeamsFetch } = props;

  useEffect(() => {
    if (keycloak?.authenticated) {
      setTimeout(() => {
        const to_date = moment();
        const from_date = moment(substractTime(to_date.format(), 'months', 6));
        getTopSalesTeamsFetch(from_date.toISOString(), to_date.toISOString(), true, 'sales', 7);
      });
    }
  }, [keycloak?.authenticated]);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <TopSalesTeamsGrid>
          <Grid className='headers' container>
            <Grid item xs={6}>
              {t('REPORTS.TABS.TOP_SALESTEAMS.SALESTEAMS')}
            </Grid>
            <Grid item xs={2}>
              {t('REPORTS.TABS.TOP_SALESTEAMS.TOTAL_SALES')}
            </Grid>
            <Grid item xs={4} className='amount'>
              {t('REPORTS.TABS.TOP_SALESTEAMS.TOTAL_SALES_MONEY')}
            </Grid>
          </Grid>
          {salesTeams.map((team: IReportsSalesTeams, index: number) => {
            return (
              <Grid className='team' container key={index}>
                <Grid item xs={6}>
                  {team.collaborator.username}
                </Grid>
                <Grid item xs={2}>
                  {team.total_sales}
                </Grid>
                <Grid item xs={4} className='amount'>
                  <Price amount={team.total_sales_money} />
                </Grid>
              </Grid>
            );
          })}
        </TopSalesTeamsGrid>
      </>
    );
  }
};

export const TopSalesTeams = (props: ITopSalesTeamsProps) => <CleanTopSalesTeams {...props} />;
