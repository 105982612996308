// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType, IBaseStateProps } from '../ActionTypes';

const initialState: IBaseStateProps = {
  loading: false,
  error: false,
  data: {}
};

export const settingsReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.SETTINGS.FETCH:
      return {
        ...state,
        loading: true,
        error: false,
        data: {}
      };
    case ACTIONS.SETTINGS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data.partner
      };
    case ACTIONS.SETTINGS.FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTIONS.SETTINGS.IMAGE.FETCH:
      return {
        ...state,
        loading: true,
        error: false,
        data: { ...state.data, logo_filename: '' }
      };
    case ACTIONS.SETTINGS.IMAGE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: { ...state.data, logo_filename: action.payload.data.location }
      };
    case ACTIONS.SETTINGS.IMAGE.FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTIONS.SETTINGS.UPDATE.FETCH:
      return {
        ...state,
        loading: true,
        error: false,
        data: {}
      };
    case ACTIONS.SETTINGS.UPDATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data.partner
      };
    case ACTIONS.SETTINGS.UPDATE.FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
};
