import React, { useState } from 'react';
import { Popper, StyledButton, Input } from '@wdynamo/common';

import { Box, Grid, MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles } from '@material-ui/core/styles';
// eslint-disable-next-line no-unused-vars
import { IGroupItem } from '../../models';
interface FilterPopperProps {
  onClick?: any;
  onClickReset?: any;
  anchorEl: any;
  toggleOpen: any;
  className?: string;
  parentList?: IGroupItem[];
}

interface IUsers {
  id: string;
  first_name: string;
  last_name: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    popper: { width: '20em', textAlign: 'left', padding: '0 1em' },
    fullWidth: { width: '100%' },
    buttonPY: { padding: '5px 0' },
    cancel: {
      width: '100%',
      backgroundColor: 'white',
      color: '#666',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    }
  })
);

const initialFilter = { parent: { value: '' }, users: { value: [] } };

export const FilterPopper: React.FC<FilterPopperProps> = (props: FilterPopperProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<any>(initialFilter);

  const { anchorEl, parentList } = props;

  const handleOnChange = (e: any, field: string): void => {
    setFilter({
      ...filter,
      [field]: { ...filter[field], value: e }
    });
  };
  const handleOnChangeMultiSelect = (target: any): void => {
    setFilter({ ...filter, ['users']: { ...filter['users'], value: target.value } });
  };

  return (
    <Popper anchorEl={anchorEl}>
      <Grid container spacing={1} className={classes.popper}>
        <Grid item xs={12} sm={12} className='form-input'>
          <Input
            label={t('GROUPS.PARENT_GROUP')}
            defaultValue={filter.parent.value}
            onChange={(e: any) => handleOnChange(e, 'parent')}
            testId='Parent'
            select={true}
          >
            {parentList?.map((item, i) => (
              <MenuItem key={i} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Input>
        </Grid>
        <Grid item xs={12} sm={12} className='form-input'>
          <TextField
            select
            fullWidth
            name='users'
            id='users'
            disabled={true}
            label={t('GROUPS.MEMBERS')}
            SelectProps={{
              multiple: true,
              value: filter.users.value,
              onChange: (e: React.ChangeEvent<unknown>) => handleOnChangeMultiSelect(e.target)
            }}
          >
            {([] as IUsers[]).map((user, i) => (
              <MenuItem key={i} value={user.id}>
                {`${user.first_name}  ${user.last_name}`}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Box width='100%' pt={5}>
          <Grid item xs={12} className={classes.buttonPY}>
            <StyledButton
              className={classes.fullWidth + ' default'}
              onClick={() => props.onClick(filter.parent.value, filter.users.value)}
            >
              {t('COMMONS.BUTTONS.FILTER')}
            </StyledButton>
          </Grid>
          <Grid item xs={12} className={classes.buttonPY}>
            <StyledButton
              className={`secondary ${classes.cancel}`}
              onClick={() => {
                props.onClickReset();
                props.toggleOpen();
              }}
            >
              {t('COMMONS.BUTTONS.RESET')}
            </StyledButton>
          </Grid>
        </Box>
      </Grid>
    </Popper>
  );
};
