import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';

type TransparentTooltipProps = {
  children: React.ReactElement;
  title: string;
};

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'var(--color-main)',
    height: 'fit-content',
    padding: '0',
    margin: '0',
    fontFamily: 'inherit',
    fontSize: '12px'
  }
})(Tooltip);

const TransparentTooltip = (props: TransparentTooltipProps) => {
  return (
    <StyledTooltip title={props.title} placement='top'>
      {props.children}
    </StyledTooltip>
  );
};

export default TransparentTooltip;
