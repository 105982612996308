/* eslint-disable no-unused-vars */
import { ACTIONS, actionType } from '../ActionTypes';
import { IInitialState } from './models';
/* eslint-enable no-unused-vars */

const initialState: IInitialState = {
  quotesInfo: {
    loading: false,
    error: false,
    data: null
  },
  products: {
    loading: false,
    error: false,
    message: '',
    data: []
  }
};

const initialAllState: IInitialState = {
  quotesInfo: {
    loading: false,
    error: false,
    data: null
  }
};

export const quotesManagementReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.QUOTE_MANAGEMENT.GET_QUOTES_INFO.FETCH:
      return {
        ...state,
        quotesInfo: {
          ...state.quotesInfo,
          loading: true,
          error: false,
          data: {}
        }
      };
    case ACTIONS.QUOTE_MANAGEMENT.GET_QUOTES_INFO.SUCCESS:
      return {
        ...state,
        quotesInfo: {
          ...state.quotesInfo,
          loading: false,
          error: false,
          data: action.payload.data
        }
      };
    case ACTIONS.QUOTE_MANAGEMENT.GET_QUOTES_INFO.FAIL:
      return {
        ...state,
        quotesInfo: {
          ...state.quotesInfo,
          loading: false,
          error: true,
          data: action.payload.data
        }
      };

    case ACTIONS.QUOTE_MANAGEMENT.GET_PRODUCTS.FETCH:
      return {
        ...state,
        products: {
          ...state.products,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTE_MANAGEMENT.GET_PRODUCTS.SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          error: false,
          data: [...action.payload]
        }
      };
    case ACTIONS.QUOTE_MANAGEMENT.GET_PRODUCTS.FAIL:
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };

    default:
      return state;
  }
};

export const quotesManagementAllReducer = (state = initialAllState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.QUOTE_MANAGEMENT.GET_ALL_QUOTES_INFO.FETCH:
      return {
        ...state,
        quotesInfo: {
          ...state.quotesInfo,
          loading: true,
          error: false,
          data: {}
        }
      };
    case ACTIONS.QUOTE_MANAGEMENT.GET_ALL_QUOTES_INFO.SUCCESS:
      return {
        ...state,
        quotesInfo: {
          ...state.quotesInfo,
          loading: false,
          error: false,
          data: action.payload.data
        }
      };
    case ACTIONS.QUOTE_MANAGEMENT.GET_ALL_QUOTES_INFO.FAIL:
      return {
        ...state,
        quotesInfo: {
          ...state.quotesInfo,
          loading: false,
          error: true,
          data: action.payload.data
        }
      };
    case ACTIONS.QUOTE_MANAGEMENT.CLEAR_ALL_QUOTES.FETCH:
      return {
        ...state,
        quotesInfo: {
          ...state.quotesInfo,
          loading: true,
          error: false,
          data: {}
        }
      };
    default:
      return state;
  }
};
