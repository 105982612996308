import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { IContact } from './models';

const Api = () => {
  const addContactFetch = (contactInfoForm: IContact): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers`, contactInfoForm);

  const updateContactFetch = (contactId: string, contactInfoForm: IContact): Promise<unknown> =>
    axios.patch(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers/${contactId}`, contactInfoForm);

  return { addContactFetch, updateContactFetch };
};

export default Api;
