import { ACTIONS } from '../../ActionTypes';

export const updateServiceFetch = (data: any) => ({
  type: ACTIONS.SERVICES.UPDATE.FETCH,
  payload: { data: data }
});

export const updateServiceSuccess = (data: any) => ({
  type: ACTIONS.SERVICES.UPDATE.SUCCESS,
  payload: { data: data }
});

export const updateServiceFail = (error: object) => ({ type: ACTIONS.SERVICES.UPDATE.FAIL, payload: { error } });
