import { ACTIONS } from '../../../../../ActionTypes';

// get contacts
export const getContactsFetch = (params: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.CONTACTS.FETCH,
  payload: { params }
});

export const getContactsSuccess = (data: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.CONTACTS.SUCCESS,
  payload: data
});

export const getContactsFail = (error: object) => ({
  type: ACTIONS.HOME_QUOTATIONS.CONTACTS.FAIL,
  payload: { error: error }
});

export const getContactsClear = () => ({
  type: ACTIONS.HOME_QUOTATIONS.CONTACTS.CLEAR
});
