const emailValidator = (email: string): boolean => {
  let isValid = true;
  // eslint-disable-next-line
        if (email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"~]+(\.[^<>()[\]\\.,;:\s@"~]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = re.test(String(email).toLowerCase());
  }
  return isValid;
};

export default emailValidator;
