import { call, put, takeLatest, takeEvery, all } from 'redux-saga/effects';
import Api from './Api';
// eslint-disable-next-line no-unused-vars
import ApiMock from './ApiMock';

import {
  getHomeStepsSuccess,
  getHomeStepsFail,
  getSourceHomeListSuccess,
  getSourceHomeListFail,
  getDataHomeSourceSuccess,
  getDataHomeSourceFail,
  createContactSuccess,
  createContactFail,
  getCitySuccess,
  getCityFail,
  getHomePlansSuccess,
  getHomePlansFail,
  getBillingModeSuccess,
  getBillingModeFail,
  getPaymentMethodSuccess,
  getPaymentMethodFail,
  getPaymentConditionSuccess,
  getPaymentConditionFail,
  updateQuotationSuccess,
  updateQuotationFail
} from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../../ActionTypes';

interface IUsersData {
  data?: any;
}

const api = Api();

// eslint-disable-next-line no-unused-vars
function* sagasGetHomeStepsFetch(action: actionType) {
  try {
    const response: IUsersData = yield call(api.getHomeStepsFetch, action.payload.params);
    const { data } = response;
    yield put(getHomeStepsSuccess(data));
  } catch (e) {
    yield put(getHomeStepsFail(e));
  }
}

function* sagasGetSourceHomeListFetch(action: actionType) {
  try {
    const response: IUsersData = yield call(api.getSourceHomeListFetch, action.payload.params);
    const { data } = response;
    yield put(getSourceHomeListSuccess({ [action.payload.params.field]: data }));
  } catch (e) {
    yield put(getSourceHomeListFail(e));
  }
}

function* sagasGetDataHomeSourceFetch(action: actionType) {
  try {
    const response: IUsersData = yield call(api.getDataHomeSourceFetch, action.payload.params);
    const { data } = response;

    yield put(getDataHomeSourceSuccess({ [action.payload.params.field]: data }));
  } catch (e) {
    yield put(getDataHomeSourceFail(e));
  }
}

function* sagasCreateContactFetch(action: actionType) {
  try {
    const response: any = yield call(api.createContactFetch, action.payload.params);
    const { data } = response;
    yield put(createContactSuccess(data));
  } catch (e) {
    yield put(createContactFail(e.response.data));
  }
}

function* sagasGetCityFetch(action: actionType) {
  try {
    const response: IUsersData = yield call(api.getCityFetch, action.payload.params);
    const { data } = response;
    yield put(getCitySuccess(data));
  } catch (e) {
    yield put(getCityFail(e.response.data));
  }
}

function* sagasGetHomePlansFetchh(action: actionType) {
  try {
    const response: any = yield call(api.getHomePlansFetch, action.payload.params);
    const { data } = response;
    yield put(getHomePlansSuccess(data));
  } catch (e) {
    yield put(getHomePlansFail(e.response.data));
  }
}

function* sagasGetBillingMethodFetch(action: actionType) {
  try {
    const response: any = yield call(api.getBillingModeFetch, action.payload.params);
    const { data } = response;
    yield put(getBillingModeSuccess(data));
  } catch (e) {
    yield put(getBillingModeFail(e.response.data));
  }
}

function* sagasGetPaymentMethodFetch(action: actionType) {
  try {
    const response: any = yield call(api.getPaymentMethodFetch, action.payload.params);
    const { data } = response;
    yield put(getPaymentMethodSuccess(data));
  } catch (e) {
    yield put(getPaymentMethodFail(e.response.data));
  }
}

function* sagasGetPaymentConditionFetch(action: actionType) {
  try {
    const response: any = yield call(api.getPaymentConditionFetch, action.payload.params);
    const { data } = response;
    yield put(getPaymentConditionSuccess(data));
  } catch (e) {
    yield put(getPaymentConditionFail(e.response.data));
  }
}

function* sagasUpdateQuotationFetch(action: actionType) {
  try {
    yield call(api.updatePlansFetch, action.payload.quotationId, action.payload.params);
    yield put(updateQuotationSuccess({ response: 'OK' }));
  } catch (e) {
    yield put(updateQuotationFail(e?.response?.data));
  }
}

// Listen for redux actions
function* quotationsHomeSaga() {
  yield all([takeLatest(ACTIONS.HOME_QUOTATIONS.STEPS.FETCH, sagasGetHomeStepsFetch)]);
  yield all([takeEvery(ACTIONS.HOME_QUOTATIONS.LISTSOURCES.FETCH, sagasGetSourceHomeListFetch)]);
  yield all([takeEvery(ACTIONS.HOME_QUOTATIONS.DATASOURCES.FETCH, sagasGetDataHomeSourceFetch)]);
  yield all([takeLatest(ACTIONS.HOME_QUOTATIONS.CREATE_CONTACT.FETCH, sagasCreateContactFetch)]);
  yield all([takeEvery(ACTIONS.HOME_QUOTATIONS.CITIES.FETCH, sagasGetCityFetch)]);
  yield all([takeLatest(ACTIONS.HOME_QUOTATIONS.PLANS.FETCH, sagasGetHomePlansFetchh)]);
  yield all([takeLatest(ACTIONS.HOME_QUOTATIONS.BILLING_MODE.FETCH, sagasGetBillingMethodFetch)]);
  yield all([takeLatest(ACTIONS.HOME_QUOTATIONS.PAYMENT_METHOD.FETCH, sagasGetPaymentMethodFetch)]);
  yield all([takeLatest(ACTIONS.HOME_QUOTATIONS.PAYMENT_CONDITION.FETCH, sagasGetPaymentConditionFetch)]);
  yield all([takeLatest(ACTIONS.HOME_QUOTATIONS.UPDATE.FETCH, sagasUpdateQuotationFetch)]);
}

export { quotationsHomeSaga };
