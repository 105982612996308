import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { RootState } from './store';

import { StylesProvider } from '@material-ui/styles';
import { Route, Switch, Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import styled from 'styled-components';
import { Grid, Container, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { getSettingsFetch } from './views/Settings/actions';

// eslint-disable-next-line no-unused-vars
import { Loading, SiteMap } from '@wdynamo/common';
import { Sidebar } from './components';

import HttpService from './services/HttpService';
import Config from './config';
import { uris } from './siteMap';
import DynamoLogo from './assets/img/LogoWDynamo.png';
import { ReactComponent as LogoutIcon } from '../src/assets/img/icons_dynamo/assign.svg';
import './App.scss';

interface AppProps {
  className?: string;
  props?: any;
  settingsStore: any;
  getSettingsFetch(partnerId: string): void;
}

const CleanApp: React.FC<AppProps> = (options: AppProps) => {
  const { t } = useTranslation();
  const [username, setUsername] = useState(null);
  const [links, setLinks] = useState<any>([]);
  const { keycloak } = useKeycloak();
  const { getSettingsFetch, settingsStore } = options;

  useEffect(() => {
    if (keycloak?.token) {
      HttpService.setAuthToken(keycloak?.token);
      parseJWT(keycloak?.token);
      handleFastLinks();
    }
  }, [keycloak?.token]);

  useEffect(() => {
    if (keycloak?.authenticated) {
      keycloak?.loadUserInfo().then((userInfo: any) => {
        setUsername(userInfo.preferred_username);
        getSettingsFetch(userInfo.partner_id);
      });
    }
  }, [keycloak?.authenticated]);

  useEffect(() => {
    options.settingsStore?.data.color &&
      document.body.style.setProperty('--color-main', options.settingsStore.data.color);
  }, [options.settingsStore]);

  const renderRouters = (routes: SiteMap[]) => {
    return routes.map((route: SiteMap) => {
      const Component = route.component;
      return (
        <Route key={route.uri} exact={route.exact} path={route.uri} render={(props) => <Component {...props} />} />
      );
    });
  };

  const renderContentGrid = (content: React.ReactNode) => (
    <Container>
      <Grid>{content}</Grid>
    </Container>
  );

  const parseJWT = (token: any) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    const payload = JSON.parse(jsonPayload);
    return { payload };
  };

  const handleFastLinks = () => {
    const { payload } = parseJWT(keycloak?.token);
    const roleAdmin = payload.realm_access.roles.find((rol: any) => rol === 'user_admin');
    const fastLinks = Config.loggedSitemap.filter((url) => url.displayAsFastLink);
    if (!roleAdmin) {
      const filterLinks = fastLinks.filter((link) => link.uri !== '/settings' && link.uri !== '/users');
      setLinks(filterLinks);
    } else {
      setLinks(fastLinks);
    }
  };

  return (
    <StylesProvider injectFirst>
      {(settingsStore?.loading || !Object.keys(settingsStore?.data ? settingsStore?.data : {}).length) &&
      keycloak?.authenticated ? (
        <Grid style={{ height: '100vh' }} container justifyContent='center' alignItems='center'>
          <Grid item>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <div className={options.className}>
          <Sidebar
            logoContent={
              <Link className='sidebar-logo' to={uris.home.uri}>
                <img src={settingsStore?.data.logo_filename || DynamoLogo} alt={DynamoLogo.split('/').pop()} />
              </Link>
            }
            logoutContent={
              <List className='logout-link'>
                <Link to='/login' style={{ textDecoration: 'none' }}>
                  <ListItem>
                    <ListItemAvatar className='icon-container'>
                      <LogoutIcon className='icon' width='42' height='42' />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ overflowWrap: 'anywhere', color: 'var' }}
                      primary={<Typography variant='h6'>{keycloak?.authenticated ? username : ''}</Typography>}
                      secondary={
                        <Typography variant='body1' color='textPrimary'>
                          {t('AUTH.LOGIN.LOGOUT')}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Link>
              </List>
            }
            fastLinks={links}
            hideSidebar={!keycloak?.authenticated}
          >
            {/* start router's sitemap */}
            {renderContentGrid(
              <Switch>
                {keycloak?.authenticated ? renderRouters(Config.loggedSitemap) : renderRouters(Config.anonymousSiteMap)}
              </Switch>
            )}
          </Sidebar>
          {/* end router's sitemap */}
        </div>
      )}
    </StylesProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  settingsStore: state.settingsState
});
const mapDispatchToProps = { getSettingsFetch };

export const App = connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(({ ...props }) => <CleanApp {...props} />)`
  * {
    font-family: 'Rubik';
  }
  .sidebar-logo {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    height: 50px;
    max-width: 240px;
  }
  .logout-link {
    display: flex;
    justify-content: center;
  }
  .icon-container {
    padding-top: 10px;
  }
  .icon > path {
    fill: var(--color-main);
  }
`);
