/* eslint-disable no-unused-vars */
import { ACTIONS, actionType } from '../../../../ActionTypes';
import { IRolesStore } from './models';
/* eslint-disable no-unused-vars */

const initialState: IRolesStore = {
  loading: false,
  error: false,
  roles: undefined
};

export const rolesListReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    // Get roles
    case ACTIONS.USERS.ROLES.FETCH:
      return {
        ...state,
        loading: true,
        error: false,
        roles: undefined
      };
    case ACTIONS.USERS.ROLES.SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload.data
      };
    case ACTIONS.USERS.ROLES.FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
};
