import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { IGroupCreateParam, AddMemberReq } from './models';

const Api = () => {
  const createGroupInfoFetch = (params: IGroupCreateParam): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_KAISER_V3_BASE_URL}/groups`, params);

  const updateGroupInfoFetch = (groupId: string, params: IGroupCreateParam): Promise<unknown> =>
    axios.patch(`${process.env.REACT_APP_KAISER_V3_BASE_URL}/groups/${groupId}`, params);

  const getGroupMembersFetch = (groupId: string): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_KAISER_V3_BASE_URL}/users/group/search?group=${groupId}`);

  const addMemberFetch = (addMemberReq: AddMemberReq): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_KAISER_V1_BASE_URL}/members/`, addMemberReq);

  const deleteMemberFetch = (id: string): Promise<unknown> =>
    axios.delete(`${process.env.REACT_APP_GET_PAGED_MEMBERS_URL!}/${id}`);

  return { createGroupInfoFetch, updateGroupInfoFetch, getGroupMembersFetch, addMemberFetch, deleteMemberFetch };
};

export default Api;
