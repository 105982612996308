import axios from 'axios';
class Api {
  // eslint-disable-next-line no-unused-vars
  getSettingsFetch = (partnerId: string): Promise<unknown> =>
    //   axios.get(`${process.env.REACT_APP_KAISER_V2_BASE_URL}/partners/${partnerId}`);
    // axios.get(`http://localhost:3005/partnerSettings/${partnerId}`);
    axios.get('https://run.mocky.io/v3/54579923-b611-4b68-af84-879d5b6875a3');

  uploadImageFetch = (imageData: any, partnerId: string): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_IMAGES_BASE_URL}`, imageData, { headers: { 'x-partner-id': partnerId } });

  updatePartnerFetch = (partnerData: any, partnerId: string): Promise<unknown> =>
    axios.patch(`${process.env.REACT_APP_KAISER_V2_BASE_URL}/partners/${partnerId}`, partnerData);
}

export default Api;
