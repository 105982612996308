import React, { useState } from 'react';
import { Grid, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import { IMaskInput } from 'react-imask';

interface IFormAddressProp {
  className?: string;
  dataForm: any;
  handleOnChange(value: string | boolean | number, name: string): void;
  statePath: string;
}

const numbMask = React.forwardRef<HTMLElement, any>(function numbMask(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;
  const contractor: string = 'contractor.address.floor';
  const insured: string = 'insured.address.floor';
  const regex = /^[0-9]*$/;

  return (
    <IMaskInput
      {...other}
      mask={regex}
      inputRef={ref}
      maxLength={props.name === contractor || props.name === insured ? 2 : 5}
      onAccept={(value: any) => onChange({ target: { value, name: props.name } })}
    />
  );
});

const CleanformAddress: React.FC<IFormAddressProp> = (props: IFormAddressProp) => {
  // eslint-disable-next-line no-unused-vars
  const { dataForm, handleOnChange, statePath } = props;
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckbox = (e: any) => {
    dataForm.street_number.value = '';
    setIsChecked(e.target.checked);
    handleOnChange(e.target.checked, e.target.name);
  };

  return (
    <>
      <Grid item md={5}>
        <TextField
          name={`${statePath}.street`}
          value={dataForm.street.value}
          error={dataForm.street.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.street.isRequired}
          label={'Calle'}
          inputProps={{
            maxLength: 70
          }}
        />
      </Grid>
      <Grid item md={1}>
        <TextField
          name={`${statePath}.street_number`}
          value={String(dataForm.street_number.value || '')}
          error={dataForm.street_number.error}
          onChange={(event): void => handleOnChange(Number(event.target.value), event.target.name)}
          fullWidth
          required={isChecked ? !dataForm.street_number.isRequired : dataForm.street_number.isRequired}
          disabled={isChecked}
          label={'Número'}
          InputProps={{
            inputComponent: numbMask as any
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item md={2} style={{ display: 'flex', justifyContent: 'center', margin: '0.7em 0' }}>
        <FormControlLabel
          style={{ margin: 0 }}
          control={
            <Checkbox
              checked={isChecked}
              onChange={(e) => handleCheckbox(e)}
              name={`${statePath}.without_street_number`}
            />
          }
          label='Sin número'
        />
      </Grid>
      <Grid item md={2}>
        <TextField
          name={`${statePath}.floor`}
          value={String(dataForm.floor.value || '')}
          error={dataForm.floor.error}
          onChange={(event): void => handleOnChange(Number(event.target.value), event.target.name)}
          fullWidth
          required={dataForm.floor.isRequired}
          label={'Piso'}
          InputProps={{
            inputComponent: numbMask as any
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item md={2}>
        <TextField
          name={`${statePath}.apartment`}
          value={dataForm.apartment.value}
          error={dataForm.apartment.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.apartment.isRequired}
          label={'Depto'}
          inputProps={{
            maxLength: 2
          }}
        ></TextField>
      </Grid>
    </>
  );
};
export const FormAddress = styled(({ ...props }) => <CleanformAddress {...props} />)`
  .radio-label {
    font-size: 13px;
  }
`;
