import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EllipseIcon } from '../../../../../../../../assets/img/icons_dynamo/icon_ellipse.svg';
import { StyledButton } from '@wdynamo/common';
import { PlanCardModal } from '../PlanCardModal';
import handleCharacteristics from '../../../../common/handleCharacteristics';

interface IPlanCardProp {
  className?: string;
  data: any;
  formatPrice(number: number): string;
  currencySimbol: string;
  onSelect(plan: any): void;
  widthTitle: any;
  itemsToShow: number;
  productId: any;
}

const CleanPlanCard: React.FC<IPlanCardProp> = (props: IPlanCardProp) => {
  // eslint-disable-next-line no-unused-vars
  const { data, formatPrice, currencySimbol, onSelect, widthTitle, itemsToShow, productId } = props;
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const homePlans = 2;

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const renderCharacteristics = (itemsToShow: any) => {
    return (
      <List dense>
        {data?.characteristics.map((item: string, i: number) => {
          const { capitalize, price } = handleCharacteristics(item);
          if (i <= itemsToShow - 1)
            return (
              <ListItem key={i}>
                <ListItemIcon>
                  <EllipseIcon fill='var(--color-main)' />
                </ListItemIcon>
                <ListItemText primary={capitalize} secondary={`${currencySimbol} ${price}`} />
              </ListItem>
            );
        })}
        {productId === homePlans && data?.characteristics.length > 4 && (
          <a className='see-more' onClick={handleOpenModal}>
            <Typography variant='body2' align='right' style={{ paddingRight: '2em' }}>
              {t('QUOTATIONS.STEP_4.SEE_MORE')}
            </Typography>
          </a>
        )}
      </List>
    );
  };

  return (
    <Grid className={props.className}>
      <Grid direction='column' container>
        <Grid item className='card-header'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: widthTitle
            }}
          >
            <Typography
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                textAlign: 'left'
              }}
            >
              {data.text}
            </Typography>
          </div>
          {data.payments_amount > 1 && (
            <Typography>
              <span className='first-payment bold'>{`${currencySimbol} ${formatPrice(
                Number(data.first_payment)
              )}`}</span>
              {t('QUOTATIONS.STEP_4.FIRST_PAYMENT')}
            </Typography>
          )}
          <Typography>
            <span className='total-price bold'>{`${currencySimbol} ${formatPrice(Number(data.price))}`}</span>
            {t('QUOTATIONS.STEP_4.TOTAL_PRICE')}
          </Typography>
        </Grid>
        <Grid item className='card-content'>
          {renderCharacteristics(itemsToShow)}
          <Grid item container className='card-action' justify='center'>
            <StyledButton onClick={() => onSelect(data)}>{t('QUOTATIONS.STEP_4.SELECT')}</StyledButton>
          </Grid>
        </Grid>
      </Grid>
      {data?.characteristics?.length && (
        <PlanCardModal
          openModal={openModal}
          data={data}
          setOpenModal={setOpenModal}
          currencySimbol={currencySimbol}
          formatPrice={formatPrice}
          onSelect={onSelect}
        />
      )}
    </Grid>
  );
};

export const PlanCard = styled(({ ...props }) => <CleanPlanCard {...props} />)`
  .card-header {
    padding: 0.5em 1em;
    color: var(--color-white);
    background-color: var(--color-main);
    border-radius: 24px 24px 0 0;
    .first-payment {
      font-size: x-large;
    }
    .total-price {
      font-size: large;
    }
    .bold {
      font-weight: bold;
      margin-right: 0.3em;
    }
    .remaining-payment {
      display: block;
      font-size: larger;
      font-weight: bold;
    }
    .ml {
      margin-left: 0.3em;
    }
  }
  .card-content {
    min-height: 27em;
    background-color: var(--color-white);
    border: 2px solid var(--color-main);
    border-radius: 0 0 24px 24px;
    position: relative;
    .green-background {
      background-color: var(--color-green);
      height: 25px;
      margin-top: 1em;
      padding: 4px 18px;
      border-radius: 0px 5px 5px 0px;
      width: 40%;
      color: var(--color-white);
    }
    .MuiList-root {
      color: var(--color-black);
      .MuiListItem-dense {
        padding: 3px 16px;
        .MuiListItemIcon-root {
          min-width: 25px;
        }
      }
    }
    .list-padding-modal {
      padding: 8px 11px;
    }
    .see-more {
      color: var(--color-main);
      &:hover {
        cursor: pointer;
      }
    }
    .card-action {
      margin: 1em 0;
      position: absolute;
      bottom: 0;
      button {
        padding: 5px 50px;
        background-color: var(--color-main);
        color: var(--color-white);
      }
    }
  }
`;
