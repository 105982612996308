export const uris = {
  home: {
    uri: '/',
    label: 'Home'
  },
  example: {
    uri: '/example',
    label: 'Ejemplo'
  },
  products: {
    uri: '/products',
    label: 'Productos'
  },
  services: {
    uri: '/services',
    label: 'Inicio'
  },
  newService: {
    uri: '/new-service',
    label: 'Servicio'
  },
  login: {
    uri: '/login',
    label: 'Login'
  },
  userInfo: {
    uri: '/user-info',
    label: 'Usuario'
  },
  checkout: {
    uri: '/checkout',
    label: 'Realizar compra'
  },
  mySales: {
    uri: '/my-sales',
    label: 'Cotizaciones'
  },
  contacts: {
    uri: '/contacts',
    label: 'Clientes'
  },
  contactInfo: {
    uri: '/contact-info',
    label: 'Contacto'
  },
  users: {
    uri: '/users',
    label: 'Usuarios'
  },
  roles: {
    uri: '/roles',
    label: 'Roles'
  },
  roleInfo: {
    uri: '/role-info',
    label: 'Rol'
  },
  reports: {
    uri: '/reports',
    label: 'Tablero'
  },
  settings: {
    uri: '/settings',
    label: 'Ajustes'
  },
  groups: {
    uri: '/groups',
    label: 'Grupos'
  },
  groupInfo: {
    uri: '/groupInfo',
    label: 'Grupo'
  },
  serviceUpdate: {
    uri: '/serviceUpdate',
    label: 'Editar servicio'
  },
  quotationsAuto: {
    uri: '/quotation-auto',
    label: 'Cotización auto'
  },
  emissions: {
    uri: '/issue',
    label: 'Emisión'
  },
  contactDetails: {
    uri: '/contact-details',
    label: 'Detalles del contacto'
  },
  inspection: {
    uri: '/inspection-form',
    label: 'Formulario de inspección'
  },
  quotes: {
    uri: '/quotes',
    label: 'Cotizaciones'
  },
  sales: {
    uri: '/sales',
    label: 'Ventas'
  },
  quotationsHome: {
    uri: '/quotation-home',
    label: 'Cotización hogar'
  },
  quotationsAp: {
    uri: '/quotation-ap',
    label: 'Cotización AP'
  }
};
