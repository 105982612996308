/* eslint-disable no-unused-vars */
import axios from 'axios';
import { IGetServicesResponse } from './models';

const Api = () => {
  const getServicesFetch = (): Promise<IGetServicesResponse> =>
    axios.get(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/product`);
  // axios.get('http://localhost:3005/product');

  return { getServicesFetch };
};

export default Api;
