import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { Keycloak, KeycloakProps, Analytics } from '@wdynamo/common/lib/services';
import { KeycloakComponent } from '@wdynamo/common/lib/components/Keycloak';

import { Store, Persistor } from './store';

import { App } from './App';

const keycloakInitProps: KeycloakProps = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM!,
  url: process.env.REACT_APP_KEYCLOAK_URL!,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!
};

const keycloakProviderInitConfig = {
  onLoad: 'check-sso',
  promiseType: 'native'
};

const Root = () => {
  useEffect(() => {
    Analytics().initializeReactGA(process.env.REACT_APP_GA, process.env.REACT_APP_GA_DEBUG === 'true');
  }, []);

  const onKeycloakEvent = (): void => {
    return;
  };

  const onKeycloakTokens = (): void => {
    Analytics().trackEvent('auth', 'login-success');
  };

  return (
    <KeycloakComponent
      keycloak={Keycloak({ ...keycloakInitProps })}
      initConfig={keycloakProviderInitConfig}
      onEvent={onKeycloakEvent}
      onTokens={onKeycloakTokens}
    >
      <Router>
        <Provider store={Store}>
          <PersistGate loading={null} persistor={Persistor}>
            <App />
          </PersistGate>
        </Provider>
      </Router>
    </KeycloakComponent>
  );
};

export default Root;
