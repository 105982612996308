import { Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

// eslint-disable-next-line no-unused-vars
import { IQuotationInput } from '../../../../models';

interface IFormSpouseProp {
  className?: string;
  dataForm: any;
  handleOnChange(value: string | number, name: string): void;
  statePath: string;
  idTypeList: IQuotationInput[];
}

const CleanformSpouse: React.FC<IFormSpouseProp> = (props: IFormSpouseProp) => {
  const { dataForm, handleOnChange, statePath, idTypeList } = props;

  return (
    <Grid container spacing={1} className={props.className + ' container'}>
      <Grid item md={12}>
        <Typography>Datos del cónyuge</Typography>
      </Grid>
      <Grid item md={4}>
        <TextField
          name={`${statePath}.name`}
          value={dataForm.name.value}
          error={dataForm.name.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.name.isRequired}
          label={'Nombre'}
        ></TextField>
      </Grid>
      <Grid item md={4}>
        <TextField
          name={`${statePath}.last_name`}
          value={dataForm.last_name.value}
          error={dataForm.last_name.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.last_name.isRequired}
          label={'Apellido'}
        ></TextField>
      </Grid>
      <Grid item md={1}>
        <TextField
          name={`${statePath}.id_type`}
          value={dataForm.id_type.value}
          error={dataForm.id_type.error}
          onChange={(event): void => handleOnChange(Number(event.target.value), event.target.name)}
          fullWidth
          required={dataForm.id_type.isRequired}
          label={'Tipo'}
          select={true}
        >
          {idTypeList.map((item: any, i: number) => (
            <MenuItem key={i} value={item.code}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3}>
        <TextField
          name={`${statePath}.id`}
          value={dataForm.id.value}
          error={dataForm.id.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.id.isRequired}
          label={'Documento'}
        ></TextField>
      </Grid>
      <Grid item md={4}>
        <TextField
          name={`${statePath}.mail`}
          value={dataForm.mail.value}
          error={dataForm.mail.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.mail.isRequired}
          type='email'
          label={'Correo electrónico'}
        ></TextField>
      </Grid>
      <Grid item md={4}>
        <TextField
          name={`${statePath}.phone`}
          value={dataForm.phone.value}
          error={dataForm.phone.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          type='number'
          required={dataForm.phone.isRequired}
          label={'Teléfono'}
        ></TextField>
      </Grid>
    </Grid>
  );
};
export const FormSpouse = styled(({ ...props }) => <CleanformSpouse {...props} />)`
  .radio-label {
    font-size: 13px;
  }
  .container {
    padding-bottom: 1em;
  }
`;
