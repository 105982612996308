import { connect } from 'react-redux';
import { ModalImportCustomersComponent as ModalImport } from './ModalImportCustomers';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../../../store';
import { contactInfoFetch } from '../../../ContactInfo/actions';
import { getUsersFetch } from '../../../../../Users/actions';
const mapStateToProps = (state: RootState) => ({
  contactInfoStore: state.contactInfoState,
  usersStore: state.usersState
});
const mapDispatchToProps = { contactInfoFetch, getUsersFetch };

export const ModalImportCustomersComponent = connect(mapStateToProps, mapDispatchToProps)(ModalImport);
