// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IGroupsProps } from './models';

const initialState: IGroupsProps = {
  loading: false,
  error: false,
  groups: [],
  parents: {
    groups: [],
    loading: false,
    error: false
  }
};

export const groupsReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.GROUPS.FETCH:
      return {
        ...state,
        loading: true,
        error: false,
        groups: []
      };
    case ACTIONS.GROUPS.SUCCESS:
      return {
        ...state,
        loading: false,
        groups: [...state.groups, ...action.payload.data]
      };
    case ACTIONS.GROUPS.FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ACTIONS.GROUPS.GROUP_PARENTS.FETCH:
      return {
        ...state,
        parents: {
          loading: true,
          error: false,
          groups: []
        }
      };
    case ACTIONS.GROUPS.GROUP_PARENTS.SUCCESS:
      return {
        ...state,
        parents: {
          ...state.parents,
          loading: false,
          groups: [...state.parents.groups, ...action.payload.data]
        }
      };
    case ACTIONS.GROUPS.GROUP_PARENTS.FAIL:
      return {
        ...state,
        parents: {
          ...state.parents,
          loading: false,
          error: true
        }
      };
    default:
      return state;
  }
};
