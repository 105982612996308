import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Container, Grid, MenuItem, Switch, Typography } from '@material-ui/core';
import {
  Button,
  Input,
  CustomAlert,
  AlertSeverities,
  CancelModal,
  SuccessModal,
  TextPoint,
  IconButton
} from '@wdynamo/common';
import { Analytics } from '@wdynamo/common/lib/services';

import CancelIllustration from '../../../../assets/img/illustrations/CancelIllustration.jpg';
import SuccessIllustration from '../../../../assets/img/illustrations/SuccessIllustration.jpg';
import { ReactComponent as iconTrash } from '../../../../assets/img/icons_dynamo/icon_trash.svg';
import { ReactComponent as addIcon } from '../../../../assets/img/icons_dynamo/icon_add.svg';

/* eslint-disable no-unused-vars */
import { IUserInfoForm, UserInfoForm, IBaseStore } from './models';
import { IGroupsProps } from '../Groups/models';
import { IRolesStore } from '../Roles/RolesList/models';
/* eslint-disable no-unused-vars */

import { uris } from '../../../../siteMap';
import { useEffect } from 'react';

interface locationStateType {
  user: any;
}

interface UserInfoFunctionalComponentProps {
  [key: string]: any;
  groupsStore: IGroupsProps;
  rolesStore: IRolesStore;
  userInfoStore: IBaseStore;
  getParentsFetch(): void;
  getRolesFetch(): void;
  userInfoFetch(data: UserInfoForm, userId?: string): void;
  className?: string;
}

const initialUserInfoForm: IUserInfoForm = {
  email: { value: '', error: false, isRequired: true },
  username: { value: '', error: false, isRequired: true },
  active: { value: true, error: false, isRequired: false },
  first_name: { value: '', error: false, isRequired: true },
  last_name: { value: '', error: false, isRequired: true },
  roles: { value: [], error: false, isRequired: false },
  producer_code: { value: '', error: false, isRequired: true }
};

const usePrevious = (value: IBaseStore): IBaseStore => {
  const ref = React.useRef<IBaseStore>();
  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current as IBaseStore;
};

const CleanUserInfoComponent: React.FC<UserInfoFunctionalComponentProps> = (
  props: UserInfoFunctionalComponentProps
) => {
  const history = useHistory();
  const location = useLocation<locationStateType>();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const { userInfoStore, rolesStore, groupsStore, getRolesFetch, getParentsFetch } = props;
  const [isUpdateUserView, setIsUpdateUserView] = React.useState<boolean>(false);
  const prevUserInfoStore = usePrevious(userInfoStore);
  const [currentUserCollaboratorId, setCurrentUserCollaboratorId] = React.useState<string[]>(['']);
  const [userInfoForm, setUserInfoForm] = React.useState<any>(initialUserInfoForm);
  const [userInfoButtonDisabled, setUserInfoButtonDisabled] = React.useState(true);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);

  React.useEffect(() => {
    Analytics().pageview('user-info');

    if (location.state && location.state.user) {
      setIsUpdateUserView(true);
      setUserInfoForm((prevState: any) => {
        let newState: IUserInfoForm = initialUserInfoForm;
        Object.keys(prevState).forEach((input) => {
          newState = {
            ...newState,
            [input]: {
              ...newState[input],
              value: location.state.user[input]
            }
          };
        });
        return newState;
      });
    } //TODO: fix for update
  }, []);

  React.useEffect(() => {
    if (keycloak?.authenticated) {
      keycloak?.loadUserInfo().then((userInfo: any) => {
        setCurrentUserCollaboratorId([userInfo.collaborator_id]);
        if (!rolesStore.roles) getRolesFetch();
        if (groupsStore?.parents?.groups?.length === 0) getParentsFetch();
      });
    }
  }, [keycloak?.authenticated]);

  React.useEffect(() => {
    setUserInfoButtonDisabled(!validateFormFields() || userInfoStore.userInfo.loading);
    if (prevUserInfoStore?.userInfo.loading && !userInfoStore!.userInfo.loading && !userInfoStore!.userInfo.error) {
      setUserInfoForm(initialUserInfoForm);
      setShowSuccessModal((show) => !show);
      if (isUpdateUserView) {
        history.push(uris.users.uri);
      }
    }
  }, [userInfoForm, userInfoStore]);

  useEffect(() => {
    userInfoStore.userInfo.error === true && toggleErrorModal();
  }, [userInfoStore.userInfo.error]);

  const validateFormFields = () => {
    let isFormValid: boolean = true;
    Object.keys(userInfoForm).forEach((item) => {
      const inputField = userInfoForm[item];
      isFormValid =
        isFormValid && !inputField.error && !!((inputField.isRequired && inputField.value) || !inputField.isRequired);
    });
    return isFormValid;
  };

  const handleOnChange = (fieldToUpdate: string, value: string | boolean) =>
    setUserInfoForm({
      ...userInfoForm,
      [fieldToUpdate]: { ...userInfoForm[fieldToUpdate], value: value, error: false }
    });

  const userInfo = () => {
    if (validateFormFields()) {
      if (isUpdateUserView) {
        const data: any = {
          first_name: userInfoForm.first_name.value as string,
          last_name: userInfoForm.last_name.value as string,
          active: userInfoForm.active.value === true,
          roles: userInfoForm.roles.value,
          producer_code: userInfoForm.producer_code.value as string
        };
        Analytics().trackEvent('user-info', 'update-user', undefined, data.email);
        props.userInfoFetch(data, location.state.user.id);
      } else {
        const emptyRolesInfo =
          userInfoForm.roles.value[0]?.role.id === '' || userInfoForm.roles.value[0]?.group.id === '';
        const data: any = {
          first_name: userInfoForm.first_name.value as string,
          last_name: userInfoForm.last_name.value as string,
          email: userInfoForm.email.value as string,
          username: userInfoForm.username.value as string,
          redirect_uri: window.location.href,
          roles: emptyRolesInfo ? [] : userInfoForm.roles.value,
          producer_code: userInfoForm.producer_code.value as string
        };
        Analytics().trackEvent('user-info', 'add-user', undefined, data.email);
        props.userInfoFetch(data);
      }
    }
  };

  const toggleCancelModal = () => {
    setShowCancelModal(!showCancelModal);
  };
  const toggleSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
  };
  const toggleErrorModal = () => {
    setShowErrorModal(!showErrorModal);
  };

  const handleCancelModalConfirm = () => {
    setUserInfoForm(initialUserInfoForm);
    toggleCancelModal();
    history.push(uris.users.uri);
  };
  const handleSuccessModalConfirm = () => {
    setUserInfoForm(initialUserInfoForm);
    toggleSuccessModal();
  };

  const renderModal = () => (
    <>
      <CancelModal
        open={showCancelModal}
        handleClose={toggleCancelModal}
        onClickConfirm={toggleCancelModal}
        onClickCancel={handleCancelModalConfirm}
        messages={{
          TITLE: t('COMMONS.MODALS.CANCEL.TITLE'),
          CANCEL_BUTTON: t('COMMONS.MODALS.CANCEL.CANCEL'),
          CONTINUE_BUTTON: t('COMMONS.MODALS.CANCEL.CONTINUE')
        }}
        img={CancelIllustration}
      />
      <SuccessModal
        open={showSuccessModal}
        handleClose={toggleSuccessModal}
        onClickConfirm={handleSuccessModalConfirm}
        description={t('USERS.USER_INFO.ADD_USER.SUCCESS.TEXT')}
        img={SuccessIllustration}
        messages={{
          TITLE: t('USERS.USER_INFO.ADD_USER.SUCCESS.TITLE'),
          CONTINUE_BUTTON: t('COMMONS.BUTTONS.CONTINUE')
        }}
      />
      <SuccessModal
        open={showErrorModal}
        handleClose={toggleErrorModal}
        onClickConfirm={toggleErrorModal}
        img={CancelIllustration}
        description={t('USERS.USER_INFO.ADD_USER.ERROR_MODAL.DESCRIPTION')}
        messages={{
          TITLE: t('USERS.USER_INFO.ADD_USER.ERROR_MODAL.TITLE'),
          CONTINUE_BUTTON: t('USERS.USER_INFO.ADD_USER.ERROR_MODAL.BUTTONS.TRY_AGAIN')
        }}
      />
    </>
  );
  const handleOnClickDelete = (index: number) => {
    const newStateUserInfoForm: IUserInfoForm = { ...userInfoForm };
    const newList: any[] = newStateUserInfoForm.roles.value;
    newList.splice(index, 1);
    setUserInfoForm(newStateUserInfoForm);
  };

  const handleOnChangeRole = (index: number, field: string, value: string) => {
    const newStateUserInfoForm: IUserInfoForm = { ...userInfoForm };
    const newValue: any = newStateUserInfoForm.roles.value[index];
    newValue[field].id = value;
    setUserInfoForm(newStateUserInfoForm);
  };

  const rolesList = (
    <Grid container item spacing={2} className='roles-list'>
      {userInfoForm.roles.value.length !== 0 && (
        <>
          <Grid item xs={12} sm={5}>
            <Typography variant='caption'>{t('USERS.USER_INFO.ROLE')}</Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography variant='caption'>{t('USERS.USER_INFO.GROUP')}</Typography>
          </Grid>
        </>
      )}

      {userInfoForm.roles.value.map((item: any, i: any) => {
        return (
          <React.Fragment key={i}>
            <Grid item xs={5} className='form-input'>
              <Input
                defaultValue={userInfoForm.roles.value[i].role.id as string}
                onChange={(value: string): void => handleOnChangeRole(i, 'role', value)}
                testId='role'
                required={true}
                select={true}
              >
                {!rolesStore?.roles && <MenuItem></MenuItem>}
                {rolesStore?.roles?.map((rol) => (
                  <MenuItem key={rol.id} value={rol.id}>
                    {rol.name}
                  </MenuItem>
                ))}
              </Input>
            </Grid>
            <Grid item xs={5} className='form-input'>
              <Input
                defaultValue={item.group.id}
                onChange={(value: string): void => handleOnChangeRole(i, 'group', value)}
                testId='group'
                required={true}
                select={true}
              >
                {groupsStore.parents?.groups?.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
              </Input>
            </Grid>
            <Grid item container xs={1} className='form-input' direction='column' alignContent='center'>
              <IconButton className='trash-icon' Icon={iconTrash} onClick={() => handleOnClickDelete(i)} />
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );

  const handleOnClickAddRole = () => {
    const emptyRole = {
      role: { id: '', description: '', name: '' },
      group: { id: '', description: '', name: '', uuid: '' }
    };
    const newStateUserInfoForm: any = {
      ...userInfoForm,
      roles: { ...userInfoForm.roles, value: [emptyRole, ...userInfoForm.roles.value] }
    };
    setUserInfoForm(newStateUserInfoForm);
  };

  const isThereAnEmptyRole = (): boolean => {
    if (userInfoForm.roles.value[0]?.role.id === '' || userInfoForm.roles.value[0]?.group.id === '') return true;
    else return false;
  };

  const buttonAddMember = (
    <Grid container item xs={12} direction='row' justify='flex-start' alignItems='center'>
      <IconButton
        Icon={addIcon}
        onClick={() => {
          handleOnClickAddRole();
        }}
        disabled={isThereAnEmptyRole()}
      />
      <Typography className='icon-label'>{t('USERS.USER_INFO.ADD_ROLE_GROUP')} </Typography>
    </Grid>
  );

  return (
    <Container className={props.className || 'add-user'} maxWidth='sm'>
      <h2>
        {isUpdateUserView ? t('USERS.USER_INFO.UPDATE_USER.TITLE') : t('USERS.USER_INFO.ADD_USER.TITLE')}
        <TextPoint />
      </h2>
      <div className='add-user-form'>
        <Grid direction='row' container spacing={2}>
          <Grid item xs={12} sm={5} className='form-input'>
            <Input
              label={t('USERS.USER_INFO.FIRST_NAME')}
              defaultValue={userInfoForm.first_name.value as string}
              error={userInfoForm.first_name.error}
              onChange={(value: string): void => handleOnChange('first_name', value)}
              testId='first_name'
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} className='form-input'>
            <Input
              label={t('USERS.USER_INFO.LAST_NAME')}
              defaultValue={userInfoForm.last_name.value as string}
              error={userInfoForm.last_name.error}
              onChange={(value: string): void => handleOnChange('last_name', value)}
              testId='last_name'
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={5} className='form-input'>
            <Input
              label={t('USERS.USER_INFO.USERNAME')}
              defaultValue={userInfoForm.username.value as string}
              error={userInfoForm.username.error}
              onChange={(value: string): void => handleOnChange('username', value)}
              testId='username'
              required={true}
              disabled={isUpdateUserView}
            />
          </Grid>
          <Grid item xs={12} sm={6} className='form-input'>
            <Input
              label={t('USERS.USER_INFO.EMAIL')}
              autoComplete={true}
              defaultValue={userInfoForm.email.value as string}
              error={userInfoForm.email.error}
              onChange={(value: string): void => handleOnChange('email', value)}
              testId='email'
              type='email'
              required={true}
              disabled={isUpdateUserView}
            />
          </Grid>
          <Grid item xs={12} sm={5} className='form-input'>
            <Input
              label={t('USERS.USER_INFO.PRODUCER_CODE')}
              autoComplete={true}
              defaultValue={userInfoForm.producer_code.value as string}
              error={userInfoForm.producer_code.error}
              onChange={(value: string): void => handleOnChange('producer_code', value)}
              testId='producer_code'
              type='producer_code'
              required={true}
            />
          </Grid>

          {rolesList}
          {buttonAddMember}
          <Grid item xs={12} sm={6} className='form-input'>
            <Typography>{t('USERS.USER_INFO.USER_STATE')}</Typography>

            <Switch
              checked={userInfoForm.active.value as boolean}
              onChange={(): void => handleOnChange('active', !userInfoForm.active.value)}
              required={true}
              color='primary'
            />
            {userInfoForm.active.value ? t('COMMONS.ACTIVE') : t('COMMONS.INACTIVE')}
          </Grid>

          {userInfoStore.userInfo.message &&
            userInfoStore.userInfo.message.map((error: string, index: number) => (
              <Grid key={index} item xs={12} sm={6} className='form-input'>
                <CustomAlert key={index} severity={AlertSeverities.ERROR} className='alert'>
                  {error}
                </CustomAlert>
              </Grid>
            ))}
        </Grid>
        <Grid className='add-user-button' direction='row' justify={'center'} container spacing={2}>
          <Grid item>
            <Button className='cancel' onClick={toggleCancelModal}>
              {t('COMMONS.BUTTONS.CANCEL')}
            </Button>
          </Grid>
          <Grid item>
            <Button className='add default' onClick={userInfo} disabled={userInfoButtonDisabled}>
              {isUpdateUserView ? t('COMMONS.BUTTONS.UPDATE') : t('COMMONS.BUTTONS.CREATE')}
            </Button>
          </Grid>
        </Grid>
        {renderModal()}
      </div>
    </Container>
  );
};

export const UserInfoComponent = styled(({ ...props }) => <CleanUserInfoComponent {...props} />)`
  .add-user-form {
    .add-user-button {
      margin-top: 40px;
      .cancel,
      .add {
        padding: 10px 50px;
        border-radius: 25px;
        text-transform: none;
        font-size: 16px;
      }
      .cancel {
        background-color: transparent;
        box-shadow: none;
        color: var(--color-grey-text);
        border: 1px solid transparent;
        &:hover {
          border: 1px solid var(--color-grey-border);
        }
      }
      .add {
        background-color: var(--color-main);
        color: var(--color-white);
      }
    }
    .roles-list {
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }
  .alert {
    margin-bottom: 20px;
  }
  .trash-icon {
    padding: 0;
  }
`;
