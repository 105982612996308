import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import { IContactInfoStore } from './../../../ContactInfo/models';
import { Loading } from '@wdynamo/common';
// eslint-disable-next-line no-unused-vars
import { IContactsStore } from '../../../../models';
import { StyledButton } from '@wdynamo/common/lib/components';
import imgAddCustomers from '../../../../../../assets/img/icons_dynamo/add_clients_import.svg';
import iconFail from '../../../../../../assets/img/icons_dynamo/icon_import_fail_result.svg';
import iconSuccess from '../../../../../../assets/img/icons_dynamo/icon_import_success_result.svg';
import iconSuccessErrors from '../../../../../../assets/img/icons_dynamo/icon_import_success_with_errors_result.svg';

interface IModalProps {
  className: string;
  contactsStore: IContactsStore;
  data: any;
  contactInfoFetch?: any;
  contactInfoStore: IContactInfoStore;
  handleCloseModal: any;
  getContactsFetch: any;
  contactInfoForm: any;
  hasResponsible: boolean;
}
interface IFileInfo {
  name: string;
  size: number;
  error: boolean;
}

const SheetJSAppComponent: React.FC<IModalProps> = (props: IModalProps) => {
  const {
    className,
    contactInfoFetch,
    contactInfoStore,
    handleCloseModal,
    getContactsFetch,
    contactInfoForm,
    hasResponsible
  } = props;
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [items, setItems] = useState<any>([]);
  const [filesInfo, setFilesInfo] = useState<any>([]);
  // eslint-disable-next-line no-unused-vars
  const [filesSize, setFilesSize] = useState<number>(0);
  const [fileData, setFileData] = useState<any>([]);
  const [err, setErr] = React.useState<boolean>(false);
  //const [fileQuantity, setFileQuantity] = useState<number>(0);
  // eslint-disable-next-line no-unused-vars
  const [errorsText, setErrorsText] = useState<any>([]);
  const [hasResponse, setHasResponse] = useState<boolean>(false);
  const [resultHasErrors, setResultHasErrors] = useState<boolean>(false);

  useEffect(() => {
    if (contactInfoStore?.message?.data?.data?.clientRepresentation) {
      contactInfoStore?.message?.data?.data?.clientRepresentation?.map((item: any) => {
        console.log(item.error);
        if (item.error != '') {
          setResultHasErrors(true);
          console.log('pasa');
        }
      });
    }
  }, [contactInfoStore?.message?.data?.data?.clientRepresentation]);

  const ButtonUpload = (
    <div className='container-files'>
      <label htmlFor='files' className='btn'>
        {t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_UPLOAD.BUTTON_UPLOAD')}
      </label>
      <input
        type='file'
        // multiple
        accept='.xlsx'
        id='files'
        className='files'
        onChange={(e: any) => {
          if (e.target.files[0]) {
            //   for (let i = 0; i <= e.target.files.length - 1 && i <= 4 && fileQuantity <= 4; i++) {
            //  readExcel(e.target.files[i]);
            readExcel(e.target.files[0]);
            // console.log('file:' + JSON.stringify(e.target.files[0].name));
            //  }
          }
        }}
      />
    </div>
  );

  const CardResult = (
    <>
      <div className='main-container'>
        <Grid className='card-body-container'>
          <Grid className='d-flex flex-column align-items-center justify-content-center'>
            <Grid className='d-flex flex-column align-items-center justify-content-center'>
              {console.log('contactInfoStoreIterable', contactInfoStore?.message?.data?.data?.clientRepresentation)}
              {console.log('contactInfoStoreStatus', contactInfoStore)}
              <img
                src={hasResponse ? (resultHasErrors ? iconFail : iconSuccessErrors) : iconSuccess}
                width='100%'
                style={{ marginBottom: '2rem' }}
              />
              <Grid className='card_title'>
                {
                  //contactInfoStore?.error &&
                  hasResponse
                    ? resultHasErrors
                      ? t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_FAIL.TITLE')
                      : t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_SUCCESS.TITLE')
                    : 'El documento fue importado de forma correcta.'
                }
              </Grid>
              <Grid className='card_text'>
                {
                  //contactInfoStore?.error &&
                  hasResponse
                    ? resultHasErrors
                      ? t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_FAIL.TEXT')
                      : 'Pero los siguientes registros fueron descartados porque contienen errores, si desea modificarlos deberá ingresarlos nuevamente.'
                    : ''
                }{' '}
              </Grid>
              <div style={{ overflowY: 'scroll', paddingTop: '1rem', paddingBottom: '1rem', height: '100px' }}>
                {contactInfoStore?.message?.data?.data?.clientRepresentation?.map((item: any) => {
                  if (item?.error !== '') {
                    return <>{item.error}</>;
                  }
                })}
              </div>
            </Grid>
          </Grid>
          <Grid className='d-flex flex-column align-items-end justify-content-end'>
            <Grid className='d-flex flex-column align-items-end justify-content-end mb-3'></Grid>
          </Grid>
        </Grid>
      </div>
      <Grid className='btn-container' style={{ backgroundColor: 'red !important', color: 'red !important' }}>
        {' '}
        <StyledButton
          className={
            contactInfoStore?.error && hasResponse ? 'second-button button-fail' : 'second-button button-success'
          }
          style={{ padding: '0', marginBottom: '0', backgroundColor: 'red !important', color: 'red !important' }}
          onClick={() => {
            acceptFail();
          }}
        >
          {t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_UPLOAD.BUTTON_FAIL_ACCEPT')}
        </StyledButton>
      </Grid>
    </>
  );

  const FilesSelectorCard = (
    <>
      <div className='main-container'>
        <Grid className='card-body-container'>
          <Grid className='d-flex flex-column align-items-center justify-content-center'>
            <Grid className='d-flex flex-column align-items-center justify-content-center'>
              <img src={imgAddCustomers} width='76%' style={{ marginBottom: '2rem' }} />
              <Grid className='card_title'>{t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_UPLOAD.TITLE')}</Grid>
              <Grid className='card_text'>{t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_UPLOAD.TEXT')} </Grid>
            </Grid>
          </Grid>
          <Grid className='d-flex flex-column align-items-end justify-content-end'>
            <Grid className='d-flex flex-column align-items-end justify-content-end mb-3'></Grid>
          </Grid>
        </Grid>
      </div>
      <Grid className='btn-container'>{ButtonUpload}</Grid>
    </>
  );
  const showHeader: any = filesInfo.filter((itemName: any) => itemName.error === false);
  const FilesListCard = (
    <Grid container className='contacts-grid' style={{ height: '543px' }}>
      <Grid item xs={12} className='header'>
        <Grid container>
          {showHeader[0] ? (
            <>
              <Grid item xs={10}>
                Archivo
              </Grid>
              <Grid item xs={2}>
                Tamaño
              </Grid>
            </>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} className='content'>
        <div style={{ height: '250px', overflow: 'auto' }}>
          {filesInfo.map((itemName: any, index: number) => (
            <>
              {!itemName.error ? (
                <tr
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'flex-start',
                    textAlign: 'start',
                    paddingTop: '0.5rem'
                  }}
                >
                  <Grid item xs={10} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    {/*itemName*/}
                    {itemName.name}
                  </Grid>
                  <Grid item xs={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    {itemName.size}
                  </Grid>
                </tr>
              ) : (
                ''
              )}
            </>
          ))}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '175px',
            overflow: 'auto',
            justifyContent: 'flex-start'
          }}
        >
          {err ? (
            <Typography style={{ textAlign: 'left', fontWeight: '600' }}>
              {t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_UPLOAD.FILES_WITH_ERROR')}
            </Typography>
          ) : (
            ''
          )}
          {filesInfo.map((itemName: any, index: number) => (
            <>
              {itemName.error ? (
                <tr
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'flex-start',
                    textAlign: 'start',
                    paddingTop: '0.5rem'
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', color: 'red', flexDirection: 'row', justifyContent: 'flex-start' }}
                  >
                    {/*itemName*/}
                    {itemName.name}
                  </Grid>
                  {
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        color: 'red',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        marginTop: '0.2'
                      }}
                    >
                      {errorsText.map((item: string, index: number) => (
                        <div key={index} style={{ fontSize: '0.8rem', marginTop: '0.2rem' }}>
                          {item}
                        </div>
                      ))}
                    </Grid>
                  }
                </tr>
              ) : (
                ''
              )}
            </>
          ))}
          {/*  <Grid item xs={12}>
        <div style={{ color: 'red' }}>{filesSize ? filesSize : ''}</div>
          </Grid> */}
        </div>
        {fileData[0] ? (
          <Grid className='btn-container' style={{ marginBottom: '0' }}>
            <StyledButton
              className='second-button'
              style={{ padding: '0', marginBottom: '0' }}
              onClick={() => {
                sendData();
              }}
            >
              {t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_UPLOAD.BUTTON_SEND')}
            </StyledButton>
          </Grid>
        ) : (
          <Grid className='btn-container' style={{ padding: '0', marginBottom: '1rem' }}>
            {ButtonUpload}
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  function mapData(datum: any) {
    const assigned = props?.data?.items?.items[0]?.assigned_user;
    const responsible =
      contactInfoForm.responsible.value !== ''
        ? contactInfoForm.responsible.value
        : hasResponsible
        ? props?.data?.items?.items[0]?.responsible
        : '';
    //  console.log('responsible: ', contactInfoform?.responsible?.value);
    datum.forEach(function (customer: any) {
      const dataObj = {
        mail: '' + customer.email + '',
        phone: '' + customer.telefono + '',
        doc_number: '' + customer.documento + '',
        lastContact: '',
        name: '' + customer.nombre + '',
        last_name: '' + customer.apellido + '',
        responsible: {
          id: responsible ? responsible?.id : '0',
          partner_id: responsible ? '' + responsible?.partner_id + '' : '',
          kaiser_username: responsible ? '' + responsible?.kaiser_username + '' : '',
          first_name: responsible ? '' + responsible?.first_name + '' : '',
          last_name: responsible ? '' + responsible?.last_name + '' : ''
        },
        assigned_user: {
          id: assigned.id,
          partner_id: '' + assigned.partner_id + '',
          kaiser_username: '' + assigned.kaiser_username + '',
          first_name: '' + assigned.first_name + '',
          last_name: '' + assigned.last_name + ''
        },
        // status: 'NoContactado',
        address: {
          street: '' + customer.direccion + '',
          // street_number: customer.direccionNumber,
          // floor: customer.direccionPiso,
          apartment: '' // customer.direccionDepto
        },
        date_of_birth: '' + customer.fechaNacimiento + '', //'28-05-2021',
        birth_place: '', // customer.birthPlace
        sex: '' + customer.sexo + '',
        marital_status: 1, //'' + customer.EstadoCivil + '',
        activity: '' + customer.actividad + '',
        /*   pep: {
          pep: '' + customer.actividad + '',
          position: '',
          agency: '',
          relationship: '',
          character: ''
        } */

        // bank_account: customer.nroCuentaBanco, //customer.nroCtaBanco
        doc_type: 96
      };
      console.log(dataObj);
      setFileData((fileData: any) => [...fileData, dataObj]);
    });
  }
  function isValid(item: any, fileSize: any) {
    let errors: boolean = false;
    const errText = [];
    const errorType: any = [];

    /*  if (!item[0]?.nombre) {
      errText.push('La columna Nombre es obligatoria');
      errors = true;
    }

    if (!item[0]?.apellido) {
      errText.push('La columna Apellido es obligatoria');
      //   console.log('El campo Apellido es obligatorio');
      errors = true;
    } */
    if (!item[0]?.telefono) {
      errText.push('La columna Telefono es obligatoria');
      //   console.log('El campo Apellido es obligatorio');
      errors = true;
    }
    if (!item[0]?.email) {
      errText.push('La columna Mail es obligatoria');
      //console.log('El campo Apellido es obligatorio');
      errors = true;
    }
    for (let i = 0; i <= item.length - 1; i++) {
      // console.log('item: ' + JSON.stringify(item[i].Nombre));

      /* if (!item[i]?.nombre && !errorType.includes(1)) {
        errText.push('El dato Nombre es obligatorio');
        errorType.push(1);
        errors = true;
      }
      if (!item[i]?.apellido && !errorType.includes(2)) {
        errText.push('El dato Apellido es obligatorio');
        errorType.push(2);
        errors = true; 
      } */
      if (!item[i]?.telefono && !errorType.includes(3)) {
        errText.push('El dato Telefono es obligatorio');
        errorType.push(3);
        errors = true;
      }
      if (!item[i]?.email && !errorType.includes(4)) {
        errText.push('El dato Mail es obligatorio');
        errorType.push(4);
        errors = true;
      }
    }
    const validSize = fileSize + filesSize;

    if (Number(validSize) > 5242880) {
      // 5242880
      // setErr(true);
      errors = true;
      errText.push('El archivo supera el tamaño máximo');
    }
    if (errors) {
      setErr(true);
      setErrorsText(errText);
    }

    return errors;
  }
  const sendData = () => {
    contactInfoFetch(fileData);
    setHasResponse(true);
  };
  /*  const acceptSuccess = () => {
    getContactsFetch();
    handleCloseModal();
    setInterval(() => setHasResponse(false), 3000);
  };*/
  const acceptFail = () => {
    getContactsFetch();
    handleCloseModal();
    setInterval(() => setHasResponse(false), 3000);
  };

  const readExcel = (file: any) => {
    // eslint-disable-next-line no-unused-vars
    let err: boolean;
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      //const valido = mimeTypeValid.includes(file.type);
      fileReader.onload = (e: any) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        //setFileQuantity((fileQuantity: number) => fileQuantity + 1);

        if (!isValid(data, file.size)) {
          err = false;
          mapData(data);
        } else {
          err = true;
        }
        setFilesInfo((filesInfo: any) => [...filesInfo, { name: file.name, size: file.size, error: err }]);
        setFilesSize((filesSize: number) => filesSize + file.size);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d: any) => {
      setItems(d);
    });
    console.log('fileData: ', fileData);
    if (fileData[0]) contactInfoFetch(fileData);
  };

  return (
    <div className={className}>
      {contactInfoStore?.loading ? (
        <Grid
          container
          className='contacts-grid'
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '543px' }}
        >
          <Loading />
        </Grid>
      ) : hasResponse ? ( //!contactInfoStore?.error && hasResponse ? (
        <>
          {CardResult}
          {/* {SuccessCard}</>
      ) : contactInfoStore?.error && hasResponse ? (
      <> {FailCard} */}
        </>
      ) : filesInfo[0] ? (
        <>{FilesListCard}</>
      ) : (
        <>{FilesSelectorCard}</>
      )}
    </div>
  );
};

export const SheetJSApp = styled(({ ...props }) => <SheetJSAppComponent {...props} />)`
  .container-files {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--color-main);
    padding: 10px;
    border-radius: 6px;
    color: #fff;

    margin-top: 20px;
    &:hover {
      opacity: 0.5;
    }
  }
  .files {
    opacity: 0;
    margin-top: -35px;
    height: 39px;
    width: 100%;
    cursor: pointer;
  }
  .content {
    margin-top: 1rem;
  }
  .contacts-grid {
    display: flex;
    /*align: flex-start; */
    justify-content: center;
    height: 300px;
    align-content: flex-start;
  }
  .header {
    height: 25px;
  }
  .main-container {
    display: flex;
    height: 442px;
    align-items: center;
    /* height: 100vh; */
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
  }
  .card_title {
    text-align: center;
    font-size: 1.75rem;
    font-weight: 500;
    margin-bottom: 0.3rem;
  }
  .card_text {
    font-size: 1.125rem;
    font-weight: 300;
  }
  .card-body-container {
    display: flex;
    align-items: center;
  }
  .image-container {
    visibility: hidden;
  }
  .btn-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    padding: 2rem 0 0.5rem 0;
  }
  .button-fail {
    background-color: #ec4b3f;
  }
  .button-success {
    background-color: #3fa957;
  }
`;
