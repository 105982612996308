import axios from 'axios';

export interface ISetOfUsersParams {
  params: {
    parent?: string;
    users?: string[];
  };
}

const Api = () => {
  const getGroupsFetch = (params: ISetOfUsersParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_KAISER_V3_BASE_URL!}/groups`, params);

  const deleteGroupFetch = (id: number): Promise<unknown> =>
    axios.delete(`${process.env.REACT_APP_KAISER_V3_BASE_URL!}/groups/${id}`);

  const getParentsFetch = (params: ISetOfUsersParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_KAISER_V3_BASE_URL!}/groups`, params);

  return { getGroupsFetch, deleteGroupFetch, getParentsFetch };
};

export default Api;
