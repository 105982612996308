import { ACTIONS } from '../../../../ActionTypes';

// Get roles
export const getRolesFetch = () => ({ type: ACTIONS.USERS.ROLES.FETCH });

export const getRolesSuccess = (roles: any) => ({
  type: ACTIONS.USERS.ROLES.SUCCESS,
  payload: { data: roles }
});

export const getRolesFail = (error: object) => ({
  type: ACTIONS.USERS.ROLES.FAIL,
  payload: { data: error }
});

export const deleteRoleFail = (error: object) => ({
  type: ACTIONS.USERS.ROLES.DELETE_FAIL,
  payload: { data: error }
});

export const deleteRoleSuccess = () => ({
  type: ACTIONS.USERS.ROLES.DELETE_SUCCESS
});

export const deleteRoleFetch = (id: string) => ({
  type: ACTIONS.USERS.ROLES.DELETE,
  payload: { data: id }
});
