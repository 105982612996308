import React from 'react';
import { Modal, Backdrop, Fade, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { StyledButton } from '@wdynamo/common/lib/components';
import { FormGroup, FormControl } from '@material-ui/core';
import { ListUnitMembers } from '../ListMembersSelect';

interface IModalProps {
  className: string;
  icon?: React.ReactNode;
  iconColor?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  twoButtons?: boolean;
  buttonColor?: string;
  secondButtonColor?: string;
  buttonText?: string;
  secondButtonText?: string;
  onClickButton(): void;
  onClickSecondButton(): void;
  open: boolean;
  handleSubmit?: any;
  handleOrderListChange?: void;
  orderFields?: any;
  cleanOrderListSubmit?: void;
  handleOrderListOnChange: void;
}

const CleanModalComponent: React.FC<IModalProps> = (props: IModalProps) => {
  const {
    className,
    title,
    buttonText,
    secondButtonText,
    open,
    handleOrderListOnChange,
    handleSubmit,
    orderFields,
    cleanOrderListSubmit
  } = props;

  return (
    <Modal className={className} open={open} closeAfterTransition BackdropComponent={Backdrop}>
      <Fade in={open}>
        <Grid className='modal-content' container direction='column' alignItems='center'>
          <Grid item className='title-container'>
            {title && (
              <Typography variant='h6' className='title'>
                {title}
              </Typography>
            )}
          </Grid>

          <form
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
            // className=
            style={{ display: 'flex', position: 'relative', width: '100%', padding: '0' }}
          >
            <div className='main-container'>
              <FormControl component='fieldset' style={{ width: '100%' }}>
                <FormGroup>
                  {/* {orderFields.map((item: any, i: number) => {
                    return (
                      <ListUnitMembers //  ListMembers component creates the toggle switches
                        key={i} // the ID React uess to track unique components and state changes
                        item={item} // an unique index from attendees array, containing a member object
                        itemIdx={i} // the array index number, needed for handleChange
                        handleOrderListChange={handleOrderListChange}
                      />
                    );
                  })} */}

                  <>
                    {orderFields.map((item: any, i: number) => {
                      return <ListUnitMembers key={i} item={item} handleOrderListOnChange={handleOrderListOnChange} />;
                    })}
                  </>
                </FormGroup>
                <Grid className='button-container'>
                  <StyledButton className='first-button' onClick={cleanOrderListSubmit}>
                    {buttonText}
                  </StyledButton>
                  <StyledButton className='second-button' onClick={handleSubmit}>
                    {secondButtonText}
                  </StyledButton>
                </Grid>
              </FormControl>
            </div>
          </form>
        </Grid>
      </Fade>
    </Modal>
  );
};

export const ModalComponent = styled(({ ...props }) => <CleanModalComponent {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  .main-container {
    width: 100%;
    padding: 0.3rem 1rem 1rem 1rem;
  }
  .title-container {
    justify-content: left;
    width: 100%;
    padding: 1rem;
  }
  .title {
    font-family: Rubik;
    width: 100%;
    text-align: left;
    font-size: 1.125rem;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0 0.5rem 0;
  }
  .modal-content {
    width: 400px;
    background-color: var(--color-white);
    border: none;
    border-radius: 5px;
    text-align: center;
    &:focus {
      outline: none;
    }
    & > * {
      padding: 0.7em;
    }
    .first-button {
      background-color: #ffffff;
      border: 1px solid grey;
      color: #000000;
      font-size: 0.8rem;
    }
    .second-button {
      background-color: ${(props) => props.secondButtonColor};
      margin-left: 1.5rem;
      font-size: 0.8rem;
    }
    svg > path {
      fill: ${(props) => props.iconColor};
    }
  }
`;
