import { call, put, takeLatest, all } from 'redux-saga/effects';

import Api from './Api';
import ApiMock from './ApiMock';

import {
  getSettingsSuccess,
  getSettingsFail,
  uploadImageSuccess,
  uploadImageFail,
  updatePartnerSuccess,
  updatePartnerFail
} from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? new ApiMock() : new Api();

function* sagasGetSettingsFetch(action: actionType) {
  try {
    const response = yield call(api.getSettingsFetch, action.payload.partnerId);
    yield put(getSettingsSuccess(response.data.data));
  } catch (e) {
    yield put(getSettingsFail(e));
  }
}

function* sagasUploadImageFetch(action: actionType) {
  try {
    const response = yield call(api.uploadImageFetch, action.payload.imageData, action.payload.partnerId);
    yield put(uploadImageSuccess(response.data.data));
  } catch (e) {
    yield put(uploadImageFail(e.response.data));
  }
}

function* sagasUpdatePartnerFetch(action: actionType) {
  try {
    const response = yield call(api.updatePartnerFetch, action.payload.partnerData, action.payload.partnerId);
    yield put(updatePartnerSuccess(response.data.data));
  } catch (e) {
    yield put(updatePartnerFail(e));
  }
}

// Listen for redux actions
function* settingsSaga() {
  yield all([takeLatest(ACTIONS.SETTINGS.FETCH, sagasGetSettingsFetch)]);
  yield all([takeLatest(ACTIONS.SETTINGS.IMAGE.FETCH, sagasUploadImageFetch)]);
  yield all([takeLatest(ACTIONS.SETTINGS.UPDATE.FETCH, sagasUpdatePartnerFetch)]);
}

export { settingsSaga };
