import { ACTIONS } from '../../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { UserInfoForm } from './models';

// User Info
export const userInfoFetch = (userInfoForm: UserInfoForm, userId?: string) => ({
  type: ACTIONS.USERS.USER_INFO.FETCH,
  payload: { data: userInfoForm, userId: userId }
});

export const userInfoSuccess = () => ({ type: ACTIONS.USERS.USER_INFO.SUCCESS });

export const userInfoFail = (error: object) => ({ type: ACTIONS.USERS.USER_INFO.FAIL, payload: { error } });
