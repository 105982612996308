import { call, put, takeLatest, all } from 'redux-saga/effects';
import Api from './Api';
// import ApiMock from './ApiMock';
import { getContactsSuccess, getContactsFail } from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../../../ActionTypes';

interface IUsersData {
  data?: any;
}

const api = Api();

function* sagasGetContactsFetch(action: actionType) {
  try {
    const response: IUsersData = yield call(api.getContactsFetch, action.payload.params);
    const { data } = response;
    yield put(getContactsSuccess(data));
  } catch (e) {
    yield put(getContactsFail(e));
  }
}

function* homeQuotationGeneralDataSaga() {
  yield all([takeLatest(ACTIONS.HOME_QUOTATIONS.CONTACTS.FETCH, sagasGetContactsFetch)]);
}

export { homeQuotationGeneralDataSaga };
