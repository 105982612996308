/* eslint-disable no-unused-vars */
import { ACTIONS, actionType } from '../ActionTypes';
import { IInspectionStore } from './models';
/* eslint-enable no-unused-vars */

const initialState: IInspectionStore = {
  getForm: {
    loading: false,
    error: false,
    data: []
  },
  sendForm: {
    loading: false,
    error: false,
    data: []
  }
};

export const inspectionReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.INSPECTION.GET_FORM.FETCH:
      return {
        ...state,
        getForm: {
          ...state.getForm,
          loading: true,
          error: false,
          data: {}
        }
      };
    case ACTIONS.INSPECTION.GET_FORM.SUCCESS:
      return {
        ...state,
        getForm: {
          ...state.getForm,
          loading: false,
          error: false,
          data: action.payload.data
        }
      };
    case ACTIONS.INSPECTION.GET_FORM.FAIL:
      return {
        ...state,
        getForm: { ...state.getForm, loading: false, error: true, data: action.payload.data }
      };
    case ACTIONS.INSPECTION.SEND_FORM.FETCH:
      return {
        ...state,
        sendForm: {
          ...state.sendForm,
          loading: true,
          error: false,
          data: {}
        }
      };
    case ACTIONS.INSPECTION.SEND_FORM.SUCCESS:
      return {
        ...state,
        sendForm: {
          ...state.sendForm,
          loading: false,
          error: false,
          data: action.payload.data
        }
      };
    case ACTIONS.INSPECTION.SEND_FORM.FAIL:
      return {
        ...state,
        sendForm: { ...state.sendForm, loading: false, error: true, data: action.payload.data }
      };
    default:
      return state;
  }
};
