// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IContactsStore } from './models';

const initialState: IContactsStore = {
  loading: false,
  error: false,
  data: {}
};

export const contactsReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.CONTACTS.FETCH:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ACTIONS.CONTACTS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case ACTIONS.CONTACTS.FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
