import { connect } from 'react-redux';
import { UsersComponent } from './view';
import { getUsersFetch } from './actions';
import { getParentsFetch } from './components/Groups/actions';
import { getRolesFetch } from './components/Roles/RolesList/actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../store';
export type { IBaseStore } from './models';

const mapStateToProps = (state: RootState) => ({
  usersStore: state.usersState,
  groupsStore: state.groupsState,
  rolesStore: state.rolesState
});
const mapDispatchToProps = {
  getUsersFetch,
  getParentsFetch,
  getRolesFetch
};

export const Users = connect(mapStateToProps, mapDispatchToProps)(UsersComponent);

export { usersSaga } from './sagas';
export { usersReducer } from './reducers';
export type { IUsersProps } from './models';
