import axios from 'axios';
// eslint-disable-next-line no-unused-vars
//import { IContact } from './models';

const Api = () => {
  /*  const getExcelExampleFetch = (): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers/excel`);
  return { getExcelExampleFetch };
};*/
  const getDownloadExcelExample = (): Promise<unknown> =>
    axios({
      url: `${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers/excel`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Accept: '*/*'
      }
    })
      .then((response: any) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ejemplo cartera clientes.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  return { getDownloadExcelExample };
};

export default Api;
