import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IMaskInput } from 'react-imask';
import { Typography, MenuItem, Box, Switch, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
// eslint-disable-next-line no-unused-vars
import Grid, { GridSize } from '@material-ui/core/Grid';

// eslint-disable-next-line no-unused-vars
import { IAccesoryParams, ICodeDescription, ICustomDataForm, ISourceParams } from '../models';
import { NavButtons } from '../../common/NavButtons';
import { AccesoriesModal } from './components/AccessoriesModal';
import { IconLabel } from '../../../../../../components';
import { AccessoriesTable } from '../AccessoriesTable';

interface ICustomInfoProp {
  className?: string;
  dataForm: ICustomDataForm;
  onChange(data: ICustomDataForm): void;
  createPersonFetch(data: any): void;
  listSources: any;
  dataSources: any;
  data: any;
  getListSourceFetch(listParams: ISourceParams): void;
  getDataSourceFetch(dataParams: ISourceParams): void;
  getAccessoryFetch(params: IAccesoryParams): void;
  setItemsToStore(data: any): void;
  stepsStore: any;
  productInfo: any;
}

interface IDataStepParams {
  [param: string]: string;
}
interface IDataStepItem {
  code: string | number;
  description: string;
}
interface IDataStepInput {
  name: string;
  type: string;
  label: string;
  default_value: string | boolean;
  source?: string;
  is_required: boolean;
  params?: IDataStepParams;
  dynamo_item?: IDataStepItem[];
  text_true?: string;
  text_false?: string;
  load_after_action?: string;
  load_dependency?: string;
  params_fields?: string[];
  custom_params?: string[];
  overwrite_field?: string;
  clean_dependency?: string;
  overwrite_value?: string;
  min_max_percentage?: string;
}

interface IDataStepGroup {
  name: string;
  inputs: IDataStepInput[];
}

let minValue: number | null;
let maxValue: number | null;

const currencyFormat = forwardRef<HTMLElement, any>(function currencyFormat(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={Number}
      thousandsSeparator='.'
      scale={0}
      inputRef={ref}
      // eslint-disable-next-line react/prop-types
      onAccept={(value: any) => onChange({ target: { value } })}
      unmask
    />
  );
});

const CleanCustomInfoComponent: React.FC<ICustomInfoProp> = (props: ICustomInfoProp) => {
  const {
    data,
    dataForm,
    onChange,
    getListSourceFetch,
    listSources,
    dataSources,
    setItemsToStore,
    getDataSourceFetch,
    getAccessoryFetch,
    stepsStore,
    productInfo
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [listsSource, setListsSource] = useState<any>({});
  const [showForm, setShowForm] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);

  const launchTrigger = (field: string, group: string) => {
    if (trigger[field]) {
      const custom_params: { [field: string]: string } = {};
      const currentGroup = dataForm.custom_step[group];

      if (trigger[field].custom_params) {
        trigger[field].custom_params.forEach((name_field: string) => {
          custom_params[name_field] = currentGroup[name_field].value && currentGroup[name_field].value.code;
        });
      }
      //if is found a trigger assigned to this field, get data using its params.
      trigger[field].type === 'list' &&
        getListSourceFetch({
          url: trigger[field].url,
          field: trigger[field].fieldOwner,
          params: { ...trigger[field].params, ...custom_params }
        });
      //buscar en trigger si tiene algo particular que identifique que sea
      if (trigger[field].type === 'string') {
        getDataSourceFetch({
          url: trigger[field].url,
          field: trigger[field].fieldOwner,
          params: { ...trigger[field].params, ...custom_params }
        });
      }
    }
  };

  useEffect(() => {
    Object.keys(dataSources.data).forEach((field) => {
      handleOnChange(dataSources.data[field], 'grupo1', field);
    });
    minValue = null;
    maxValue = null;
  }, [dataSources.data]);

  const handleOnChange = (value: any, group: string, fieldName: string) => {
    const prevState = { ...dataForm };
    if (prevState?.custom_step && prevState?.custom_step[group]) {
      const currentGroup = prevState.custom_step[group];
      const currentField = currentGroup[fieldName];
      currentField.value = value;
      onChange(prevState);
    }
  };

  const handleCleaningDependencyFields = (currentGroup: any, clean_dependency: any) => {
    const dependencyField = currentGroup[clean_dependency];
    dependencyField.value = { code: '', description: '' };
  };

  const handleOverWriteField = (currentGroup: any, field: any) => {
    const currentField = currentGroup[field.overwrite_field];
    currentField.value = { code: field.overwrite_value, description: field.overwrite_value };
  };

  const handleMinMaxValue = (percentageStr: string) => {
    if (dataSources.data.value) {
      const serviceValue = Number(dataSources.data.value.description);
      const percentage = Number(percentageStr);
      const diff = (serviceValue * percentage) / 100;
      minValue = Math.ceil(serviceValue - diff);
      maxValue = Math.ceil(serviceValue + diff);
    }
  };

  const handleOnChangeCustomField = (value: any, group: string, field: IDataStepInput) => {
    const { name, overwrite_field, clean_dependency, min_max_percentage } = field;
    const prevState = { ...dataForm };
    const currentGroup = prevState.custom_step[group];
    if (clean_dependency) handleCleaningDependencyFields(currentGroup, clean_dependency);
    if (overwrite_field) handleOverWriteField(currentGroup, field);
    if (min_max_percentage) handleMinMaxValue(min_max_percentage);
    const currentField = currentGroup[name];
    currentField.value = value;
    onChange(prevState);
    launchTrigger(name, group);
  };

  const handleOnBlur = (value: any, group: string, field: IDataStepInput) => {
    if (Number(value) < minValue!) {
      handleOnChangeCustomField({ description: String(minValue) }, group, field);
    } else if (Number(value) > maxValue!) {
      handleOnChangeCustomField({ description: String(maxValue) }, group, field);
    }
  };

  useEffect(() => {
    setShowForm(true);
    let state: any = {};
    const objTrigger: any = {};
    //maps inputter to load the trigger state
    data?.input_groups &&
      data.input_groups.map((group: IDataStepGroup) => {
        group.inputs.map((input: IDataStepInput) => {
          if (input.type === 'LIST' || input.type === 'AUTOCOMPLETE') {
            if (input.load_dependency && input.load_after_action === 'onchange') {
              objTrigger[input.load_dependency] = {
                url: input.source,
                fieldOwner: input.name,
                params: input.params,
                custom_params: input.custom_params,
                type: 'list'
              };
            }
            //it's has items, set into store
            if (input.dynamo_item) setItemsToStore({ [input.name]: input.dynamo_item });
            if (!input.load_dependency && input.source) {
              getListSourceFetch({ url: input.source, field: input.name, params: input.params });
            }
            state = {
              ...state,
              [group.name]: { ...state[group.name], [input.name]: { url: input.source } }
            };
          } else if (input.load_dependency && input.load_after_action === 'onchange') {
            objTrigger[input.load_dependency] = {
              url: input.source,
              fieldOwner: input.name,
              params: input.params,
              custom_params: input.custom_params,
              type: 'string'
            };
          }
        });
      });
    setTrigger(objTrigger);
    setListsSource(state);
  }, [data.input_groups]);

  const itemWidth = (span: number = 1): GridSize => {
    const defaultSize = 3;
    return (defaultSize * span) as GridSize;
  };

  const renderMenuItems = (list: any[]) => {
    let items = [
      <MenuItem key={0} value={0}>
        {''}
      </MenuItem>
    ];
    if (list)
      items = list.map((item: any) => (
        <MenuItem key={item.code} value={item.code}>
          {item.description}
        </MenuItem>
      ));
    return items;
  };

  const fieldComponent = (groupName: string, field: any, fieldState: any) => {
    const { name, type, disabled_dependency, disabled_trigger_value } = field;
    const groupState = dataForm.custom_step[groupName];
    const parsedDisabled_trigger_value = disabled_trigger_value && disabled_trigger_value.toString();
    const disabled_by_dependency =
      disabled_dependency !== undefined &&
      groupState[disabled_dependency].value?.description !== undefined &&
      groupState[disabled_dependency].value?.description === parsedDisabled_trigger_value;

    const dataList = listSources && listSources?.data[name];

    switch (type) {
      case 'LIST':
        return (
          <TextField
            value={fieldState.value.code as string}
            error={fieldState.error}
            onChange={(event): void => handleOnChangeCustomField(event.target, groupName, field)}
            select={type === 'LIST'}
            disabled={type === 'LIST' && (disabled_by_dependency || !dataList)}
            fullWidth
            required={field.is_required}
            placeholder={field.placeholder ? field.placeholder : ''}
          >
            {renderMenuItems(dataList)}
          </TextField>
        );
      case 'TEXT':
        return (
          <TextField
            value={fieldState.value.description as string}
            error={fieldState.error}
            onChange={(event): void => handleOnChangeCustomField({ description: event.target.value }, groupName, field)}
            disabled={disabled_by_dependency || !dataList}
            fullWidth
            required={field.is_required}
            placeholder={field.placeholder ? field.placeholder : ''}
          ></TextField>
        );
      case 'SWITCH':
        return (
          <Grid container alignItems='center'>
            <Switch
              checked={fieldState.value?.description === 'true'}
              onChange={(): void =>
                handleOnChangeCustomField(
                  fieldState.value.description === 'true'
                    ? { code: 'false', description: 'false' }
                    : { code: 'true', description: 'true' },
                  groupName,
                  field
                )
              }
              color='primary'
            />
            <Typography>{fieldState.value?.description === 'true' ? field.text_true : field.text_false}</Typography>
          </Grid>
        );
      case 'AUTOCOMPLETE':
        return (
          <Autocomplete
            disabled={disabled_by_dependency || !dataList}
            value={fieldState.value}
            autoComplete={true}
            autoHighlight={true}
            options={dataList ? dataList : []}
            noOptionsText={'Sin coincidencias'}
            getOptionLabel={(option: any) => option.description || ''}
            renderInput={(params) => <TextField {...params} variant='standard' />}
            onChange={(e, value) => handleOnChangeCustomField(value, groupName, field)}
          ></Autocomplete>
        );
      case 'NUMBER':
        return (
          <TextField
            value={String(Number(fieldState.value?.description) || '')}
            error={fieldState.error}
            InputProps={field.mask && { inputComponent: currencyFormat as any }}
            onChange={(event): void => handleOnChangeCustomField({ description: event.target.value }, groupName, field)}
            onBlur={field.mask ? () => handleOnBlur(fieldState.value?.description, groupName, field) : undefined}
            disabled={disabled_by_dependency}
            fullWidth
            required={field.is_required}
            placeholder={field.placeholder ? field.placeholder : ''}
            helperText={field.field_message}
          ></TextField>
        );
      case 'BUTTON':
        return (
          <IconLabel
            label='Agregar accesorios'
            onClick={handleOnClickAccessoriesButton}
            disabled={!dataForm?.custom_step?.grupo1?.year?.value?.code}
          />
        );
    }
  };

  const handleOnClickAccessoriesButton = () => {
    if (Array.isArray(stepsStore.accesory.data) && !stepsStore.accesory.data.length)
      getAccessoryFetch({
        branch: productInfo.branch,
        currency: '',
        year: dataForm.custom_step.grupo1.year.value.code,
        Okm: dataForm.custom_step.grupo1.Okm.value.code,
        validSince: '',
        chapterCode: 1,
        rcCode: 1,
        airCode: 1
      });
    let accessoriesData = dataForm.custom_step.grupo1.accesories.value.description;
    const prevDataForm = { ...dataForm };
    if (accessoriesData.length) accessoriesData = JSON.parse(accessoriesData);
    else accessoriesData = [{ code: '', description: '' }];
    prevDataForm.custom_step.grupo1.accesories.value.description = accessoriesData;
    onChange(prevDataForm);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const dynamic_form = () => {
    return data?.input_groups?.map((group: any, i: number) => {
      const groupState = dataForm?.custom_step[group.name];
      return (
        <Grid container spacing={2} key={i}>
          {group.inputs.map((field: any, j: number) => {
            const fieldState = groupState[field.name];
            const size: GridSize = itemWidth(field.span);
            return (
              <Grid item md={size} xs={12} className='py' key={j}>
                <Typography>
                  {field.label}
                  <span className='colored-mark'>{field.is_required && '*'}</span>
                </Typography>
                {fieldComponent(group.name, field, fieldState)}
              </Grid>
            );
          })}
        </Grid>
      );
    });
  };

  return (
    <Box className={props.className}>
      {showForm && dynamic_form()}
      {dataForm?.custom_step?.grupo1?.accesories && (
        <>
          <AccesoriesModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            dataForm={dataForm}
            setDataForm={onChange}
            loading={stepsStore.accesory.loading}
            accessoriesList={stepsStore.accesory.data}
            handleCloseModal={handleCloseModal}
          />
          <AccessoriesTable
            accessoriesListData={stepsStore.accesory.data}
            accessoriesValue={dataForm.custom_step?.grupo1?.accesories?.value?.description}
            dataForm={dataForm}
            setDataForm={onChange}
            showInSummary={false}
          />
        </>
      )}
      <NavButtons {...props} />
    </Box>
  );
};
export const CustomForm = styled(({ ...props }) => <CleanCustomInfoComponent {...props} />)`
  .py {
    padding-top: 2em;
  }
  .MuiMenu-paper {
    max-height: 2em;
  }
  .table {
    width: 50%;
    border-spacing: 0.8rem;
    .row {
      margin-top: 0.5rem;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
