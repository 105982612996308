import axios from 'axios';

const Api = () => {
  const baseURL = `${process.env.REACT_APP_KAISER_V3_BASE_URL}/role`;
  const getRolesFetch: any = (): Promise<unknown> => axios.get(`${baseURL}`);
  const deleteRole = (id: string): Promise<unknown> => axios.delete(`${baseURL}/${id}`);

  return { getRolesFetch, deleteRole };
};

export default Api;
