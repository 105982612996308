import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { IconButton } from '@wdynamo/common';

import { ReactComponent as AddIcon } from '../../assets/img/icons_dynamo/icon_add.svg';

interface IIconLabelProps {
  className: string;
  icon?: React.ReactNode;
  onClick(): void;
  label: string;
  disabled: boolean;
}

const CleanIconLabel: React.FC<IIconLabelProps> = (props: IIconLabelProps) => {
  const { className, icon = AddIcon, onClick, label, disabled } = props;

  return (
    <Box className={className}>
      <IconButton Icon={icon} onClick={onClick} disabled={disabled} />
      <Typography className='icon-label' display='inline' onClick={disabled ? undefined : onClick}>
        {label}
      </Typography>
    </Box>
  );
};

export const IconLabel = styled(({ ...props }) => <CleanIconLabel {...props} />)`
  path {
    fill: var(--color-main);
  }
  .icon-label {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;
