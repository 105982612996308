import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IconLabel } from '../IconLabel';
interface IFileInputProps {
  className: string;
  file: { file_name: string; string_base_64: string };
  onChange(value: object): void;
  onError(error: boolean, value: string): void;
  imageHeight?: string;
  acceptFormats: string;
  fileSize: number;
}

const CleanFileInputComponent: React.FC<IFileInputProps> = (props: IFileInputProps) => {
  const {
    className,
    file,
    onChange,
    onError,
    imageHeight = '120px',
    acceptFormats = '.png,.jpg',
    fileSize = 524288
  } = props;
  const fileInput = React.useRef<HTMLInputElement>(null);
  const [fileTypeIspdf, setFileTypeIsPdf] = React.useState(false);
  const { t } = useTranslation();

  const handleOnChange = (e: any) => {
    try {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      if (file.size < fileSize)
        reader.onload = (e) => {
          const result = String(e.target?.result);
          file.type === 'application/pdf' ? setFileTypeIsPdf(true) : setFileTypeIsPdf(false);
          onChange({ file_name: file.name, string_base_64: result });
        };
      else onError(true, t('COMMONS.MODALS.FILE_SIZE'));
    } catch (e) {
      console.log(`Error: ${e}`);
    }
  };

  return (
    <Grid container className={className} alignItems='center'>
      <Grid item xs={12}>
        <IconLabel label={t('COMMONS.BUTTONS.ADD_FILE')} onClick={() => fileInput.current?.click()} />
        <input
          type='file'
          accept={acceptFormats}
          ref={fileInput}
          style={{ display: 'none' }}
          onChange={handleOnChange}
        />
      </Grid>
      {fileTypeIspdf ? (
        <Typography>{file.file_name}</Typography>
      ) : (
        <img src={file.string_base_64} height={imageHeight} />
      )}
    </Grid>
  );
};

export const FileInputComponent = styled(({ ...props }) => <CleanFileInputComponent {...props} />)`
  display: inline;
`;
