import { connect } from 'react-redux';
import { exampleFetch } from './actions';
import { ExampleComponent } from './view';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  exampleStore: state.exampleState
});
const mapDispatchToProps = { exampleFetch };
export const Example = connect(mapStateToProps, mapDispatchToProps)(ExampleComponent);

export { Home } from './view';
export { exampleSaga } from './sagas';
export { exampleReducer } from './reducers';
