import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '../../../../../../../assets/img/icons_dynamo/arrow.svg';

interface IStyledAccordion {
  className?: string;
  title: string;
  children?: React.ReactNode;
  expanded?: boolean;
}

const CleanStyledAccordion: React.FC<IStyledAccordion> = (props: IStyledAccordion) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChangeAccordion = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={props.className}>
      <Accordion
        expanded={props.expanded || expanded === 'panel1'}
        onChange={handleChangeAccordion('panel1')}
        square={false}
      >
        <AccordionSummary expandIcon={<ArrowIcon />}>
          <Typography>{props.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Accordion>
    </div>
  );
};
export const StyledAccordion = styled(({ ...props }) => <CleanStyledAccordion {...props} />)`
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordion-root:before {
    opacity: 0;
  }
  .MuiAccordionSummary-root {
    border-radius: 15px;
    background-color: var(--color-grey-background);
    color: black;
    rect {
      fill: var(--color-main);
    }
    path {
      fill: var(--color-white);
    }
    &.Mui-expanded {
      color: white;
      background-color: var(--color-main);
      path {
        fill: var(--color-main);
      }
      rect {
        fill: var(--color-white);
      }
    }
  }
`;
