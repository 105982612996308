// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IBaseStore } from './models';

const initialState: IBaseStore = {
  groupInfo: {
    loading: false,
    error: false,
    message: undefined
  },
  groupMembers: {
    loading: false,
    error: false,
    members: undefined
  },
  deleteMember: {
    loading: false,
    error: false,
    message: undefined
  }
};

export const groupInfoReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.GROUPS.GROUP_INFO.FETCH:
      return {
        ...state,
        groupInfo: { loading: true, error: false, message: undefined }
      };
    case ACTIONS.GROUPS.GROUP_INFO.SUCCESS:
      return {
        ...state,
        groupInfo: { loading: false, error: false, message: action.payload?.data }
      };
    case ACTIONS.GROUPS.GROUP_INFO.FAIL:
      return {
        ...state,
        groupInfo: { loading: false, error: true, message: action.payload.error.data.errors }
      };
    case ACTIONS.GROUPS.GROUP_MEMBERS.FETCH:
      return {
        ...state,
        groupMembers: {
          loading: true,
          error: false,
          members: undefined
        }
      };
    case ACTIONS.GROUPS.GROUP_MEMBERS.SUCCESS:
      return {
        ...state,
        groupMembers: {
          loading: false,
          members: action.payload.data,
          error: false
        },
        deleteMember: {
          ...state.deleteMember,
          error: false
        }
      };
    case ACTIONS.GROUPS.GROUP_MEMBERS.FAIL:
      return {
        ...state,
        groupMembers: {
          loading: false,
          error: true,
          members: undefined
        }
      };
    case ACTIONS.GROUPS.GROUP_MEMBERS.REMOVE.FETCH:
      return {
        ...state,
        deleteMember: {
          loading: true,
          error: false,
          message: undefined
        }
      };
    case ACTIONS.GROUPS.GROUP_MEMBERS.REMOVE.SUCCESS:
      return {
        ...state,
        deleteMember: {
          loading: false,
          message: action.payload.data,
          error: false
        }
      };
    case ACTIONS.GROUPS.GROUP_MEMBERS.REMOVE.FAIL:
      return {
        ...state,
        deleteMember: {
          loading: false,
          error: true,
          message: action.payload.data
        }
      };
    default:
      return state;
  }
};
