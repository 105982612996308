import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomTooltip } from '@wdynamo/common';

import iconEntered from '../../assets/img/icons_dynamo/icon_entered.svg';
import iconCheck from '../../assets/img/icons_dynamo/icon_check.svg';
import iconTime from '../../assets/img/icons_dynamo/icon_time.svg';
import iconTrash from '../../assets/img/icons_dynamo/icon_trash.svg';
import iconExpired from '../../assets/img/icons_dynamo/icon_expired.svg';

interface IOrderStatusProps {
  status: string;
  payment?: boolean;
}

export const OrderStatus: React.FC<IOrderStatusProps> = (props: IOrderStatusProps) => {
  const { t } = useTranslation();
  const { status, payment } = props;

  const getStatusIcon = (status: string): React.ReactElement<HTMLImageElement> => {
    switch (status) {
      case 'DRAFT':
      case 'ENTERED':
        return <img src={iconEntered} alt='' />;
      case 'APPROVED':
        return <img src={iconCheck} alt='' />;
      case 'PENDING':
        return <img src={iconTime} alt='' />;
      case 'EXPIRED':
        return <img src={iconExpired} alt='' />;
      case 'DECLINED':
        return <img src={iconTrash} alt='' />;
      default:
        return <img src={iconTime} alt='' />;
    }
  };

  const getOrderStatus = (status: string): string => {
    if (status === undefined) return t('COMMONS.TOOLTIPS.ORDER_STATUS.PENDING');
    return t(`COMMONS.TOOLTIPS.ORDER_STATUS.${status}`);
  };

  const getPaymentStatus = (status: string): string => {
    if (status === undefined) return t('COMMONS.TOOLTIPS.PAYMENT_STATUS.PENDING');
    return t(`COMMONS.TOOLTIPS.PAYMENT_STATUS.${status}`);
  };

  return (
    <CustomTooltip title={payment ? getPaymentStatus(status) : getOrderStatus(status)} arrow placement='bottom'>
      {getStatusIcon(status)}
    </CustomTooltip>
  );
};
