import axios from 'axios';

const Api = () => {
  const getPermissionsFetch = (): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_KAISER_V3_BASE_URL}/permissions`);

  const addRoleFetch = (addRoleForm: any): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_KAISER_V3_BASE_URL}/role`, addRoleForm);

  const updateRoleFetch = (id: string, roleForm: any): Promise<unknown> =>
    axios.patch(`${process.env.REACT_APP_KAISER_V3_BASE_URL}/role/${id}`, roleForm);

  return { getPermissionsFetch, addRoleFetch, updateRoleFetch };
};

export default Api;
