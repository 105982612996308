import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { UserInfoForm } from './models';

const Api = () => {
  const addUserFetch = (addUserForm: UserInfoForm): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_KAISER_V3_BASE_URL}/users`, addUserForm);

  const updateUserFetch = (userId: string, updateUserForm: UserInfoForm): Promise<unknown> =>
    axios.patch(`${process.env.REACT_APP_KAISER_V3_BASE_URL}/users/${userId}`, updateUserForm);

  return { addUserFetch, updateUserFetch };
};

export default Api;
