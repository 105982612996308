import { connect } from 'react-redux';

// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../../../store';
import { CustomForm } from './CustomForm';
import { getAccessoryFetch } from '../actions';

const mapStateToProps = (state: RootState) => ({
  stepsStore: state.quotationState
});
const mapDispatchToProps = {
  getAccessoryFetch
};

export const CustomInfo = connect(mapStateToProps, mapDispatchToProps)(CustomForm);
