import { call, put, all, takeEvery } from 'redux-saga/effects';
import { getServicesSuccess, getServicesFail } from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IGetServicesResponse } from './models';
import Api from './Api';
import ApiMock from './ApiMock';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasGetServicesFetch() {
  try {
    const response: IGetServicesResponse = yield call(api.getServicesFetch);
    yield put(getServicesSuccess(response.data.data.items.items));
  } catch (e) {
    yield put(getServicesFail(e));
  }
}

function* servicesSaga() {
  yield all([takeEvery(ACTIONS.SERVICES.FETCH, sagasGetServicesFetch)]);
}

export { servicesSaga };
