import axios from 'axios';

let authenticationToken: string;

export default class HttpService {
  static _setHeaders = () => {
    axios.interceptors.request.use(
      (config) => {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${authenticationToken}`
        };
        return config;
      },
      (err) => Promise.reject(err)
    );
  };

  static setAuthToken = (token: string) => {
    authenticationToken = token;
    HttpService._setHeaders();
  };
}
