import MOCK_TYPES from '../../../../assets/mocks/Services/types';

const ApiMock = () => {
  // eslint-disable-next-line no-unused-vars
  const getTypesFetch = (): Promise<unknown> =>
    new Promise((res) => {
      res(MOCK_TYPES);
    }).then((res) => res);
  // eslint-disable-next-line no-unused-vars

  return { getTypesFetch };
};

export default ApiMock;
