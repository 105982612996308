import { call, put, takeLatest, all } from 'redux-saga/effects';

import Api from './Api';
import ApiMock from './ApiMock';
import {
  groupInfoSuccess,
  groupInfoFail,
  getGroupMembersSuccess,
  getGroupMembersFail,
  deleteMemberSuccess,
  deleteMemberFail
} from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../../../ActionTypes';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasGroupInfoFetch(action: actionType) {
  try {
    if (action.payload.groupId) {
      yield call(api.updateGroupInfoFetch, action.payload.groupId, action.payload.data);
    } else {
      yield call(api.createGroupInfoFetch, action.payload.data);
    }
    yield put(groupInfoSuccess());
  } catch (e) {
    yield put(groupInfoFail(e.response.data));
  }
}

function* sagasGetGroupMembersFetch(action: actionType) {
  try {
    const response = yield call(api.getGroupMembersFetch, action.payload.groupId);
    yield put(getGroupMembersSuccess(response.data));
  } catch (e) {
    yield put(getGroupMembersFail(e));
  }
}

function* sagasDeleteGroupMembersFetch(action: actionType) {
  try {
    const response = yield call(api.deleteMemberFetch, action.payload.id);
    yield put(deleteMemberSuccess(response));
  } catch (e) {
    yield put(deleteMemberFail(e));
  }
}

// Listen for redux actions
function* groupSaga() {
  yield all([takeLatest(ACTIONS.GROUPS.GROUP_INFO.FETCH, sagasGroupInfoFetch)]);
  yield all([takeLatest(ACTIONS.GROUPS.GROUP_MEMBERS.FETCH, sagasGetGroupMembersFetch)]);
  yield all([takeLatest(ACTIONS.GROUPS.GROUP_MEMBERS.REMOVE.FETCH, sagasDeleteGroupMembersFetch)]);
}

export { groupSaga };
