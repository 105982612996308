import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import { Grid } from '@material-ui/core';
import { fromMomentToUTCNumber, setTimeToInitOf, Button, Loading, fromNumberToUTCMoment } from '@wdynamo/common';
// eslint-disable-next-line no-unused-vars
import { IChartPeriods, IReportsOrdersGroupedByDateStore } from '../../models';

interface ISalesChartProps {
  ordersGroupedByDate: IReportsOrdersGroupedByDateStore;
  className?: string;
  [key: string]: any;
}

const CleanSalesChart: React.FC<ISalesChartProps> = (props: ISalesChartProps) => {
  const { t } = useTranslation();
  const { loading, ordersGroupedByDate } = props.ordersGroupedByDate;
  const [selectedChartPeriod, setSelectedChartPeriod] = useState<IChartPeriods>(IChartPeriods.DAILY);

  const ChartButtons = styled(Grid)`
    display: flex;
    justify-content: space-between;
    button {
      background-color: transparent;
      border: none;
      color: var(--color-grey-text);
      box-shadow: none;
      padding: 5px 50px;
      border-radius: 20px;
      text-transform: none;
      &:hover {
        background-color: var(--color-white);
      }
      &.active {
        border: 1px solid var(--color-main);
        color: var(--color-main);
      }
    }
  `;

  if (loading) return <Loading />;
  else if (!ordersGroupedByDate.length) return <></>;
  else {
    const calculateChartData = (): { [key: string]: number } => {
      const salesByDay: { [key: string]: number } = {};

      ordersGroupedByDate.forEach((item: any) => {
        let date: number;
        switch (selectedChartPeriod) {
          case IChartPeriods.DAILY:
            date = fromMomentToUTCNumber(setTimeToInitOf(item.date, 'day'));
            break;
          case IChartPeriods.WEEKLY:
            date = fromMomentToUTCNumber(setTimeToInitOf(item.date, 'week'));
            break;
          case IChartPeriods.MONTHLY:
            date = fromMomentToUTCNumber(setTimeToInitOf(item.date, 'month'));
            break;
          default:
            date = fromMomentToUTCNumber(setTimeToInitOf(item.date, 'day'));
            break;
        }
        salesByDay[date] ? (salesByDay[date] += item.total_sales) : (salesByDay[date] = item.total_sales);
      });
      return salesByDay;
    };

    const salesByDay: { [key: string]: number } = calculateChartData();

    const series = [
      {
        data: Object.entries(salesByDay)
          .map((item: [string, number]): [number, number] => [parseInt(item[0]), item[1]])
          .sort()
      }
    ];

    const mainColor = document.body.style.getPropertyValue('--color-main') || '#ff9800';

    const options: any = {
      chart: {
        id: 'area-datetime',
        height: 390,
        type: 'area',
        zoom: {
          autoScaleYaxis: true
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
        style: 'hollow'
      },
      xaxis: {
        type: 'datetime',
        min: Object.keys(salesByDay).slice(-1)[0],
        tickAmount: 6,
        categories: Object.entries(salesByDay)
          .map((item: [string, number]): number => parseInt(item[0]))
          .sort(),
        labels: {
          show: true,
          formatter: (val: string) => {
            const xTime = fromNumberToUTCMoment(val);
            let xFormatedTime: string;
            switch (selectedChartPeriod) {
              case IChartPeriods.DAILY:
                xFormatedTime = xTime.format('DD/MM');
                break;
              case IChartPeriods.WEEKLY:
                xFormatedTime = xTime.format('DD/MM');
                break;
              case IChartPeriods.MONTHLY:
                xFormatedTime = xTime.format('MMMM');
                break;
              default:
                xFormatedTime = xTime.format('DD/MM');
                break;
            }
            return xFormatedTime;
          }
        }
      },
      yaxis: {
        type: 'numeric',
        min: 0,
        forceNiceScale: true,
        floating: false
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        }
      },
      colors: [mainColor],
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100]
        },
        colors: [mainColor]
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      }
    };
    return (
      <Grid container className='chart-content'>
        <Grid item xs={12} className='chart'>
          <Chart options={options} series={series} type='area' height={390} />
        </Grid>
        <ChartButtons item xs={12}>
          <Button
            className={`${selectedChartPeriod === IChartPeriods.MONTHLY && 'active'}`}
            onClick={() => setSelectedChartPeriod(IChartPeriods.MONTHLY)}
          >
            {t('MY_SALES.DASHBOARD.CHART.BUTTONS.MONTH')}
          </Button>
          <Button
            className={`${selectedChartPeriod === IChartPeriods.WEEKLY && 'active'}`}
            onClick={() => setSelectedChartPeriod(IChartPeriods.WEEKLY)}
          >
            {t('MY_SALES.DASHBOARD.CHART.BUTTONS.WEEK')}
          </Button>
          <Button
            className={`${selectedChartPeriod === IChartPeriods.DAILY && 'active'}`}
            onClick={() => setSelectedChartPeriod(IChartPeriods.DAILY)}
          >
            {t('MY_SALES.DASHBOARD.CHART.BUTTONS.DAY')}
          </Button>
        </ChartButtons>
      </Grid>
    );
  }
};

export const SalesChart = (props: ISalesChartProps) => <CleanSalesChart {...props} />;
