import { connect } from 'react-redux';
import { ReportsComponent } from './view';
import {
  getOrdersGroupedByDateFetch,
  getTopProductsFetch,
  getTopSalesTeamsFetch,
  getTopSalesmenFetch,
  getOrdersFetch,
  getOrdersNextPageFetch,
  getOrdersToExportFetch
} from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  reportsStore: state.reportsState
});
const mapDispatchToProps = {
  getOrdersGroupedByDateFetch,
  getTopProductsFetch,
  getTopSalesTeamsFetch,
  getTopSalesmenFetch,
  getOrdersFetch,
  getOrdersNextPageFetch,
  getOrdersToExportFetch
};

export const Reports = connect(mapStateToProps, mapDispatchToProps)(ReportsComponent);
export { reportsSaga } from './sagas';
export { reportsReducer } from './reducers';
export type { IReportsProps } from './models';
