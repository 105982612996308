import React, { useEffect, useState } from 'react';
import { Box, Dialog, makeStyles, Typography, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import { IAccesoryParams, IPaymentMethodParams } from '../../../Services/Quotations/components/AutoQuotation/models';
// eslint-disable-next-line no-unused-vars
import { IQuoteSummary } from '../../models';
// eslint-disable-next-line no-unused-vars
import { IQuotationInput } from '../../../Services/Emissions/models';
import icon_cross from '../../../../assets/img/icons_dynamo/icon_cross.svg';

type SummaryModalProps = {
  open: boolean;
  onClose: () => void;
  quoteData: any;
  loading: boolean;
  getPaymentMethodFetch: (params: IPaymentMethodParams) => void;
  getAccessoryFetch: (params: IAccesoryParams) => void;
  stepsStore: any;
  // getProducerFetch: () => void;
};

const formatCurrencyDisplay = (value: number) => {
  return value.toLocaleString('es-PY', {
    style: 'decimal',
    minimumFractionDigits: 2
  });
};

const useStyles = makeStyles({
  root: {
    '& p,h5,h6': {
      fontFamily: 'inherit'
    },
    '& p': {
      fontSize: 14
    }
  },
  box: {
    backgroundColor: 'white',
    minWidth: '520px',
    minHeight: '500px',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  detailText: {
    fontSize: 12
  }
});

const SummaryModal = (props: SummaryModalProps) => {
  const { quoteData: data, loading, stepsStore } = props;
  const { t } = useTranslation();

  const classes = useStyles();

  const [displayData, setDisplayData] = useState<IQuoteSummary>();

  const makeAccessoriesDisplay = (accessoriesString: string, accessoriesList: IQuotationInput[]) => {
    const accObject = JSON.parse(accessoriesString);
    const accNameArray = accObject.map((acc: IQuotationInput) => {
      return accessoriesList.find((a) => a.code === acc.code)?.description || '';
    });
    const displayText = accNameArray.join(', ');
    return displayText;
  };

  useEffect(() => {
    if (data) {
      const paymentMethodParams: IPaymentMethodParams = {
        productId: String(data.product.id),
        commercialPlan: data.commercialPlan,
        billingMode: data.billingMethod,
        currency: ''
      };
      const accesoriesParams: IAccesoryParams = {
        branch: data.product.branch,
        currency: '',
        year: Number(data.inputs.year.code),
        Okm: data.inputs.Okm.code === 'true',
        validSince: '',
        chapterCode: 1,
        rcCode: 1,
        airCode: 1
      };
      props.getPaymentMethodFetch(paymentMethodParams);
      props.getAccessoryFetch(accesoriesParams);
      // props.getProducerFetch();
      setDisplayData({
        vehicle: `${data.inputs.brand.description} - ${data.inputs.model.description}`,
        secureValue: formatCurrencyDisplay(+data.inputs.value.description),
        occupants: data.inputs.occupants.description,
        accesories: data.inputs.accesories.description ? '' : t('QUOTE_MANAGEMENT.SUMMARY.NONE').toUpperCase(),
        year: data.inputs.year.description,
        zeroKm: data.inputs.Okm.code === 'true' ? t('COMMONS.YES') : t('COMMONS.NO'),
        phone: data.customer.phone,
        mail: data.customer.mail,
        producer: data.producerDescription,
        validity: t('QUOTE_MANAGEMENT.SUMMARY.ANNUAL_PAYMENT').toLocaleUpperCase('es-PY'),
        paymentMethod: '',
        paymentCondition: data.coveragePlan
          ? data.coveragePlan.billsAmount > 1
            ? `${data.coveragePlan.billsAmount} CUOTAS`
            : 'UN PAGO'
          : '',
        billsAmount: data.coveragePlan
          ? data.coveragePlan.billsAmount > 1
            ? `${data.coveragePlan.billsAmount} CUOTAS`
            : 'UN PAGO'
          : '',
        price: data.coveragePlan ? data.coveragePlan.price && formatCurrencyDisplay(data.coveragePlan.price) : '',
        payments: data.coveragePlan ? data.coveragePlan.billsAmount : '',
        paymentsValue: data.coveragePlan
          ? data.coveragePlan.paymentLeft && formatCurrencyDisplay(data.coveragePlan.paymentLeft)
          : '',
        planName: data.coveragePlan ? data.coveragePlan.planName : '',
        planDetails: data.characteristics ? data.characteristics : null
      });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const paymentMethodList = stepsStore.paymentMethod?.data ?? [];
      const accesoriesList = stepsStore.accesory?.data ?? [];
      // const producerList = stepsStore.producerList.data ?? [];
      if (paymentMethodList.length > 0) {
        const method = paymentMethodList.find((m: IQuotationInput) => m.code === data.paymentMethod)?.description;
        setDisplayData((previousState: any) => {
          return {
            ...previousState,
            paymentMethod: method ?? ''
          };
        });
      }
      if (data.inputs.accesories.description && accesoriesList.length > 0) {
        const accessoryDisplay: string = makeAccessoriesDisplay(data.inputs.accesories.description, accesoriesList);
        setDisplayData((previousState: any) => {
          return {
            ...previousState,
            accesories: accessoryDisplay ?? ''
          };
        });
      }
      // if (producerList.length > 0) {
      //   const producer = producerList.find((p: any) => p.producerCode === +data.producer)?.name;
      //   setDisplayData((previousState: any) => {
      //     return {
      //       ...previousState,
      //       producer: producer ?? ''
      //     };
      //   });
      // }
    }
  }, [stepsStore]);

  return (
    <Dialog open={props.open} onClose={props.onClose} className={classes.root}>
      <Box p='30px' className={classes.box}>
        <Box display='flex' alignItems='center'>
          <Box flexGrow={1}>
            <Typography variant='h5'>{t('QUOTE_MANAGEMENT.SUMMARY.TITLE')}</Typography>
          </Box>
          <IconButton onClick={props.onClose}>
            <img src={icon_cross} width='15rem' height='auto' />
          </IconButton>
        </Box>
        {displayData && displayData.accesories && displayData.paymentMethod && !loading ? (
          <>
            <Box mt={2}>
              <Typography variant='h6'>{t('QUOTE_MANAGEMENT.SUMMARY.CAR_INFO')}</Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.VEHICLE')}: <strong>{displayData.vehicle}</strong>
              </Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.SECURE_VALUE')}: <strong>{displayData.secureValue}</strong>
              </Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.OCCUPANTS')}: <strong>{displayData.occupants}</strong>
              </Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.ACCESORIES')}: <strong>{displayData.accesories}</strong>
              </Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.YEAR')}: <strong>{displayData.year}</strong>
              </Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.0KM')}: <strong>{displayData.zeroKm}</strong>
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant='h6'>{t('QUOTE_MANAGEMENT.SUMMARY.GENERAL_INFO')}</Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.PHONE')}: <strong>{displayData.phone}</strong>
              </Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.EMAIL')}: <strong>{displayData.mail}</strong>
              </Typography>
              <Typography>
                {t('QUOTATIONS.STEP_2.CHANNEL')}: <strong>{displayData.producer}</strong>
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant='h6'>{t('QUOTE_MANAGEMENT.SUMMARY.PLAN_INFO')}</Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.VALIDITY')}: <strong>{displayData.validity}</strong>
              </Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.PAYMENT_METHOD')}: <strong>{displayData.paymentMethod}</strong>
              </Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.PAYMENT_CONDITION')}: <strong>{displayData.paymentCondition}</strong>
              </Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.PRIZE')}: <strong>{displayData.price}</strong>
              </Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.PAYMENTS')}: <strong>{displayData.payments}</strong>
              </Typography>
              <Typography>
                {t('QUOTE_MANAGEMENT.SUMMARY.PAYMENTS_VALUE')}: <strong>{displayData.paymentsValue}</strong>
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography style={{ fontWeight: 'bold' }}>{displayData.planName}</Typography>
              {displayData.planDetails && (
                <>
                  <Typography style={{ color: 'var(--color-main)' }}>
                    {t('QUOTE_MANAGEMENT.SUMMARY.PLAN_DETAILS')}
                  </Typography>
                  {displayData.planDetails.map((line: string, index: number) => (
                    <Typography key={index} className={classes.detailText}>
                      {line}
                    </Typography>
                  ))}
                </>
              )}
            </Box>
          </>
        ) : (
          <Typography>{t('COMMONS.MODALS.LOADING.TITLE')}</Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default SummaryModal;
