import { call, put, takeLatest, all } from 'redux-saga/effects';
import ApiMock from './ApiMock';
import Api from './Api';
import { getContactsSuccess, getContactsFail } from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasGetContactsFetch(action: actionType) {
  try {
    const response = yield call(api.getContactsFetch, action.payload.params);

    yield put(getContactsSuccess(response.data.data));
  } catch (e) {
    yield put(getContactsFail(e));
  }
}

// Listen for redux actions
function* contactsSaga() {
  yield all([takeLatest(ACTIONS.CONTACTS.FETCH, sagasGetContactsFetch)]);
}

export { contactsSaga };
