/* eslint-disable no-unused-vars */
import axios from 'axios';
import { IGetInspectionFormParams, ISendInspectionFormParams } from './models';
/* eslint-enable no-unused-vars */

class Api {
  // eslint-disable-next-line no-unused-vars
  getInspectionFormFetch = (params: IGetInspectionFormParams): Promise<unknown> =>
    // axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/car/inspection`, {
    //   params: params
    // });
    // axios.get('http://localhost:3005/inspectionForm', { params: params });
    axios.get('https://run.mocky.io/v3/bbafad2d-23db-4c5b-8f6e-ad87e30937ec');

  sendInspectionFormFetch = (params: ISendInspectionFormParams): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/car/inspection`, params);
  // axios.post('http://localhost:3005/sendInspectionForm', params);
}

export default Api;
