import React from 'react';
import { Popper } from '@wdynamo/common';
import { Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';

interface AssingUserPopperProps {
  onClick?: any;
  anchorEl: any;
  toggleOpen: any;
  listUsers: any[];
  assigned_user?: any;
  handleOnChange(field: string, value: any): void;
}

const useStyles = makeStyles(() =>
  createStyles({
    popper: { width: '20em', textAlign: 'left' },
    fullWidth: { width: '100%' },
    active: {
      backgroundColor: (props: any) => props.mainColor,
      color: 'white',
      border: 'none',
      '&:hover': {
        backgroundColor: (props: any) => props.mainColor,
        color: 'white'
      }
    },
    cancel: {
      backgroundColor: 'white',
      color: '#666',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    }
  })
);

export const AssingUserPopper: React.FC<AssingUserPopperProps> = (props: AssingUserPopperProps) => {
  const mainColor = document.body.style.getPropertyValue('--color-main') || '#ff9800';
  const classes = useStyles({ mainColor });
  const { t } = useTranslation();

  const { anchorEl } = props;

  return (
    <Popper
      anchorEl={anchorEl}
      modifiers={{
        offset: {
          enabled: false,
          offset: '0, 30'
        }
      }}
    >
      <Grid container spacing={1} className={classes.popper}>
        <Grid item xs={12}>
          <Autocomplete
            value={props.assigned_user}
            options={props.listUsers}
            onChange={(e, value) => props.handleOnChange('assigned_user', value)}
            getOptionLabel={(option: any) => `${option.first_name || ''} ${option.last_name || ''}`}
            renderInput={(params) => (
              <TextField {...params} label={t('CONTACTS.CONTACT_INFO.SEARCH_SELLER')} variant='standard' />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
    </Popper>
  );
};
