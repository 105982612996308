import { Radio, RadioGroup, Grid, FormGroup, FormControlLabel, FormLabel } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { FormCommonNaturalPerson } from './FormCommonNaturalPerson';
import { FormPEP } from './FormPEP';

interface IFormNPProp {
  className?: string;
  dataForm: any;
  handleOnChange(value: string | boolean | number, name: string): void;
  handleOnChangeRuc(e: any, path: string): void;
  statePath: string;
  nationalityList: any;
  idTypeList: any;
  maritalStatusList: any;
  activityList: any;
  provinceList: any;
  cityList: any;
  neighborhoodList: any;
}

const CleanFormInsured: React.FC<IFormNPProp> = (props: IFormNPProp) => {
  const {
    dataForm,
    provinceList,
    cityList,
    neighborhoodList,
    handleOnChange,
    handleOnChangeRuc,
    statePath,
    nationalityList,
    idTypeList,
    maritalStatusList,
    activityList
  } = props;

  return (
    <>
      <FormCommonNaturalPerson
        dataForm={dataForm}
        handleOnChange={handleOnChange}
        handleOnChangeRuc={handleOnChangeRuc}
        statePath={statePath}
        nationalityList={nationalityList}
        idTypeList={idTypeList}
        maritalStatusList={maritalStatusList}
        activityList={activityList}
        provinceList={provinceList}
        neighborhoodList={neighborhoodList}
        cityList={cityList}
      />
      <Grid item md={3}>
        <FormLabel className='radio-label'>P.E.P</FormLabel>
        <FormGroup>
          <RadioGroup
            name={`${statePath}.is_pep`}
            value={dataForm.is_pep.value}
            onChange={(event): void => handleOnChange(event.target.value === 'true', `${statePath}.is_pep`)}
            row={true}
          >
            <FormControlLabel value={false} control={<Radio />} label={'No'} />
            <FormControlLabel value={true} control={<Radio />} label={'Sí'} />
          </RadioGroup>
        </FormGroup>
      </Grid>
      {dataForm.is_pep.value === true && (
        <FormPEP statePath={`${statePath}.pep`} handleOnChange={handleOnChange} dataForm={dataForm.pep} />
      )}
    </>
  );
};
export const FormInsured = styled(({ ...props }) => <CleanFormInsured {...props} />)`
  .radio-label {
    font-size: 13px;
  }
`;
