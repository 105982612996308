// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../ActionTypes';
const initialState: any = {
  loading: false,
  error: false,
  budgets: [],
  message: []
};
export const contactDetailsReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.CONTACTS.CONTACT_DETAILS.FETCH:
      return {
        ...state,
        loading: true,
        error: false,
        budgets: undefined
      };
    case ACTIONS.CONTACTS.CONTACT_DETAILS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        budgets: [...action.payload.budgets]
      };
    case ACTIONS.CONTACTS.CONTACT_DETAILS.FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        message: [action.payload.error.message]
      };
    case ACTIONS.CONTACTS.CHANGE_STATUS.FETCH:
      return {
        ...state,
        /* loading: true,
        error: false,*/
        budgets: [...state.budgets]
      };
    case ACTIONS.CONTACTS.CHANGE_STATUS.SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const a: any = [
        ...state.budgets.filter((item: any) => item.idProduct !== action.payload.budgets.data.communication.product.id),
        {
          idProduct: action.payload.budgets.data.communication.product.id,
          product: action.payload.budgets.data.communication.product.name,
          state: action.payload.budgets.data.communication.state
        }
      ];
      return {
        ...state,
        budgets: a.sort(function (c: any, b: any) {
          if (c.product < b.product) {
            return -1;
          }
          if (c.product > c.product) {
            return 1;
          }
          return 0;
        }) //a.sort((c: any, b: any) => (c.idProduct > b.idProduct ? 1 : -1))
      };
    case ACTIONS.CONTACTS.CHANGE_STATUS.FAIL:
      return {
        ...state
        /* loading: false,
        error: true,
        message: [action.payload.error.message]*/
      };

    default:
      return state;
  }
};
