// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IServicesStore } from './models';

const initialState: IServicesStore = {
  services: {
    loading: false,
    error: false,
    message: '',
    data: []
  }
};

export const servicesReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.SERVICES.FETCH:
      return {
        ...state,
        services: {
          ...state.services,
          loading: true,
          error: false
        }
      };
    case ACTIONS.SERVICES.SUCCESS:
      return {
        ...state,
        services: {
          ...state.services,
          loading: false,
          error: false,
          data: [...action.payload]
        }
      };
    case ACTIONS.SERVICES.FAIL:
      return {
        ...state,
        services: {
          ...state.services,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    default:
      return state;
  }
};
