import { connect } from 'react-redux';
import { GroupsListComponent } from './view';
import { getGroupsFetch, deleteGroupFetch, getParentsFetch } from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../store';

const mapStateToProps = (state: RootState) => ({
  groupsStore: state.groupsState
});

const mapDispatchToProps = { getGroupsFetch, deleteGroupFetch, getParentsFetch };

export { groupsSaga } from './sagas';
export { groupsReducer } from './reducers';
export type { IGroupsProps } from './models';

export const Groups = connect(mapStateToProps, mapDispatchToProps)(GroupsListComponent);
