import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Analytics } from '@wdynamo/common/lib/services';
import { uris } from '../../../../siteMap';
import { Grid } from '@material-ui/core';
import { CustomInfiniteScroll, Loading } from '@wdynamo/common';
import { ReactComponent as PlayIcon } from '../../../../assets/img/icons_dynamo/icon_more_info.svg';
import { ReactComponent as ImportIcon } from '../../../../assets/img/icons_dynamo/icon_import.svg';
import { ReactComponent as VectorDown } from '../../../../assets/img/icons_dynamo/arrow_down.svg';
import { ReactComponent as VectorUp } from '../../../../assets/img/icons_dynamo/arrow_up.svg';
//import { ReactComponent as IconFilter } from '../../../../assets/img/icons_dynamo/icon_filter_new_2.svg';
//import { ContactsFilterPopper } from '../ContactsFilterPopper';
import { ModalImportCustomersComponent } from './components';
/* eslint-disable no-unused-vars */
import { IContactsStore, IContact } from '../../models';
import SearchBar from 'material-ui-search-bar';
import { History } from 'history';
import { IconLabel } from '../../../../components';
import Pagination from '@material-ui/lab/Pagination';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { isConstructorDeclaration } from 'typescript';
import TransparentTooltip from '../../../../components/Tooltip/Tooltip';
//import { EnzymeAdapter } from 'enzyme';
// eslint-disable-next-line no-unused-vars
import { IContactInfoForm } from '../ContactInfo/models';
import CryptoAES from 'crypto-js/aes';
import { ENCRYPTION_KEY } from '../..';

interface IContactsListProps {
  contactsStore: IContactsStore;
  history: History;
  firstLoading: boolean;
  getContactsFetch(params?: any): void;
  className: string;
}

interface IDataOrderFields {
  name: string;
  id: number;
  isActive: boolean;
  value: string;
}
const initialContactInfoForm: any = {
  responsible: { value: '', error: false, isRequired: true }
};

const CustomersListComponent: React.FC<IContactsListProps> = (props: IContactsListProps) => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const { contactsStore, history } = props;
  const { loading, data } = contactsStore;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [contactList, setContactList] = useState<any>([]);
  const [searched, setSearched] = useState<string>('');
  const [productSelect, setProductSelect] = useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalImportCustomers, setOpenModalImportCustomers] = useState<boolean>(false);
  const [sorted, setSortedby] = useState<string>('');
  const [orderedFieldBy, setOrderedFieldBy] = useState('asc');
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const location = useLocation();
  const contacts = data.items ? data.items : [];
  const paramsStorage = sessionStorage.getItem('params') || '';
  const paramsFromStorage = paramsStorage && JSON.parse(paramsStorage);
  const [orderFields, setOrderFields] = useState();
  const [contactInfoForm, setContactInfoForm] = React.useState<IContactInfoForm>(initialContactInfoForm);
  const [hasResponsible, setHasResposible] = React.useState<boolean>(false);

  React.useEffect(() => {
    Analytics().pageview('display-contacts');
  }, []);

  React.useEffect(() => {
    let params: any;

    if (paramsFromStorage && location?.state !== 'sideBar') {
      // setInStorage(paramsFromStorage);
      setPageNumber(paramsFromStorage.page);
      setSortedby(paramsFromStorage.sort);
      setOrderedFieldBy(paramsFromStorage.order);
      setSearched(paramsFromStorage.search);

      params = {
        search: paramsFromStorage.search,
        page: paramsFromStorage.page,
        sort: paramsFromStorage.sort,
        order: paramsFromStorage.search
      };
    } else {
      window.localStorage.removeItem('paramsFromStorage');
      params = {
        search: '',
        page: 1
        /* sort: 'status',
    order: orderedFieldBy */
      };
    }
    if (keycloak?.authenticated)
      setTimeout(() => {
        props.getContactsFetch(params);
      });
  }, [keycloak?.authenticated]);

  React.useEffect(() => {
    setContactList(contactsStore?.data?.items?.items);
    setTotalPages(contactsStore?.data?.items?.total_pages);
  }, [contactsStore]);

  const setInStorage = (params: any) => {
    window.sessionStorage.setItem('params', JSON.stringify(params));
    setPageNumber(params.page);
    setSortedby(params.sort);
    setOrderedFieldBy(params.order);
    setSearched(params.search);
  };

  const getFormattedDate = (dateString: string) => {
    const dateSplittedDate = dateString.split(' ');
    const dateSplitted = dateSplittedDate[0].split('-') as unknown[] as number[];
    const [day, month, year] = dateSplitted;
    return `${year}-${month}-${day}`;
  };

  const handlePageChange = (event: any, value: number) => {
    setPageNumber(value);
    const params = {
      search: searched,
      page: value,
      sort: sorted,
      order: orderedFieldBy
    };
    setInStorage(params);
    props.getContactsFetch(params);
  };

  const handleChange = (e: any) => {
    setProductSelect(e.target.value);
  };

  const requestSearch = (searchedVal: string) => {
    const params = {
      search: searchedVal,
      page: 1,
      sort: sorted,
      order: orderedFieldBy
    };
    if (searchedVal.length > 1) {
      props.getContactsFetch(params);
    } else if (searchedVal.length === 0) {
      params.search = '';
      props.getContactsFetch(params);
    }
    setInStorage(params);
    setSearched(searchedVal);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch('');
    //  setInStorage()
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleCloseImportCustomersModal = () => {
    setOpenModalImportCustomers(false);
    setHasResposible(false);
    setContactInfoForm(initialContactInfoForm);
    setTimeout(() => {
      setSlideIndex(0);
    }, 500);
  };

  const handleOrderListOnChange = (name: string, value: string) => {
    setSortedby(name);
    setOrderedFieldBy(value);

    const params = {
      search: searched,
      page: pageNumber,
      sort: name,
      order: value
    };

    props.getContactsFetch(params);
  };

  const handleNewContactClick = () => {
    window.sessionStorage.removeItem('contact');
    history.push(uris.contactInfo.uri);
  };

  const handleContactDetailClick = (contact: IContact) => {
    const json = JSON.stringify(contact);
    const encrypted = CryptoAES.encrypt(json, ENCRYPTION_KEY).toString();

    window.sessionStorage.setItem('contact', encrypted);
    history.push(uris.contactDetails.uri, contact?.id);
  };

  const useStyles = makeStyles({
    select: {
      backgroundColor: 'var(--color-main)',
      color: 'white',
      width: '15rem',
      height: '2.5rem',
      border: 'none',
      borderStyle: 'none',
      textAlign: 'center',
      borderRadius: '0 10px 10px 0'
    },
    formControl: {
      paddingTop: '0',
      paddingBottom: '0',
      minWidth: '15rem',
      height: '2.5rem',
      borderStyle: 'none',
      borderRadius: '0 10px 10px 0'
    },
    searchBar: {
      border: '1px solid #B1ACAC',
      boxSizing: 'border-box',
      borderRadius: '10px',
      boxShadow: 'none',
      height: '2.5rem',
      width: '15rem'
    }
  });
  const classes = useStyles();
  return (
    <div className={`${props.className}`} style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Grid container className='header-container'>
        <Grid item xs={6}>
          <h3 className='main-title'>{t('CONTACTS.TABLE.TITLE')}</h3>
        </Grid>
        <Grid item container xs={6} direction='row' justify='flex-end'>
          <div className='filter-buttons'>
            <Grid xs={10} className='div-searcher' justify='flex-end'>
              <>
                <ModalImportCustomersComponent
                  open={openModalImportCustomers}
                  handleCloseModal={handleCloseImportCustomersModal}
                  initialContactInfoForm={initialContactInfoForm}
                  //   handleOrderListChange={handleOrderListChange}
                  orderFields={orderFields}
                  setOpenModal={setOpenModal}
                  //   cleanOrderListSubmit={cleanOrderListSubmit}
                  slideIndex={slideIndex}
                  setSlideIndex={setSlideIndex}
                  contactInfoForm={contactInfoForm}
                  setContactInfoForm={setContactInfoForm}
                  hasResponsible={hasResponsible}
                  setHasResposible={setHasResposible}
                  {...props}
                />
              </>
              <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder={t('CONTACTS.PLACEHOLDER_SEARCHER')}
                className={classes.searchBar}
              />
              {/*  <FormControl variant='standard' className={classes.formControl}>
                <Select
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  type={'select'}
                  value={productSelect}
                  className={classes.select}
                  onChange={handleChange}
                >
                  <MenuItem key={'autos1'} value={1} style={{ color: 'black', paddingRight: '0.3rem' }}>
                    {'Autos'}
                  </MenuItem>
                </Select>
                  </FormControl>*/}
            </Grid>
          </div>
        </Grid>
        <Grid item container className='action-buttons'>
          <Grid item style={{ textAlign: 'left' }}>
            <Grid className='icon-label' item style={{ marginLeft: '-12px' }}>
              <IconLabel label={t('CONTACTS.BUTTON_ADD_CUSTOMER')} onClick={handleNewContactClick} />
            </Grid>
          </Grid>{' '}
          <Grid item>
            <Grid className='icon-label' item style={{ marginLeft: '-19px' }}>
              <IconLabel
                icon={ImportIcon}
                label={t('CONTACTS.BUTTON_IMPORT_CUSTOMERS')}
                onClick={() => {
                  setOpenModalImportCustomers(true);
                }}
                // onClick={() => props.history.push(uris.contactInfo.uri)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className='contacts-grid'>
        <Grid item xs={12} className='header'>
          <Grid container>
            <Grid item xs={2} className='title-container'>
              <div>{t('CONTACTS.TABLE.HEADERS.DATE')} </div>
              <div className='arrows-container'>
                <VectorUp
                  className={sorted + '_' + orderedFieldBy === 'lastContact_asc' ? 'arrow-up active' : 'arrow-up'}
                  onClick={() => {
                    handleOrderListOnChange('lastContact', 'asc');
                  }}
                />
                <VectorDown
                  className={sorted + '_' + orderedFieldBy === 'lastContact_desc' ? 'arrow-down active' : 'arrow-down'}
                  onClick={() => {
                    handleOrderListOnChange('lastContact', 'desc');
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={3} className='title-container'>
              <div> {t('CONTACTS.TABLE.HEADERS.RESPONSIBLE')} </div>
              <div className='arrows-container'>
                <VectorUp
                  className={sorted + '_' + orderedFieldBy === 'responsible_desc' ? 'arrow-up active' : 'arrow-up'}
                  onClick={() => {
                    handleOrderListOnChange('responsible', 'desc');
                  }}
                />
                <VectorDown
                  className={sorted + '_' + orderedFieldBy === 'responsible_asc' ? 'arrow-down active' : 'arrow-down'}
                  onClick={() => {
                    handleOrderListOnChange('responsible', 'asc');
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={3} className='title-container'>
              <div> {t('CONTACTS.TABLE.HEADERS.NAME')} </div>
              <div className='arrows-container'>
                <VectorUp
                  className={sorted + '_' + orderedFieldBy === 'lastName_desc' ? 'arrow-up active' : 'arrow-up'}
                  onClick={() => {
                    handleOrderListOnChange('lastName', 'desc');
                  }}
                />
                <VectorDown
                  className={sorted + '_' + orderedFieldBy === 'lastName_asc' ? 'arrow-down active' : 'arrow-down'}
                  onClick={() => {
                    handleOrderListOnChange('lastName', 'asc');
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={3} className='title-container'>
              {t('CONTACTS.TABLE.HEADERS.EMAIL')}
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        {!contacts.length && loading ? (
          <Loading />
        ) : !data?.items?.items[0] && searched ? (
          <p>{t('CONTACTS.SEARCH_NO_RESULTS_1') + searched + '.' + t('CONTACTS.SEARCH_NO_RESULTS_2')}</p>
        ) : (
          <Grid item xs={12} className='content'>
            <CustomInfiniteScroll
              //  nextUrl={nextPage}
              // paginate={handleChangePage}
              paginate={() => {}}
              firstLoading={props.firstLoading}
            >
              {contactList &&
                contactList.map((contact: IContact, i: number) => (
                  <Grid container key={i} className='contact'>
                    <Grid item xs={2}>
                      {contact?.last_contact ? `${getFormattedDate(contact?.last_contact)}` : ''}
                    </Grid>
                    <Grid item xs={3}>
                      {contact?.responsible ? `${contact.responsible.first_name} ${contact.responsible.last_name}` : ''}
                    </Grid>
                    <Grid item xs={3}>
                      {`${contact.name ? contact.name : ''} ${contact.last_name ? contact.last_name : ''}`}
                    </Grid>
                    <Grid item xs={3}>
                      {contact.mail}
                    </Grid>

                    <Grid item xs={1} className='icon-grid'>
                      <TransparentTooltip title={t('CONTACTS.TABLE.HEADERS.DETAIL')}>
                        <PlayIcon onClick={() => handleContactDetailClick(contact)} />
                      </TransparentTooltip>
                    </Grid>
                  </Grid>
                ))}
            </CustomInfiniteScroll>
          </Grid>
        )}
      </Grid>
      <Grid item className='paginator-cont'>
        <Pagination
          //  className='my-3'
          count={totalPages}
          page={pageNumber}
          //   siblingCount={1}
          boundaryCount={1}
          variant='outlined'
          //  shape='rounded'
          onChange={handlePageChange}
        />
      </Grid>
    </div>
  );
};

export const CustomersList = styled(({ ...props }) => <CustomersListComponent {...props} />)`
  .paginator-cont {
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
  .div-searcher {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
  }
  .arrow-up {
    cursor: pointer;
    margin-top: 0.2rem;
  }

  .arrow-down {
    cursor: pointer;
    margin-top: 0.1rem;
  }
  .arrow-up path:hover {
    fill: var(--color-main);
  }
  .arrow-down path:hover {
    fill: var(--color-main);
  }

  .active path {
    fill: var(--color-main);
  }

  .header-container {
    display: flex;
    margin-top: 0.5rem;
  }
  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
  }
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    div {
      margin-left: 2rem;
    }
  }
  .icon-grid {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .arrows-container {
    display: flex;
    flex-direction: column;
    margin-left: 0.3rem;
  }
  .MuiPagination-ul {
    justify-content: center;
  }
  .main-title {
    font-size: 1.75rem;
  }
  .align-right {
    text-align: right;
  }
  .contacts-grid {
    .header {
      border: none;
      font-weight: bold;
      text-align: left;
      div {
        padding: 5px 0;
      }
    }
    .content {
      .contact {
        padding: 16px 0;
        border-bottom: 1px solid var(--color-grey-border);
        cursor: pointer;
        g.main-color {
          fill: red;
        }
      }
    }
    .last_contact {
      text-align: right;
    }
  }
  .filter-buttons {
    display: flex;
    padding: 0;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  }
  .MuiInput-underline:before {
    border-bottom: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  .MuiInput-underline:after {
    border-bottom: none;
  }
`;
