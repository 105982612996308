import React, { useRef } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface ImageUploaderProps {
  // onImageSelected(imageSelected: string): void;
  className?: any;
}

// eslint-disable-next-line no-unused-vars
export const ImageUploaderComponent: React.FC<ImageUploaderProps> = (props: ImageUploaderProps) => {
  const { t } = useTranslation();
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileInput = (e: any) => {
    console.log(e.target.files[0]);
    // props.onImageSelected(e.target.files[0]);
  };

  return (
    <div className={`${props.className}`}>
      <input type='file' onChange={handleFileInput} ref={fileInput} style={{ display: 'none' }} />
      <Button variant='outlined' color='primary' onClick={() => fileInput.current && fileInput.current.click()}>
        {t('SERVICES.NEW_SERVICE.STEP_2.SELECT_IMAGE')}
      </Button>
    </div>
  );
};

export const ImageUploader = styled(({ ...props }) => <ImageUploaderComponent {...props} />)``;
