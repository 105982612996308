import React from 'react';
import styled from 'styled-components';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface IFormHeaderComponentProps {
  className: string;
  inspectionStore: any;
  request: string;
}

const CleanFormHeaderComponent: React.FC<IFormHeaderComponentProps> = (props: IFormHeaderComponentProps) => {
  const { className, inspectionStore, request } = props;
  const { t } = useTranslation();

  const renderDescriptionRow = (
    subtitle: string,
    description: string,
    secondSubtitle: string,
    secondDescription: string
  ) => (
    <Grid container justifyContent='space-between'>
      <Grid item xs={12} sm={8}>
        <Typography variant='subtitle1'>{subtitle}</Typography>
        <Typography>{description}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant='subtitle1'>{secondSubtitle}</Typography>
        <Typography>{secondDescription}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <Box className={className}>
      <Grid container justifyContent='flex-end'>
        <img src={inspectionStore.partnerConfigInfo.logo_filename} alt='logo de empresa' width='263'></img>
      </Grid>
      <Typography variant='h3' style={{ marginBottom: '1rem' }}>
        {t('INSPECTION.FORM.TITLE')}
      </Typography>
      {renderDescriptionRow(
        t('INSPECTION.FORM.CAR_DESCRIPTION'),
        inspectionStore.getForm.data[0].risk_description,
        t('INSPECTION.FORM.BRANCH'),
        inspectionStore.getForm.data[0].branch_description
      )}
      {renderDescriptionRow(
        t('INSPECTION.FORM.REQUEST'),
        request,
        t('INSPECTION.FORM.ADMISSION_DATE'),
        moment(inspectionStore.getForm.data[0].date_time).format('DD/MM/YYYY')
      )}
    </Box>
  );
};

export const FormHeaderComponent = styled(({ ...props }) => <CleanFormHeaderComponent {...props} />)`
  .MuiGrid-item {
    margin: 0.5rem 0;

    .MuiTypography-subtitle1 {
      display: inline;
      font-weight: bold;
      margin: 1rem 0;
    }

    .MuiTypography-body1 {
      display: inline;
    }
  }
`;
