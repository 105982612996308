const MOCK_TYPES = {
  data: {
    status: 'success',
    status_code: 200,
    data: [
      {
        id: '0',
        name: 'string',
        has_list: false
      },
      {
        id: '1',
        name: 'number',
        has_list: false
      },
      {
        id: '2',
        name: 'options',
        has_list: true
      }
    ]
  }
};

export default MOCK_TYPES;
