import { call, put, takeLatest, all } from 'redux-saga/effects';

import Api from './Api';
import ApiMock from './ApiMock';
import { getRolesSuccess, getRolesFail, deleteRoleFail, deleteRoleSuccess } from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../../ActionTypes';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasGetRolesFetch() {
  try {
    const response = yield call(api.getRolesFetch);
    yield put(getRolesSuccess(response?.data));
  } catch (e) {
    yield put(getRolesFail(e.response?.data));
  }
}

function* sagasDeleteRoleFetch(action: actionType) {
  try {
    yield call(api.deleteRole, action.payload.data);
    yield put(deleteRoleSuccess());
  } catch (e) {
    yield put(deleteRoleFail(e.response.data));
  }
}

// Listen for redux actions
function* rolesListSaga() {
  yield all([takeLatest(ACTIONS.USERS.ROLES.FETCH, sagasGetRolesFetch)]);
  yield all([takeLatest(ACTIONS.USERS.ROLES.DELETE, sagasDeleteRoleFetch)]);
}

export { rolesListSaga };
