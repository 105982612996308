import { connect } from 'react-redux';
import { RoleInfoComponent } from './view';
import { getPermissionsFetch, roleInfoAddFetch, roleInfoUpdateFetch } from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../../store';

const mapDispatchToProps = { getPermissionsFetch, roleInfoAddFetch, roleInfoUpdateFetch };

const mapStateToProps = (state: RootState) => ({
  roleInfoStore: state.roleInfoState
});

export const RoleInfo = connect(mapStateToProps, mapDispatchToProps)(RoleInfoComponent);

export { roleInfoReducer } from './reducer';
export { roleInfoSaga } from './sagas';
