import { connect } from 'react-redux';
import { ContactInfoComponent } from './view';
import { contactInfoFetch } from './actions';
import { getUsersFetch } from '../../../Users/actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../store';

const mapStateToProps = (state: RootState) => ({
  contactInfoStore: state.contactInfoState,
  usersStore: state.usersState
});
const mapDispatchToProps = { contactInfoFetch, getUsersFetch };

export const ContactInfo = connect(mapStateToProps, mapDispatchToProps)(ContactInfoComponent);
export { contactInfoSaga } from './sagas';
export { contactInfoReducer } from './reducers';
export type { IContactInfoStore } from './models';
