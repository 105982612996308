import React from 'react';
import styled from 'styled-components';
import { Chip } from '@material-ui/core';

interface SelectedTagProps {
  onDelete(): void;
  name: string;
  className?: any;
}

const CleanSelectedTag: React.FC<SelectedTagProps> = (props: SelectedTagProps) => {
  return (
    <span className={`${props.className}`}>
      <Chip
        style={{ margin: '10px' }}
        color='primary'
        variant='outlined'
        label={props.name}
        onDelete={props.onDelete}
      />
    </span>
  );
};

export const SelectedTag = styled(({ ...props }) => <CleanSelectedTag {...props} />)`
  .MuiChip-colorPrimary {
    border: 1px solid var(--color-main);
    color: var(--color-main);
  }

  .chip {
    margin: 10px;
  }
`;
