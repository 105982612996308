// eslint-disable-next-line no-unused-vars
import { IQuotation } from './models';
export class PrincipalState {
  constructor(quotationData: IQuotation) {
    this.quotationData = quotationData;
    this.sourceFundDefaultValue = { code: '', description: '' };
  }
  quotationData: IQuotation;
  sourceFundDefaultValue = { code: '1', description: 'Ingresos genuinos/Ahorros personales' };

  initialState: any = {
    person_data: {
      creditor: {
        business_name: { value: '', isRequired: true, error: false },
        // ruc: { value: '', isRequired: true, error: false },
        id: { value: '', isRequired: true, error: false },
        address: {
          street: { value: '', isRequired: true, error: false },
          street_number: { value: '', isRequired: true, error: false },
          without_street_number: { value: false, isRequired: false, error: false },
          floor: { value: '', isRequired: false, error: false },
          apartment: { value: '', isRequired: false, error: false }
        },
        city: { value: '', isRequired: true, error: false },
        postal_code: { value: '', isRequired: true, error: false },
        province: { value: '', isRequired: true, error: false },
        phone: { value: '', isRequired: true, error: false },
        mail: { value: '', isRequired: true, error: false },
        is_holder: { value: true, isRequired: true, error: false },
        holder: {
          name: { value: '', isRequired: true, error: false },
          last_name: { value: '', isRequired: true, error: false },
          id_type: { value: '80', isRequired: true, error: false },
          id: { value: '', isRequired: true, error: false },
          character: { value: '', isRequired: true, error: false }
        },
        obligated_subject: { value: 0, isRequired: true, error: false },
        activity: { value: '', isRequired: true, error: false },
        legal_rep: {
          name: { value: '', isRequired: false, error: false },
          date_of_birth: { value: '', isRequired: false, error: false },
          birth_place: { value: '', isRequired: false, error: false },
          nationality: { value: 0, isRequired: false, error: false },

          sex: { value: 1, isRequired: false, error: false },
          id_type: { value: '96', isRequired: false, error: false },
          id: { value: '', isRequired: false, error: false },
          IVA_category: { value: '', isRequired: false, error: false },

          address: {
            street: { value: '', isRequired: false, error: false },
            street_number: { value: '', isRequired: false, error: false },
            without_street_number: { value: false, isRequired: false, error: false },
            floor: { value: '', isRequired: false, error: false },
            apartment: { value: '', isRequired: false, error: false }
          },
          phone: { value: '', isRequired: false, error: false },
          mail: { value: '', isRequired: false, error: false }
        }
      },
      contractor: {
        type: { value: 'natural', isRequired: true, error: false },
        business_name: { value: '', isRequired: true, error: false },
        // ruc: { value: '', isRequired: true, error: false },
        name: { value: '', isRequired: true, error: false },
        last_name: { value: '', isRequired: true, error: false },
        date_of_birth: { value: '', isRequired: false, error: false },
        birth_place: { value: '', isRequired: false, error: false },
        nationality: { value: '1', isRequired: true, error: false },
        province: { value: '', isRequired: true, error: false },
        postal_code: { value: '', isRequired: true, error: false },
        sex: { value: 1, isRequired: true, error: false },
        id_type: { value: '80', isRequired: true, error: false },
        id: { value: '', isRequired: true, error: false },
        IVA_category: { value: '', isRequired: false, error: false },
        marital_status: { value: '4', isRequired: true, error: false },
        mail: { value: '', isRequired: true, error: false },
        phone: { value: '', isRequired: true, error: false },
        spouse: {
          name: { value: '', isRequired: true, error: false },
          last_name: { value: '', isRequired: true, error: false },
          id_type: { value: '96', isRequired: true, error: false },
          phone: { value: '', isRequired: true, error: false },
          mail: { value: '', isRequired: true, error: false },
          is_pep: { value: false, isRequired: true, error: false },
          id: { value: '', isRequired: true, error: false }
        },
        address: {
          street: { value: '', isRequired: true, error: false },
          street_number: { value: '', isRequired: true, error: false },
          without_street_number: { value: false, isRequired: false, error: false },
          floor: { value: '', isRequired: false, error: false },
          apartment: { value: '', isRequired: false, error: false }
        },
        city: { value: '', isRequired: true, error: false },
        is_pep: { value: false, isRequired: false, error: false },
        pep: {
          position: { value: '', isRequired: true, error: false },
          agency: { value: '', isRequired: true, error: false },
          character: { value: '', isRequired: true, error: false }
        },
        is_holder: { value: true, isRequired: true, error: false },
        holder: {
          name: { value: '', isRequired: true, error: false },
          last_name: { value: '', isRequired: true, error: false },
          id_type: { value: '96', isRequired: true, error: false },
          id: { value: '', isRequired: true, error: false },
          character: { value: '', isRequired: true, error: false }
        },
        obligated_subject: { value: 0, isRequired: true, error: false },
        activity: { value: '', isRequired: true, error: false },
        source_fund: {
          value: this.sourceFundDefaultValue,
          isRequired: true,
          error: false
        },
        legal_rep: {
          name: { value: '', isRequired: false, error: false },
          date_of_birth: { value: '', isRequired: false, error: false },
          birth_place: { value: '', isRequired: false, error: false },
          nationality: { value: 0, isRequired: false, error: false },
          sex: { value: false, isRequired: false, error: false },
          id_type: { value: '96', isRequired: false, error: false },
          id: { value: '', isRequired: false, error: false },
          IVA_category: { value: '', isRequired: false, error: false },
          postal_code: { value: '', isRequired: false, error: false },
          address: {
            street: { value: '', isRequired: false, error: false },
            street_number: { value: '', isRequired: false, error: false },
            without_street_number: { value: false, isRequired: false, error: false },
            floor: { value: '', isRequired: false, error: false },
            apartment: { value: '', isRequired: false, error: false }
          },
          phone: { value: '', isRequired: false, error: false },
          mail: { value: '', isRequired: false, error: false }
        }
      },
      insured: {
        name: { value: '', isRequired: true, error: false },
        last_name: { value: '', isRequired: true, error: false },
        date_of_birth: { value: '', isRequired: false, error: false },
        birth_place: { value: '', isRequired: false, error: false },
        nationality: { value: 1, isRequired: true, error: false },
        province: { value: '', isRequired: true, error: false },
        city: { value: '', isRequired: true, error: false },
        postal_code: { value: '', isRequired: true, error: false },
        sex: { value: 1, isRequired: true, error: false },
        id_type: { value: '80', isRequired: true, error: false },
        id: { value: '', isRequired: true, error: false },
        IVA_category: { value: '', isRequired: false, error: false },
        address: {
          street: { value: '', isRequired: true, error: false },
          street_number: { value: '', isRequired: true, error: false },
          without_street_number: { value: false, isRequired: false, error: false },
          floor: { value: '', isRequired: false, error: false },
          apartment: { value: '', isRequired: false, error: false }
        },
        obligated_subject: { value: 0, isRequired: true, error: false },
        activity: { value: '', isRequired: true, error: false },
        marital_status: { value: '4', isRequired: true, error: false },
        spouse: {
          name: { value: '', isRequired: true, error: false },
          last_name: { value: '', isRequired: true, error: false },
          id_type: { value: '96', isRequired: true, error: false },
          id: { value: '', isRequired: true, error: false },
          phone: { value: '', isRequired: true, error: false },
          mail: { value: '', isRequired: true, error: false },
          is_pep: { value: false, isRequired: true, error: false }
        },
        is_pep: { value: false, isRequired: true, error: false },
        pep: {
          position: { value: '', isRequired: true, error: false },
          agency: { value: '', isRequired: true, error: false },
          character: { value: '', isRequired: true, error: false }
        },
        is_holder: { value: true, isRequired: true, error: false },
        holder: {
          name: { value: '', isRequired: true, error: false },
          last_name: { value: '', isRequired: true, error: false },
          id_type: { value: '96', isRequired: true, error: false },
          id: { value: '', isRequired: true, error: false },
          character: { value: '', isRequired: true, error: false }
        },
        phone: { value: '', isRequired: true, error: false }
      }
    },
    payment_data: {
      amount_of_payments: { value: '', isRequired: true, error: false },
      payment_method: { value: '', isRequired: true, error: false },
      second_payment_expiration: { value: '', isRequired: true, error: false },
      bank: { value: '', isRequired: true, error: false },
      card: { value: '', isRequired: true, error: false },
      card_number: { value: '', isRequired: true, error: false },
      due_date: { value: '', isRequired: true, error: false },
      bank_ob_account_type: { value: '', isRequired: true, error: false },
      account_number: { value: '', isRequired: true, error: false }
    },
    // id_info_data: {
    //   id_document_front: { value: { file_name: '', string_base_64: '' }, isRequired: false, error: false },
    //   id_document_back: { value: { file_name: '', string_base_64: '' }, isRequired: false, error: false },
    //   signature: { value: { file_name: '', string_base_64: '' }, isRequired: false, error: false }
    // },
    custom_data: {}
  };

  handlePersonDataRecovery = (personDataState: any, personData: any) => {
    Object.keys(personData).forEach((field) => {
      if (personDataState[field]) {
        if (typeof personData[field] === 'object') {
          this.handlePersonDataRecovery(personDataState[field], personData[field]);
        } else {
          personDataState[field].value = personData[field];
        }
      }
    });
  };

  // handleIdInfoDataRecovery = (idInfoState: any, idInfoData: any) => {
  //   idInfoData.forEach((document: any) => {
  //     idInfoState[document.name] = {
  //       value: { file_name: document.description, string_base_64: document.seedName },
  //       isRequired: false,
  //       error: false
  //     };
  //   });
  // };

  handlePaymentDataRecovery = (paymentDataState: any, paymentData: any) => {
    Object.keys(paymentData).forEach((field) => {
      paymentDataState[field].value = paymentData[field];
    });
  };

  handleIssueDataRecovery = () => {
    const {
      initialState,
      quotationData: { issue }
    } = this;
    const fullIssueData = { ...initialState };
    this.handlePersonDataRecovery(fullIssueData.person_data.contractor, issue.contractor);
    if (issue.creditor) this.handlePersonDataRecovery(fullIssueData.person_data.creditor, issue.creditor);
    if (issue.insured) this.handlePersonDataRecovery(fullIssueData.person_data.insured, issue.insured);
    // if (issue.document_data) this.handleIdInfoDataRecovery(fullIssueData.id_info_data, issue.document_data);
    if (issue.payment) this.handlePaymentDataRecovery(fullIssueData.payment_data, issue.payment);
    return fullIssueData;
  };
}
