import { call, put, takeLatest, all } from 'redux-saga/effects';
import Api from './Api';
import ApiMock from './ApiMock';
import { getUsersSuccess, getUsersFail, getFilteredUsersByTextSuccess, getFilteredUsersByTextFail } from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';

interface IUsersData {
  data?: any;
}

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasGetUsersFetch(action: actionType) {
  try {
    const response: IUsersData = yield call(api.getUsersFetch, {
      params: {
        page_len: action.payload.page_len,
        page: action.payload.page
      }
    });
    const { data } = response.data;
    yield put(getUsersSuccess(data.user, data.total_pages));
  } catch (e) {
    yield put(getUsersFail(e));
  }
}

function* sagasGetFilteredUsersFetch(action: actionType) {
  try {
    const response = yield call(api.getFilteredUsersByTextFetch, action.payload.text);
    yield put(getFilteredUsersByTextSuccess(response.data));
  } catch (e) {
    yield put(getFilteredUsersByTextFail(e));
  }
}

// Listen for redux actions
function* usersSaga() {
  yield all([takeLatest(ACTIONS.USERS.FETCH, sagasGetUsersFetch)]);
  yield all([takeLatest(ACTIONS.USERS.NON_PAGED_LIST.FETCH, sagasGetFilteredUsersFetch)]);
}

export { usersSaga };
