import React from 'react';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { uris } from '../../../siteMap';
import CancelIllustration from '../../../assets/img/illustrations/CancelIllustration.jpg';
import SuccessIllustration from '../../../assets/img/illustrations/SuccessIllustration.jpg';

import {
  CommissionComponent,
  CustomFieldsComponent,
  InformationComponent,
  PaymentsComponent,
  PriceAndImageComponent
} from './Tabs';
import { TextPoint, CustomTabs, StyledButton, CancelModal, SuccessModal } from '@wdynamo/common';
import { Box, Grid } from '@material-ui/core';

/* eslint-disable no-unused-vars */
import { IBaseStateProps } from '../../ActionTypes';
import { History } from 'history';
/* eslint-disable no-unused-vars */

interface ILocationStateType {
  service: any;
}

interface IServiceUpdateProps {
  serviceUpdateStore: IBaseStateProps;
  updateServiceFetch(data: any): void;
  history: History;
  [key: string]: any;
}

const initialState = {
  name: { value: '', error: false, isRequired: false, maxLength: 25 },
  subtitle: { value: '', error: false, isRequired: false, maxLength: 47 },
  description: { value: '', error: false, isRequired: false, maxLength: 132 },
  commercial: { value: '', error: false, isRequired: false },
  code: { value: '', error: false, isRequired: false },
  tags: { value: [], error: false, isRequired: false },
  price: { value: '', error: false, isRequired: false },
  currency: { value: '', error: false, isRequired: false },
  clarification: { value: '', error: false, isRequired: false, maxLength: 155 },
  image: { value: '', error: false, isRequired: false },
  paymentMethods: { value: [], error: false, isRequired: false },
  salesChannels: { value: [], error: false, isRequired: false },
  serviceState: { value: true, error: false, isRequired: false },
  comission: { value: 0, error: false, isRequired: false },
  customFields: { value: [], error: false, isRequired: false },
  logo_filename: {
    value: 'https://i.pinimg.com/originals/2b/08/85/2b0885f4cd9af3b60cd730bddb7d376d.jpg',
    error: false,
    isRequired: false
  }
};

export const ServiceUpdateComponent: React.FC<IServiceUpdateProps> = (props: IServiceUpdateProps) => {
  const { t } = useTranslation();
  const location = useLocation<ILocationStateType>();
  const [tabValue, setTabValue] = React.useState(0);
  const [service, setService] = React.useState<any>(initialState);
  const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState<boolean>(false);
  const { history } = props;

  React.useEffect(() => {
    const updatedState = { ...service };
    const data = location.state?.service;

    data &&
      Object.keys(updatedState).forEach((field) => {
        if (data[field]) updatedState[field].value = data[field];
      });
    setService(updatedState);
  }, []);

  const initialTabsHeaders = [
    {
      index: 0,
      label: t('SERVICES.UPDATE.INFORMATION.TITLE')
    },
    {
      index: 1,
      label: t('SERVICES.UPDATE.PRICE_AND_IMAGE.TITLE')
    },
    {
      index: 2,
      label: t('SERVICES.UPDATE.PAYMENTS.TITLE')
    },
    {
      index: 3,
      label: t('SERVICES.UPDATE.COMMISSION.TITLE')
    },
    {
      index: 4,
      label: t('SERVICES.UPDATE.CUSTOM_FIELDS.TITLE')
    }
  ];

  const handleOnChange = (fieldToUpdate: string, value: string) => {
    const prevService = { ...service };
    prevService[fieldToUpdate].value = value;
    setService(prevService);
  };

  const [tabsHeaders, setTabsHeaders] = React.useState(initialTabsHeaders);

  const tabsPanels = [
    {
      index: 0,
      content: (
        <InformationComponent
          service={service}
          handleOnChange={handleOnChange}
          commercials={[{ name: 'Rubro 1' }, { name: 'Rubro 2' }]}
        />
      )
    },

    {
      index: 1,
      content: (
        <PriceAndImageComponent
          service={service}
          handleOnChange={handleOnChange}
          currencies={[{ name: 'USD' }, { name: 'PYG' }, { name: 'ARS' }]}
        />
      )
    },
    {
      index: 2,
      content: <PaymentsComponent service={service} onChange={setService} />
    },
    { index: 3, content: <CommissionComponent service={service} handleOnChange={handleOnChange} /> },

    { index: 4, content: <CustomFieldsComponent service={service} onChange={setService} /> }
  ];

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const toggleCancelModal = () => {
    props.history.push(uris.services.uri);
  };

  const handleCancelModalConfirm = () => {
    setShowCancelModal(!showCancelModal);
  };

  const toggleSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
  };

  const handleSuccessModalConfirm = () => {
    history.push(uris.services.uri);
  };

  const renderModal = () => {
    return (
      <>
        <CancelModal
          open={showCancelModal}
          handleClose={toggleCancelModal}
          onClickConfirm={handleCancelModalConfirm}
          onClickCancel={toggleCancelModal}
          messages={{
            TITLE: t('SERVICES.UPDATE.MODALS.CANCEL_MODAL.TITLE'),
            CANCEL_BUTTON: t('COMMONS.MODALS.CANCEL.CANCEL'),
            CONTINUE_BUTTON: t('COMMONS.MODALS.CANCEL.CONTINUE')
          }}
          img={CancelIllustration}
        />
        <SuccessModal
          open={showSuccessModal}
          handleClose={toggleSuccessModal}
          onClickConfirm={handleSuccessModalConfirm}
          img={SuccessIllustration}
          description={''}
          messages={{
            TITLE: t('GROUPS.NEW_GROUP.MODAL.TITLE_SUCCESS'),
            CONTINUE_BUTTON: t('COMMONS.BUTTONS.CONTINUE')
          }}
        />
      </>
    );
  };

  return (
    <Grid container alignItems='center'>
      <Grid item xs={12}>
        <h1 className='title'>
          {t('SERVICES.UPDATE.TITLE')}
          <TextPoint />
        </h1>
        <CustomTabs tabHeaders={tabsHeaders} tabPanels={tabsPanels} value={tabValue} handleChange={handleTabChange} />
        <Box py={2}>
          <Grid container item justify='center' xs={12} spacing={3}>
            <Grid item>
              <StyledButton onClick={() => setShowCancelModal(true)} className='secondary '>
                {t('COMMONS.BUTTONS.CANCEL')}
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton onClick={() => setShowSuccessModal(true)} className='default continue'>
                {t('COMMONS.BUTTONS.CONFIRMATION')}
              </StyledButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {renderModal()}
    </Grid>
  );
};
