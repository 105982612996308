import React from 'react';
import { IMaskInput } from 'react-imask';

const TextMask = React.forwardRef<HTMLElement, any>(function textMask(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;
  const contractor: string = 'contractor.business_name';
  const creditor: string = 'creditor.business_name';

  const regex = /^[A-Za-zÁÉÍÓÚÑáéíóúñ ]*$/;

  return (
    <IMaskInput
      {...other}
      mask={regex}
      inputRef={ref}
      maxLength={props.name === contractor || props.name === creditor ? 60 : 30}
      onAccept={(value: any) => onChange({ target: { value, name: props.name } })}
    />
  );
});

export default TextMask;
