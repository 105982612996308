import React, { useEffect } from 'react';
import { Radio, RadioGroup, Grid, FormGroup, FormControlLabel, FormLabel } from '@material-ui/core';
import styled from 'styled-components';
import { StyledAccordion } from './components/StyledAccordion';
import { FormNaturalPerson } from './components/Forms/FormNaturalPerson';
import { FormLegalPerson } from './components/Forms/FormLegalPerson';
import { FormInsured } from './components/Forms/FormInsured';
import rucValidator from './components/common/rucValidator';
import emailValidator from '../../../Quotations/components/common/emailValidator';

interface IPersonDataProp {
  className?: string;
  personDataForm: any;
  setDataForm(dataForm: any): void;
  dataForm: any;
  riskHasPledgee: any;
  setRiskHasPledgee(riskHasPledgee: any): void;
  isContractorInsured: any;
  setIsContractorInsured(isContractorInsured: any): void;
  nationalityList: any;
  provinceList: any;
  contractorCityList: any;
  contractorNeighborhoodList: any;
  creditorCityList: any;
  creditorNeighborhoodList: any;
  insuredCityList: any;
  insuredNeighborhoodList: any;
  idTypeList: any;
  getCityFetch: any;
  getNeighborhoodFetch: any;
  maritalStatusList: any;
  activityList: any;
  sourceFundList: any;
}

const CleanPersonData: React.FC<IPersonDataProp> = (props: IPersonDataProp) => {
  const {
    setDataForm,
    dataForm,
    personDataForm,
    riskHasPledgee,
    setRiskHasPledgee,
    isContractorInsured,
    setIsContractorInsured,
    getCityFetch,
    getNeighborhoodFetch,
    nationalityList,
    contractorCityList,
    contractorNeighborhoodList,
    creditorCityList,
    creditorNeighborhoodList,
    insuredCityList,
    provinceList,
    insuredNeighborhoodList,
    idTypeList,
    maritalStatusList,
    activityList,
    sourceFundList
  } = props;

  const isLegal: boolean = personDataForm.contractor.type.value === 'legal';

  useEffect(() => {
    const contractorForm: any = personDataForm.contractor;
    const creditorForm: any = personDataForm.creditor;
    const insuredForm: any = personDataForm.insured;
    if (contractorForm) {
      handleErrors(contractorForm);
    }
    if (creditorForm) {
      handleErrors(creditorForm);
    }
    if (insuredForm) {
      handleErrors(insuredForm);
    }
    if (isLegal) setIsContractorInsured(true);
  }, [personDataForm]);

  const handleErrors = (currentForm: any) => {
    Object.keys(currentForm).forEach((field) => {
      const addressForm = currentForm.address;
      if (currentForm.id_type?.value === '80') {
        if (currentForm[field].value !== '' && currentForm[field].isRequired && field !== 'id') {
          currentForm[field].error = false;
        }
      } else {
        if (currentForm[field].value !== '' && currentForm[field].isRequired) {
          currentForm[field].error = false;
        }
      }
      Object.keys(addressForm).forEach((addressField) => {
        if (addressForm[addressField].value !== '' && addressForm[addressField].isRequired) {
          addressForm[addressField].error = false;
        }
      });
    });
  };

  const handleOnChange = (value: string | number | boolean, name: string) => {
    const updatePath = name.split('.');
    const path1 = updatePath[0];
    let pathReplaced: any;

    if (updatePath[1] === 'province') {
      if (isLegal) {
        pathReplaced = path1 + '_city_legal';
      } else {
        pathReplaced = path1 + '_city';
      }
      const params = {
        path: pathReplaced,
        province: value
      };
      getCityFetch(params);
      dataForm.person_data[path1].city.value = '';
      dataForm.person_data[path1].postal_code.value = '';
    }
    if (updatePath[1] === 'city') {
      if (isLegal) {
        pathReplaced = path1 + '_neighborhood_legal';
      } else {
        pathReplaced = path1 + '_neighborhood';
      }
      const params = {
        path: pathReplaced,
        province: dataForm?.person_data[path1]?.province?.value,
        city: value
      };
      dataForm.person_data[path1].postal_code.value = '';
      getNeighborhoodFetch(params);
    }

    let prevState = { ...dataForm };
    const firstLvl = prevState.person_data[updatePath[0]];
    const secondLvl = firstLvl[updatePath[1]];

    if (updatePath.length === 2) {
      prevState = {
        ...prevState,
        person_data: {
          ...prevState.person_data,
          [updatePath[0]]: { ...firstLvl, [updatePath[1]]: { ...secondLvl, value: value } }
        }
      };
    }
    if (updatePath.length === 3) {
      const thirdLvl = secondLvl[updatePath[2]];
      prevState = {
        ...prevState,
        person_data: {
          ...prevState.person_data,
          [updatePath[0]]: {
            ...firstLvl,
            [updatePath[1]]: { ...secondLvl, [updatePath[2]]: { ...thirdLvl, value: value } }
          }
        }
      };
    }
    if (updatePath.length === 4) {
      const thirdLvl = secondLvl[updatePath[2]];
      const fourthLvl = thirdLvl[updatePath[3]];
      prevState = {
        ...prevState,
        person_data: {
          ...prevState.person_data,
          [updatePath[0]]: {
            ...firstLvl,
            [updatePath[1]]: {
              ...secondLvl,
              [updatePath[2]]: { ...thirdLvl, [updatePath[3]]: { ...fourthLvl, value: value } }
            }
          }
        }
      };
    }
    setDataForm(prevState);
  };

  const handleOnChangeRuc = (e: any, path: string, secondPath?: string) => {
    const ruc = e.target.value;
    if (secondPath) {
      personDataForm[path][secondPath].id.error = !rucValidator(ruc);
    } else {
      personDataForm[path].id.error = !rucValidator(ruc);
    }
    handleOnChange(ruc, e.target.name);
  };

  const handleOnChangeEmail = (e: any, path: string) => {
    const email = e.target.value;
    personDataForm[path].mail.error = !emailValidator(email);
    handleOnChange(email, e.target.name);
  };

  return (
    <div className={props.className}>
      <StyledAccordion title='Tomador.' className='title' expanded={true}>
        <Grid container spacing={1} className='container'>
          <Grid item md={12}>
            <FormLabel className='radio-label'>Tipo de persona</FormLabel>
            <FormGroup>
              <RadioGroup
                name='typePerson'
                value={personDataForm.contractor.type.value}
                onChange={(event): void => handleOnChange(event.target.value, 'contractor.type')}
                row={true}
              >
                <FormControlLabel value='natural' control={<Radio />} label={'Física'} />
                <FormControlLabel value='legal' control={<Radio />} label={'Jurídica'} />
              </RadioGroup>
            </FormGroup>
          </Grid>
          {personDataForm.contractor.type.value === 'natural' ? (
            <FormNaturalPerson
              statePath={'contractor'}
              handleOnChange={handleOnChange}
              handleOnChangeRuc={handleOnChangeRuc}
              dataForm={personDataForm.contractor}
              nationalityList={nationalityList}
              provinceList={provinceList}
              cityList={contractorCityList}
              neighborhoodList={contractorNeighborhoodList}
              idTypeList={idTypeList}
              maritalStatusList={maritalStatusList}
              activityList={activityList}
              sourceFundList={sourceFundList}
            />
          ) : (
            <FormLegalPerson
              statePath={'contractor'}
              handleOnChange={handleOnChange}
              handleOnChangeRuc={handleOnChangeRuc}
              handleOnChangeEmail={handleOnChangeEmail}
              dataForm={personDataForm.contractor}
              idTypeList={idTypeList}
              activityList={activityList}
              provinceList={provinceList}
              cityList={contractorCityList}
              neighborhoodList={contractorNeighborhoodList}
              sourceFundList={sourceFundList}
            />
          )}
        </Grid>
      </StyledAccordion>
      <StyledAccordion title='Acreedor.'>
        <Grid container spacing={1} className='container'>
          <Grid item md={12}>
            <FormLabel className='radio-label'>¿Posee Acreedor?</FormLabel>
            <FormGroup>
              <RadioGroup
                name='hasCreditor'
                value={riskHasPledgee}
                onChange={(event): void => setRiskHasPledgee(event.target.value === 'true')}
                row={true}
              >
                <FormControlLabel value={false} control={<Radio />} label={'No'} />
                <FormControlLabel value={true} control={<Radio />} label={'Si'} />
              </RadioGroup>
            </FormGroup>
          </Grid>
          {riskHasPledgee && (
            <FormLegalPerson
              statePath={'creditor'}
              handleOnChange={handleOnChange}
              handleOnChangeRuc={handleOnChangeRuc}
              dataForm={personDataForm.creditor}
              idTypeList={idTypeList}
              activityList={activityList}
              provinceList={provinceList}
              cityList={creditorCityList}
              neighborhoodList={creditorNeighborhoodList}
            />
          )}
        </Grid>
      </StyledAccordion>
      <StyledAccordion title='Asegurado'>
        <Grid container spacing={1} className='container'>
          <Grid item md={12}>
            <FormLabel className='radio-label'>¿El asegurado es igual al tomador?</FormLabel>
            <FormGroup>
              <RadioGroup
                name='isContractorInsured'
                value={isContractorInsured}
                onChange={(event): void => setIsContractorInsured(event.target.value === 'true')}
                row={true}
              >
                <FormControlLabel value={true} control={<Radio />} label={'Si'} disabled={isLegal} />
                <FormControlLabel value={false} control={<Radio />} label={'No'} disabled={isLegal} />
              </RadioGroup>
            </FormGroup>
          </Grid>
          {!isContractorInsured && !isLegal && (
            <FormInsured
              statePath={'insured'}
              handleOnChange={handleOnChange}
              handleOnChangeRuc={handleOnChangeRuc}
              dataForm={personDataForm.insured}
              nationalityList={nationalityList}
              idTypeList={idTypeList}
              maritalStatusList={maritalStatusList}
              activityList={activityList}
              provinceList={provinceList}
              cityList={insuredCityList}
              neighborhoodList={insuredNeighborhoodList}
            />
          )}
        </Grid>
      </StyledAccordion>
    </div>
  );
};
export const PersonDataComponent = styled(({ ...props }) => <CleanPersonData {...props} />)`
  .container {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }
  .title {
    font-size: 20px;
  }
  .radio-label {
    font-size: 13px;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordion-root:before {
    opacity: 0;
  }
  .MuiAccordionSummary-root {
    border-radius: 15px;
    background-color: var(--color-grey-background);
    color: black;
    rect {
      fill: var(--color-main);
    }
    path {
      fill: var(--color-white);
    }
    &.Mui-expanded {
      color: white;
      background-color: var(--color-main);
      path {
        fill: var(--color-main);
      }
      rect {
        fill: var(--color-white);
      }
    }
  }
  .MuiTypography-body1 {
    font-weight: 600;
  }
`;
