import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  //MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FileInputComponent } from '../../../../../components';
//import { Autocomplete } from '@material-ui/lab';
// eslint-disable-next-line no-unused-vars
import { IInputter, IInput, IPreloadField, ISourceParams } from '../../models';

interface ICustomDataProp {
  className?: string;
  customDataForm: any;
  setDataForm(dataForm: any): void;
  dataForm: any;
  inputter: IInputter;
  listSources?: any;
  quotationInputs: any;
  getListSourceFetch(listParams: ISourceParams): void;
}

const CleanCustomData: React.FC<ICustomDataProp> = (props: ICustomDataProp) => {
  // eslint-disable-next-line no-unused-vars
  const { customDataForm, setDataForm, dataForm, inputter, listSources, quotationInputs, getListSourceFetch } = props;
  const inputs = inputter.input_groups && inputter.input_groups[0].inputs;
  const { preload_fields } = inputter;
  const objToDelete = 'country';
  const inputsWithoutCountry = inputs.filter((obj: any) => obj.name !== objToDelete);

  const handleOnChange = (value: any, field: string) => {
    const prevState = { ...dataForm };
    prevState.custom_data[field].value = value;
    setDataForm(prevState);
  };

  useEffect(() => {
    inputter.flow &&
      inputter.input_groups &&
      inputter.input_groups[0].inputs.forEach((input: IInput) => {
        // if (input.type === 'LIST' || input.type === 'AUTOCOMPLETE')
        if (input.source) getListSourceFetch({ url: input.source, field: input.name });
      });
  }, [inputter]);

  const handleOnError = (error: string, value: any) => {
    console.log(error, value);
  };

  // const renderMenuItems = (list: any[]) => {
  //   let items = [
  //     <MenuItem key={0} value={0}>
  //       {''}
  //     </MenuItem>
  //   ];
  //   if (list)
  //     items = list.map((item: any) => (
  //       <MenuItem key={item.code} value={item.code}>
  //         {item.description}
  //       </MenuItem>
  //     ));
  //   return items;
  // };

  const isHide = (field: IInput): boolean => {
    return !(
      quotationInputs[field.hide_by_preloaded_dependency.field].code === field.hide_by_preloaded_dependency.value
    );
  };

  const fieldComponent = (field: IInput) => {
    // eslint-disable-next-line no-unused-vars
    const { type, name, label, disabled_by_dependency } = field;
    const fieldState = customDataForm[name];

    //const dataList = listSources && listSources?.data[name];

    switch (type) {
      // case 'LIST':
      //   return (
      //     <TextField
      //       value={fieldState?.value.code as string}
      //       error={fieldState?.error}
      //       onChange={(event): void => handleOnChange(event.target, name)}
      //       select={type === 'LIST'}
      //       //disabled={(type === 'LIST' && (disabled_by_dependency || !dataList) ) as boolean}
      //       fullWidth
      //       required={field.is_required}
      //       placeholder={field.placeholder ? field.placeholder : ''}
      //     >
      //       {renderMenuItems(dataList)}
      //     </TextField>
      //   );
      case 'TEXT':
        return (
          <TextField
            inputProps={name === 'chassis' || name === 'engine_number' ? { maxLength: 17 } : { maxLength: 70 }}
            label={label}
            value={fieldState?.value.description as string}
            error={fieldState?.error}
            onChange={(event): void => handleOnChange({ description: event.target.value }, name)}
            //disabled={disabled_by_dependency || !dataList}
            fullWidth
            required={field.is_required}
            placeholder={field.placeholder ? field.placeholder : ''}
          ></TextField>
        );
      case 'SWITCH':
        return (
          <Grid container alignItems='center'>
            <Switch
              checked={fieldState?.value?.description === 'true'}
              onChange={(): void =>
                handleOnChange(
                  fieldState?.value.description === 'true'
                    ? { code: 'false', description: 'false' }
                    : { code: 'true', description: 'true' },

                  name
                )
              }
              color='primary'
            />
            <Typography>{fieldState?.value?.description === 'true' ? field.text_true : field.text_false}</Typography>
          </Grid>
        );
      // case 'AUTOCOMPLETE':
      //   return (
      //     <Autocomplete
      //       //disabled={disabled_by_dependency || !dataList}
      //       value={fieldState?.value}
      //       autoComplete={true}
      //       autoHighlight={true}
      //       options={dataList ? dataList : []}
      //       noOptionsText={'Sin coincidencias'}
      //       getOptionLabel={(option: any) => option.description && `${option.description}`}
      //       renderInput={(params) => (
      //         <TextField
      //           {...params}
      //           error={fieldState?.error}
      //           label={label}
      //           variant='standard'
      //           required={field.is_required}
      //         />
      //       )}
      //       onChange={(e, value) => handleOnChange(value, name)}
      //     ></Autocomplete>
      //   );
      case 'NUMBER':
        return (
          <TextField
            label={label}
            value={fieldState?.value?.description as string}
            type='number'
            error={fieldState?.error}
            //InputProps={name === 'chassis' ? { max: '10' } : { max: '10' }}
            onChange={(event): void => handleOnChange({ description: event.target.value }, name)}
            //disabled={disabled_by_dependency}
            fullWidth
            required={field.is_required}
            placeholder={field.placeholder ? field.placeholder : ''}
          ></TextField>
        );
      case 'RADIO':
        return (
          <>
            <FormLabel className='radio-label'>{label}</FormLabel>
            <FormGroup>
              <RadioGroup
                name={name}
                value={fieldState?.value.code as string}
                onChange={(event): void =>
                  handleOnChange({ code: event.target.value, description: event.target.value }, name)
                }
                row={true}
              >
                {field?.radio_options?.map((opt, i) => {
                  return <FormControlLabel key={i} value={opt.value} control={<Radio />} label={opt.label} />;
                })}
              </RadioGroup>
            </FormGroup>
          </>
        );
      case 'FILE':
        if (isHide(field))
          return (
            <>
              <Typography>
                {label}
                <span className='main-color'>*</span>
              </Typography>
              <FileInputComponent
                file={customDataForm.certificate.value}
                onChange={(value: object) => handleOnChange(value, 'certificate')}
                onError={handleOnError}
                acceptFormats='.pdf,.png,.jpg'
              />
            </>
          );
    }
  };

  const showPreloadField = (field: IPreloadField) => {
    if (field.type === 'SWITCH') return quotationInputs[field.field].description === 'true' ? 'Si' : 'No';
    else return quotationInputs[field.field].description;
  };

  return (
    <div className={props.className}>
      <Grid container spacing={1} className='preload-fields'>
        {preload_fields?.map((quotationField: IPreloadField, i) => {
          return (
            <React.Fragment key={i}>
              <Grid item md={4}>
                <Typography>{quotationField.label}</Typography>
                {quotationInputs && showPreloadField(quotationField)}
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      <Grid container spacing={2}>
        {Object.keys(customDataForm).length > 0 &&
          inputsWithoutCountry.map((inp: IInput, i: number) => {
            return (
              <React.Fragment key={i}>
                <Grid item md={4}>
                  {customDataForm && fieldComponent(inp)}
                </Grid>
              </React.Fragment>
            );
          })}
      </Grid>
    </div>
  );
};
export const CustomDataComponent = styled(({ ...props }) => <CleanCustomData {...props} />)`
  .MuiGrid-item {
    padding-top: 1em;
  }
  .preload-fields {
    padding-bottom: 1em;
  }
`;
