import MOCK_PAYMENT_METHOD from '../../../../assets/mocks/Services/paymentMethods';
import MOCK_SALE_CHANNELS from '../../../../assets/mocks/Services/saleChannels';

const ApiMock = () => {
  // eslint-disable-next-line no-unused-vars
  const getPaymentMethodsFetch = (): Promise<unknown> =>
    new Promise((res) => {
      res(MOCK_PAYMENT_METHOD);
    }).then((res) => res);
  // eslint-disable-next-line no-unused-vars
  const getSaleChannelsFetch = (): Promise<unknown> =>
    new Promise((res) => {
      res(MOCK_SALE_CHANNELS);
    }).then((res) => res);

  return { getPaymentMethodsFetch, getSaleChannelsFetch };
};

export default ApiMock;
