import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';

import { Grid } from '@material-ui/core';
import { CustomInfiniteScroll, TextPoint, IconButton, Modal, StyledButton } from '@wdynamo/common';
import { ReactComponent as ModalEditIcon } from '../../../../../assets/img/icons_dynamo/icon_edit_modal.svg';
import { ReactComponent as AddIcon } from '../../../../../assets/img/icons_dynamo/icon_add.svg';
import { ReactComponent as TrashIcon } from '../../../../../assets/img/icons_dynamo/icon_trash.svg';
import { ReactComponent as BackIcon } from '../../../../../assets/img/icons_dynamo/icon_back.svg';
import CancelIllustration from '../../../../../assets/img/illustrations/CancelIllustration.jpg';

import { Analytics } from '@wdynamo/common/lib/services';
import { uris } from '../../../../../siteMap';
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
import { IRole, IRolesStore } from './models';

interface IRolesScrollProps {
  rolesStore: IRolesStore;
  getRolesFetch(): void;
  className?: string;
  deleteRoleFetch(id: string): void;
}

const CleanRolesListComponent: React.FC<IRolesScrollProps> = (props: IRolesScrollProps) => {
  const analytics = Analytics();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const { rolesStore } = props;
  const history = useHistory();
  const [openConfirmModal, setOpenConfirmModal] = React.useState<boolean>(false);
  const [selectedModal, setSelectedModal] = React.useState<string>();

  const StyledGridItem = styled(Grid)`
    padding: 10px 0;
    border-bottom: 1px solid var(--color-grey-border);
  `;

  const StyledQuestionMark = styled('text')`
    color: var(--color-main);
  `;

  const StyledHeader = styled(Grid)`
    border: none;
    font-weight: bold;
    text-align: left;
    div {
      padding: 5px;
    }
  `;

  useEffect(() => {
    analytics.pageview('display-roles');
  }, []);

  React.useEffect(() => {
    if (keycloak?.authenticated) {
      setTimeout(() => props.getRolesFetch());
    }
  }, [keycloak?.authenticated]);

  const toggleConfirmModal = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const renderModal = (): React.ReactNode => {
    return (
      <Modal
        open={openConfirmModal}
        handleClose={() => {
          setOpenConfirmModal(false);
        }}
        className='padding'
        title={
          <Grid direction='row' justify={'center'} container>
            <img src={CancelIllustration} />
            <Grid item>
              <h2>
                <StyledQuestionMark>¿</StyledQuestionMark>
                {t('USERS.ROLES.LIST.DELETE_CONFIRMATION')}
                <StyledQuestionMark>?</StyledQuestionMark>
              </h2>
              <p style={{ textAlign: 'center' }}>{t('USERS.ROLES.LIST.NO_RECOVERY')}</p>
            </Grid>
          </Grid>
        }
        body={
          <>
            {selectedModal && (
              <Grid direction='row' justify={'center'} container spacing={2}>
                <Grid item>
                  <StyledButton
                    className='secondary'
                    onClick={() => {
                      props.deleteRoleFetch(selectedModal!);
                      toggleConfirmModal();
                      props.getRolesFetch();
                    }}
                  >
                    {t('USERS.ROLES.LIST.DELETE')}
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    onClick={() => {
                      toggleConfirmModal();
                    }}
                    className='default'
                  >
                    {t('COMMONS.MODALS.CANCEL.CONTINUE')}
                  </StyledButton>
                </Grid>
              </Grid>
            )}
          </>
        }
      />
    );
  };

  const renderRolesTable = (): React.ReactNode => {
    const { roles } = rolesStore;
    return (
      <>
        <Grid container>
          {renderModal()}
          <Grid xs={9}>
            <h1>
              {t('USERS.ROLES.LIST.TITLE')}
              <TextPoint />
            </h1>
          </Grid>
          <Grid item xs={3}>
            <div style={{ textAlign: 'right' }}>
              <IconButton
                onClick={() => {
                  history.push(uris.roleInfo.uri);
                }}
                Icon={AddIcon}
              />
            </div>
          </Grid>
        </Grid>
        <Grid>
          <div
            onClick={() => {
              history.push(uris.users.uri);
            }}
          >
            <IconButton Icon={BackIcon} />
            {t('COMMONS.BUTTONS.GOBACK')}
          </div>
        </Grid>
        <Grid container className={'roles-grid'}>
          <StyledHeader item xs={12} className='header'>
            <Grid container>
              <Grid item xs={3}>
                <div style={{ textAlign: 'left' }}>{t('USERS.ROLES.LIST.DENOMINATION')}</div>
              </Grid>
              <Grid item xs={3}>
                {t('USERS.ROLES.LIST.DESCRIPTION')}
              </Grid>
              <Grid item xs={3}>
                {t('USERS.ROLES.LIST.PERMISSIONS')}
              </Grid>
              <Grid item xs={3}>
                <div className='actions'>{t('USERS.ROLES.LIST.ACTIONS')}</div>
              </Grid>
            </Grid>
          </StyledHeader>

          <Grid item xs={12} className='content'>
            <CustomInfiniteScroll paginate={() => {}} firstLoading={false}>
              {roles &&
                roles?.map((role: IRole) => (
                  <StyledGridItem container key={role.id}>
                    <Grid item xs={3}>
                      {role.name}
                    </Grid>
                    <Grid item xs={3}>
                      {role.description}
                    </Grid>
                    <Grid item xs={3}>
                      {role.permissions &&
                        role.permissions?.map((permission: any, key: any) => (
                          <p key={key}>-{t(`USERS.PERMISSIONS.${permission.toUpperCase()}`)}</p>
                        ))}
                    </Grid>
                    <Grid item xs={3}>
                      <div className='actions'>
                        <IconButton
                          onClick={() => {
                            history.push(uris.roleInfo.uri, { role });
                          }}
                          Icon={ModalEditIcon}
                        />
                        <IconButton
                          onClick={() => {
                            setSelectedModal(role.id);
                            toggleConfirmModal();
                          }}
                          Icon={TrashIcon}
                        />
                      </div>
                    </Grid>
                  </StyledGridItem>
                ))}
            </CustomInfiniteScroll>
          </Grid>
        </Grid>
      </>
    );
  };

  return <div className={props.className || 'roles-list'}>{renderRolesTable()}</div>;
};

export const RolesListComponent = styled(({ ...props }) => <CleanRolesListComponent {...props} />)`
  .actions {
    text-align: right;
  }
`;
