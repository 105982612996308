import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import { ICustomDataForm } from '../../AutoQuotation/models';

import { StyledButton } from '@wdynamo/common';
import { Grid, Box } from '@material-ui/core';

interface INavButtonsProp {
  className?: string;
  dataForm: ICustomDataForm;
  onChange(data: ICustomDataForm): void;
  createPersonFetch(data: any): void;
  nextStep(param?: any): void;
  prevStep(): void;
  cancel(): void;
  isNextButtonDisabled?: boolean;
  isNextButtonHide?: boolean;
  isFirstStep?: boolean;
  currentContact?: any;
}

const CleanNavButtonsComponent: React.FC<INavButtonsProp> = (props: INavButtonsProp) => {
  const { t } = useTranslation();
  const { prevStep, nextStep, className, isNextButtonHide, isFirstStep, isNextButtonDisabled, currentContact } = props;

  return (
    <Box paddingTop={8} className={`${className}`}>
      <Grid container justifyContent='space-between'>
        <Grid item>
          <StyledButton onClick={() => prevStep()} className='secondary '>
            {isFirstStep ? t('COMMONS.BUTTONS.CANCEL') : t('COMMONS.BUTTONS.GOBACK')}
          </StyledButton>
        </Grid>
        {!isNextButtonHide && (
          <Grid item>
            <StyledButton
              onClick={() => {
                return nextStep(currentContact ? currentContact : null);
              }}
              className='default next-button'
              disabled={isNextButtonDisabled}
            >
              {t('COMMONS.BUTTONS.CONTINUE')}
            </StyledButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export const NavButtonsComponent = styled(({ ...props }) => <CleanNavButtonsComponent {...props} />)`
  .next-button {
    padding: 10px 120px;
    font-size: 20px;
  }
`;
