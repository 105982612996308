import axios from 'axios';

const Api = () => {
  const getContactsFetch = (params: any): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers`, { params });

  return {
    getContactsFetch
  };
};

export default Api;
