import { call, put, takeLatest, all } from 'redux-saga/effects';
import { contactDetailsFail, contactDetailsSuccess, changeStatusSuccess, changeStatusFail } from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../ActionTypes';
import Api from './Api';
//import ApiMock from './ApiMock';

const api = Api();

// eslint-disable-next-line no-unused-vars
function* sagasContactDetailsFetch(action: actionType) {
  try {
    const response = yield call(api.getContactDetailsFetch, action.payload.contactId);

    yield put(contactDetailsSuccess(response.data.data.items.items));
  } catch (e) {
    yield put(contactDetailsFail(e));
  }
}

// eslint-disable-next-line no-unused-vars
function* sagasChangeStatusFetch(action: any) {
  try {
    // console.log('action: ' + JSON.stringify('action.payload.params ' + JSON.stringify(action)));
    const response = yield call(api.patchChangeStatus, action.payload.params);

    yield put(changeStatusSuccess(response.data));
  } catch (e) {
    yield put(changeStatusFail(e));
  }
}

function* contactDetailsSaga() {
  yield all([takeLatest(ACTIONS.CONTACTS.CONTACT_DETAILS.FETCH, sagasContactDetailsFetch)]);
  yield all([takeLatest(ACTIONS.CONTACTS.CHANGE_STATUS.FETCH, sagasChangeStatusFetch)]);
}

export { contactDetailsSaga };
