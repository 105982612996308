import { connect } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../../store';
import { HomeQuotationsComponent } from './view';
import {
  getHomeStepsFetch,
  getSourceHomeListFetch,
  getDataHomeSourceFetch,
  createContactFetch,
  getHomePlansFetch,
  setItemsToStore,
  updateQuotationFetch,
  cleanStore
} from './actions';

const mapStateToProps = (state: RootState) => ({
  stepsHomeStore: state.quotationHomeState
});
const mapDispatchToProps = {
  getHomeStepsFetch,
  getSourceHomeListFetch,
  getDataHomeSourceFetch,
  setItemsToStore,
  createContactFetch,
  getHomePlansFetch,
  updateQuotationFetch,
  cleanStore
};

export { quotationsHomeSaga } from './sagas';
export { quotationHomeStepsReducer } from './reducers';

export const HomeQuotations = connect(mapStateToProps, mapDispatchToProps)(HomeQuotationsComponent);
export type { IBaseHomeStore } from './model';
