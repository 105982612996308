import { connect } from 'react-redux';
import { QuoteManagementComponent } from './view';
import { getQouteManagementInfoFetch, getAllItemsFetch, getClearAllItemsFetch, getProductsFetch } from './actions';
//eslint-disable-next-line no-unused-vars
import { getQuotationByIdFetch, getQuotationByIdFetchClean, cleanStore } from '../Services/Emissions/actions';
import {
  getPaymentMethodFetch,
  getAccessoryFetch,
  getProducerFetch
} from '../Services/Quotations/components/AutoQuotation/actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  quoteManagementStore: state.quoteManagementState,
  quoteManagementeAllStore: state.quoteManagementeAllState,
  stepsStore: {
    ...state.emissionState,
    billingMode: state.quotationState.billingMode,
    paymentMethod: state.quotationState.paymentMethod,
    paymentCondition: state.quotationState.paymentCondition,
    accesory: state.quotationState.accesory,
    producerList: state.quotationState.producer
  }
});
const mapDispatchToProps = {
  getQouteManagementInfoFetch,
  getAllItemsFetch,
  getClearAllItemsFetch,
  getQuotationByIdFetch,
  getQuotationByIdFetchClean,
  getProductsFetch,
  cleanStore,
  getPaymentMethodFetch,
  getAccessoryFetch,
  getProducerFetch
};
export const QuoteManagement = connect(mapStateToProps, mapDispatchToProps)(QuoteManagementComponent);
