import React from 'react';
import { Popper, StyledButton, DatePicker, substractTime, Input } from '@wdynamo/common';
import moment from 'moment';
import { Grid, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface ContactsFilterPopperProps {
  onClick?: any;
  actionTitle: string;
  anchorEl: any;
  toggleOpen: any;
  dateFrom?: string;
  dateTo?: string;
  salesType: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    popper: { maxWidth: '20em', textAlign: 'left' },
    fullWidth: { width: '100%' },
    active: {
      backgroundColor: (props: any) => props.mainColor,
      color: 'white',
      border: 'none',
      '&:hover': {
        backgroundColor: (props: any) => props.mainColor,
        color: 'white'
      }
    },
    cancel: {
      backgroundColor: 'white',
      color: '#666',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    }
  })
);

export const ContactsFilterPopper: React.FC<ContactsFilterPopperProps> = (props: ContactsFilterPopperProps) => {
  const mainColor = document.body.style.getPropertyValue('--color-main') || '#ff9800';
  const classes = useStyles({ mainColor });
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = React.useState();
  const [dateFrom, setDateFrom] = React.useState<string>(
    props.dateFrom ||
      moment(substractTime(moment().toString(), 'months', 6)).format(process.env.REACT_APP_DATE_FORMAT).toString()
  );
  const [dateTo, setDateTo] = React.useState<string>(
    props.dateTo || moment().format(process.env.REACT_APP_DATE_FORMAT).toString()
  );
  const { anchorEl } = props;

  const handleCancel = (): void => {
    props.toggleOpen();
  };
  return (
    <Popper anchorEl={anchorEl}>
      <Grid container spacing={1} className={classes.popper}>
        <Grid item xs={12}>
          <Input
            label={'Sales'}
            defaultValue={selectedType}
            onChange={(e: any) => setSelectedType(e)}
            testId='Sales'
            select={true}
          >
            <MenuItem value={''}>
              <em>{t('COMMONS.FILTER.SELECT')}</em>
            </MenuItem>
            {props.salesType?.map((item: any, i: number) => (
              <MenuItem key={i} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Input>
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label={t('REPORTS.EXPORT.DATE_FROM')}
            value={dateFrom}
            onChange={(value: string): void => setDateFrom(value)}
            testId='dateFrom'
            format={process.env.REACT_APP_DATE_FORMAT!}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label={t('REPORTS.EXPORT.DATE_TO')}
            value={dateTo}
            onChange={(value: string): void => setDateTo(value)}
            testId='dateTo'
            format={process.env.REACT_APP_DATE_FORMAT!}
          />
        </Grid>

        <Grid item xs={12}>
          <StyledButton
            className={classes.fullWidth + ' default'}
            onClick={() => {
              //   props.onClick(dateFrom, dateTo);
              console.log(dateTo, dateFrom, selectedType);
            }}
          >
            {props.actionTitle}
          </StyledButton>
        </Grid>
        <Grid item xs={12}>
          <StyledButton className={`secondary ${classes.fullWidth} ${classes.cancel}`} onClick={handleCancel}>
            {t('COMMONS.BUTTONS.CANCEL')}
          </StyledButton>
        </Grid>
      </Grid>
    </Popper>
  );
};
