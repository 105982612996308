import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography, Box, TextField, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { NavButtons } from '../../common/NavButtons';
import emailValidator from '../../common/emailValidator';

interface IGeneralDataProp {
  className?: string;
  getSourceHomeListFetch(listParams: any): void;
  getDataHomeSourceFetch(dataParams: any): void;
  data: any;
  listSources: any;
  stepsHomeStore: any;
  dataForm: any;
  onChange(data: any): void;
  currentContact: any;
  setCurrentContact(contact: any | undefined): void;
  createPersonFetch(data: any): void;
  getContactsFetch(params: any): void;
  getCityFetch(params: any): void;
  getContactsClear(): void;
  // setItemsToStore(data: any): void;
  contactsStore: any;
}

const CleanGeneralDataComponent: React.FC<IGeneralDataProp> = (props: IGeneralDataProp) => {
  const {
    data,
    dataForm,
    onChange,
    getSourceHomeListFetch,
    // eslint-disable-next-line no-unused-vars
    getDataHomeSourceFetch,
    getContactsFetch,
    getCityFetch,
    getContactsClear,
    // setItemsToStore,
    currentContact,
    setCurrentContact,
    listSources,
    contactsStore,
    stepsHomeStore
  } = props;
  const listCity = stepsHomeStore?.cities?.data ? stepsHomeStore.cities.data : [];
  const dataInputs = data?.input_groups ? data.input_groups : [];
  // eslint-disable-next-line no-unused-vars
  const [listsSource, setListsSource] = useState<any>({});
  const [showForm, setShowForm] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [trigger, setTrigger] = useState<any>({});
  const [listContacts, setListContacts] = useState<any>([]);

  useEffect(() => {
    if (currentContact?.id) {
      handleOnInputChange(currentContact?.phone, 'telephone');
      handleOnInputChange(currentContact?.mail, 'mail');
    }
  }, []);

  useEffect(() => {
    setListContacts(
      Array.isArray(contactsStore?.contacts?.data)
        ? contactsStore?.contacts?.data
        : contactsStore?.contacts?.data?.items?.items
    );
  }, [contactsStore.contacts.data]);

  const getCities = () => {
    const province_code = dataForm?.general_data?.province?.value?.code;
    getCityFetch({
      postalCode: '',
      subPostalCode: '',
      townCode: '',
      provinceCode: province_code
    });
  };

  useEffect(() => {
    setShowForm(true);
    let state: any = {};
    const objTrigger: any = {};
    data?.input_groups &&
      data.input_groups.map((group: any) => {
        const firstGroup = group.inputs.slice(0, 2);
        firstGroup.map((input: any) => {
          if (input.type === 'AUTOCOMPLETE') {
            if ((input.source && input.name === 'home_type') || input.name === 'province') {
              getSourceHomeListFetch({ url: input.source, field: input.name });
            }
            state = {
              ...state,
              [group.name]: { ...state[group.name], [input.name]: { url: input.source } }
            };
          } else if (input.load_dependency && input.load_after_action === 'onchange') {
            objTrigger[input.load_dependency] = {
              url: input.source,
              fieldOwner: input.name,
              type: 'string'
            };
          }
        });
      });
    setTrigger(objTrigger);
    setListsSource(state);
  }, [data.input_groups]);

  const handleOnChange = (value: any, groupName: any) => {
    const prevState = { ...dataForm };
    const currentField = prevState.general_data[groupName];
    currentField.value = value;
    onChange(prevState);
    if (groupName === 'province' && dataForm?.general_data?.province?.value?.code) {
      getCities();
    }
  };

  const handleOnInputChange = (value: any, field: string) => {
    const prevState = { ...dataForm };
    const currentField = prevState.general_data[field];
    currentField.value = value;
    if (field === 'mail') {
      currentField.error = !emailValidator(value);
    }
    onChange(prevState);
  };

  const dataIsInList = (key: string, value: string): any[] => {
    return listContacts.filter((contact: any) => contact[key] === value);
  };

  const handleOnInputChangePhone = (value: any) => {
    handleOnInputChange(value, 'telephone');
    if (value.length === 4) {
      getContactsFetch({ search: value });
    } else if (dataIsInList('phone', value).length) {
      setCurrentContact(dataIsInList('phone', value)[0]);
      handleOnInputChange(dataIsInList('phone', value)[0].mail, 'mail');
    } else if (value !== '') {
      setCurrentContact(undefined);
    } else if (value.length === 10) {
      getContactsClear();
    }
  };

  const handleOnChangePhone = (value: any) => {
    setCurrentContact(value);
    if (value === null) {
      handleOnInputChange('', 'email');
      getContactsClear();
    }
  };

  const handleOnInputChangeEmail = (value: any) => {
    handleOnInputChange(value, 'mail');
    if (dataForm?.general_data?.telephone?.value === '') {
      if (value.length === 4) {
        getContactsFetch({ search: value });
      } else if (dataIsInList('mail', value).length) {
        setCurrentContact(dataIsInList('mail', value)[0]);
        handleOnInputChange(dataIsInList('mail', value)[0].phone, 'telephone');
      } else {
        if (value !== '') {
          setCurrentContact(undefined);
          handleOnInputChange('', 'telephone');
        }
      }
    }
  };

  const handleOnChangeEmail = (value: any) => {
    setCurrentContact(value);
  };

  const fieldComponent = (groupName: string, inputGroup: any, fieldState: any) => {
    const { name, type, disabled_dependency } = inputGroup;
    const groupState = dataForm.general_data[groupName];
    const disabled_by_dependency =
      disabled_dependency !== undefined && groupState[disabled_dependency].value?.description !== undefined;

    const dataList = listSources && listSources?.data[name];

    if (type === 'AUTOCOMPLETE') {
      return (
        <Autocomplete
          disabled={disabled_by_dependency || !dataList}
          inputValue={(groupState.value?.description as string) || ''}
          value={fieldState.value}
          autoComplete={true}
          autoHighlight={true}
          options={dataList ? dataList : []}
          noOptionsText={'Sin coincidencias'}
          getOptionLabel={(option: any) => `${option.description}`}
          renderInput={(params) => <TextField {...params} variant='standard' placeholder={inputGroup.placeholder} />}
          onChange={(e, value) => handleOnChange(value, groupName)}
        ></Autocomplete>
      );
    }
  };

  const dynamicForm = () => {
    return dataInputs.map((dataInput: any, i: number) => {
      const inputGroups = dataInput.inputs.slice(0, 2);
      const groupState = dataForm?.general_data;
      return (
        <Grid container spacing={3} key={i}>
          {inputGroups.map((inputGroup: any, index: number) => {
            const fieldState = groupState[inputGroup.name];
            return (
              <Grid item xs={6} key={index} className='py'>
                <Typography>
                  {inputGroup.label}
                  <span className='colored-mark'>*</span>
                </Typography>
                {fieldComponent(inputGroup.name, inputGroup, fieldState)}
              </Grid>
            );
          })}
        </Grid>
      );
    });
  };

  const handleForm = () => {
    const cityState = dataForm?.general_data.city;
    return (
      <Grid container spacing={3}>
        <Grid item xs={4} className='py'>
          <Typography>
            Localidad<span className='colored-mark'>*</span>
          </Typography>
          <Autocomplete
            inputValue={dataForm?.general_data?.city?.value?.description || ''}
            value={cityState.value}
            autoComplete={true}
            autoHighlight={true}
            clearOnBlur={false}
            blurOnSelect={false}
            getOptionLabel={(option: any) => `${option.description}`}
            onChange={(e, value) => handleOnChange(value, 'city')}
            renderInput={(params) => <TextField {...params} variant='standard' placeholder={'Seleccionar'} />}
            options={listCity.length ? listCity : []}
            noOptionsText={'Sin coincidencias'}
          ></Autocomplete>
        </Grid>
        <Grid item xs={4} className='py'>
          <Typography>
            Teléfono<span className='colored-mark'>*</span>
          </Typography>
          <Autocomplete
            inputValue={dataForm?.general_data?.telephone?.value || ''}
            clearOnBlur={false}
            blurOnSelect={false}
            getOptionLabel={(option: any) => `${option.phone}`}
            onChange={(e, value) => handleOnChangePhone(value)}
            onInputChange={(e, value) => handleOnInputChangePhone(value)}
            renderInput={(params) => <TextField {...params} variant='standard' placeholder={'Ingresar'} />}
            options={listContacts}
            noOptionsText={'Sin coincidencias'}
          ></Autocomplete>
        </Grid>
        <Grid item xs={4} className='py'>
          <Typography>
            Correo electrónico <span className='colored-mark'>*</span>
          </Typography>
          <Autocomplete
            inputValue={dataForm?.general_data?.mail?.value || ''}
            clearOnBlur={false}
            blurOnSelect={false}
            getOptionLabel={(option: any) => `${option.mail}`}
            onChange={(e, value) => handleOnChangeEmail(value)}
            onInputChange={(e, value) => handleOnInputChangeEmail(value)}
            renderInput={(params) => <TextField {...params} variant='standard' placeholder={'Ingresar'} />}
            options={listContacts}
            noOptionsText={'Sin coincidencias'}
          ></Autocomplete>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box className={props.className}>
      {showForm && dynamicForm()}
      {handleForm()}
      <NavButtons {...props} />
    </Box>
  );
};

export const GeneralDataComponent = styled(({ ...props }) => <CleanGeneralDataComponent {...props} />)`
  .py {
    padding-top: 2em;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
