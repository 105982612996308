import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Analytics, TextPoint } from '@wdynamo/common';
// eslint-disable-next-line no-unused-vars
import { IMySalesProps } from './models';
import { OrdersScroll } from './components/ordersScroll';
import { Grid } from '@material-ui/core';
import { SalesTab } from './components/CustomTabs';
import { GroupSales } from './components/Tabs/GroupSales';
interface MySalesComponentProps {
  [key: string]: any;
  mySalesStore: IMySalesProps;
  getMySalesFetch(): void;
  getMySalesNextPageFetch(nextPage: string): void;
  className?: string;
  getGroupsFetch(): void;
}

const CleanMySalesComponent: React.FC<MySalesComponentProps> = (props: MySalesComponentProps) => {
  const { keycloak } = useKeycloak();
  const { mySalesStore, getMySalesFetch } = props;
  const { t } = useTranslation();
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    Analytics().pageview('my-sales');
  }, []);

  useEffect(() => {
    if (keycloak?.authenticated)
      setTimeout(() => {
        getMySalesFetch();
        props.getGroupsFetch();
      });
  }, [keycloak?.authenticated]);

  useEffect(() => {
    setFirstLoading(false);
  }, [mySalesStore]);

  const initialTabsHeaders = [
    {
      index: 0,
      label: t('MY_SALES.TABS.MY_SALES')
    },
    {
      index: 1,
      label: t('MY_SALES.TABS.GROUP_SALES')
    }
  ];
  // eslint-disable-next-line no-unused-vars
  const [tabsHeaders, setTabsHeaders] = React.useState(initialTabsHeaders);
  const tabsPanels = [
    {
      index: 0,
      content: (
        <OrdersScroll
          ordersData={mySalesStore.table}
          firstLoading={firstLoading}
          paginate={props.getMySalesNextPageFetch}
        />
      )
    },
    {
      index: 1,
      content: <GroupSales mySalesStore={mySalesStore} />
    }
  ];

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div className={`${props.className} my-sales`}>
      <Grid container>
        <Grid item xs={6}>
          <h1 className='title'>
            {t('MY_SALES.TITLE')}
            <TextPoint />
          </h1>
        </Grid>
      </Grid>
      <div className='tabs'>
        <SalesTab tabHeaders={tabsHeaders} tabPanels={tabsPanels} value={tabValue} handleChange={handleTabChange} />
      </div>
    </div>
  );
};

export const MySalesComponent = styled(({ ...props }) => <CleanMySalesComponent {...props} />)`
  .title {
    font-weight: regular;
  }
`;
