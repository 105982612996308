import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
  getPermissionsFail,
  getPermissionsSuccess,
  roleInfoAddFail,
  roleInfoAddSuccess,
  roleInfoUpdateFail,
  roleInfoUpdateSuccess
} from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../../ActionTypes';
import Api from './Api';
import ApiMock from './ApiMock';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasGetPermissionsFetch() {
  try {
    const response = yield call(api.getPermissionsFetch);
    yield put(getPermissionsSuccess(response.data.data.permissions));
  } catch (e) {
    yield put(getPermissionsFail(e.response.data));
  }
}

function* sagasUserInfoAddFetch(action: actionType) {
  try {
    yield call(api.addRoleFetch, action.payload.data);
    yield put(roleInfoAddSuccess());
  } catch (e) {
    yield put(roleInfoAddFail(e.response.data));
  }
}

function* sagasUserInfoUpdateFetch(action: actionType) {
  try {
    yield call(api.updateRoleFetch, action.payload.id, action.payload.roleInfoForm);
    yield put(roleInfoUpdateSuccess());
  } catch (e) {
    yield put(roleInfoUpdateFail(e.response.data));
  }
}

function* roleInfoSaga() {
  yield all([takeLatest(ACTIONS.USERS.ROLE_INFO.GET_PERMISSIONS.FETCH, sagasGetPermissionsFetch)]);
  yield all([takeLatest(ACTIONS.USERS.ROLE_INFO.ADD.FETCH, sagasUserInfoAddFetch)]);
  yield all([takeLatest(ACTIONS.USERS.ROLE_INFO.UPDATE.FETCH, sagasUserInfoUpdateFetch)]);
}

export { roleInfoSaga };
