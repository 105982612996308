import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { Box, Grid, Typography, Container } from '@material-ui/core';
import { StyledButton } from '@wdynamo/common';
import { ReactComponent as CartIcon } from '../../../../../../assets/img/icons_dynamo/icon_cart.svg';
import { ReactComponent as RestartIcon } from '../../../../../../assets/img/icons_dynamo/icon_restart.svg';
import { ReactComponent as RightArrowIcon } from '../../../../../../assets/img/icons_dynamo/icon_right_arrow.svg';
import { BlueBoxLabel } from '../../common/BlueBoxLabel/BlueBoxLabel';
import { Analytics } from '@wdynamo/common/lib/services';
import handleCharacteristics from '../../common/handleCharacteristics';

interface IHomeSummaryProps {
  className?: string;
  setCurrentStep(step: number): void;
  stepsHomeStore: any;
  dataForm: any;
  cleanState(): void;
  formatPrice(number: number): string;
}

const CleanHomeSummaryComponent: React.FC<IHomeSummaryProps> = (props: IHomeSummaryProps) => {
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const { setCurrentStep, stepsHomeStore, dataForm, cleanState, formatPrice } = props;
  // eslint-disable-next-line no-unused-vars
  const quoteHomeData = stepsHomeStore?.plans?.data?.quote ? stepsHomeStore.plans.data.quote : '';

  useEffect(() => {
    Analytics().pageview('home-budget | summary');
  }, []);

  const handleRestart = () => {
    cleanState();
    setCurrentStep(0);
  };

  const handleHideShowElements = (className: string, isToHide: Boolean) => {
    const itemsToHide = document.getElementsByClassName(className);
    if (isToHide) {
      for (let i = 0; i < itemsToHide.length; i++) {
        const element = itemsToHide[i];
        element.setAttribute('style', 'display:none');
      }
    } else {
      for (let i = 0; i < itemsToHide.length; i++) {
        const element = itemsToHide[i];
        element.setAttribute('style', 'display:block');
      }
    }
  };

  const handleDownloadPdf = () => {
    handleHideShowElements('hide-pdf', true);
    const canvasLogo = html2canvas(document.getElementById('sidebar-logo')!);
    const canvasBody = html2canvas(document.getElementById('printPdf')!);
    Promise.all([canvasLogo, canvasBody]).then((canvas) => {
      const logo = canvas[0].toDataURL('image/png');
      const body = canvas[1].toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(logo, 'PNG', 120, 10, 57, 18);
      pdf.addImage(body, 'PNG', 10, 10, 200, 110);
      pdf.save(t('Plan_Cotizacion'));
      handleHideShowElements('hide-pdf', false);
    });
  };

  const showInfoCombo = () => {
    if (!showInfo) {
      setShowInfo(true);
    } else {
      setShowInfo(false);
    }
  };

  const navButtons: React.ReactNode = (
    <Grid container spacing={5} className='mt'>
      <Grid item md={3} className='button-container'>
        <StyledButton onClick={() => handleRestart()} className='secondary restart-button'>
          <RestartIcon />
          {t('QUOTATIONS.STEP_5.BUTTONS.RESTART')}
        </StyledButton>
      </Grid>
      <Grid item md={3} className='button-container'>
        <StyledButton onClick={handleDownloadPdf} className='secondary download-button'>
          {t('QUOTATIONS.STEP_5.BUTTONS.DOWNLOAD')}
        </StyledButton>
      </Grid>
      <Grid item md={3} className='button-container'>
        <StyledButton onClick={() => {}} className='default '>
          {t('QUOTATIONS.STEP_5.BUTTONS.SEND')}
        </StyledButton>
      </Grid>
      <Grid item md={3} className='button-container'>
        <StyledButton className='secondary '>
          <CartIcon />
          {t('QUOTATIONS.STEP_5.BUTTONS.REQUEST')}
        </StyledButton>
      </Grid>
    </Grid>
  );

  const goBackGeneralData = () => {
    setCurrentStep(0);
  };

  const goBackPlans = () => {
    setCurrentStep(1);
  };

  //TODO TRAER EL DATO CORRECTO
  const getPaymentMethod = (code: any) => {
    if (code === '0') {
      return 'MANUAL';
    } else if (code === '2') {
      return 'DEBITO AUTOMATICO EN CUENTA BANCARIA';
    } else if (code === '3') {
      return 'DEBITO AUTOMATICO EN TARJETA DE CRÉDITO';
    }
  };

  return (
    <Box className={props.className}>
      <Container id='printPdf'>
        {/* DATOS GENERALES START */}
        <Grid container>
          <BlueBoxLabel label={'Datos Generales.'} onClick={goBackGeneralData} />
          <Grid item xs={6}>
            <Typography>
              {t('HOME_QUOTATIONS.STEP_3.GENERAL_DATA.HOME_TYPE')}:{' '}
              <strong>{dataForm?.general_data?.home_type?.value?.description}</strong>
            </Typography>
            <Typography className='mt-txt'>
              {t('HOME_QUOTATIONS.STEP_3.GENERAL_DATA.PROVINCE')}:{' '}
              <strong>{dataForm?.general_data?.province?.value?.description}</strong>
            </Typography>
            <Typography className='mt-txt'>
              {t('HOME_QUOTATIONS.STEP_3.GENERAL_DATA.LOCATION')}:{' '}
              <strong>{dataForm?.general_data?.city?.value?.description}</strong>
            </Typography>
            <Typography className='mt-txt'>
              {t('HOME_QUOTATIONS.STEP_3.GENERAL_DATA.PHONE')}:{' '}
              <strong>{dataForm?.general_data?.telephone?.value}</strong>
            </Typography>
            <Typography className='mt-txt'>
              {t('HOME_QUOTATIONS.STEP_3.GENERAL_DATA.EMAIL')}: <strong>{dataForm?.general_data?.mail?.value}</strong>
            </Typography>
          </Grid>
        </Grid>
        {/* DATOS GENERALES END */}
        {/* PLANES START */}
        <Grid container>
          <BlueBoxLabel label={'Planes.'} onClick={goBackPlans} />
          <Grid item>
            <Typography>
              {t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.VALIDITY')}: <strong>FACTURACIÓN ANUAL</strong>
            </Typography>
            <Typography className='mt-txt'>
              {t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.PAYMENT_METHOD')}:{' '}
              <strong>{getPaymentMethod(quoteHomeData?.paymentMethod)}</strong>
            </Typography>
            <Typography className='mt-txt'>
              {t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.PAYMENT_CONDITION')}:{' '}
              {quoteHomeData?.paymentCondition === '01' ? (
                <strong>{+quoteHomeData?.paymentCondition} CUOTA</strong>
              ) : (
                <strong>{+quoteHomeData?.paymentCondition} CUOTAS</strong>
              )}
            </Typography>
            {/* <Typography className='mt-txt'>
              {t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.FISCAL_CONDITION')}: <strong>CONSUMIDOR FINAL</strong>
            </Typography> */}
          </Grid>
        </Grid>
        <Grid container className='mt-plans'>
          <Grid item xs={8}>
            <strong>{dataForm?.plans?.name?.value}</strong>
          </Grid>
          <Grid item xs={4}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.PRIZE')}</Typography>
                <strong>{formatPrice(Number(dataForm?.plans?.price?.value))}</strong>
              </Grid>
              <Grid item xs={4}>
                <Typography>{t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.DUES')}</Typography>
                <strong>{+dataForm?.plans?.payment_condition?.value} CUOTAS</strong>
              </Grid>
              <Grid item xs={4}>
                <Typography>{t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.VALUES')}</Typography>
                <strong>{formatPrice(Number(dataForm?.plans?.remaining_payments?.value))}</strong>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* PLANES END */}
        {/* DETALLE DEL PLAN START */}
        <Grid container>
          <Typography className='blue-text' onClick={showInfoCombo}>
            {t('HOME_QUOTATIONS.STEP_3.PLANS_DATA.DETAILS')}
            <RightArrowIcon className={!showInfo ? 'blue-icon' : 'blue-icon rotate'} />
          </Typography>
        </Grid>
        {showInfo && (
          <Box>
            {dataForm?.plans?.characteristics.length
              ? dataForm?.plans?.characteristics.map((charact: any, index: any) => {
                  const { capitalize, price } = handleCharacteristics(charact);
                  return (
                    <Typography key={index}>
                      {capitalize} ₲{price}
                    </Typography>
                  );
                })
              : ''}
          </Box>
        )}
        {/* DETALLE DEL PLAN END */}
      </Container>
      {navButtons}
      {/* {loader && (
        <UnclosableModal img={CreatingOrderIllustration} messages={{ TITLE: t('COMMONS.MODALS.LOADING.TITLE') }} />
      )} */}
    </Box>
  );
};
export const HomeSummaryComponent = styled(({ ...props }) => <CleanHomeSummaryComponent {...props} />)`
  margin-top: 2em;
  .mt-txt {
    margin-top: 13px;
  }
  .blue-box {
    background-color: var(--color-main);
    display: flex;
    align-items: center;
    width: 63vw;
    height: 40px;
    border-radius: 3px;
    padding: 0px 10px;
    margin: 1em 0;
  }
  .positionRelative {
    position: relative;
    z-index: 1;
  }
  .positionAbsolute {
    position: absolute;
    z-index: 2;
  }
  .title-color {
    color: #fff;
  }
  .text-size {
    font-size: 17px;
    margin-top: 10px;
  }
  .ml {
    margin-left: 5px;
  }
  .blue-text {
    color: var(--color-main);
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .blue-icon {
    margin-left: 3px;
    fill: #fff;
    border-radius: 50%;
    background-color: var(--color-main);
  }
  .rotate {
    transform: rotate(90deg);
  }
  .mt {
    margin-top: 20em;
  }
  .mt-plans {
    margin-top: 3em;
  }
  .download-button {
    border: 1px solid var(--color-main);
    color: var(--color-main);
    background-color: var(--color-white);
  }
  .restart-button {
    border: 1px solid var(--color-main);
    color: var(--color-main);
    background-color: var(--color-white);
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .button-container {
    text-align: center;
    button {
      width: 100%;
    }
  }
`;
