import { ACTIONS } from '../../../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { RoleInfoForm } from './models';

// permissions
export const getPermissionsFetch = () => ({ type: ACTIONS.USERS.ROLE_INFO.GET_PERMISSIONS.FETCH });
export const getPermissionsSuccess = (permissions: any) => ({
  type: ACTIONS.USERS.ROLE_INFO.GET_PERMISSIONS.SUCCESS,
  payload: { data: permissions }
});

export const getPermissionsFail = (error: object) => ({
  type: ACTIONS.USERS.ROLE_INFO.GET_PERMISSIONS.FAIL,
  payload: { data: error }
});

// roles
export const roleInfoAddFetch = (roleInfoForm: RoleInfoForm) => ({
  type: ACTIONS.USERS.ROLE_INFO.ADD.FETCH,
  payload: { data: roleInfoForm }
});

export const roleInfoAddSuccess = () => ({
  type: ACTIONS.USERS.ROLE_INFO.ADD.SUCCESS
});
export const roleInfoAddFail = (error: object) => ({
  type: ACTIONS.USERS.ROLE_INFO.ADD.FAIL,
  payload: { error }
});

export const roleInfoUpdateFetch = (id: string, roleInfoForm: RoleInfoForm) => ({
  type: ACTIONS.USERS.ROLE_INFO.UPDATE.FETCH,
  payload: { id, roleInfoForm }
});
export const roleInfoUpdateFail = (error: object) => ({
  type: ACTIONS.USERS.ROLE_INFO.UPDATE.FAIL,
  payload: { error }
});
export const roleInfoUpdateSuccess = () => ({
  type: ACTIONS.USERS.ROLE_INFO.UPDATE.SUCCESS
});
