import { connect } from 'react-redux';
import { NewServiceComponent } from './view';

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state: any) => ({
  // servicesStore: MOCK_SERVICES //state.productsState,
});
const mapDispatchToProps = {};

export const NewService = connect(mapStateToProps, mapDispatchToProps)(NewServiceComponent);
