import { ACTIONS } from '../ActionTypes';

// Search Users
export const getContactsFetch = (params: any) => ({
  type: ACTIONS.CONTACTS.FETCH,
  payload: { params }
});

export const getContactsSuccess = (data: any) => ({
  type: ACTIONS.CONTACTS.SUCCESS,
  // payload: { contacts: contacts }
  payload: data
});

export const getContactsFail = (error: object) => ({ type: ACTIONS.CONTACTS.FAIL, payload: { error } });
