import React, { useState } from 'react';
import { Popper, StyledButton, Input } from '@wdynamo/common';

import { Box, Grid, MenuItem, FormGroup, FormControlLabel, Typography, Radio, RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { makeStyles, createStyles } from '@material-ui/core/styles';
// eslint-disable-next-line no-unused-vars
import { IGroup, ISetOfPermissions } from '../../models';
interface UsersFilterPopperProps {
  onClick?: any;
  onClickReset?: any;
  anchorEl: any;
  toggleOpen: any;
  className?: string;
  groups: IGroup[];
  roles: ISetOfPermissions[];
}

interface IUsers {
  id: string;
  first_name: string;
  last_name: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    popper: { width: '20em', textAlign: 'left', padding: '0 1em' },
    fullWidth: { width: '100%' },
    buttonPY: { padding: '5px 0' },
    cancel: {
      width: '100%',
      backgroundColor: 'white',
      color: '#666',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    }
  })
);

const initialFilter = { role: { value: '' }, group: { value: '' } };

const CleanUsersFilterPopper: React.FC<UsersFilterPopperProps> = (props: UsersFilterPopperProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<any>(initialFilter);

  const { anchorEl, roles } = props;

  const handleOnChange = (e: any, field: string): void => {
    setFilter({
      ...filter,
      [field]: { ...filter[field], value: e }
    });
  };

  const handleOnChangeRadio = (e: any) => {
    setFilter({ ...filter, status: { [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value } });
  };

  return (
    <Popper className={props.className} anchorEl={anchorEl}>
      <Grid container spacing={1} className={classes.popper}>
        <Grid item xs={12} sm={12} className='form-input'>
          <Input
            label={t('USERS.USER_FILTER.ROLE')}
            defaultValue={filter.role.value}
            onChange={(e: any) => handleOnChange(e, 'role')}
            testId='Role'
            select={true}
          >
            <MenuItem value={''}>
              <em>{t('COMMONS.FILTER.SELECT')}</em>
            </MenuItem>
            {roles?.map((item, i) => (
              <MenuItem key={i} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Input>
        </Grid>
        <Grid item xs={12} sm={12} className='form-input'>
          <Input
            label={t('USERS.USER_FILTER.GROUP')}
            defaultValue={filter.group.value}
            onChange={(e: any) => handleOnChange(e, 'group')}
            testId='Group'
            select={true}
          >
            <MenuItem value={''}>
              <em>{t('COMMONS.FILTER.SELECT')}</em>
            </MenuItem>
            {props.groups?.map((item, i) => (
              <MenuItem key={i} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Input>
        </Grid>
        <Box pt={5}>
          <Typography>{t('USERS.USER_FILTER.USERS_STATE')}</Typography>
          <FormGroup>
            <RadioGroup name='stateFilter' value={filter.status} onChange={handleOnChangeRadio} row={false}>
              <FormControlLabel value='active' control={<Radio />} label={t('USERS.USER_FILTER.ACTIVE')} />
              <FormControlLabel value='disabled' control={<Radio />} label={t('USERS.USER_FILTER.DISABLED')} />
            </RadioGroup>
          </FormGroup>
        </Box>

        <Box width='100%' pt={2}>
          <Grid item xs={12} className={classes.buttonPY}>
            <StyledButton className={classes.fullWidth + ' default'} onClick={() => props.onClick()}>
              {t('COMMONS.BUTTONS.FILTER')}
            </StyledButton>
          </Grid>
          <Grid item xs={12} className={classes.buttonPY}>
            <StyledButton
              className={`secondary ${classes.cancel}`}
              onClick={() => {
                props.onClickReset();
                props.toggleOpen();
              }}
            >
              {t('COMMONS.BUTTONS.RESET')}
            </StyledButton>
          </Grid>
        </Box>
      </Grid>
    </Popper>
  );
};

export const UsersFilterPopper = styled(({ ...props }) => <CleanUsersFilterPopper {...props} />)``;
