import React from 'react';
import styled from 'styled-components';
import { Input } from '@wdynamo/common';
import { MenuItem, Button } from '@material-ui/core';
import PhoneMask from '../../../../../../Contacts/components/common/PhoneMask';
// eslint-disable-next-line no-unused-vars
import { ICustomDataForm } from '../../models';

interface IInputPhoneProp {
  className?: string;
  handleOnChangePhone(value: any): void;
  handleOnInputChangePhone(event: any): void;
  handleOnClickPhone(event?: any, contact?: any): void;
  handleClickBttn(): void;
  phoneContact: any;
  dataForm: ICustomDataForm;
  contactPrefill: boolean;
  showPopup: boolean;
  listContacts: any;
  bttnClicked: boolean;
}

const CleanInputPhone: React.FC<IInputPhoneProp> = (props: IInputPhoneProp) => {
  const {
    className,
    handleOnChangePhone,
    handleOnInputChangePhone,
    handleOnClickPhone,
    handleClickBttn,
    phoneContact,
    dataForm,
    contactPrefill,
    showPopup,
    listContacts,
    bttnClicked
  } = props;

  return (
    <span className={className}>
      <div className='position-relative'>
        <Input
          className='input-phone'
          value={phoneContact !== '' ? phoneContact : dataForm.general_info?.phone_number?.value}
          error={dataForm?.general_info?.phone_number?.error}
          disabled={contactPrefill}
          type='text'
          required
          onChange={(value: any) => handleOnChangePhone(value)}
          onInput={(event: any) => handleOnInputChangePhone(event.target.value)}
          InputProps={{
            inputComponent: PhoneMask
          }}
        />
        {bttnClicked ? (
          <Button className='bttn' onClick={handleClickBttn}>
            <svg className='MuiSvgIcon-root icon-arrow up' focusable='false' viewBox='0 0 24 24' aria-hidden='true'>
              <path d='M7 10l5 5 5-5z'></path>
            </svg>
          </Button>
        ) : (
          <Button className='bttn' onClick={handleClickBttn} disabled={contactPrefill}>
            <svg className='MuiSvgIcon-root icon-arrow' focusable='false' viewBox='0 0 24 24' aria-hidden='true'>
              <path d='M7 10l5 5 5-5z'></path>
            </svg>
          </Button>
        )}
        {showPopup && (
          <div className='popup'>
            {listContacts.length > 0 ? (
              <>
                {listContacts.map((contact: any) => {
                  return (
                    <MenuItem
                      style={{ height: '43px' }}
                      key={contact}
                      onClick={(event: any) => handleOnClickPhone(event, contact)}
                    >
                      {contact.phone}
                    </MenuItem>
                  );
                })}
              </>
            ) : (
              <MenuItem style={{ height: '50px' }}>Sin coincidencias</MenuItem>
            )}
          </div>
        )}
      </div>
    </span>
  );
};

export const InputPhone = styled(({ ...props }) => <CleanInputPhone {...props} />)`
  .position-relative {
    position: relative;
  }
  .bttn {
    position: absolute;
    right: -1.2em;
    top: 0.2em;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    .icon-arrow {
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
  .MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: transparent;
  }
  .icon-arrow:hover {
    border-radius: 50%;
    background-color: #f4f3f3;
  }
  .up {
    transform: rotate(180deg);
  }
  .input-phone:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }
  .input-phone:after {
    border-bottom: 2px solid #3f51b5;
  }
  .popup {
    position: absolute;
    z-index: 5;
    background-color: white;
    box-shadow: -1px 2px 2px 0px rgba(219, 216, 216, 1);
    -webkit-box-shadow: -1px 2px 2px 0px rgba(219, 216, 216, 1);
    -moz-box-shadow: -1px 2px 2px 0px rgba(219, 216, 216, 1);
    border-radius: 3px;
    max-height: 215px;
    overflow: hidden;
    overflow-y: auto;
    width: -webkit-fill-available;
  }
`;
