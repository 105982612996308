const rucValidator = (ruc: string, p_basemax: number = 11) => {
  let isValid = true;

  if (ruc) {
    const rucWithNoDash = ruc.replace(/-/g, '');
    const rucWithNoVD = rucWithNoDash.slice(0, -1);
    let v_total: number = 0;
    let v_resto: number = 0;
    let k: number = 0;
    let v_numero_aux: number = 0;
    let v_digit: number = 0;
    let v_numero_al: string = '';
    for (let i = 0; i < rucWithNoVD.length; i++) {
      const c = Number(rucWithNoVD.charAt(i));
      v_numero_al += c.toString();
    }
    k = 2;
    v_total = 0;
    for (let i = v_numero_al.length - 1; i >= 0; i--) {
      if (k > p_basemax) {
        k = 2;
      }
      v_numero_aux = Number(v_numero_al.charAt(i));
      v_total += v_numero_aux * k;
      k = k + 1;
    }

    v_resto = v_total % 11;
    if (v_resto > 1) {
      v_digit = 11 - v_resto;
    } else {
      v_digit = 0;
    }

    const last_digit = +rucWithNoDash.charAt(rucWithNoDash.length - 1);
    isValid = v_digit === last_digit && last_digit != 0;
    return isValid;
  }
};

export default rucValidator;
