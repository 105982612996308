import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { uris } from '../../../siteMap';
import { Analytics } from '@wdynamo/common/lib/services';
import { StyledButton } from '@wdynamo/common/lib/components';
import { Grid } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
// eslint-disable-next-line no-unused-vars
import { CustomInfiniteScroll, Loading } from '@wdynamo/common';
import TransparentTooltip from '../../../components/Tooltip/Tooltip';
import { ReactComponent as PlayIcon } from '../../../assets/img/icons_dynamo/icon_more_info.svg';
import autos from '../../../assets/img/icons_dynamo/icon_seguro_automotor.svg';
import clientsNumber from '../../../assets/img/icons_dynamo/add_clients_card_3.svg';

interface ServicesListComponentProps {
  servicesStore: any;
  className?: string;
  getServicesFetch(): void;
}

const CleanServicesListComponent: React.FC<ServicesListComponentProps> = (props: ServicesListComponentProps) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const { servicesStore } = props;
  const [productList, setProductList] = useState<any>([]);
  const [searched, setSearched] = useState<string>('');

  useEffect(() => {
    Analytics().pageview('display-services');
  }, []);

  useEffect(() => {
    if (keycloak?.authenticated)
      setTimeout(() => {
        props.getServicesFetch();
      });
  }, [keycloak?.authenticated]);

  useEffect(() => {
    setProductList(servicesStore?.data);
  }, [servicesStore]);

  const requestSearch = (searchedVal: string) => {
    const filteredRows = servicesStore?.data.filter((item: any) => {
      return item.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setProductList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  return (
    <div className={`${props.className} services`}>
      <Grid container className='header'>
        <Grid item>
          <h1 className='main-title'>{t('SERVICES.TITLE')}</h1>
        </Grid>
        {/* <Grid item xs={12} md={6} className='add-service'>
          <Grid className='icon-label' item>
            <IconLabel
              label={t('SERVICES.BUTTON_ADD_SERVICE')}
              onClick={() => {
                history.push(uris?.newService.uri);
              }}
            />
          </Grid>
        </Grid> */}
        <Grid item className='search-bar'>
          <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            placeholder={t('SERVICES.PLACEHOLDER_SEARCHER')}
            style={{
              border: '1px solid #B1ACAC',
              boxSizing: 'border-box',
              borderRadius: '4px',
              boxShadow: 'none',
              height: '2.5rem'
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify='center'>
        {productList?.map((service: any, key: string) => (
          <Grid container direction='column' key={key} item xs={12} sm={6} md={4} lg={3}>
            <Grid item className='card-content'>
              {console.log('service logo: ', service.thumbnail)}
              <div className='icon-title'>
                <img src={autos} alt='' style={{ height: '45px' }} />
                <div className='title'>
                  <h3 className='card-title'>{service.name}</h3>
                </div>
              </div>

              <div className='sell'>
                {/* TODO: NO DEJAR MAGIC NUMBER */}
                <StyledButton
                  onClick={() => {
                    (service.id === 1 && history.push(uris.quotationsAuto.uri, { service: service })) ||
                      (service.id === 2 && history.push(uris.quotationsHome.uri, { service: service })) ||
                      (service.id === 3 && history.push(uris.quotationsAp.uri, { service: service }));
                  }}
                >
                  {t('SERVICES.LIST')}
                </StyledButton>
              </div>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} justify='center' style={{ marginTop: '0.5rem' }}>
        <Grid container direction='column' key={1} item xs={12} sm={6} md={6} lg={6}>
          <Grid item className='card-content'>
            <Grid
              container
              className='contacts-grid'
              style={{
                color: 'black',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'flex-start',
                textAlign: 'start'
              }}
            >
              <Grid item xs={12} className='header' style={{ padding: 0, margin: 0 }}>
                <Grid container>
                  <Grid item xs={2} className='title-container'>
                    <div>{/* t('Fecha') */ 'Fecha'} </div>
                  </Grid>
                  <Grid item xs={3} className='title-container'>
                    <div> {/*t('CONTACTS.TABLE.HEADERS.RESPONSIBLE')*/ 'Ventas Totales'} </div>
                  </Grid>
                  <Grid item xs={3} className='title-container'>
                    <div> {/*t('CONTACTS.TABLE.HEADERS.NAME') */ 'Comisión total'} </div>
                  </Grid>

                  <Grid item xs={1} />
                </Grid>
              </Grid>

              <Grid item xs={12} className='content'>
                <CustomInfiniteScroll
                  //  nextUrl={nextPage}
                  // paginate={handleChangePage}
                  paginate={() => {}}
                  firstLoading={false}
                >
                  {/*  {contactList &&
                contactList.map((contact: IContact, i: number) => ( */}
                  <Grid container className='contact'>
                    <Grid item xs={2}>
                      {/*contact?.last_contact ? `${getFormattedDate(contact?.last_contact)}` : '' */}
                    </Grid>
                    <Grid item xs={3}>
                      {/*contact?.responsible ? `${contact.responsible.first_name} ${contact.responsible.last_name}` : ''*/}
                    </Grid>
                    <Grid item xs={3}>
                      {/*`${contact.name ? contact.name : ''} ${contact.last_name ? contact.last_name : ''}`*/}
                    </Grid>
                    <Grid item xs={3}>
                      {/*contact.mail*/}
                    </Grid>

                    <Grid item xs={1} className='icon-grid'>
                      <TransparentTooltip title={t('CONTACTS.TABLE.HEADERS.DETAIL')}>
                        <PlayIcon
                          onClick={() => {
                            alert('click');
                          }}
                        />
                      </TransparentTooltip>
                    </Grid>
                  </Grid>
                </CustomInfiniteScroll>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction='column' item xs={12} sm={6} md={4} lg={3}>
          <Grid item className='card-content'>
            <h3 className='card-title'>{'Mi cartera de clientes'}</h3>
            <img src={clientsNumber} alt='' style={{ width: '100%' }} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export const ServicesListComponent = styled(({ ...props }) => <CleanServicesListComponent {...props} />)`
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0 5rem 0;
  }
  .main-title {
    font-size: 1.75rem;
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: #666;
  }
  .icon-title {
    display: flex;
    flex-direction: row;
  }
  .search-bar {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
  .card-title {
    color: #393737;
    //  text-align: center;

    font-size: 1.25rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    margin-left: 0.2rem;
  }
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 10em;
    background-color: var(--color-white);
    border-radius: 16px;
    color: var(--color-dark);
    position: relative;
    box-shadow: 3px 2px 6px 5px rgba(0, 0, 0, 0.27);
    padding: 0.5rem;
  }
  .MuiCardContent-root {
    padding-bottom: 0.2em;
    padding-top: 0;
  }
  .sell {
    text-align: end;
    justify-content: end;
    align-items: flex-end;
  }
  .add-service {
    text-align: end;
    margin-top: 1.675rem;
  }
  .logo {
    position: relative;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;
    border-radius: 0 0 16px 16px;
  }
  .card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7em 2em;
    color: var(--color-dark);
    background-color: var(--color-main);
    border-radius: 16px 16px 0 0;
    height: 5rem;
  }
  .contacts-grid {
    .header {
      border: none;
      font-weight: bold;
      text-align: left;
      div {
        padding: 5px 0;
      }
    }
    .content {
      .contact {
        padding: 16px 0;
        border-bottom: 1px solid var(--color-grey-border);
        cursor: pointer;
        g.main-color {
          fill: red;
        }
      }
    }
    .last_contact {
      text-align: right;
    }
  }
  .MuiPagination-ul {
    justify-content: center;
  }
  .MuiInput-underline:before {
    border-bottom: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  .MuiInput-underline:after {
    border-bottom: none;
  }
  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
  }
`;
