import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { Input, TextPoint } from '@wdynamo/common/lib/components';
import { useTranslation } from 'react-i18next';
import { SelectedTag } from '../SelectedTag/SelectedTag';
import { Analytics } from '@wdynamo/common/lib/services';
import IconTag from '../../../../assets/img/icons_dynamo/icon_tag.svg';

interface InfoFormProps {
  commercials: [];
  className?: any;
  service: any;
  handleOnChange(fieldToUpdate: string, value: any): void;
}

const CleanInfoForm: React.FC<InfoFormProps> = (props: InfoFormProps) => {
  const { t } = useTranslation();
  const { commercials, handleOnChange, service } = props;
  const [currentTag, setCurrentTag] = useState<string>('');

  const handleSelectedCommercialChange = (e: any) => {
    handleOnChange('commercial', e.target.value);
  };

  React.useEffect(() => {
    Analytics().pageview('new-service | info');
  }, []);

  const handleCodeChange = (value: string) => {
    const code = value;
    if (!Number(code) && code !== '') {
      return;
    }
    handleOnChange('code', code);
  };

  const handleDenominationChange = (value: any) => {
    handleOnChange('name', value.toString().slice(0, service.name?.maxLength));
  };
  const handleSubtitleChange = (value: any) => {
    handleOnChange('subtitle', value.toString().slice(0, service.subtitle?.maxLength));
  };
  const handleDescriptionChange = (value: any) => {
    handleOnChange('description', value.toString().slice(0, service.description?.maxLength));
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const addCurrentToTags = () => {
    if (currentTag !== '') {
      const found = service.tags?.value.find((tag: any) => tag == capitalizeFirstLetter(currentTag));
      if (found != undefined) {
        return;
      }
      handleOnChange('tags', [...service.tags?.value, capitalizeFirstLetter(currentTag)]);
      setCurrentTag('');
    }
  };

  const handleDeleteTag = (value: any) => {
    const filtered = service.tags?.value.filter((tag: any) => {
      return tag !== value;
    });
    handleOnChange('tags', filtered);
  };

  const letterCounter = (value: string, max: number) => {
    return (
      <span>
        {value?.length === 0
          ? t('SERVICES.MAX_LENGTH', { max: max })
          : t('SERVICES.CURRENT_LENGTH', { max: max, current: value?.length })}
      </span>
    );
  };

  return (
    <Grid className={`${props.className}`} container spacing={3}>
      <Grid item xs={12}>
        <h3>
          {t('SERVICES.NEW_SERVICE.STEP_1.TITLE')}
          <TextPoint />
        </h3>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          onChange={handleDenominationChange}
          defaultValue={service.name?.value}
          label={t('SERVICES.NEW_SERVICE.STEP_1.DENOMINATION')}
        />
        {letterCounter(service.name?.value, service.name?.maxLength)}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          onChange={handleSubtitleChange}
          defaultValue={service.subtitle?.value}
          label={t('SERVICES.NEW_SERVICE.STEP_1.SUBTITLE')}
        />
        {letterCounter(service.subtitle?.value, service.subtitle?.maxLength)}
      </Grid>
      <Grid item xs={12}>
        <Input
          onChange={handleDescriptionChange}
          defaultValue={service.description?.value}
          label={t('SERVICES.NEW_SERVICE.STEP_1.DESCRIPTION')}
        />
        {letterCounter(service.description?.value, service.description?.maxLength)}
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>{t('SERVICES.NEW_SERVICE.STEP_1.COMMERCIAL')}</InputLabel>
          <Select fullWidth onChange={handleSelectedCommercialChange} value={service.commercial?.value}>
            {commercials?.map((com: any, key: any) => (
              <MenuItem key={key} value={com.name}>
                {com.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          type='number'
          defaultValue={service.code?.value}
          onChange={(value: string) => {
            handleCodeChange(value);
          }}
          label={t('SERVICES.NEW_SERVICE.STEP_1.CODE')}
        />
      </Grid>
      <Grid container item xs={6}>
        <Grid item xs={1}>
          <img style={{ paddingTop: '10px' }} alt='' src={IconTag} />
        </Grid>
        <Grid item xs={11}>
          <Input
            onChange={setCurrentTag}
            defaultValue={currentTag}
            onKeyPress={(e) => {
              if (e.key == 'Enter') {
                addCurrentToTags();
              }
            }}
            label={t('SERVICES.NEW_SERVICE.STEP_1.TAGS')}
          />
        </Grid>
        <Grid item xs={12}>
          <span className='clarification'>{t('SERVICES.NEW_SERVICE.STEP_1.CLARIFICATION')}</span>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div>
          {service.tags?.value?.map((tag: any, key: number) => (
            <span key={key}>
              <SelectedTag
                name={tag}
                onDelete={() => {
                  handleDeleteTag(tag);
                }}
              />
            </span>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export const InfoForm = styled(({ ...props }) => <CleanInfoForm {...props} />)`
  .clarification {
    font-size: 14px;
  }
`;
