import { ACTIONS } from '../../../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IBillingModeHomeParams, IPaymentMethodHomeParams, IPaymentConditionHomeParams } from './model';

//get Steps
export const getHomeStepsFetch = (text: string) => ({
  type: ACTIONS.HOME_QUOTATIONS.STEPS.FETCH,
  payload: { params: text }
});

export const getHomeStepsSuccess = (data: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.STEPS.SUCCESS,
  payload: { data: data }
});

export const getHomeStepsFail = (error: object) => ({
  type: ACTIONS.HOME_QUOTATIONS.STEPS.FAIL,
  payload: { error: error }
});

//getSourceList
export const getSourceHomeListFetch = (params: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.LISTSOURCES.FETCH,
  payload: { params }
});

export const getSourceHomeListSuccess = (data: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.LISTSOURCES.SUCCESS,
  payload: data
});

export const getSourceHomeListFail = (error: object) => ({
  type: ACTIONS.HOME_QUOTATIONS.LISTSOURCES.FAIL,
  payload: { error: error }
});

//getSourceData
export const getDataHomeSourceFetch = (params: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.DATASOURCES.FETCH,
  payload: { params }
});

export const getDataHomeSourceSuccess = (data: {}) => ({
  type: ACTIONS.HOME_QUOTATIONS.DATASOURCES.SUCCESS,
  payload: data
});

export const getDataHomeSourceFail = (error: object) => ({
  type: ACTIONS.HOME_QUOTATIONS.DATASOURCES.FAIL,
  payload: { error: error }
});

//create contact
export const createContactFetch = (params: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.CREATE_CONTACT.FETCH,
  payload: { params }
});

export const createContactSuccess = (data: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.CREATE_CONTACT.SUCCESS,
  payload: data
});

export const createContactFail = (error: object) => ({
  type: ACTIONS.HOME_QUOTATIONS.CREATE_CONTACT.FAIL,
  payload: { error: error }
});

// get cities
export const getCityFetch = (params: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.CITIES.FETCH,
  payload: { params }
});

export const getCitySuccess = (data: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.CITIES.SUCCESS,
  payload: data
});

export const getCityFail = (error: object) => ({
  type: ACTIONS.HOME_QUOTATIONS.CITIES.FAIL,
  payload: { error: error }
});

//get plans
export const getHomePlansFetch = (params: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.PLANS.FETCH,
  payload: { params }
});

export const getHomePlansSuccess = (data: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.PLANS.SUCCESS,
  payload: data
});

export const getHomePlansFail = (error: object) => ({
  type: ACTIONS.HOME_QUOTATIONS.PLANS.FAIL,
  payload: { error: error }
});

//put dynamo_items from inputter into store
export const setItemsToStore = (data: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.LISTSOURCES.SET_ITEMS_FROM_INPUTTER,
  payload: data
});

//get billingMode (modo de facturación)
export const getBillingModeFetch = (params: IBillingModeHomeParams) => ({
  type: ACTIONS.HOME_QUOTATIONS.BILLING_MODE.FETCH,
  payload: { params }
});

export const getBillingModeSuccess = (data: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.BILLING_MODE.SUCCESS,
  payload: data
});

export const getBillingModeFail = (error: object) => ({
  type: ACTIONS.HOME_QUOTATIONS.BILLING_MODE.FAIL,
  payload: { error: error }
});

//get paymentMethod (Forma de pago)
export const getPaymentMethodFetch = (params: IPaymentMethodHomeParams) => ({
  type: ACTIONS.HOME_QUOTATIONS.PAYMENT_METHOD.FETCH,
  payload: { params }
});

export const getPaymentMethodSuccess = (data: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.PAYMENT_METHOD.SUCCESS,
  payload: data
});

export const getPaymentMethodFail = (error: object) => ({
  type: ACTIONS.HOME_QUOTATIONS.PAYMENT_METHOD.FAIL,
  payload: { error: error }
});

//get paymentCondition (condicion de pago)
export const getPaymentConditionFetch = (params: IPaymentConditionHomeParams) => ({
  type: ACTIONS.HOME_QUOTATIONS.PAYMENT_CONDITION.FETCH,
  payload: { params }
});

export const getPaymentConditionSuccess = (data: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.PAYMENT_CONDITION.SUCCESS,
  payload: data
});

export const getPaymentConditionFail = (error: object) => ({
  type: ACTIONS.HOME_QUOTATIONS.PAYMENT_CONDITION.FAIL,
  payload: { error: error }
});

//update quotation
export const updateQuotationFetch = (quotationId: string, params: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.UPDATE.FETCH,
  payload: { quotationId: quotationId, params }
});

export const updateQuotationSuccess = (data: any) => ({
  type: ACTIONS.HOME_QUOTATIONS.UPDATE.SUCCESS,
  payload: data
});

export const updateQuotationFail = (error: object) => ({
  type: ACTIONS.HOME_QUOTATIONS.UPDATE.FAIL,
  payload: { error: error }
});

//clean store
export const cleanStore = () => ({
  type: ACTIONS.HOME_QUOTATIONS.CLEAN
});
