import { call, put, takeLatest, all } from 'redux-saga/effects';
import ApiMock from './ApiMock';
import { getTypesSuccess, getTypesFail } from './actions';
// eslint-disable-next-line no-unused-vars
import { CUSTOM_FIELDS_TYPES } from './types';

const api = ApiMock();

function* sagasGetTypesFetch() {
  try {
    const response = yield call(api.getTypesFetch);
    yield put(getTypesSuccess(response.data.data));
  } catch (e) {
    yield put(getTypesFail(e));
  }
}

// Listen for redux actions
function* customFieldsFormSaga() {
  yield all([takeLatest(CUSTOM_FIELDS_TYPES.FETCH, sagasGetTypesFetch)]);
}

export { customFieldsFormSaga };
