import React from 'react';
import styled from 'styled-components';

interface IModalProps {
  className: string;
  value: string;
  color: string;
}

const CleanColoredSymbolComponent: React.FC<IModalProps> = (props: IModalProps) => {
  const { className, value } = props;

  return <span className={className}>{value}</span>;
};

export const ColoredSymbolComponent = styled(({ ...props }) => <CleanColoredSymbolComponent {...props} />)`
  color: ${(props) => props.color};
`;
