const MOCK_SERVICE_INFO = {
  data: {
    status: 'success',
    status_code: 200,
    data: {
      code: '12413',
      name: 'Seguro Auto',
      price: 2000,
      description: 'Seguro de auto muy copado te juro ',
      subtitle: 'Seguro de autos',
      paymentMethods: ['1', '4'],
      salesChannels: ['1', '3'],
      active: false,
      customFields: [
        {
          id: '0',
          name: 'Campo 1',
          id_type: '1'
        },
        {
          id: '1',
          name: 'Campo 2',
          id_type: '2'
        },
        {
          id: '2',
          name: 'Campo 3',
          id_type: '3',
          items: ['Opcion 1', 'Opcion 2', 'Opcion 3']
        }
      ]
    }
  }
};

export default MOCK_SERVICE_INFO;
