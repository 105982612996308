import axios from 'axios';

export interface IReportsParams {
  params: {
    from_date?: string;
    to_date?: string;
    include_children?: boolean;
    order_status?: string;
    sort?: string;
    items_limit?: number;
    nextPage?: string;
    pagelen?: number;
    toExport?: boolean;
  };
}

const Api = () => {
  const bookingReportsURL = `${process.env.REACT_APP_BOOKING_BASE_URL}/reports`;

  const getOrdersGroupedByDateFetch = (params: IReportsParams): Promise<unknown> =>
    axios.get(`${bookingReportsURL}/sales-history-by-salesman`, params);

  const getTopProductsFetch = (params: IReportsParams): Promise<unknown> =>
    axios.get(`${bookingReportsURL}/products-by-sales`, params);

  const getTopSalesTeamsFetch = (params: IReportsParams): Promise<unknown> =>
    axios.get(`${bookingReportsURL}/sales-by-supervisor`, params);

  const getTopSalesmenFetch = (params: IReportsParams): Promise<unknown> =>
    axios.get(`${bookingReportsURL}/sales-by-salesman`, params);

  const getOrdersCsvFetch = (params: IReportsParams): Promise<unknown> =>
    axios.get(`${bookingReportsURL}/orders-by-salesman-csv`, params);

  const getOrdersFetch = (params: IReportsParams): Promise<unknown> =>
    params.params.nextPage
      ? axios.get(params.params.nextPage)
      : axios.get(`${bookingReportsURL}/orders-by-salesman`, params);

  return {
    getOrdersGroupedByDateFetch,
    getTopProductsFetch,
    getTopSalesTeamsFetch,
    getTopSalesmenFetch,
    getOrdersCsvFetch,
    getOrdersFetch
  };
};

export default Api;
