import React from 'react';
import styled from 'styled-components';
import { Typography, Box, Icon } from '@material-ui/core';

interface IUnderlineButtonProps {
  icon?: any;
  label: string;
  className: string;
  onClick(): void;
}

const CleanUnderlineButton: React.FC<IUnderlineButtonProps> = (props: IUnderlineButtonProps) => {
  const { className, label, icon, onClick } = props;

  return (
    <Box className={className}>
      <a onClick={onClick}>
        <div className='container-bttn'>
          {icon && <Icon className='icon'>{icon}</Icon>}
          <Typography className='icon-text' display='inline'>
            {label}
          </Typography>
        </div>
      </a>
    </Box>
  );
};

export const UnderlineButton = styled(({ ...props }) => <CleanUnderlineButton {...props} />)`
  a {
    display: flex;
  }
  .icon {
    fill: var(--color-main);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-text {
    border-bottom: 1px solid var(--color-main);
    color: var(--color-main);
    font-size: 16px;
  }
  .container-bttn {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
  }
`;
