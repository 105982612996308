import React from 'react';
import { Modal, Backdrop, Fade, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { StyledButton } from '@wdynamo/common/lib/components';
import icon_cross from '../../assets/img/icons_dynamo/icon_cross.svg';

interface IModalProps {
  className: string;
  icon?: React.ReactNode;
  iconColor?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  description?: any;
  details?: string;
  loadingButtons?: boolean;
  twoButtons?: boolean;
  buttonColor?: string;
  secondButtonColor?: string;
  buttonText?: string;
  secondButtonText?: string;
  buttonIcon?: React.ReactNode;
  secondButtonIcon?: React.ReactNode;
  buttonLoadingText?: string;
  secondButtonLoadingText?: string;
  onClickButton?(): void;
  onClickSecondButton?(): void;
  open: boolean;
  handleCloseCrossModal(): void;
}

const CleanModalComponent: React.FC<IModalProps> = (props: IModalProps) => {
  const {
    className,
    icon,
    title,
    subtitle,
    description,
    details,
    loadingButtons,
    twoButtons,
    buttonText,
    secondButtonText,
    buttonIcon,
    secondButtonIcon,
    buttonLoadingText,
    secondButtonLoadingText,
    onClickButton,
    onClickSecondButton,
    open,
    handleCloseCrossModal
  } = props;

  return (
    <Modal className={className} open={open} closeAfterTransition BackdropComponent={Backdrop}>
      <Fade in={open}>
        <Grid className='modal-content' container direction='column' alignItems='center'>
          <Grid className='cross-button-container'>
            <img
              src={icon_cross}
              width='15rem'
              height='auto'
              onClick={handleCloseCrossModal}
              className='cross-image-button'
            />
          </Grid>
          <Grid item style={{ padding: '0' }}>
            {icon && icon}
          </Grid>
          <Grid item>
            {title && (
              <>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                  {title}
                </Typography>
                {subtitle && <Typography>{subtitle}</Typography>}
              </>
            )}
          </Grid>
          <Grid item>{description && <Typography className='description-text'>{description}</Typography>}</Grid>
          {details && (
            <Grid className='details-box' item>
              <Typography className='description-text'>{details}</Typography>
            </Grid>
          )}
          {buttonText ? (
            <Grid className={`buttons ${!twoButtons && 'centered'}`} item>
              {twoButtons ? (
                <>
                  <StyledButton
                    className='first-button'
                    startIcon={buttonIcon}
                    loading={loadingButtons}
                    loadingChildren={buttonLoadingText}
                    onClick={onClickButton}
                  >
                    {buttonText}
                  </StyledButton>
                  <StyledButton
                    className='second-button'
                    startIcon={secondButtonIcon}
                    loading={loadingButtons}
                    loadingChildren={secondButtonLoadingText}
                    onClick={onClickSecondButton}
                  >
                    {secondButtonText}
                  </StyledButton>
                </>
              ) : (
                <StyledButton
                  className='first-button'
                  startIcon={buttonIcon}
                  loading={loadingButtons}
                  loadingChildren={buttonLoadingText}
                  onClick={onClickButton}
                >
                  {buttonText}
                </StyledButton>
              )}
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Fade>
    </Modal>
  );
};

export const ModalComponent = styled(({ ...props }) => <CleanModalComponent {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content {
    width: 650px;
    background-color: var(--color-white);
    border: none;
    border-radius: 20px;
    text-align: center;
    padding: 10px 10px 25px;
    &:focus {
      outline: none;
    }
    & > * {
      padding: 0.7em;
    }
    & p,
    h6,
    button {
      font-family: inherit;
    }
    .description-text {
      white-space: pre-line;
      font-size: 18px;
    }
    .details-box {
      color: red;
      margin: 5px 0;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 500px;
      &.centered {
        justify-content: center;
      }
    }
    .first-button {
      width: 290px;
      color: white;
      background-color: ${(props) => props.buttonColor};
    }
    .second-button {
      background-color: ${(props) => props.secondButtonColor};
    }
    .cross-image-button {
      cursor: pointer;
    }
    .cross-button-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
      padding: 10px 10px 0 0;
      & svg > path {
        fill: ${(props) => props.iconColor};
      }
    }
  }
`;
