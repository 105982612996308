import { call, put, takeLatest, all } from 'redux-saga/effects';
import Api from './Api';
// eslint-disable-next-line no-unused-vars
import ApiMock from './ApiMock';

import {
  getApStepsSuccess,
  getApStepsFail,
  createContactSuccess,
  createContactFail,
  getContactsSuccess,
  getContactsFail,
  getBillingModeSuccess,
  getBillingModeFail,
  getPaymentMethodSuccess,
  getPaymentMethodFail,
  getPaymentConditionSuccess,
  getPaymentConditionFail,
  getPlansSuccess,
  getPlansFail,
  updateQuotationSuccess,
  updateQuotationFail
} from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../../ActionTypes';

interface IUsersData {
  data?: any;
}

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();
// const api = Api();

function* sagasGetApStepsFetch(action: actionType) {
  try {
    const response: IUsersData = yield call(api.getApStepsFetch, action.payload.params);
    const { data } = response;
    yield put(getApStepsSuccess(data));
  } catch (e) {
    yield put(getApStepsFail(e));
  }
}

function* sagasCreateContactFetch(action: actionType) {
  try {
    const response: any = yield call(api.createContactFetch, action.payload.params);
    const { data } = response;
    yield put(createContactSuccess(data));
  } catch (e) {
    yield put(createContactFail(e.response.data));
  }
}

function* sagasGetContactsFetch(action: actionType) {
  try {
    const response: IUsersData = yield call(api.getContactsFetch, action.payload.params);
    const { data } = response;
    yield put(getContactsSuccess(data));
  } catch (e) {
    yield put(getContactsFail(e));
  }
}

function* sagasGetBillingMethodFetch(action: actionType) {
  try {
    const response: any = yield call(api.getBillingModeFetch, action.payload.params);
    const { data } = response;
    yield put(getBillingModeSuccess(data));
  } catch (e) {
    yield put(getBillingModeFail(e.response.data));
  }
}

function* sagasGetPaymentMethodFetch(action: actionType) {
  try {
    const response: any = yield call(api.getPaymentMethodFetch, action.payload.params);
    const { data } = response;
    yield put(getPaymentMethodSuccess(data));
  } catch (e) {
    yield put(getPaymentMethodFail(e.response.data));
  }
}

function* sagasGetPaymentConditionFetch(action: actionType) {
  try {
    const response: any = yield call(api.getPaymentConditionFetch, action.payload.params);
    const { data } = response;
    yield put(getPaymentConditionSuccess(data));
  } catch (e) {
    yield put(getPaymentConditionFail(e.response.data));
  }
}

function* sagasGetPlansFetch(action: actionType) {
  try {
    const response: any = yield call(api.getPlansFetch, action.payload.params);
    const { data } = response;
    yield put(getPlansSuccess(data));
  } catch (e) {
    yield put(getPlansFail(e.response.data));
  }
}

function* sagasUpdateQuotationFetch(action: actionType) {
  try {
    yield call(api.updateQuotationFetch, action.payload.quotationId, action.payload.params);
    yield put(updateQuotationSuccess({ response: 'OK' }));
  } catch (e) {
    yield put(updateQuotationFail(e?.response?.data));
  }
}

// Listen for redux actions
function* quotationsApSaga() {
  yield all([takeLatest(ACTIONS.AP_QUOTATIONS.STEPS.FETCH, sagasGetApStepsFetch)]);
  yield all([takeLatest(ACTIONS.AP_QUOTATIONS.CREATE_CONTACT.FETCH, sagasCreateContactFetch)]);
  yield all([takeLatest(ACTIONS.AP_QUOTATIONS.CONTACTS.FETCH, sagasGetContactsFetch)]);
  yield all([takeLatest(ACTIONS.AP_QUOTATIONS.BILLING_MODE.FETCH, sagasGetBillingMethodFetch)]);
  yield all([takeLatest(ACTIONS.AP_QUOTATIONS.PAYMENT_METHOD.FETCH, sagasGetPaymentMethodFetch)]);
  yield all([takeLatest(ACTIONS.AP_QUOTATIONS.PAYMENT_CONDITION.FETCH, sagasGetPaymentConditionFetch)]);
  yield all([takeLatest(ACTIONS.AP_QUOTATIONS.PLANS.FETCH, sagasGetPlansFetch)]);
  yield all([takeLatest(ACTIONS.AP_QUOTATIONS.UPDATE.FETCH, sagasUpdateQuotationFetch)]);
}

export { quotationsApSaga };
