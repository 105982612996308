import React from 'react';
import styled from 'styled-components';
import { Box, Typography, Grid } from '@material-ui/core';
import whiteLogo from '../../../../../../assets/img/Logo_Basa_blanco.png';
import { ReactComponent as EditIcon } from '../../../../../../assets/img/icons_dynamo/icon_edit_pencil.svg';

interface IBlueBoxProps {
  className?: string;
  label: string;
  onClick?(): void;
  editIcon?: boolean;
}

const CleanBlueBoxLabel: React.FC<IBlueBoxProps> = (props: IBlueBoxProps) => {
  const { className, label, onClick, editIcon = true } = props;

  return (
    <Grid container className={className}>
      <Box className='blue-box positionRelative'>
        <Typography variant={'h6'} className='title'>
          {label}
        </Typography>
        {editIcon && <EditIcon width={'20'} height={'20'} onClick={onClick} />}
      </Box>
      <Box className='positionAbsolute'>
        <img src={whiteLogo} alt='logo'></img>
      </Box>
    </Grid>
  );
};

export const BlueBoxLabel = styled(({ ...props }) => <CleanBlueBoxLabel {...props} />)`
  .blue-box {
    background-color: var(--color-main);
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    width: 100vw !important;
    height: 40px;
    border-radius: 3px;
    padding: 0px 10px;
    margin: 1em 0;
  }
  .title {
    color: #fff;
  }
  .positionRelative {
    position: relative;
    z-index: 1;
  }
  .positionAbsolute {
    position: absolute;
    z-index: 2;
  }
`;
