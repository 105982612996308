const MOCK_SALE_CHANNELS = {
  data: {
    status: 'success',
    status_code: 200,
    data: [
      {
        id: '0',
        name: 'nostrud'
      },
      {
        id: '1',
        name: 'qui'
      },
      {
        id: '2',
        name: 'qui'
      },
      {
        id: '3',
        name: 'mollit'
      },
      {
        id: '4',
        name: 'non'
      }
    ]
  }
};

export default MOCK_SALE_CHANNELS;
