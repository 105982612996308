import { PAYMENT_METHOD, SALE_CHANNELS } from './types';

export const getPaymentMethodsFetch = () => ({
  type: PAYMENT_METHOD.FETCH
});

export const getPaymentMethodsSuccess = (data: any) => ({
  type: PAYMENT_METHOD.SUCCESS,
  payload: { data: data }
});

export const getPaymentMethodsFail = (error: object) => ({ type: PAYMENT_METHOD.FAIL, payload: { error } });

export const getSaleChannelsFetch = () => ({
  type: SALE_CHANNELS.FETCH
});

export const getSaleChannelsSuccess = (data: any) => ({
  type: SALE_CHANNELS.SUCCESS,
  payload: { data: data }
});

export const getSaleChannelsFail = (error: object) => ({ type: SALE_CHANNELS.FAIL, payload: { error } });
