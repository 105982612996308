import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { TextPoint } from '@wdynamo/common';
import { SettingsForm } from './components';
import { Analytics } from '@wdynamo/common/lib/services';

import { useTranslation } from 'react-i18next';

export const SettingsComponent = (props: any) => {
  const { t } = useTranslation();
  const { settingsStore, uploadImageFetch, updatePartnerFetch, history } = props;

  React.useEffect(() => {
    Analytics().pageview('settings');
  }, []);

  return (
    <Container maxWidth='sm'>
      <Grid container>
        <Grid item xs={12}>
          <h2>
            {t('SETTINGS.TITLE')}
            <TextPoint />
          </h2>
        </Grid>
        <Grid item xs={12}>
          <SettingsForm
            settings={settingsStore?.data}
            uploadImageFetch={uploadImageFetch}
            updatePartnerFetch={updatePartnerFetch}
            history={history}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
