import React from 'react';
import IMask from 'imask';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
// eslint-disable-next-line no-unused-vars
import { ICodeDescription, ICustomDataForm } from '../models';
import { IconButton } from '@wdynamo/common';
import { ReactComponent as trashIcon } from '../../../../../../assets/img/icons_dynamo/icon_trash.svg';

interface IAccessoriesTableProps {
  className: string;
  accessoriesListData: any;
  accessoriesValue: any;
  dataForm: ICustomDataForm;
  setDataForm(dataForm: ICustomDataForm): void;
  showInSummary: boolean;
}

const CleanAccessoriesTable: React.FC<IAccessoriesTableProps> = (props: IAccessoriesTableProps) => {
  const { className, accessoriesListData, accessoriesValue, dataForm, setDataForm, showInSummary } = props;
  const { t } = useTranslation();
  const prevDataForm = { ...dataForm };

  const handleFindAccessory = (accessoryData: ICodeDescription) => {
    if (accessoriesListData.length) {
      const foundAccessory = accessoriesListData.find(
        (accessory: ICodeDescription) => accessory.code === accessoryData.code
      );
      return foundAccessory && foundAccessory.description;
    }
  };
  const handleOnClickTrashIcon = (index: number) => {
    const arrPrevDataForm = JSON.parse(prevDataForm.custom_step.grupo1.accesories.value.description);
    arrPrevDataForm.splice(index, 1);
    if (arrPrevDataForm.length < 1) {
      arrPrevDataForm.push({ code: '', description: '' });
    }
    prevDataForm.custom_step.grupo1.accesories.value.description = JSON.stringify(arrPrevDataForm);
    setDataForm(prevDataForm);
  };

  const handleFormatAccessoryDescription = (value: string) => {
    const masked = IMask.createMask({ mask: Number, thousandsSeparator: '.', scale: 0 });
    masked.resolve(value);
    return masked.value;
  };

  const renderAccessories = (accesoriesData: []) => (
    <div className={className}>
      <table width={!showInSummary ? '50%' : '100%'}>
        {!showInSummary ? (
          <>
            <thead>
              <tr>
                <th>
                  <Typography align='left'>{t('QUOTATIONS.ACCESSORIES_TABLE.ACCESSORIES')} *</Typography>
                </th>
                <th>
                  <Typography align='left'>{t('QUOTATIONS.ACCESSORIES_TABLE.VALUE')} *</Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {accesoriesData?.map((accessoryData: any, i: number) => (
                <tr key={accessoryData.code + i} className='row'>
                  <td>
                    <Typography align='left'>{handleFindAccessory(accessoryData)}</Typography>
                  </td>
                  <td>
                    <Typography align='left'>{handleFormatAccessoryDescription(accessoryData.description)}</Typography>
                  </td>
                  {accessoryData.description ? (
                    <td>
                      <IconButton className='trash-button' Icon={trashIcon} onClick={() => handleOnClickTrashIcon(i)} />
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              ))}
            </tbody>
          </>
        ) : (
          <tbody>
            {accesoriesData?.map((accessoryData: any, i: number) => (
              <tr key={i}>
                <td>
                  <Typography className={i === 0 ? '' : 'margin-top'}>
                    {t('QUOTATIONS.ACCESSORIES_TABLE.ACCESSORIES')}:{' '}
                    <strong>{handleFindAccessory(accessoryData)}</strong>
                  </Typography>
                </td>
                {accessoryData.description ? (
                  <td>
                    <Typography className={i === 0 ? '' : 'margin-top'}>
                      {t('QUOTATIONS.ACCESSORIES_TABLE.VALUE')}:{' '}
                      <strong>{handleFormatAccessoryDescription(accessoryData.description)}</strong>
                    </Typography>
                  </td>
                ) : (
                  ''
                )}
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );

  const handleShowAccessories = () => {
    const accessories = accessoriesValue;
    if (accessories?.length) {
      if (typeof accessories === 'string') {
        const accessoriesObject = JSON.parse(accessories);
        if (accessoriesObject[0]?.code !== '') return renderAccessories(accessoriesObject);
      } else return renderAccessories(accessoriesValue);
    }
    return <span></span>;
  };

  return handleShowAccessories();
};

export const AccessoriesTable = styled(({ ...props }) => <CleanAccessoriesTable {...props} />)`
  border-spacing: 0.8rem;
  .row {
    margin-top: 0.5rem;
  }
  .margin-top {
    margin-top: 0.6rem;
  }
`;
