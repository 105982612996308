import React, { useEffect } from 'react';
import { Modal, Backdrop, Fade, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { Carrousel } from '../Carrousel';
import icon_cross from '../../../../../../assets/img/icons_dynamo/icon_cross.svg';
import { SheetJSApp } from '../ImportCustomers';
// eslint-disable-next-line no-unused-vars
import { IUsersProps } from '../../../../../Users/models';

interface IModalProps {
  className: string;
  icon?: React.ReactNode;
  iconColor?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  twoButtons?: boolean;
  buttonColor?: string;
  secondButtonColor?: string;
  buttonText?: string;
  secondButtonText?: string;
  onClickButton(): void;
  onClickSecondButton(): void;
  open: boolean;
  handleSubmit?: any;
  handleOrderListChange?: void;
  orderFields?: any;
  cleanOrderListSubmit?: void;
  handleCloseModal?: any;
  slideIndex?: number;
  setSlideIndex?: any;
  contactsStore: any;
  contactInfoFetch?: any;
  contactInfoStore?: any;
  getContactsFetch: any;
  usersStore: IUsersProps;
  listUsers: any;
  getUsersFetch(page_len: number, page: number): void;
  setContactInfoForm: any;
  contactInfoForm: any;
  hasResponsible: any;
  setHasResposible: any;
  initialContactInfoForm: any;
}

const CleanModalComponent: React.FC<IModalProps> = (props: IModalProps) => {
  const {
    className,
    open,
    handleCloseModal,
    slideIndex = 0,
    setSlideIndex,
    getContactsFetch,
    usersStore,
    getUsersFetch,
    setContactInfoForm,
    contactInfoForm,
    hasResponsible,
    setHasResposible,
    initialContactInfoForm
  } = props;

  const [listUsers, setListUsers] = React.useState<any[]>([]);

  React.useEffect(() => {
    getUsersFetch(15, 1);
  }, []);

  useEffect(() => {
    usersStore.users?.length &&
      setListUsers(
        usersStore.users.map((user: any) => {
          return {
            kaiser_username: user.username && user.username,
            first_name: user.first_name && user.first_name,
            last_name: user.last_name && user.last_name,
            partner_id: user.partner_id && user.partner_id
          };
        })
      );
  }, [usersStore.users]);

  return (
    <Modal className={className} open={open} closeAfterTransition BackdropComponent={Backdrop}>
      <Fade in={open}>
        <Grid className='modal-content' container direction='column' alignItems='center'>
          <Grid item className='title-container-import'>
            <img src={icon_cross} width='15rem' height='auto' className='icon-cross' onClick={handleCloseModal} />
          </Grid>
          {slideIndex < 4 ? (
            <>
              <Carrousel
                slideIndex={slideIndex}
                setSlideIndex={setSlideIndex}
                initialContactInfoForm={initialContactInfoForm}
                handleCloseModal={handleCloseModal}
                setListUsers={setListUsers}
                listUsers={listUsers}
                setContactInfoForm={setContactInfoForm}
                contactInfoForm={contactInfoForm}
                hasResponsible={hasResponsible}
                setHasResposible={setHasResposible}
              />
            </>
          ) : (
            <SheetJSApp
              {...props.contactsStore}
              contactInfoFetch={props.contactInfoFetch}
              contactInfoStore={props.contactInfoStore}
              handleCloseModal={handleCloseModal}
              getContactsFetch={getContactsFetch}
              contactInfoForm={contactInfoForm}
              hasResponsible={hasResponsible}
            />
          )}
        </Grid>
      </Fade>
    </Modal>
  );
};

export const ModalImportCustomersComponent = styled(({ ...props }) => <CleanModalComponent {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  .main-container {
    width: 100%;
  }
  .title-container-import {
    text-align: end;
    width: 100%;
    padding: 1rem;
  }
  .icon-cross {
    cursor: pointer;
  }
  .title {
    font-family: Rubik;
    width: 100%;
    text-align: left;
    font-size: 1.125rem;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0 0.5rem 0;
  }
  .modal-content {
    width: 429px;
    background-color: var(--color-white);

    border: none;
    border-radius: 5px;
    text-align: center;
    &:focus {
      outline: none;
    }
    & > * {
      padding: 1em;
    }
    .first-button {
      background-color: #ffffff;
      border: 1px solid grey;
      color: #000000;
      font-size: 0.8rem;
    }
    .second-button {
      background-color: ${(props) => props.secondButtonColor};
      margin-left: 1.5rem;
      font-size: 0.8rem;
    }
    svg > path {
      fill: ${(props) => props.iconColor};
    }
  }
`;
