import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// import { useKeycloak } from '@react-keycloak/web';
import { Analytics } from '@wdynamo/common/lib/services';
import { Redirect } from 'react-router-dom';
import { TextPoint } from '@wdynamo/common';
import { uris } from '../../../siteMap';
import { Container, Grid } from '@material-ui/core';
import { Stepper, StyledButton, SuccessModal } from '@wdynamo/common/lib/components';
// import CancelIllustration from '../../../assets/img/illustrations/CancelIllustration.jpg';
// import CreatingOrderIllustration from '../../../assets/img/illustrations/CreatingOrderIllustration.svg';
import SuccessIllustration from '../../../assets/img/illustrations/SuccessIllustration.jpg';
// import ErrorIllustration from '../../../assets/img/illustrations/ErrorIllustration.svg';
import { InfoForm } from '../components';
import { PriceForm } from '../components';
import { ComissionForm } from '../components';
import { PaymentsForm } from '../components';
import { CustomFieldsForm } from '../components';

const CleanNewServiceComponent: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  // const { keycloak } = useKeycloak();

  const initialServiceForm = {
    name: { value: '', error: false, isRequired: false, maxLength: 25 },
    subtitle: { value: '', error: false, isRequired: false, maxLength: 47 },
    description: { value: '', error: false, isRequired: false, maxLength: 132 },
    commercial: { value: '', error: false, isRequired: false },
    code: { value: '', error: false, isRequired: false },
    tags: { value: [], error: false, isRequired: false },
    price: { value: '', error: false, isRequired: false },
    currency: { value: '', error: false, isRequired: false },
    clarification: { value: '', error: false, isRequired: false, maxLength: 155 },
    image: { value: '', error: false, isRequired: false },
    paymentMethods: { value: [], error: false, isRequired: false },
    salesChannels: { value: [], error: false, isRequired: false },
    serviceState: { value: true, error: false, isRequired: false },
    comission: { value: 0, error: false, isRequired: false },
    customFields: { value: [], error: false, isRequired: false },
    logo_filename: {
      value: 'https://i.pinimg.com/originals/2b/08/85/2b0885f4cd9af3b60cd730bddb7d376d.jpg',
      error: false,
      isRequired: false
    }
  };
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [service, setService] = React.useState<any>(initialServiceForm);

  const steps = [
    t('SERVICES.NEW_SERVICE.STEP_1.STEPPER'),
    t('SERVICES.NEW_SERVICE.STEP_2.STEPPER'),
    t('SERVICES.NEW_SERVICE.STEP_3.STEPPER'),
    t('SERVICES.NEW_SERVICE.STEP_4.STEPPER'),
    t('SERVICES.NEW_SERVICE.STEP_5.STEPPER')
  ];

  const defaultRedirect = <Redirect to={uris.services.uri} />;

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  useEffect(() => {
    Analytics().pageview('new-service');
  }, []);

  const handleOnChange = (fieldToUpdate: string, value: string) => {
    setService({ ...service, [fieldToUpdate]: { ...service[fieldToUpdate], value: value } });
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <InfoForm
            service={service}
            handleOnChange={handleOnChange}
            commercials={[{ name: 'Rubro 1' }, { name: 'Rubro 2' }]}
          />
        );
      case 1: {
        return (
          <PriceForm
            service={service}
            handleOnChange={handleOnChange}
            currencies={[{ name: 'USD' }, { name: 'PYG' }, { name: 'ARS' }]}
          />
        );
      }
      case 2:
        return <PaymentsForm onChange={setService} service={service} />;
      case 3:
        return <ComissionForm service={service} handleOnChange={handleOnChange} />;
      case 4:
        return <CustomFieldsForm service={service} onChange={setService} />;
      case 5:
        return (
          <SuccessModal
            open={true}
            handleClose={() => window.location.replace(uris.services.uri)}
            onClickConfirm={() => window.location.replace(uris.services.uri)}
            img={SuccessIllustration}
            messages={{
              TITLE: t('SERVICES.NEW_SERVICE.FINISHED.SUCCESS.TITLE'), // translate properly
              CONTINUE_BUTTON: t('COMMONS.BUTTONS.CONTINUE')
            }}
            description={t('SERVICES.NEW_SERVICE.FINISHED.SUCCESS.TITLE')}
          />
        );
      default:
        return defaultRedirect;
    }
  };

  const renderForm = () => {
    return (
      <div className={`${props.className} new-service`}>
        <Stepper activeStep={activeStep} steps={steps} className='new-service-stepper' />
        <Grid>
          <h1>
            {t('SERVICES.NEW_SERVICE.TITLE')}
            <TextPoint />
          </h1>
        </Grid>
        <div>{getStepContent(activeStep)}</div>
        <div className='stepper-buttons'>
          <Grid item xs={12}>
            <StyledButton onClick={handleBack} disabled={activeStep === 0} className='secondary cancel'>
              {activeStep === 0 ? t('SERVICES.NEW_SERVICE.BUTTONS.CANCEL') : t('SERVICES.NEW_SERVICE.BUTTONS.GOBACK')}
            </StyledButton>
            <StyledButton onClick={handleNext} className='default continue'>
              {activeStep === steps.length - 1
                ? t('SERVICES.NEW_SERVICE.BUTTONS.FINISH')
                : t('SERVICES.NEW_SERVICE.BUTTONS.CONTINUE')}
            </StyledButton>
          </Grid>
        </div>
      </div>
    );
  };
  return <Container>{renderForm()}</Container>;
};
export const NewServiceComponent = styled(({ ...props }) => <CleanNewServiceComponent {...props} />)`
  .stepper-buttons {
    margin-top: 55px;
    display: flex;
    text-align: center;
  }
  .cancel,
  .continue {
    padding: 10px 50px;
    border-radius: 25px;
    text-transform: none;
    font-size: 16px;
  }
  .cancel {
    background-color: transparent;
    box-shadow: none;
    color: var(--color-grey-text);
    border: 1px solid transparent;
    &:hover {
      border: 1px solid var(--color-grey-border);
    }
  }
  .continue {
    background-color: var(--color-main);
    color: var(--color-white);
  }
`;
