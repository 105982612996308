import { ACTIONS } from '../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IgetQouteManagementParams } from './models';

// TODO: This is an example
export const getQouteManagementInfoFetch = (params: IgetQouteManagementParams) => ({
  type: ACTIONS.QUOTE_MANAGEMENT.GET_QUOTES_INFO.FETCH,
  payload: { params }
});

export const getQouteManagementInfoSuccess = (data: any) => ({
  type: ACTIONS.QUOTE_MANAGEMENT.GET_QUOTES_INFO.SUCCESS,
  payload: data
});

export const getQouteManagementInfoFail = (error: Error) => ({
  type: ACTIONS.QUOTE_MANAGEMENT.GET_QUOTES_INFO.FAIL,
  payload: error
});

//trae todos los items
export const getAllItemsFetch = (params: IgetQouteManagementParams) => ({
  type: ACTIONS.QUOTE_MANAGEMENT.GET_ALL_QUOTES_INFO.FETCH,
  payload: { params }
});

export const getAllItemsSuccess = (data: any) => ({
  type: ACTIONS.QUOTE_MANAGEMENT.GET_ALL_QUOTES_INFO.SUCCESS,
  payload: data
});

export const getAllItemsFail = (error: Error) => ({
  type: ACTIONS.QUOTE_MANAGEMENT.GET_ALL_QUOTES_INFO.FAIL,
  payload: error
});

// Get products
export const getProductsFetch = () => ({
  type: ACTIONS.QUOTE_MANAGEMENT.GET_PRODUCTS.FETCH
});
export const getProductsSuccess = (data: any) => ({
  type: ACTIONS.QUOTE_MANAGEMENT.GET_PRODUCTS.SUCCESS,
  payload: data
});
export const getProductsFail = (error: object) => ({
  type: ACTIONS.QUOTE_MANAGEMENT.GET_PRODUCTS.FAIL,
  payload: { error: error }
});

//clear all items
export const getClearAllItemsFetch = () => ({
  type: ACTIONS.QUOTE_MANAGEMENT.CLEAR_ALL_QUOTES.FETCH,
  payload: {}
});
