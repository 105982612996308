import { connect } from 'react-redux';
import { CustomFieldsFormComponent } from './view';
import { getTypesFetch } from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../store';

const mapStateToProps = (state: RootState) => ({
  customFieldsFormStore: state.customFieldsFormState
});
const mapDispatchToProps = { getTypesFetch };

export const CustomFieldsForm = connect(mapStateToProps, mapDispatchToProps)(CustomFieldsFormComponent);

export { customFieldsFormReducer } from './reducers';
export { customFieldsFormSaga } from './sagas';
export { CustomFieldsFormComponent } from './view';
