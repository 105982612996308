import React from 'react';
import { Box, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../assets/img/icons_dynamo/icon_close.svg';
import { ReactComponent as TrashIcon } from '../../assets/img/icons_dynamo/icon_trash.svg';

interface ICheckBoxListProps {
  listChecked: any[];
  setListChecked(list: any[]): void;
  list: any[];
  title: string;
  className?: string;
}

const CleanCheckBoxList: React.FC<ICheckBoxListProps> = (props: ICheckBoxListProps) => {
  const { listChecked, setListChecked, list, title } = props;

  const handleClearChecked = () => () => setListChecked([]);

  const handleToggleCheck = (item: any) => {
    const newChecked = [...listChecked];
    const currentIndex = listChecked.findIndex((listItem) => listItem.id === item.id);

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setListChecked(newChecked);
  };

  return (
    <Grid item xs={12} className={props.className}>
      <Typography>{title}</Typography>

      <Box className='box-container'>
        <Grid container>
          <Grid item container xs={10} direction='row' justify='flex-start' alignItems='center'>
            {listChecked.map((item, i) => (
              <div className='removable-item' key={i}>
                {item.name} <CloseIcon className='close-icon' onClick={() => handleToggleCheck(item)} />
              </div>
            ))}
          </Grid>
          <Grid item container xs={2} justify='flex-end'>
            <TrashIcon className='trash-icon' onClick={handleClearChecked()} />
          </Grid>
        </Grid>
        <hr />
        <List className='checkbox-list'>
          {list?.map((item, i) => {
            const labelId = `checkbox-list-label-${item.name}`;

            return (
              <ListItem key={i} dense button onClick={() => handleToggleCheck(item)}>
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    checked={listChecked.findIndex((listCheckedItem) => listCheckedItem.id === item.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${item.name}`} />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Grid>
  );
};

export const CheckBoxList = styled(({ ...props }) => <CleanCheckBoxList {...props} />)`
  .box-container {
    border: var(--color-grey-border) solid 1px;
  }
  .checkbox-list {
    max-height: 13em;
    overflow: auto;
  }
  .removable-item {
    padding: 0.6em;
    padding-bottom: 0;
  }
  #iconClose-Group {
    fill: red;
  }
  .close-icon {
    max-width: 15px;
    max-height: 15px;
    cursor: pointer;
    padding-top: 5px;
  }
  hr {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  .trash-icon {
    max-width: 20px;
    cursor: pointer;
    padding-top: 5px;
    padding-right: 10px;
  }
`;
