import MOCK_SERVICE from '../../../assets/mocks/Services/service';

const ApiMock = () => {
  // eslint-disable-next-line no-unused-vars
  const updateServiceFetch = (data: any): Promise<unknown> =>
    new Promise((res) => {
      res(MOCK_SERVICE);
    }).then((res) => res);

  return { updateServiceFetch };
};

export default ApiMock;
