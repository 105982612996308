import { connect } from 'react-redux';
import { RolesListComponent } from './view';
import { getRolesFetch, deleteRoleFetch } from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../../store';

const mapStateToProps = (state: RootState) => ({
  rolesStore: state.rolesState
});
const mapDispatchToProps = { getRolesFetch, deleteRoleFetch };

export const RolesList = connect(mapStateToProps, mapDispatchToProps)(RolesListComponent);

export { rolesListSaga } from './sagas';
export { rolesListReducer } from './reducers';
