import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input } from '@wdynamo/common';
import { Analytics } from '@wdynamo/common/lib/services';
import { PlanBox } from './components/PlanBox';
import { LoadingPlanBox } from './components/LoadingPlanBox';
import { Box, Grid, MenuItem, Typography, Slider, InputAdornment, TextField } from '@material-ui/core';
import { NavButtons } from '../../common/NavButtons';
import { usePrevious } from '../../../../../../hooks/usePrevius';

// icon
import { ReactComponent as DownloadArrowIcon } from '../../../../../../assets/img/icons_dynamo/icon_arrow_download.svg';
import { ReactComponent as CheckIcon } from '../../../../../../assets/img/icons_dynamo/icon_check.svg';

/* eslint-disable no-unused-vars */
import {
  ICustomDataForm,
  IQuotationInput,
  ICommercialPlanParams,
  IBillingModeParams,
  IPaymentMethodParams,
  IPaymentConditionParams
} from '../models';
import moment from 'moment';
import Api from '../Api';
import { UnderlineButton } from '../../../../../../components/UnderlineButton';
import { Spinner } from '../../common/Spinner';
/* eslint-enable no-unused-vars */

//swiper
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import SwiperCore, { Navigation } from 'swiper';

interface IPlansProp {
  className?: string;
  stepsStore: any;
  dataForm: ICustomDataForm;
  onChange(data: ICustomDataForm): void;
  nextStep(): void;
  getPlans(requoteParams?: any): void;
  plansData: any;
  productInfo: any;
  formatPrice(number: number): string;
  producerList: { id: number; channel: string; name: string; producerCode: number }[];
  setProducer(value: any): void;
  producer: any;
  getCommercialPlanFetch(params: ICommercialPlanParams): void;
  getBillingModeFetch(params: IBillingModeParams): void;
  getPaymentMethodFetch(params: IPaymentMethodParams): void;
  getPaymentConditionFetch(params: IPaymentConditionParams): void;
  savePlan(planParams: any): void;
}

const CleanPlansComponent: React.FC<IPlansProp> = (props: IPlansProp) => {
  const { t } = useTranslation();
  const {
    dataForm,
    stepsStore,
    onChange,
    nextStep,
    getPlans,
    plansData,
    productInfo,
    formatPrice,
    producerList,
    setProducer,
    producer,
    getCommercialPlanFetch,
    getBillingModeFetch,
    getPaymentMethodFetch,
    getPaymentConditionFetch,
    savePlan
  } = props;
  const commercialPlansList = stepsStore.commercialPlan.data;
  const prevProducerCode = usePrevious(dataForm.plans.producer_code.value);
  const prevCommercialPlan = usePrevious(dataForm.plans.commercial_plan.value);
  const prevPaymentMethod = usePrevious(dataForm.plans.payment_method.value);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [billingModeList, setBillingModeList] = useState([]);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [paymentConditionList, setPaymentConditionList] = useState([]);

  const [billingSelected, setBillingSelected] = useState<string>('');
  const [paymentMethodSelected, setPaymentMethodSelected] = useState<string>('');
  const [paymentConditionSelected, setPaymentConditionSelected] = useState<string>('');

  useEffect(() => {
    Analytics().pageview('car-badget | plans');
    if (dataForm.plans.producer_code.value) {
      getCommercialPlan();
    }
    if (dataForm.plans.commercial_plan.value) {
      getBillingMode();
    }
  }, []);

  useEffect(() => {
    if (prevProducerCode !== undefined && prevProducerCode !== dataForm.plans.producer_code.value) {
      dataForm.plans.commercial_plan.value = '';
      getCommercialPlan();
    }
  }, [dataForm.plans.producer_code.value]);

  useEffect(() => {
    setBillingModeList(stepsStore.billingMode.data);
  }, [stepsStore.billingMode.data]);

  useEffect(() => {
    setPaymentMethodList(stepsStore.paymentMethod.data);
  }, [stepsStore.paymentMethod.data]);

  useEffect(() => {
    setPaymentConditionList(stepsStore.paymentCondition.data);
  }, [stepsStore.paymentCondition.data]);

  useEffect(() => {
    if (prevCommercialPlan !== undefined && prevCommercialPlan !== dataForm.plans.commercial_plan.value) {
      dataForm.plans.payment_method.value = '';
      setPaymentMethodSelected('');
    }
  }, [dataForm.plans.commercial_plan.value]);

  useEffect(() => {
    if (prevPaymentMethod !== undefined && prevPaymentMethod !== dataForm.plans.payment_method.value) {
      dataForm.plans.payment_condition.value = '';
      setPaymentConditionSelected('');
    }
  }, [dataForm.plans.payment_method.value]);

  const getCommercialPlan = () => {
    getCommercialPlanFetch({
      branch: productInfo?.branch,
      policyType:
        productInfo?.policytype?.length > 0
          ? productInfo.policytype[0]
          : productInfo?.sub_product_list[0]?.sub_branch_id,
      producer: dataForm.plans.producer_code.value
    });
  };

  const getBillingMode = () => {
    const producer = dataForm?.plans?.producer_code?.value;
    const commercialPlan = dataForm?.plans?.commercial_plan?.value;
    const currency = '';
    if (dataForm.plans.commercial_plan.value) {
      getBillingModeFetch({
        productId: '1',
        subProductId: '1',
        producer: producer,
        commercialPlan: commercialPlan,
        currency: currency
      });
    }
  };

  const getPaymentMethod = () => {
    const commercialPlan = dataForm.plans.commercial_plan.value;
    const currency = '';
    const billingMode = dataForm?.plans?.billing_mode?.value || stepsStore?.billingMode[0]?.data?.value;
    if (dataForm.plans.billing_mode.value) {
      getPaymentMethodFetch({
        productId: '1',
        subProductId: '1',
        commercialPlan: commercialPlan,
        billingMode: billingMode,
        currency: currency
      });
    }
  };

  const getPaymentCondition = () => {
    const producer = dataForm.plans.producer_code.value;
    const policyType = productInfo?.policytype
      ? productInfo.policytype[0]
      : productInfo.sub_product_list[0].sub_branch_id;
    const commercialPlan = dataForm.plans.commercial_plan.value;
    const currency = '';
    const company = '1';
    const branch = productInfo.branch;
    const date = moment(productInfo.updatedAt).format('YYYYMMDD');

    getPaymentConditionFetch({
      company: company,
      branch: branch,
      date: date,
      producer: producer,
      commercialPlan: commercialPlan,
      billingMode: dataForm.plans.billing_mode.value,
      currency: currency,
      policyType: policyType,
      paymentMethod: dataForm.plans.payment_method.value
    });
  };

  /*  const requoteButtonIsDisabled = (): boolean => {
    return !fopIsChanged() && dataForm.plans.bonus.value === plansData?.data?.quote?.bonus_percentage;
  };
*/
  const fopIsChanged = (): boolean => {
    return (
      dataForm.plans.billing_mode.value !== plansData?.data?.quote?.billingMode ||
      dataForm.plans.payment_method.value !== plansData?.data?.quote?.paymentMethod
    );
  };

  const handleSelectPlan = (value: any) => {
    const prevState = { ...dataForm };
    const producerCode = dataForm.plans.producer_code.value;
    const producerDesc = producer.value.name;
    prevState.plans.name.value = value.text;
    prevState.plans.code.value = value?.plan_code;
    // prevState.plans.first_payment.value = value.first_payment;
    prevState.plans.price.value = value?.price;
    prevState.plans.remaining_payments.value = value.payments_price;
    savePlan({
      plan: {
        plan: value.text,
        plan_code: value.plan_code,
        first_payment: Number(value.first_payment),
        payment_left: Number(value.payments_price),
        price: Number(value.price),
        bills_amount: value.payments_amount,
        characteristics: value.characteristics
      },
      payment_method: dataForm.plans.payment_method.value, //value?.payment_method
      producer_code: producerCode,
      producer_code_description: producerDesc
    });
    onChange(prevState);
    nextStep();
  };

  const handleProducerCode = (event: any) => {
    const producerId = event.target.value;
    const channelSelected = producerList.find((p: any) => p.id === producerId);
    const producerCode = channelSelected?.producerCode;
    setProducer({
      value: channelSelected
    });
    handleOnChange(producerCode, 'producer_code');
  };

  const handleOnChange = (value: any, field: string) => {
    const prevState = { ...dataForm };
    prevState.plans[field].value = value;
    onChange(prevState);

    if (field === 'producer_code') {
      getCommercialPlan();
    }

    if (field === 'commercial_plan') {
      getBillingMode();
    }

    if (field === 'billing_mode') {
      setBillingSelected(value);
      setPaymentMethodList([]);
      setPaymentMethodSelected('');
      getPaymentMethod();
    }

    if (field === 'payment_method') {
      setPaymentMethodSelected(value);
      setPaymentConditionList([]);
      setPaymentConditionSelected('');
      getPaymentCondition();
    }

    if (field === 'payment_condition') {
      setPaymentConditionSelected(value);
      getPlans();
    }
  };

  const handleChangeBonus = (value: string) => {
    const numberValue = Number(value);
    if (numberValue >= 0 && numberValue <= 20 && numberValue % 5 === 0) handleOnChange(numberValue, 'bonus');
    else window.event?.preventDefault();
  };

  const showMessage = () => {
    const whithoutPlans = plansData.data?.quote?.planList?.length === 0;
    return (
      <Box className='align-text'>
        <Typography variant='h5'>¡Lo sentimos!</Typography>
        <Typography>
          {whithoutPlans ? t('QUOTATIONS.STEP_3.WHITHOUT_PLANS') : t('QUOTATIONS.STEP_3.SERVICE_ERROR')}
        </Typography>
        {!whithoutPlans && <Typography>{plansData.message.errors}</Typography>}
      </Box>
    );
  };

  const handleDownloadPdf = () => {
    setIsLoading(true);
    Api()
      .getDownloadPlans(stepsStore.plans.data?.quote?.id)
      .then((response: any) => {
        if (response) {
          const url = window.URL.createObjectURL(response?.data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'planes.pdf');
          document.body.appendChild(link);
          link.click();
          setIsLoading(false);
        }
        setIsLoading(false);
      });
  };

  const renderPlansCards = () => {
    return plansData?.data?.quote?.planList?.map((plan: any, i: number) => (
      <span key={i}>
        <PlanBox
          key={i}
          data={plan}
          formatPrice={formatPrice}
          currencySimbol={'₲'}
          onSelect={handleSelectPlan}
          bonus={plansData?.data?.quote?.bonusPercentage ? plansData.data.quote.bonusPercentage.toString() : '0'} //TODO: use data from response of quotation pending by juanpi 19-8
        />
      </span>
    ));
  };

  const renderPlans = () => {
    SwiperCore.use([Navigation]);
    const params = {
      slidesPerView: 3,
      spaceBetween: 30,
      grabCursor: true,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    };

    if (plansData.loading)
      return (
        <>
          <LoadingPlanBox />
          <LoadingPlanBox />
          <LoadingPlanBox />
        </>
      );
    else
      return (
        <>
          <Swiper {...params}>{renderPlansCards()}</Swiper>
        </>
      );
  };

  return (
    <>
      <Box className={props.className}>
        {plansData.error || plansData.data?.quote?.planList?.length === 0 ? (
          showMessage()
        ) : (
          <>
            <Grid container spacing={5} alignItems='flex-end'>
              <Grid item xs={12} md={4}>
                <Typography>
                  {/* {t('QUOTATIONS.STEP_2.PRODUCER')} */}
                  {t('QUOTATIONS.STEP_2.CHANNEL')}
                  <span className='colored-mark'>*</span>
                </Typography>
                <TextField
                  error={dataForm?.plans?.producer_code?.error}
                  // value={
                  //   dataForm.plans.producer_code.value ? (dataForm.plans.producer_code.value as string) : ('' as string)
                  // }
                  onChange={(event: any) => handleProducerCode(event)}
                  select
                  fullWidth
                >
                  {producerList.map((producer, index) => (
                    <MenuItem key={index} value={producer?.id}>
                      {producer?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>
                  {t('QUOTATIONS.STEP_2.COMMERCIAL_PLAN')}
                  <span className='colored-mark'>*</span>
                </Typography>
                <TextField
                  error={dataForm?.plans?.commercial_plan?.error}
                  value={
                    dataForm.plans.commercial_plan.value
                      ? (dataForm.plans.commercial_plan.value as string)
                      : ('' as string)
                  }
                  onChange={(event) => handleOnChange(event.target.value, 'commercial_plan')}
                  disabled={!commercialPlansList.length}
                  select
                  fullWidth
                >
                  {commercialPlansList.map((producer: IQuotationInput) => (
                    <MenuItem key={String(producer.code)} value={String(producer.code)}>
                      {producer.description}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>
                  {t('QUOTATIONS.STEP_1.BILLING_MODE')}
                  <span className='colored-mark'>*</span>
                </Typography>
                <TextField
                  // defaultValue={billingSelected}
                  error={dataForm?.plans?.billing_mode?.error}
                  value={billingSelected}
                  onChange={(event) => handleOnChange(event.target.value, 'billing_mode')}
                  disabled={!billingModeList.length}
                  select
                  fullWidth
                >
                  {billingModeList.map((item: IQuotationInput) => (
                    <MenuItem key={String(item.code)} value={String(item.code)}>
                      {item.description}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>
                  {t('QUOTATIONS.STEP_1.PAYMENT_METHOD')}
                  <span className='colored-mark'>*</span>
                </Typography>
                <TextField
                  error={dataForm?.plans?.payment_method?.error}
                  value={paymentMethodSelected}
                  onChange={(event) => handleOnChange(event.target.value, 'payment_method')}
                  disabled={!paymentMethodList.length}
                  required
                  select
                  fullWidth
                >
                  {paymentMethodList.map((item: IQuotationInput) => (
                    <MenuItem key={String(item.code)} value={String(item.code)}>
                      {item.description}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>
                  {t('QUOTATIONS.STEP_1.PAYMENT_CONDITION')}
                  <span className='colored-mark'>*</span>
                </Typography>
                <TextField
                  error={dataForm?.plans?.payment_condition?.error}
                  value={paymentConditionSelected}
                  onChange={(event) => handleOnChange(event.target.value, 'payment_condition')}
                  disabled={!paymentMethodSelected /*!paymentConditionList.length*/}
                  required
                  select
                  fullWidth
                >
                  {paymentConditionList.map((item: IQuotationInput) => (
                    <MenuItem key={String(item.code)} value={String(item.code)}>
                      {item.description}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={9} md={2}>
                <Typography>{t('QUOTATIONS.STEP_3.BONUS')}</Typography>
                <Slider
                  value={dataForm?.plans?.bonus?.value as number}
                  step={5}
                  max={20}
                  onChange={(event, value) => handleOnChange(value, 'bonus')}
                />
              </Grid>
              <Grid item xs={3} md={1}>
                <Input
                  type='number'
                  subInputProps={{ style: { textAlign: 'center' } }}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>%</InputAdornment>
                  }}
                  value={dataForm?.plans?.bonus?.value}
                  onChange={handleChangeBonus}
                  size='small'
                  required
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <UnderlineButton
                  label='Recotizar'
                  onClick={() => {
                    plansData.error
                      ? getPlans()
                      : getPlans({
                          billing_method: dataForm.plans.billing_mode.value,
                          payment_method: dataForm.plans.payment_method.value,
                          payment_condition: dataForm.plans.payment_condition.value,
                          request: plansData.data.quote.quotationExternalID,
                          fop_changed: fopIsChanged(),
                          sub_product_id: '1',
                          producer_code: dataForm.plans.producer_code.value,
                          commercial_plan: dataForm.plans.commercial_plan.value,
                          bonus_percentage: dataForm.plans.bonus.value
                        });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='center' spacing={2} className='plans'>
              {renderPlans()}
            </Grid>
            {plansData?.data?.quote?.planList ? (
              <>
                {plansData?.data?.quote?.planList[0].payments_amount !== 1 && (
                  <Typography className='note'>{t('QUOTATIONS.STEP_4.NOTE')}</Typography>
                )}

                <Typography className='green-title'>
                  <CheckIcon className='green-icon' width='24' height='24' />
                  {t('QUOTATIONS.STEP_4.RELIABLE_INFO.TITLE')}
                </Typography>
                <Typography className='margin'>{t('QUOTATIONS.STEP_4.RELIABLE_INFO.DESCRIPTION')}</Typography>
                {!plansData.loading &&
                  (isLoading ? (
                    <Spinner />
                  ) : (
                    <UnderlineButton
                      onClick={handleDownloadPdf}
                      label='Descargar planes PDF'
                      icon={<DownloadArrowIcon width='16' height='16' />}
                    />
                  ))}
              </>
            ) : (
              ''
            )}
          </>
        )}
      </Box>
      <NavButtons {...props} />
    </>
  );
};
export const PlansComponent = styled(({ ...props }) => <CleanPlansComponent {...props} />)`
  .swiper-container {
    height: 100%;
    max-height: 100vw;
    min-height: 0;
    min-width: 0;
    max-width: 70vw;
    width: 100%;
    overflow: hidden;
  }
  .swiper-slide {
    width: 100%;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
  }
  .swiper-wrapper {
    width: 100%;
    max-height: 100%;
    height: 100%;
    display: flex;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 693px;
  }
  .swiper-button-prev {
    left: 262px;
  }
  .note {
    padding-top: 2em;
  }
  .green-title {
    color: var(--color-green);
    display: flex;
    align-items: center;
    margin-top: 2em;
  }
  .green-icon {
    fill: var(--color-green);
    margin-right: 2px;
  }
  .margin {
    margin: 1px 26px 10px;
  }
  .plans {
    margin-top: 2em;
  }
  .cards {
    flex-flow: wrap-reverse;
  }
  .align-text {
    text-align: center;
  }
  .MuiSlider-rail {
    height: 8px;
    border-radius: 4px;
  }
  .MuiSlider-track {
    height: 8px;
    border-radius: 4px;
  }
  .MuiSlider-thumb {
    height: 24px;
    width: 24px;
    margin: -8px 0 0 -12px;
  }
`;
