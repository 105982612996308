import React from 'react';
import { IMaskInput } from 'react-imask';

const AlphaMask = React.forwardRef<HTMLElement, any>(function alphaMask(props, ref) {
  const { onChange, ...other } = props;

  const regex = /^[A-Za-zÁÉÍÓÚÑáéíóúñ ]*$/;

  return (
    <IMaskInput
      {...other}
      mask={regex}
      inputRef={ref}
      onAccept={(value: string) => onChange({ target: { value, name: props.name } })}
    />
  );
});

export default AlphaMask;
