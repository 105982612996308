import React from 'react';
import { IMaskInput } from 'react-imask';

const RucMask = React.forwardRef<HTMLElement, any>(function rucMask(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;

  const masks = [
    {
      mask: '0000000-0'
    },
    {
      mask: '00000000-0'
    }
  ];

  return (
    <IMaskInput
      {...other}
      mask={masks}
      inputRef={ref}
      minLength={9}
      maxLength={10}
      // eslint-disable-next-line react/prop-types
      onAccept={(value: any) => onChange({ target: { value, name: props.name } })}
    />
  );
});

export default RucMask;
