import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { IgetQouteManagementParams } from './models';

class Api {
  getQouteManagementInfoFetch = (params: IgetQouteManagementParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/quotation`, { params });

  getAllItemsFetch = (params: IgetQouteManagementParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/quotation`, { params });

  getProductsFetch = (): Promise<unknown> => axios.get(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/product`);

  getDownloadPDF = (id: any): Promise<unknown> =>
    axios({
      url: `${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/quotation/${id}/printApplicationRequest`,
      method: 'POST',
      responseType: 'blob' // important
    }).then((response) => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'solicitud-de-emision.pdf');
      document.body.appendChild(link);
      link.click();
    });
}

export default Api;
