// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IMySalesProps } from './models';

const initialState: IMySalesProps = {
  groups: {
    loading: false,
    error: false,
    items: []
  },
  table: {
    loading: false,
    error: false,
    nextPage: undefined,
    items: []
  },
  dashboard: {
    loading: false,
    error: false,
    items: []
  }
};

export const mySalesReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    // Table
    case ACTIONS.MY_SALES.TABLE.FETCH:
      return {
        ...state,
        table: {
          ...state.table,
          loading: true,
          error: false,
          items: []
        }
      };
    case ACTIONS.MY_SALES.TABLE.NEXT_PAGE:
      return {
        ...state,
        table: {
          ...state.table,
          loading: true
        }
      };
    case ACTIONS.MY_SALES.TABLE.SUCCESS:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          nextPage: action.payload.data.next,
          items: [...state.table.items, ...action.payload.data.items]
        }
      };
    case ACTIONS.MY_SALES.TABLE.FAIL:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          error: true
        }
      };

    // Dashboard
    case ACTIONS.MY_SALES.DASHBOARD.FETCH:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: true,
          error: false,
          items: []
        }
      };
    case ACTIONS.MY_SALES.DASHBOARD.SUCCESS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: false,
          items: action.payload.data.items
        }
      };
    case ACTIONS.MY_SALES.DASHBOARD.FAIL:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: false,
          error: true
        }
      };
    // get groups
    case ACTIONS.MY_SALES.GROUPS.FETCH:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: true,
          error: false,
          items: []
        }
      };
    case ACTIONS.MY_SALES.GROUPS.SUCCESS:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: false,
          items: action.payload.data
        }
      };
    case ACTIONS.MY_SALES.GROUPS.FAIL:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: false,
          error: true
        }
      };

    default:
      return state;
  }
};
