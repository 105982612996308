import { connect } from 'react-redux';
import { ContactDetailsComponent } from './view';
import { contactDetailsFetch, changeStatusFetch } from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../store';
import { getContactsFetch } from '../../actions';

const mapStateToProps = (state: RootState) => ({
  contactDetailsStore: state.contactDetailsState
});
const mapDispatchToProps = {
  contactDetailsFetch,
  getContactsFetch,
  changeStatusFetch
};

export const ContactDetails = connect(mapStateToProps, mapDispatchToProps)(ContactDetailsComponent);
export { contactDetailsReducer } from './reducers';
export { contactDetailsSaga } from './sagas';
