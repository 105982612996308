import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTabs } from '@wdynamo/common';
import { MostSoldItems } from './components/MostSoldItems';
import { TopSalesTeams } from './components/TopSalesTeams';
import { TopSalesmen } from './components/TopSalesmen';

interface IReportsTabProps {
  products: any;
  salesTeams: any;
  salesmen: any;
  getTopProductsFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    items_limit: number
  ): void;
  getTopSalesTeamsFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    items_limit: number
  ): void;
  getTopSalesmenFetch(
    from_date: string,
    to_date: string,
    include_children: boolean,
    sort: string,
    items_limit: number
  ): void;
  className?: string;
  [key: string]: any;
}

const CleanReportsTabs: React.FC<IReportsTabProps> = (props: IReportsTabProps) => {
  const { t } = useTranslation();
  const { products, salesTeams, salesmen } = props;
  const [value, setValue] = useState(0);

  const initialTabsHeaders = [
    {
      index: 0,
      label: t('REPORTS.TABS.TOP_PRODUCTS.TITLE')
    },

    {
      index: 1,
      label: t('REPORTS.TABS.TOP_SALESMEN.TITLE')
    },
    {
      index: 2,
      label: t('REPORTS.TABS.TOP_SALESTEAMS.TITLE')
    }
  ];

  const tabsHeaders = initialTabsHeaders;

  const tabsPanels = [
    { index: 0, content: <MostSoldItems products={products} getTopProductsFetch={props.getTopProductsFetch} /> },

    { index: 1, content: <TopSalesmen salesmen={salesmen} getTopSalesmenFetch={props.getTopSalesmenFetch} /> },
    {
      index: 2,
      content: <TopSalesTeams salesTeams={salesTeams} getTopSalesTeamsFetch={props.getTopSalesTeamsFetch} />
    }
  ];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return <CustomTabs tabHeaders={tabsHeaders} tabPanels={tabsPanels} value={value} handleChange={handleChange} />;
};

export const ReportsTabs = (props: IReportsTabProps) => <CleanReportsTabs {...props} />;
