import { call, put, takeLatest, all, takeEvery } from 'redux-saga/effects';
import Api from './Api';
import ApiMock from './ApiMock';
import {
  getStepsSuccess,
  getStepsFail,
  getBankSuccess,
  getBankFail,
  getCardSuccess,
  getCardFail,
  issueAddSuccess,
  issueAddFail,
  getNationalitySuccess,
  getNationalityFail,
  getProvinceSuccess,
  getProvinceFail,
  getCitySuccess,
  getCityFail,
  getNeighborhoodSuccess,
  getNeighborhoodFail,
  getIdTypeSuccess,
  getIdTypeFail,
  getMaritalStatusSuccess,
  getMaritalStatusFail,
  getActivitySuccess,
  getActivityFail,
  getListSourceSuccess,
  getListSourceFail,
  initializePersistenceSuccess,
  initializePersistenceFail,
  saveFirstStepSuccess,
  saveFirstStepFail,
  saveSecondStepSuccess,
  saveSecondStepFail,
  // saveThirdStepSuccess,
  // saveThirdStepFail,
  saveFourthStepSuccess,
  saveFourthStepFail,
  getQuotationByIdFail,
  getQuotationByIdSuccess,
  getSecondInstallmentDateSuccess,
  getSecondInstallmentDateFail,
  getSourceFundSuccess,
  getSourceFundFail
} from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../ActionTypes';

interface IStepsData {
  data?: any;
}

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

// eslint-disable-next-line no-unused-vars
function* sagasGetStepsFetch(action: actionType) {
  try {
    const response: IStepsData = yield call(api.getStepsFetch, action.payload.params);
    const { data } = response;
    yield put(getStepsSuccess(data));
  } catch (e) {
    yield put(getStepsFail(e));
  }
}

function* sagasGetBankFetch() {
  try {
    const response: any = yield call(api.getBankFetch);
    const { data } = response;
    yield put(getBankSuccess(data));
  } catch (e) {
    yield put(getBankFail(e.response.data));
  }
}

function* sagasGetCardFetch() {
  try {
    const response: any = yield call(api.getCardFetch);
    const { data } = response;
    yield put(getCardSuccess(data));
  } catch (e) {
    yield put(getCardFail(e.response.data));
  }
}

function* sagasGetNationalityFetch() {
  try {
    const response: any = yield call(api.getNationalityFetch);
    const { data } = response;
    yield put(getNationalitySuccess(data));
  } catch (e) {
    yield put(getNationalityFail(e.response.data));
  }
}

function* sagasGetProvinceFetch() {
  try {
    const response: any = yield call(api.getProvinceFetch);
    const { data } = response;
    yield put(getProvinceSuccess(data));
  } catch (e) {
    yield put(getProvinceFail(e.response.data));
  }
}

function* sagasGetCityFetch(action: actionType) {
  try {
    const response: any = yield call(api.getCityFetch, action.payload.params);
    //const { data } = response;
    const data = { data: response.data, path: action.payload.params.path };
    yield put(getCitySuccess(data));
  } catch (e) {
    yield put(getCityFail(e.response.data));
  }
}
function* sagasGetNeighborhoodFetch(action: actionType) {
  try {
    const response: any = yield call(api.getNeighborhoodFetch, action.payload.params);
    // const { data } = response;
    const data = { data: response.data, path: action.payload.params.path };
    yield put(getNeighborhoodSuccess(data));
  } catch (e) {
    yield put(getNeighborhoodFail(e.response.data));
  }
}

function* sagasGetIdTypeFetch() {
  try {
    const response: any = yield call(api.getIdTypeFetch);
    const { data } = response;
    yield put(getIdTypeSuccess(data));
  } catch (e) {
    yield put(getIdTypeFail(e.response.data));
  }
}

function* sagasGetMaritalStatusFetch() {
  try {
    const response: any = yield call(api.getMaritalStatusFetch);
    const { data } = response;
    yield put(getMaritalStatusSuccess(data));
  } catch (e) {
    yield put(getMaritalStatusFail(e.response.data));
  }
}

function* sagasGetActivityFetch() {
  try {
    const response: any = yield call(api.getActivityFetch);
    const { data } = response;
    yield put(getActivitySuccess(data));
  } catch (e) {
    yield put(getActivityFail(e.response.data));
  }
}

function* sagasIssueAddFetch(action: actionType) {
  try {
    const response: any = yield call(api.issueAddFetch, action.payload.params);
    const { data } = response;
    yield put(issueAddSuccess(data));
  } catch (e) {
    const responseData = e.response?.data ?? { error: '', timeout: true };
    yield put(issueAddFail(responseData));
  }
}
function* sagasGetListSourceFetch(action: actionType) {
  try {
    const response: IStepsData = yield call(api.getSourceList, action.payload.params);
    const { data } = response;

    yield put(getListSourceSuccess({ [action.payload.params.field]: data }));
  } catch (e) {
    yield put(getListSourceFail(e));
  }
}

function* sagasInitializePersistenceFetch(action: actionType) {
  try {
    const response: any = yield call(api.initializePersistenceFetch, action.payload.quotationExternalID);
    const { data } = response;
    yield put(initializePersistenceSuccess(data));
  } catch (e) {
    yield put(initializePersistenceFail(e.response.data));
  }
}

function* sagasSaveFirstStepFetch(action: actionType) {
  try {
    const response: any = yield call(api.saveFirstStepFetch, action.payload.issueId, action.payload.params);
    const { data } = response;
    yield put(saveFirstStepSuccess(data));
  } catch (e) {
    yield put(saveFirstStepFail(e.response.data));
  }
}

function* sagasSaveSecondStepFetch(action: actionType) {
  try {
    const response: any = yield call(api.saveSecondStepFetch, action.payload.issueId, action.payload.params);
    const { data } = response;
    yield put(saveSecondStepSuccess(data));
  } catch (e) {
    yield put(saveSecondStepFail(e.response.data));
  }
}

// function* sagasSaveThirdStepFetch(action: actionType) {
//   try {
//     const response: any = yield call(api.saveThirdStepFetch, action.payload.issueId, action.payload.params);
//     const { data } = response;
//     yield put(saveThirdStepSuccess(data));
//   } catch (e) {
//     yield put(saveThirdStepFail(e.response.data));
//   }
// }

function* sagasSaveFourthStepFetch(action: actionType) {
  try {
    const response: any = yield call(api.saveFourthStepFetch, action.payload.issueId, action.payload.params);
    const { data } = response;
    yield put(saveFourthStepSuccess(data));
  } catch (e) {
    yield put(saveFourthStepFail(e.response.data));
  }
}

function* sagasgetQuotationByIdFetch(action: actionType) {
  try {
    const response: any = yield call(api.getQuotationByIdFetch, action.payload.id);
    const { data } = response;
    yield put(getQuotationByIdSuccess(data));
  } catch (e) {
    yield put(getQuotationByIdFail(e.response.data));
  }
}

function* sagasGetSecondInstallmentDateFetch() {
  try {
    const response: any = yield call(api.getSecondInstallmentDateFetch);
    const { data } = response;
    yield put(getSecondInstallmentDateSuccess(data));
  } catch (e) {
    yield put(getSecondInstallmentDateFail(e.response.data));
  }
}

function* sagasGetSourceFundFetch() {
  try {
    const response: any = yield call(api.getSourceFundFetch);
    const { data } = response;
    yield put(getSourceFundSuccess(data));
  } catch (e) {
    yield put(getSourceFundFail(e.response.data));
  }
}

// Listen for redux actions
function* emissionsSaga() {
  yield all([takeLatest(ACTIONS.ISSUE.STEPS.FETCH, sagasGetStepsFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.BANK.FETCH, sagasGetBankFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.CARD.FETCH, sagasGetCardFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.NATIONALITY.FETCH, sagasGetNationalityFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.PROVINCE.FETCH, sagasGetProvinceFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.CITY.FETCH, sagasGetCityFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.NEIGHBORHOOD.FETCH, sagasGetNeighborhoodFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.ID_TYPE.FETCH, sagasGetIdTypeFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.MARITAL_STATUS.FETCH, sagasGetMaritalStatusFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.ACTIVITY.FETCH, sagasGetActivityFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.CREATE.FETCH, sagasIssueAddFetch)]);
  yield all([takeEvery(ACTIONS.ISSUE.LISTSOURCES.FETCH, sagasGetListSourceFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.INITIALIZE_PERSISTENCE.FETCH, sagasInitializePersistenceFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.SAVE_FIRST_STEP.FETCH, sagasSaveFirstStepFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.SAVE_SECOND_STEP.FETCH, sagasSaveSecondStepFetch)]);
  // yield all([takeLatest(ACTIONS.ISSUE.SAVE_THIRD_STEP.FETCH, sagasSaveThirdStepFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.SAVE_FOURTH_STEP.FETCH, sagasSaveFourthStepFetch)]);
  yield all([takeLatest(ACTIONS.QUOTATION_BY_ID.FETCH, sagasgetQuotationByIdFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.SECOND_INSTALLMENT_DATE.FETCH, sagasGetSecondInstallmentDateFetch)]);
  yield all([takeLatest(ACTIONS.ISSUE.SOURCE_FUND.FETCH, sagasGetSourceFundFetch)]);
}

export { emissionsSaga };
