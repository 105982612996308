import { call, put, takeLatest, all } from 'redux-saga/effects';
import Api from './Api';
import ApiMock from './ApiMock';
import {
  getGroupsSuccess,
  getGroupsFail,
  deleteGroupSuccess,
  deleteGroupFail,
  getParentsSuccess,
  getParentsFail
} from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../ActionTypes';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasGetGroupsFetch(action: actionType) {
  try {
    const response = yield call(api.getGroupsFetch, {
      params: {
        parent: action.payload.parent,
        users: action.payload.users
      }
    });
    yield put(getGroupsSuccess(response.data.data.groups));
  } catch (e) {
    yield put(getGroupsFail(e));
  }
}
function* sagasGetParentsFetch(action: actionType) {
  try {
    const response = yield call(api.getParentsFetch, {
      params: {
        parent: action.payload.parent
      }
    });
    yield put(getParentsSuccess(response.data.data.groups));
  } catch (e) {
    yield put(getParentsFail(e));
  }
}

function* sagasRemoveGroupsFetch(action: actionType) {
  try {
    const response = yield call(api.deleteGroupFetch, action.payload.id);
    yield put(deleteGroupSuccess(response.data));
  } catch (e) {
    yield put(deleteGroupFail(e));
  }
}

// Listen for redux actions
function* groupsSaga() {
  yield all([takeLatest(ACTIONS.GROUPS.FETCH, sagasGetGroupsFetch)]);
  yield all([takeLatest(ACTIONS.GROUPS.GROUP_PARENTS.FETCH, sagasGetParentsFetch)]);
  yield all([takeLatest(ACTIONS.GROUPS.REMOVE.FETCH, sagasRemoveGroupsFetch)]);
}

export { groupsSaga };
