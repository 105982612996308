import { call, put, takeLatest, all } from 'redux-saga/effects';

import Api from './Api';
import ApiMock from './ApiMock';
import { userInfoSuccess, userInfoFail } from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../ActionTypes';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasUserInfoFetch(action: actionType) {
  try {
    if (action.payload.userId) {
      yield call(api.updateUserFetch, action.payload.userId, action.payload.data);
    } else {
      yield call(api.addUserFetch, action.payload.data);
    }
    yield put(userInfoSuccess());
  } catch (e) {
    yield put(userInfoFail(e.response.data));
  }
}

// Listen for redux actions
function* userInfoSaga() {
  yield all([takeLatest(ACTIONS.USERS.USER_INFO.FETCH, sagasUserInfoFetch)]);
}

export { userInfoSaga };
