import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { uris } from '../../../../../siteMap';
import { Container, Grid, Box, StepLabel, Step } from '@material-ui/core';
import { Loading, TextPoint, Stepper } from '@wdynamo/common/lib/components';
import { GeneralData } from './GeneralData';
import { ApPlans } from './ApPlans';
import { ApSummary } from './ApSummary/ApSummary';
import { Analytics } from '@wdynamo/common/lib/services';
// eslint-disable-next-line no-unused-vars
import { ICustomApDataForm } from './models';

interface ApQuotationsComponentProps {
  className?: string;
  location: any;
  getApStepsFetch(text: string): void;
  stepsApStore: any;
  createContactFetch(params: any): void;
  getContactsClear(): void;
  getPlansFetch(params: any): void;
  updateQuotationFetch(quotationId: string, params: any): void;
  cleanStore(): void;
}

const initialState: any = {
  general_data: {
    amount_asured: { value: '', isRequired: true, error: false },
    // activity: { value: '', isRequired: true, error: false },
    has_motorcycle: { value: '', isRequired: true, error: false },
    telephone: { value: '', isRequired: true, error: false },
    mail: { value: '', isRequired: true, error: false }
  },
  plans: {
    billing_mode: { value: '', isRequired: true, error: false },
    payment_method: { value: '', isRequired: true, error: false },
    payment_condition: { value: '', isRequired: true, error: false },
    name: { value: '', isRequired: true, error: false },
    code: { value: '', isRequired: true, error: false },
    first_payment: { value: '', isRequired: true, error: false },
    remaining_payments: { value: '', isRequired: true, error: false },
    bonus: { value: '0', isRequired: true, error: false },
    price: { value: '', isRequired: false, error: false },
    characteristics: { value: '', isRequired: false, error: false }
  }
};

let currentQuoteParams: any;

const CleanApQuotationsComponent: React.FC<ApQuotationsComponentProps> = (props: ApQuotationsComponentProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const {
    location,
    getApStepsFetch,
    stepsApStore,
    createContactFetch,
    getContactsClear,
    getPlansFetch,
    updateQuotationFetch,
    // eslint-disable-next-line no-unused-vars
    cleanStore
  } = props;
  const fixedSteps: string[] = [
    t('HOME_QUOTATIONS.STEP_1.LABEL'),
    t('HOME_QUOTATIONS.STEP_2.LABEL'),
    t('HOME_QUOTATIONS.STEP_3.LABEL')
  ];
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [steps, setSteps] = React.useState<string[]>(fixedSteps);
  const [dataForm, setDataForm] = React.useState<ICustomApDataForm>(initialState);
  const [currentId, setCurrentId] = React.useState<number>();
  const [currentContact, setCurrentContact] = React.useState<any>();
  const defaultRedirect = <Redirect to={uris.services.uri} />;
  const productId = location?.state?.service?.id ? location.state.service.id : '3';
  const initialCustomState: any = {};

  useEffect(() => {
    Analytics().pageview('ap-badget');
    if (currentStep && currentStep !== 0 && currentStep !== 1) {
      setCurrentStep(1);
    }
    if (!location?.state?.service?.id && !location?.state?.requote?.customer?.id) {
      history.push(uris?.services.uri);
    }
    return () => {
      // cleanStore();
      setDataForm(initialState);
    };
  }, []);

  useEffect(() => {
    if (location?.state?.service?.id) {
      getApStepsFetch(productId);
      setCurrentStep(0);
    }
  }, [location.state]);

  useEffect(() => {
    if (stepsApStore.steps.error) {
      alert('Ocurrio un error');
      history.push(uris.services.uri);
    }
  }, [stepsApStore.steps.loading]);

  useEffect(() => {
    setSteps(steps);
    setDataForm({ ...dataForm });
  }, [stepsApStore.steps.data]);

  useEffect(() => {
    if (stepsApStore?.createContact?.data?.data?.clientRepresentation[0].id) {
      setCurrentId(stepsApStore.createContact.data.data.clientRepresentation[0].id);
      handleNext();
    }
  }, [stepsApStore.createContact.data]);

  const handleCreateContact = (currentContact?: any) => {
    if (currentContact) {
      setCurrentId(currentContact.id);
      setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
    } else {
      createContactFetch([
        {
          index: 0,
          phone: dataForm.general_data.telephone.value as string,
          mail: dataForm.general_data.mail.value as string
        }
      ]);
    }
  };

  const isValid = (stepName: string): boolean => {
    let valid = true;
    const stepData = dataForm[stepName];
    if (stepData) {
      Object.keys(stepData).forEach((field) => {
        if (stepData[field].isRequired && (stepData[field].value === '' || stepData[field].error)) valid = false;
      });
    }

    return valid;
  };
  const formatPrice = (number: number): string => {
    return number.toLocaleString('es-AR', { maximumFractionDigits: 0 });
  };

  const getPlans = (requoteParams: any) => {
    const subProductId = 3;
    const inputs = {
      has_motorcycle: {
        code: dataForm?.general_data?.has_motorcycle?.value,
        description: ''
      },
      postal_code: {
        code: '000100',
        description: '000100'
      },
      sub_postal_code: {
        code: '0',
        description: '0'
      },
      risk_type_code: {
        code: '0001',
        description: '0001'
      },
      address_street: {
        code: 0,
        description: ''
      },
      address_dto: {
        code: 0,
        description: ''
      },
      address_floor: {
        code: 0,
        description: ''
      }
    };

    currentQuoteParams = requoteParams
      ? {
          product_id: productId,
          customer_id: currentId ? currentId : location?.state?.requote?.customer?.id,
          inputs: inputs,
          ...requoteParams
        }
      : {
          producer_code: '2',
          commercial_plan: 'PCAPM',
          insurance_type: '001',
          bonus_percentage: 0,
          fop_changed: false,
          product_id: productId,
          sub_product_id: subProductId,
          customer_id: currentId,
          inputs: inputs,
          billing_method: dataForm.plans.billing_mode.value, // dataForm.plans.billing_mode.value || '12',
          payment_method: dataForm.plans.payment_method.value, // dataForm.plans.payment_method.value || '2',
          payment_condition: dataForm.plans.payment_condition.value, // dataForm.plans.payment_condition.value || '12',
          request: location?.state?.requote?.quotationExternalId
            ? Number(location.state.requote.quotationExternalId)
            : undefined
        };
    getPlansFetch(currentQuoteParams);
  };

  const handleNext = () => setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);

  const handleBack = () => setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);

  const savePlan = (planParams: any): void => {
    updateQuotationFetch(stepsApStore.plans.data.quote.id, { ...currentQuoteParams, ...planParams });
  };

  const handleCancel = () => {
    const initialForm = { ...initialState };
    initialForm.general_data.amount_asured.value = '';
    initialForm.general_data.has_motorcycle.value = '';
    setDataForm(initialForm);
    history.push(uris?.services.uri);
  };

  const cleanState = () => {
    const cleanForm = {
      ...initialState,
      general_data: initialCustomState
    };
    setCurrentContact(undefined);
    setDataForm(cleanForm);
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <GeneralData
            isFirstStep={true}
            // prevStep={handleBack}
            prevStep={handleCancel}
            nextStep={handleCreateContact}
            // cancel={handleCancel}
            data={stepsApStore.steps.data}
            stepsApStore={stepsApStore}
            dataForm={dataForm}
            onChange={setDataForm}
            currentContact={currentContact}
            setCurrentContact={setCurrentContact}
            getContactsClear={getContactsClear}
            dataContacts={stepsApStore.contacts}
            isNextButtonDisabled={!isValid('general_data')}
          />
        );
      case 1:
        return (
          <ApPlans
            nextStep={handleNext}
            prevStep={handleBack}
            isNextButtonHide={true}
            dataForm={dataForm}
            setDataForm={setDataForm}
            onChange={setDataForm}
            getPlans={getPlans}
            plansData={stepsApStore.plans}
            productInfo={location.state.requote?.product ? location.state.requote.product : location.state.service}
            formatPrice={formatPrice}
            savePlan={savePlan}
          />
        );
      case 2:
        return (
          <ApSummary
            prevStep={handleBack}
            stepsApStore={stepsApStore}
            dataForm={dataForm}
            setCurrentStep={setCurrentStep}
            formatPrice={formatPrice}
            cleanState={cleanState}
          />
        );
      default:
        return defaultRedirect;
    }
  };

  return (
    <Container className={props.className}>
      <Container>
        {/* TITULO START */}
        <Grid item>
          <h1>
            Cotización - Seguro accidentes personales
            <TextPoint />
          </h1>
        </Grid>
        {/* TITULO END */}
        {stepsApStore.steps.loading ? (
          <Loading />
        ) : (
          <>
            <Box className='labels-root'>
              {/* STEPPER START */}
              <Grid container>
                {steps.map((label, i) => (
                  <Box key={label} className={`${currentStep === i && 'label-active'} label-item`}>
                    <Grid item>{label}</Grid>
                  </Box>
                ))}
              </Grid>
            </Box>
            <Stepper activeStep={currentStep} alternativeLabel steps={steps}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {/* STEPPER END */}
            <div>{getStepContent(currentStep)}</div>
          </>
        )}
      </Container>
    </Container>
  );
};

export const ApQuotationsComponent = styled(({ ...props }) => <CleanApQuotationsComponent {...props} />)`
  .labels-root {
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
  }
  .label-item {
    padding-left: 8px;
    padding-right: 8px;
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .label-active {
    font-weight: bolder;
  }
  .colored-mark {
    color: var(--color-main);
    padding-left: 0.3em;
  }
`;
