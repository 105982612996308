import React from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { StyledButton } from '@wdynamo/common';
import { ReactComponent as IconCross } from '../../../../../../../../assets/img/icons_dynamo/icon_cross_white.svg';
import { ReactComponent as EllipseIcon } from '../../../../../../../../assets/img/icons_dynamo/icon_ellipse.svg';
import handleCharacteristics from '../../../../common/handleCharacteristics';

interface IPlanCardModal {
  className?: string;
  data: any;
  openModal: boolean;
  setOpenModal: any;
  currencySimbol: string;
  formatPrice(number: number): string;
  onSelect(plan: any): void;
}

const CleanPlanCardModal: React.FC<IPlanCardModal> = (props: IPlanCardModal) => {
  const { data, openModal, setOpenModal, currencySimbol, formatPrice, onSelect } = props;
  const { t } = useTranslation();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Dialog
      className={props.className}
      open={openModal}
      onClose={handleCloseModal}
      PaperProps={{
        style: { borderRadius: '24px', width: '516px' }
      }}
    >
      <DialogTitle className='dialog-title-container'>
        <div className='icon-container'>
          <IconCross style={{ cursor: 'pointer' }} width={'16px'} height={'16px'} onClick={handleCloseModal} />
        </div>
        <Typography className='dialog-title'>{data.text}</Typography>
        <Typography className='dialog-subtitle'>
          {data.payments_amount > 1 ? (
            <>
              <span className='large bold'>{`${currencySimbol} ${formatPrice(Number(data.first_payment))}`}</span>
              <span className='large'> 1° cuota mensual</span>
            </>
          ) : (
            <>
              <span className='large bold'>{`${currencySimbol} ${formatPrice(Number(data.price))}`}</span>
              <span className='large'> precio total</span>
            </>
          )}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ position: 'relative' }}>
        <List>
          {data?.characteristics.map((item: string, index: number) => {
            const { capitalize, price } = handleCharacteristics(item);
            return (
              <ListItem key={index} style={{ padding: '0px' }}>
                <ListItemIcon style={{ minWidth: '30px' }}>
                  <EllipseIcon fill='var(--color-main)' />
                </ListItemIcon>
                <ListItemText primary={capitalize} secondary={`${currencySimbol} ${price}`} />
              </ListItem>
            );
          })}
        </List>
        <Grid className='button-container'>
          <StyledButton className='button-select' onClick={() => onSelect(data)}>
            {t('QUOTATIONS.STEP_4.SELECT')}
          </StyledButton>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const PlanCardModal = styled(({ ...props }) => <CleanPlanCardModal {...props} />)`
  .dialog-title-container {
    background-color: var(--color-main);
    padding: 0.7em 1em;
    .icon-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
    .dialog-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 19px;
      font-weight: bold;
      text-align: center;
      width: 100%;
      color: var(--color-white);
      margin-top: 0.3em;
    }
    .dialog-subtitle {
      text-align: center;
      color: var(--color-white);
    }
    .large {
      font-size: large;
    }
    .bold {
      font-weight: bold;
    }
  }
  .button-container {
    margin: 1em 0;
    display: flex;
    justify-content: center;
    .button-select {
      padding: 5px 50px;
      background-color: var(--color-main);
      color: var(--color-white);
    }
  }
`;
