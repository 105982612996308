import axios from 'axios';
/* eslint-disable no-unused-vars */
import {
  ISourceHomeParams,
  IContactParams,
  ICityParams,
  IHomePlansParams,
  IBillingModeHomeParams,
  IPaymentMethodHomeParams,
  IPaymentConditionHomeParams
} from './model';
/* eslint-enable no-unused-vars */

const Api = () => {
  // eslint-disable-next-line no-unused-vars
  const getHomeStepsFetch = (id: string): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/inputs/2`);

  const getSourceHomeListFetch = (listParams: ISourceHomeParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}${listParams.url}`, { params: listParams.params });

  const getDataHomeSourceFetch = (dataParams: ISourceHomeParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}${dataParams.url}`, { params: dataParams.params });

  const createContactFetch = (params: IContactParams): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/customers`, params);

  const getCityFetch = (params: ICityParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/homes/town`, { params });

  //create de quotation home
  const getHomePlansFetch = (params: IHomePlansParams): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/quotation/createHomeQuote`, params);

  //get modo de facturación
  const getBillingModeFetch = (params: IBillingModeHomeParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/payment/billing`, {
      params: params
    });

  // get forma de pago
  const getPaymentMethodFetch = (params: IPaymentMethodHomeParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/payment/method`, {
      params: params
    });

  // get condición de pago
  const getPaymentConditionFetch = (params: IPaymentConditionHomeParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/payment_conditions`, {
      params: params
    });

  const updatePlansFetch = (quotationId: string, params: any): Promise<unknown> =>
    axios.patch(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/quotation/${quotationId}`, params);

  return {
    getHomeStepsFetch,
    getSourceHomeListFetch,
    getDataHomeSourceFetch,
    createContactFetch,
    getCityFetch,
    getHomePlansFetch,
    getBillingModeFetch,
    getPaymentMethodFetch,
    getPaymentConditionFetch,
    updatePlansFetch
  };
};

export default Api;
