import { call, put, takeLatest, all } from 'redux-saga/effects';

import Api from './Api';
import ApiMock from './ApiMock';
import { getProductsSuccess, getProductsFail } from './actions';
import { ACTIONS } from '../ActionTypes';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasGetProductsFetch() {
  try {
    const response = yield call(api.getProductsFetch);
    yield put(getProductsSuccess(response.data.items));
  } catch (e) {
    yield put(getProductsFail(e));
  }
}

// Listen for redux actions
function* productsSaga() {
  yield all([takeLatest(ACTIONS.PRODUCTS.LIST.FETCH, sagasGetProductsFetch)]);
}

export { productsSaga };
