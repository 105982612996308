import { call, put, takeLatest, all } from 'redux-saga/effects';
import ApiMock from './ApiMock';
import {
  getPaymentMethodsSuccess,
  getPaymentMethodsFail,
  getSaleChannelsSuccess,
  getSaleChannelsFail
} from './actions';
// eslint-disable-next-line no-unused-vars
import { PAYMENT_METHOD, SALE_CHANNELS } from './types';

const api = ApiMock();

function* sagasGetPaymentMethodsFetch() {
  try {
    const response = yield call(api.getPaymentMethodsFetch);
    yield put(getPaymentMethodsSuccess(response.data.data));
  } catch (e) {
    yield put(getPaymentMethodsFail(e));
  }
}

function* getSaleChannelsFetch() {
  try {
    const response = yield call(api.getSaleChannelsFetch);
    yield put(getSaleChannelsSuccess(response.data.data));
  } catch (e) {
    yield put(getSaleChannelsFail(e));
  }
}

// Listen for redux actions
function* paymentsFormSaga() {
  yield all([takeLatest(PAYMENT_METHOD.FETCH, sagasGetPaymentMethodsFetch)]);
  yield all([takeLatest(SALE_CHANNELS.FETCH, getSaleChannelsFetch)]);
}

export { paymentsFormSaga };
