// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IBaseApStore } from './models';

const initialState: IBaseApStore = {
  steps: {
    loading: false,
    error: false,
    message: '',
    data: {}
  },
  createContact: {
    loading: false,
    error: false,
    data: {}
  },
  contacts: {
    loading: false,
    error: false,
    data: []
  },
  billingMode: {
    loading: false,
    error: false,
    data: []
  },
  paymentMethod: {
    loading: false,
    error: false,
    data: []
  },
  paymentCondition: {
    loading: false,
    error: false,
    data: []
  },
  plans: {
    loading: false,
    error: false,
    data: {}
  },
  update: {
    loading: false,
    error: false,
    data: {}
  }
};

export const quotationApStepsReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.AP_QUOTATIONS.STEPS.FETCH:
      return {
        ...state,
        steps: {
          ...state.steps,
          loading: true,
          error: false
        }
      };
    case ACTIONS.AP_QUOTATIONS.STEPS.SUCCESS:
      return {
        ...state,
        steps: {
          ...state.steps,
          loading: false,
          error: false,
          data: { ...state.steps.data, ...action.payload.data.input_flow[0] }
          // data: { ...state.steps.data, ...action.payload.data }
        }
      };
    case ACTIONS.AP_QUOTATIONS.STEPS.FAIL:
      return {
        ...state,
        steps: {
          ...state.steps,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.AP_QUOTATIONS.CREATE_CONTACT.FETCH:
      return {
        ...state,
        createContact: {
          ...state.createContact,
          loading: false,
          error: false
        }
      };
    case ACTIONS.AP_QUOTATIONS.CREATE_CONTACT.SUCCESS:
      return {
        ...state,
        createContact: {
          ...state.createContact,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.AP_QUOTATIONS.CREATE_CONTACT.FAIL:
      return {
        ...state,
        createContact: {
          ...state.createContact,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.AP_QUOTATIONS.CONTACTS.FETCH:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: true,
          error: false
        }
      };
    case ACTIONS.AP_QUOTATIONS.CONTACTS.SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
          error: false,
          data: action.payload.data
        }
      };
    case ACTIONS.AP_QUOTATIONS.CONTACTS.FAIL:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.AP_QUOTATIONS.CONTACTS.CLEAR:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
          error: false,
          data: []
        }
      };
    case ACTIONS.AP_QUOTATIONS.BILLING_MODE.FETCH:
      return {
        ...state,
        billingMode: {
          ...state.billingMode,
          loading: true,
          error: false
        }
      };
    case ACTIONS.AP_QUOTATIONS.BILLING_MODE.SUCCESS:
      return {
        ...state,
        billingMode: {
          ...state.billingMode,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.AP_QUOTATIONS.BILLING_MODE.FAIL:
      return {
        ...state,
        billingMode: {
          ...state.billingMode,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.AP_QUOTATIONS.PAYMENT_METHOD.FETCH:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          loading: true,
          error: false
        }
      };
    case ACTIONS.AP_QUOTATIONS.PAYMENT_METHOD.SUCCESS:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.AP_QUOTATIONS.PAYMENT_METHOD.FAIL:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.AP_QUOTATIONS.PAYMENT_CONDITION.FETCH:
      return {
        ...state,
        paymentCondition: {
          ...state.paymentCondition,
          loading: true,
          error: false
        }
      };
    case ACTIONS.AP_QUOTATIONS.PAYMENT_CONDITION.SUCCESS:
      return {
        ...state,
        paymentCondition: {
          ...state.paymentCondition,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.AP_QUOTATIONS.PAYMENT_CONDITION.FAIL:
      return {
        ...state,
        paymentCondition: {
          ...state.paymentCondition,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };

    case ACTIONS.AP_QUOTATIONS.PLANS.FETCH:
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: true,
          error: false
        }
      };
    case ACTIONS.AP_QUOTATIONS.PLANS.SUCCESS:
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: false,
          error: false,
          data: action.payload.data
        }
      };
    case ACTIONS.AP_QUOTATIONS.PLANS.FAIL:
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.AP_QUOTATIONS.UPDATE.FETCH:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
          error: false
        }
      };
    case ACTIONS.AP_QUOTATIONS.UPDATE.SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.AP_QUOTATIONS.UPDATE.FAIL:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.AP_QUOTATIONS.CLEAN:
      return { ...initialState };

    default:
      return state;
  }
};
