import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Box, Grid, Hidden } from '@material-ui/core';
import { Loading, Modal, TextPoint, IconButton, Date } from '@wdynamo/common';
import { Pagination } from '@material-ui/lab';
import { ReactComponent as ModalEditIcon } from '../../../../assets/img/icons_dynamo/icon_edit_modal.svg';
import { ReactComponent as DetailIcon } from '../../../../assets/img/icons_dynamo/icon_detail.svg';

import { Analytics } from '@wdynamo/common/lib/services';
/* eslint-disable no-unused-vars */
import { IUsersProps, IUser } from '../../models';
import { History } from 'history';
/* eslint-disable no-unused-vars */
import { uris } from '../../../../siteMap';

interface IPagedUsersProps {
  [key: string]: any;
  usersStore: IUsersProps;
  history: History;
  firstLoading: boolean;
  getUsersFetch(page_len: number, page: number): void;
  getParentsFetch(parent?: string): void;
  getRolesFetch(): void;
  className?: string;
  deleteUser(userId: string): void;
}

const CleanUsersListComponent: React.FC<IPagedUsersProps> = (props: IPagedUsersProps) => {
  const PAGE_LEN: number = 15;
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const { usersStore, history, getUsersFetch, getParentsFetch, getRolesFetch } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedModal, setSelectedModal] = React.useState<IUser | any>({});
  const [page, setPage] = React.useState<number>(1);

  useEffect(() => {
    Analytics().pageview('display-users');
  }, []);

  useEffect(() => {
    if (keycloak?.authenticated)
      setTimeout(() => {
        keycloak?.loadUserInfo().then(() => {
          getUsersFetch(PAGE_LEN, page);
          getParentsFetch();
          getRolesFetch();
        });
      });
  }, [keycloak?.authenticated]);

  const handleChangePage = (selectedPage: number) => {
    setPage(selectedPage);
    getUsersFetch(PAGE_LEN, selectedPage);
  };

  const renderModal = (): React.ReactNode => {
    return (
      <Modal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        className='order-modal padding'
        title={
          <Grid container>
            <Grid item xs={11}>
              <h2>
                {t('USERS.TABLE.USER_MODAL.TITLE')}
                <TextPoint />
              </h2>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={() => history.push(uris.userInfo.uri, { user: selectedModal })}
                Icon={ModalEditIcon}
              />
            </Grid>
          </Grid>
        }
        body={
          <>
            {(selectedModal as IUser) && (
              <Grid container>
                {selectedModal.first_name && (
                  <Grid xs={12} sm={6} item className='item-container'>
                    <div className='item-label'>{t('USERS.TABLE.USER_MODAL.NAME')}</div>
                    <div className='item-value'>{selectedModal.first_name}</div>
                  </Grid>
                )}
                {selectedModal.last_name && (
                  <Grid xs={12} sm={6} item className='item-container'>
                    <div className='item-label'>{t('USERS.TABLE.USER_MODAL.LAST_NAME')}</div>
                    <div className='item-value'>{selectedModal.last_name}</div>
                  </Grid>
                )}

                {selectedModal.created_at && (
                  <Grid xs={12} sm={6} item className='item-container'>
                    <div className='item-label'>{t('USERS.TABLE.USER_MODAL.CREATED_AT')}</div>
                    <div className='item-value'>{selectedModal.created_at?.split('T')[0]}</div>
                  </Grid>
                )}
                {selectedModal.username && (
                  <Grid xs={12} sm={6} item className='item-container'>
                    <div className='item-label'>{t('USERS.TABLE.USER_MODAL.USERNAME')}</div>
                    <div className='item-value'>{selectedModal.username}</div>
                  </Grid>
                )}
                {selectedModal.email && (
                  <Grid xs={12} sm={12} item className='item-container'>
                    <div className='item-label'>{t('USERS.TABLE.USER_MODAL.EMAIL')}</div>
                    <div className='item-value'>{selectedModal.email}</div>
                  </Grid>
                )}

                {selectedModal.roles && (
                  <Grid xs={12} sm={6} item className='item-container'>
                    <div className='item-label'>{t('USERS.TABLE.USER_MODAL.ROLES')}</div>
                    <div className='item-value'>
                      {selectedModal.roles?.map((roleGroup: any, i: number) => (
                        <p className='role-group-item' key={i}>
                          {roleGroup.role?.name}
                        </p>
                      ))}
                    </div>
                  </Grid>
                )}
                {selectedModal.roles && (
                  <Grid xs={12} sm={6} item className='item-container'>
                    <div className='item-label'>{t('USERS.TABLE.USER_MODAL.GROUPS')}</div>
                    <div className='item-value'>
                      {selectedModal.roles?.map((roleGroup: any, i: number) => (
                        <p className='role-group-item' key={i}>
                          {roleGroup.group?.name}
                        </p>
                      ))}
                    </div>
                  </Grid>
                )}
                <Grid xs={12} sm={6} item className='item-container'>
                  <div className='item-label'>{t('USERS.TABLE.USER_MODAL.STATE')}</div>
                  <div className='item-value'>
                    {selectedModal.active ? t('USERS.TABLE.USER_MODAL.ENABLED') : t('USERS.TABLE.USER_MODAL.DISABLED')}
                  </div>{' '}
                </Grid>
              </Grid>
            )}
          </>
        }
      />
    );
  };

  const renderUsersTable = (): React.ReactNode => {
    const { loading, users } = usersStore;

    if (!users.length && loading) return <Loading />;
    else if (!users.length) return <></>;
    else
      return (
        <>
          <Grid container className='users-grid'>
            <Hidden smDown>
              <Grid item xs={12} className='header'>
                <Grid container>
                  <Grid item xs={12} md={2}>
                    {t('USERS.TABLE.HEADERS.CREATED_AT')}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    {t('USERS.TABLE.HEADERS.USERNAME')}
                  </Grid>
                  <Grid item xs={2} md={3}>
                    {t('USERS.TABLE.HEADERS.NAME')}
                  </Grid>
                  <Grid item xs={2} md={3}>
                    {t('USERS.TABLE.HEADERS.EMAIL')}
                  </Grid>
                  <Grid item xs={2} md={2} className='last-row'>
                    {t('USERS.TABLE.HEADERS.DETAIL')}
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Grid item xs={12} className='content'>
              <Box>
                {users.map((user: IUser) => (
                  <Grid
                    onClick={() => {
                      setSelectedModal(user);
                      setOpenModal(true);
                    }}
                    container
                    key={user.id}
                    className={`user ${!user.active && ' disabled'}`}
                    alignItems='center'
                  >
                    <Grid item xs={12} md={2}>
                      <Hidden mdUp>
                        <b>{`${t('USERS.TABLE.HEADERS.CREATED_AT')}: `}</b>
                      </Hidden>
                      <Date date={user.created_at} format={process.env.REACT_APP_DATE_FORMAT!} />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Hidden mdUp>
                        <b>{`${t('USERS.TABLE.HEADERS.USERNAME')}: `}</b>
                      </Hidden>
                      {user.username}
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Hidden mdUp>
                        <b>{`${t('USERS.TABLE.HEADERS.NAME')}: `}</b>
                      </Hidden>
                      {`${user.first_name} ${user.last_name}`}
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Hidden mdUp>
                        <b>{`${t('USERS.TABLE.HEADERS.EMAIL')}: `}</b>
                      </Hidden>
                      {user.email}
                    </Grid>
                    <Hidden smDown>
                      <Grid item md={2} className='last-row'>
                        <DetailIcon className='icon' />
                      </Grid>
                    </Hidden>
                    <Hidden mdUp>
                      <Grid container justifyContent='center' style={{ marginTop: '0.5em' }}>
                        <DetailIcon className='icon' />
                      </Grid>
                    </Hidden>
                  </Grid>
                ))}
              </Box>
              <Box mt={2}>
                <Grid container justify='center'>
                  <Pagination
                    size='large'
                    count={Number(usersStore.total_pages)}
                    page={page}
                    onChange={(e, selectedPage) => handleChangePage(selectedPage)}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </>
      );
  };

  return (
    <div className={props.className}>
      {renderModal()}
      {renderUsersTable()}
    </div>
  );
};

export const UsersListComponent = styled(({ ...props }) => <CleanUsersListComponent {...props} />)`
  .icon > rect {
    fill: var(--color-main);
  }
  .icon > path {
    fill: var(--color-white) !important;
  }
  .last-row {
    text-align: right;
  }
  .add-user {
    padding: 25px;
  }
  .users-grid {
    .header {
      border: none;
      font-weight: bold;
      text-align: left;
      div {
        padding: 5px 0;
      }
    }
    .content {
      .disabled {
        text-decoration: line-through;
      }
      .user {
        .last-row {
          text-align: right;
        }
        padding: 15px 0;
        border-bottom: 1px solid var(--color-grey-border);
      }
      .checkbox {
        color: var(--color-main);
      }
    }
  }
  .Mui-selected {
    color: var(--color-white);
    background-color: var(--color-main);
  }
  svg.MuiPaginationItem-icon {
    color: var(--color-main);
  }
  .role-group-item {
    margin: 0;
    padding-bottom: 0.5em;
  }
`;
