import { connect } from 'react-redux';
import { PaymentsFormComponent } from './view';
import { getPaymentMethodsFetch, getSaleChannelsFetch } from './actions';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../store';

const MOCK_SERVICES = [
  {
    code: '123',
    name: 'Seguro Celular',
    price: 2000,
    description: 'Seguro de celular muy copado te juro ',
    subtitle: 'Seguro de celular',
    paymentMethods: ['2', '3'],
    salesChannels: ['0', '2'],
    active: true
  },
  {
    code: '12413',
    name: 'Seguro Auto',
    price: 2000,
    description: 'Seguro de auto muy copado te juro ',
    subtitle: 'Seguro de auto',
    paymentMethods: ['1', '4'],
    salesChannels: ['1', '3'],
    active: false
  }
];

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state: RootState) => ({
  servicesStore: MOCK_SERVICES,
  paymentsFormStore: state.paymentsFormState
});
const mapDispatchToProps = { getPaymentMethodsFetch, getSaleChannelsFetch };

export const PaymentsForm = connect(mapStateToProps, mapDispatchToProps)(PaymentsFormComponent);

export { paymentsFormReducer } from './reducers';
export { paymentsFormSaga } from './sagas';
export { PaymentsFormComponent } from './view';
