import { connect } from 'react-redux';
import { uploadImageFetch, updatePartnerFetch } from './actions';
import { SettingsComponent } from './view';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../store';

const mapStateToProps = (state: RootState) => ({
  settingsStore: state.settingsState
});
const mapDispatchToProps = { uploadImageFetch, updatePartnerFetch };

export const Settings = connect(mapStateToProps, mapDispatchToProps)(SettingsComponent);
