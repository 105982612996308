import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Price, Loading, Modal, CardComponent, ProductCard, CustomTooltip } from '@wdynamo/common';
import iconBoxWhite from '../../../../../assets/img/icons_dynamo/icon_box_white.svg';
import iconBoxBlack from '../../../../../assets/img/icons_dynamo/icon_box_black.svg';
import { Grid } from '@material-ui/core';
// eslint-disable-next-line no-unused-vars
import { IReportsProducts, IReportsProductsStore } from '../../../models';

interface IMostSoldItemsProps {
  products: IReportsProductsStore;
  className?: string;
  [key: string]: any;
}

const initialSelectedOrderState: IReportsProducts = {
  price: 0,
  title: '',
  total_sales: 0,
  total_sales_money: 0,
  short_description: '',
  description: '',
  id: 0,
  code: ''
};

export const MostSoldItemCard = styled(CardComponent)`
  margin: 10px 0px 0px 0px;
  box-shadow: none;
  border-color: var(--color-main);
  &:last-child {
    margin-bottom: 0;
  }
  &.salient {
    background-color: var(--color-main);
    color: var(--color-white);
    h4 {
      color: var(--color-white);
    }
  }
  .card-area {
    display: flex;
    max-height: 93px;
    .card-img {
      width: 50%;
      height: 100%;
      min-height: 120px;
    }
    .card-content {
      width: 100%;
      font-size: 20px;
      padding: 0px;
      margin: 10px 10px 10px 20px;
      h4 {
        margin: 0px 0px 10px 0px;
      }
      .price {
        margin-top: 0;
      }
      .amount {
        justify-content: flex-end;
        .total {
          text-align: end;
          min-width: 25px;
        }
      }
    }
  }
`;

const CleanMostSoldItems: React.FC<IMostSoldItemsProps> = (props: IMostSoldItemsProps) => {
  const { t } = useTranslation();
  const { loading, products } = props.products;
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState<IReportsProducts>(initialSelectedOrderState);

  const renderModal = (): React.ReactNode => (
    <Modal
      open={openModal}
      handleClose={() => {
        setSelectedOrder(initialSelectedOrderState);
        setOpenModal(false);
      }}
      className='order-modal'
      body={
        <ProductCard
          className='item-modal'
          imageToRight={true}
          product={{
            //@TODO: replace the image for the real image.
            thumbnail: {
              url: process.env.REACT_APP_DEFAULT_PRODUCT_IMG,
              alt: selectedOrder.title
            },
            imageToRight: true,
            title: selectedOrder.title,
            short_description: selectedOrder.short_description,
            description: selectedOrder.description,
            price: { amount: selectedOrder.price },
            featured: false
          }}
        />
      }
    />
  );

  if (loading) return <Loading />;
  else if (!products.length) return <></>;
  else
    return (
      <>
        {renderModal()}
        {products.map((product: IReportsProducts, index: number) => (
          <MostSoldItemCard
            key={index}
            className={`${index === 0 && 'salient'}  sold-item-card`}
            //@TODO: replace the image for the real image.
            cardMedia={{
              src: process.env.REACT_APP_DEFAULT_PRODUCT_IMG,
              alt: product.title
            }}
            cardContent={
              <Grid container>
                <Grid item xs={10}>
                  <h4>{product.title}</h4>
                </Grid>
                <Grid item xs={10}>
                  <Price amount={product.total_sales_money} className='price' />
                </Grid>
                <CustomTooltip
                  title={t('REPORTS.TABS.TOP_PRODUCTS.TOOLTIPS.QUANTITY_SOLD') as string}
                  arrow
                  placement='bottom'
                >
                  <Grid container item xs={2} className='amount'>
                    <Grid item>
                      <img src={index === 0 ? iconBoxWhite : iconBoxBlack} alt='' />
                    </Grid>
                    <Grid item className='total'>
                      <span>{product.total_sales}</span>
                    </Grid>
                  </Grid>
                </CustomTooltip>
              </Grid>
            }
            onClick={() => {
              setSelectedOrder(product);
              setOpenModal(true);
            }}
          />
        ))}
      </>
    );
};
export const MostSoldItems = (props: IMostSoldItemsProps) => <CleanMostSoldItems {...props} />;
