import React from 'react';
import styled from 'styled-components';
import { Popper, StyledButton, DatePicker, substractTime, Input } from '@wdynamo/common';
import moment from 'moment';
import { Grid, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface SalesFilterProps {
  className: string;
  anchorEl: any;
  toggleOpen(): void;
  onClick(): void;
  dateFrom?: string;
  dateTo?: string;
  requests: [];
  insurances: [];
  actionTitle: string;
}
const CleanSalesFilter = (props: SalesFilterProps) => {
  const { t } = useTranslation();
  const [selectedInsurance, setSelectedInsurance] = React.useState();
  const [selectedRequest, setSelectedRequest] = React.useState();
  const [dateFrom, setDateFrom] = React.useState<string>(
    props.dateFrom ||
      moment(substractTime(moment().toString(), 'months', 6)).format(process.env.REACT_APP_DATE_FORMAT).toString()
  );
  const [dateTo, setDateTo] = React.useState<string>(
    props.dateTo || moment().format(process.env.REACT_APP_DATE_FORMAT).toString()
  );
  const { anchorEl } = props;

  const handleCancel = (): void => {
    props.toggleOpen();
  };
  return (
    <div className={props.className}>
      <Popper anchorEl={anchorEl}>
        <Grid container spacing={1} className={'popper'} style={{ width: '20em' }}>
          <Grid item xs={6}>
            <DatePicker
              label={t('REPORTS.EXPORT.DATE_FROM')}
              value={dateFrom}
              onChange={(value: string): void => setDateFrom(value)}
              testId='dateFrom'
              format={process.env.REACT_APP_DATE_FORMAT!}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label={t('REPORTS.EXPORT.DATE_TO')}
              value={dateTo}
              onChange={(value: string): void => setDateTo(value)}
              testId='dateTo'
              format={process.env.REACT_APP_DATE_FORMAT!}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label={'Seguros'}
              defaultValue={selectedInsurance}
              onChange={(e: any) => setSelectedInsurance(e)}
              testId='Seguros'
              select={true}
            >
              <MenuItem value={''}>
                <em>{t('COMMONS.FILTER.SELECT')}</em>
              </MenuItem>
              {props.insurances?.map((item: any, i: number) => (
                <MenuItem key={i} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Input>
            <Input
              label={t('MY_SALES.TABLE.REQUEST')}
              defaultValue={selectedRequest}
              onChange={(e: any) => setSelectedRequest(e)}
              testId='Sales'
              select={true}
            >
              <MenuItem value={''}>
                <em>{t('COMMONS.FILTER.SELECT')}</em>
              </MenuItem>
              {props.requests?.map((item: any, i: number) => (
                <MenuItem key={i} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Input>
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              className={'fullWidth default'}
              onClick={() => {
                //   props.onClick(dateFrom, dateTo);
                console.log(dateTo, dateFrom, selectedRequest, selectedInsurance);
              }}
            >
              {props.actionTitle}
            </StyledButton>
          </Grid>
          <Grid item xs={12}>
            <StyledButton className={'secondary fullWidth'} onClick={handleCancel}>
              {t('COMMONS.BUTTONS.CANCEL')}
            </StyledButton>
          </Grid>
        </Grid>
      </Popper>
    </div>
  );
};

export const SalesFilter = styled(({ ...props }) => <CleanSalesFilter {...props} />)`
  .popper {
    text-align: left;
  }
  .fullWidth {
    width: 100%;
  }
  .active {
    background-color: var(--color-main);
    color: white;
    border: none;
    &:hover {
      color: white;
      background-color: var(--color-main);
    }
  }
  .cancel {
    background-color: white;
    color: #666;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;
