import React from 'react';
import Carousel from 'nuka-carousel';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { StyledButton } from '@wdynamo/common/lib/components';
import Api from './Api';
import { Autocomplete } from '@material-ui/lab';
import { TextField, FormControlLabel, Radio, FormGroup, RadioGroup } from '@material-ui/core';

interface IProps {
  slideIndex?: number;
  setSlideIndex: any;
  handleCloseModal: any;
  className: string;
  excelImportExampleFetch: any;
  importExcelExampleStore: any;
  getDownloadExcelExample: any;
  initialContactInfoForm: any;
  listUsers: any;
  setListUsers: void;
  contactInfoForm: any;
  setContactInfoForm: any;
  hasResponsible: boolean;
  setHasResposible: any;
}

//
const CarrouselComponent: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const { slideIndex = 0, listUsers, contactInfoForm, setContactInfoForm, hasResponsible, setHasResposible } = props;
  //const [hasResponsible, setHasResposible] = React.useState<boolean>(false);
  const data = [
    {
      id: 0,
      title: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_1.TITLE'),
      text: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_1.TEXT'),
      linkText: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_1.LINK_TEXT'),
      linkSkip: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_1.LINK_SKIP'),
      buttonText: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_1.BUTTON_NEXT'),
      linkExample: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_1.LINK_EXAMPLE'),
      bgImage: require('../../../../../../assets/img/icons_dynamo/add_clients_card_1.svg')
    },
    {
      id: 1,
      title: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_2.TITLE'),
      text: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_2.TEXT'),
      linkText: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_2.LINK_TEXT'),
      linkSkip: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_2.LINK_SKIP'),
      buttonText: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_2.BUTTON_NEXT'),
      bgImage: require('../../../../../../assets/img/icons_dynamo/add_clients_card_2.svg')
    },

    {
      id: 2,
      title: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_4.TITLE'),
      text: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_4.TEXT'),
      linkText: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_4.LINK_TEXT'),
      linkSkip: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_4.LINK_SKIP'),
      buttonText: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_4.BUTTON_NEXT'),
      bgImage: require('../../../../../../assets/img/icons_dynamo/add_clients_card_4.svg')
    },
    {
      id: 3,
      title: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.TITLE'),
      text: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.TEXT'),
      linkText: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.LINK_TEXT'),
      linkSkip: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.LINK_SKIP'),
      buttonText: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.BUTTON_NEXT'),
      bgImage: require('../../../../../../assets/img/icons_dynamo/add_clients_card_3.svg')
    }

    /* {
      id: 4,
      title: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.TITLE'),
      text: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.TEXT'),
      linkText: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.LINK_TEXT'),
      linkSkip: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.LINK_SKIP'),
      buttonText: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.BUTTON_NEXT'),
      bgImage: require('../../../../../../assets/img/icons_dynamo/add_clients_card_5.svg')
    },
    {
      id: 5,
      title: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.TITLE'),
      text: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.TEXT'),
      linkText: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.LINK_TEXT'),
      linkSkip: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.LINK_SKIP'),
      buttonText: t('CONTACTS.CUSTOMER_LIST_MODAL_IMPORT.CARD_3.BUTTON_NEXT'),
      bgImage: require('../../../../../../assets/img/icons_dynamo/add_clients_card_4.svg')
    } */
  ];

  const handleOnChange = (fieldToUpdate: string, value: any) =>
    setContactInfoForm((prevState: any) => ({
      ...prevState,
      [fieldToUpdate]: { ...prevState[fieldToUpdate], value: value, error: false }
    }));

  const downloadExcel = () => {
    Api().getDownloadExcelExample();
  };

  return (
    <div className={props.className}>
      <Carousel
        defaultControlsConfig={{
          pagingDotsStyle: {
            fill: 'var(--color-main)',
            outline: 0,
            marginBottom: '7.2rem'
          },
          nextButtonText: 'Custom Next',
          prevButtonText: 'Custom Prev'
        }}
        slideIndex={props.slideIndex}
        afterSlide={(slideIndex) => props.setSlideIndex(slideIndex)}
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
      >
        {data.map((item: any, id: number) => (
          <div className='main-container' key={id}>
            <Grid className='card-body-contariner'>
              <Grid className='image-container'></Grid>
              <Grid className='d-flex flex-column align-items-center justify-content-center'>
                <Grid className='d-flex flex-column align-items-center justify-content-center'>
                  <img src={item.bgImage} width='100%' height='300px' style={{ marginBottom: '2rem' }} />
                  <Grid className='card_title'>{item.title}</Grid>
                  <Grid className='card_text'>
                    {item.text}{' '}
                    {slideIndex === 3 ? (
                      <Grid
                        direction='row'
                        style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                        container
                        spacing={2}
                      >
                        <Grid item xs={6} sm={6}>
                          <FormGroup>
                            <RadioGroup
                              name='hasResponsible'
                              value={hasResponsible}
                              onChange={(event): void => setHasResposible(event.target.value === 'true')}
                              style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                              row={true}
                            >
                              <FormControlLabel
                                value={false}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  marginLeft: '0.8rem',
                                  marginRight: '0.8rem'
                                }}
                                control={<Radio />}
                                label={'No'}
                              />
                              <FormControlLabel
                                value={true}
                                style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                                control={<Radio />}
                                label={'Si'}
                              />
                            </RadioGroup>
                          </FormGroup>
                        </Grid>

                        {hasResponsible === false ? (
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            style={{
                              display: 'flex',
                              justifyContent: 'right',
                              textAlign: 'center',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            <Autocomplete
                              style={{
                                width: '100%'
                              }}
                              //  disabled={isUpdateContactView}
                              value={contactInfoForm.responsible.value}
                              options={listUsers}
                              onChange={(e, value) => handleOnChange('responsible', value)}
                              getOptionLabel={(option: any) => `${option.first_name || ''} ${option.last_name || ''}`}
                              renderInput={(params) => <TextField {...params} variant='standard' />}
                            ></Autocomplete>{' '}
                          </Grid>
                        ) : (
                          ''
                        )}
                      </Grid>
                    ) : (
                      ''
                    )}
                    {item.linkExample ? (
                      <a className='link' onClick={() => downloadExcel()}>
                        {item.linkExample}
                      </a>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ))}
      </Carousel>
      <Grid className='button-container'>
        <StyledButton
          className='first-button'
          onClick={() => {
            props.setSlideIndex(4);
          }}
        >
          {data[slideIndex].linkSkip}
        </StyledButton>
        <StyledButton
          className='second-button'
          onClick={() => {
            props.setSlideIndex(slideIndex + 1);
          }}
        >
          {data[slideIndex].buttonText}
        </StyledButton>
      </Grid>
    </div>
  );
};

export const Carrousel = styled(({ ...props }) => <CarrouselComponent {...props} />)`
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
  }
  .card_title {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 0.3rem;
  }
  .card_text {
    font-size: 1.125rem;
    font-weight: 300;
    height: 90px;
  }
  .card-body-contariner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .image-container {
    visibility: hidden;
  }
  .link {
    text-decoration: underline;
    color: var(--color-main);
    cursor: pointer;
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }
`;
