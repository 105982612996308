// eslint-disable-next-line no-unused-vars
import { Moment } from 'moment';

// eslint-disable-next-line no-unused-vars
import { IBaseStateProps } from '../ActionTypes';

export interface IDocInfoForm {
  docType: string;
  docNumber: string;
  name: string;
  birthDate: string;
  cuit: string;
  gender: string;
  legalNature: string;
}

export interface IDocInfo extends IBaseStateProps {
  selectedDocType: string;
  docNumber: string;
  data?: IDocInfoForm;
}

export interface IClientInfoForm {
  [key: string]: IDocInfoForm | IClientInfo;
}

export interface IAddressInfo {
  zipCode: string | number;
  street: string;
  number: string;
  floor?: string;
  door?: string;
  state?: string;
  city?: string;
}

export interface IClientInfo {
  gender: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  email: string;
  phone: string;
}

export interface IZipCode extends IBaseStateProps {
  errorMessage: string;
}

export interface ICheckoutProducProps {
  [key: string]: any;
}

export interface IConfirmationOrder extends IBaseStateProps {
  paymentMethod?: PaymentMethods;
  orderId?: number | string;
}

export interface IPaymentUrl extends IBaseStateProps {
  paymentMethod?: PaymentMethods;
  paymentUrl?: string;
  paymentSubscription?: string;
}

export interface ICheckoutProps {
  docInfo: IDocInfo;
  clientInfo: IClientInfo;
  clientAdress: IAddressInfo;
  confirmation: IConfirmationOrder;
  payment: IPaymentUrl;
  product?: ICheckoutProducProps;
}

export interface ISearchDocInfoForm {
  [key: string]: {
    value?: string | number;
    error: boolean;
    isRequired: boolean;
    validateBy?(key: string | Moment, value: string): void;
    validateKey?: string;
  };
}

export interface IGenderProps {
  [key: string]: string;
}

export interface IUserInfo {
  [key: string]: string | undefined;
}

export enum PaymentMethods {
  // eslint-disable-next-line no-unused-vars
  EXPRESS = 'EXPRESS',
  // eslint-disable-next-line no-unused-vars
  OFFERER = 'OFFERER',
  // eslint-disable-next-line no-unused-vars
  OFFERER_PLUS_EMAIL = 'OFFERER_PLUS_EMAIL'
}
