import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavButtons } from '../../common/NavButtons';
import { Input } from '@wdynamo/common';
import { Analytics } from '@wdynamo/common/lib/services';
import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
/* eslint-disable no-unused-vars */
import {
  ICustomHomeDataForm,
  IBillingModeHomeParams,
  IPaymentMethodHomeParams,
  IPaymentConditionHomeParams
} from '../model';
import moment from 'moment';
import { LoadingPlanBox } from '../../AutoQuotation/Plans/components/LoadingPlanBox';
import { PlanCard } from './components/PlanCard';

// eslint-disable-next-line no-unused-vars
import { UnderlineButton } from '../../../../../../components/UnderlineButton';

//swiper
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import SwiperCore, { Navigation, Pagination } from 'swiper';

// icon
// import { ReactComponent as DownloadArrowIcon } from '../../../../../../assets/img/icons_dynamo/icon_arrow_download.svg';

/* eslint-disable no-unused-vars */
// import moment from 'moment';
interface IHomePlansProps {
  className?: string;
  stepsHomeStore: any;
  dataForm: any;
  // setDataForm(data: ICustomHomeDataForm): void;
  onChange(data: ICustomHomeDataForm): void;
  nextStep(): void;
  getPlans(requoteParams?: any): void;
  plansData: any;
  productInfo: any;
  getBillingModeFetch(params: IBillingModeHomeParams): void;
  getPaymentMethodFetch(params: IPaymentMethodHomeParams): void;
  getPaymentConditionFetch(params: IPaymentConditionHomeParams): void;
  formatPrice?(number: number): string;
  savePlan(plansParams: any): void;
}

const CleanHomePlansComponent: React.FC<IHomePlansProps> = (props: IHomePlansProps) => {
  const { t } = useTranslation();
  const {
    stepsHomeStore,
    dataForm,
    // setDataForm,
    onChange,
    nextStep,
    getPlans,
    plansData,
    productInfo,
    getBillingModeFetch,
    getPaymentMethodFetch,
    getPaymentConditionFetch,
    formatPrice,
    savePlan
  } = props;
  const billingModeList = stepsHomeStore.billingMode.data;

  const [billingSelected, setBillingSelected] = useState('');
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState('');
  const [paymentConditionList, setPaymentConditionList] = useState([]);
  const [paymentConditionSelected, setPaymentConditionSelected] = useState('');
  // const prevBillingMode = usePrevious(dataForm.plans.billing_mode.value);
  // const prevPaymentMethod = usePrevious(dataForm.plans.payment_method.value);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const currency = '';
    getBillingModeFetch({
      productId: '2',
      subProductId: productInfo?.sub_product_list[0].id,
      currency: currency,
      producer: '2',
      commercialPlan: 'HPB'
    });
    Analytics().pageview('home-badget | plans');
  }, []);

  useEffect(() => {
    setPaymentMethodList(stepsHomeStore.paymentMethod.data);
  }, [stepsHomeStore.paymentMethod.data]);

  useEffect(() => {
    setPaymentConditionList(stepsHomeStore.paymentCondition.data);
  }, [stepsHomeStore.paymentCondition.data]);

  const getPaymentMethod = () => {
    const currency = '';
    const billingMode = dataForm?.plans?.billing_mode?.value;
    getPaymentMethodFetch({
      productId: '2',
      subProductId: productInfo?.sub_product_list[0].id,
      commercialPlan: 'HPB',
      billingMode: billingMode,
      currency: currency
    });
  };

  const getPaymentCondition = () => {
    const company = '1';
    const branch = productInfo.branch;
    const date = moment(productInfo.updatedAt).format('YYYYMMDD');
    const billingMode = dataForm?.plans?.billing_mode?.value;
    const currency = '';
    const policyType = productInfo?.sub_product_list[0].sub_branch_id;
    const paymentMethod = dataForm?.plans?.payment_method?.value;
    getPaymentConditionFetch({
      company: company,
      branch: branch,
      date: date,
      billingMode: billingMode,
      producer: '2',
      commercialPlan: 'HPB',
      currency: currency,
      policyType: policyType,
      paymentMethod: paymentMethod,
      insuranceType: ''
    });
  };

  const handleOnChange = (value: any, field: string) => {
    const prevState = { ...dataForm };
    prevState.plans[field].value = value;
    onChange(prevState);

    if (field === 'billing_mode') {
      setBillingSelected(value);
      setPaymentMethodList([]);
      setPaymentMethodSelected('');
      getPaymentMethod();
    }
    if (field === 'payment_method') {
      setPaymentMethodSelected(value);
      setPaymentConditionList([]);
      setPaymentConditionSelected('');
      getPaymentCondition();
    }
    if (field === 'payment_condition') {
      setPaymentConditionSelected(value);
      getPlans();
    }
  };

  const handleSelectPlan = (value: any) => {
    const prevState = { ...dataForm };
    prevState.plans.name.value = value.text;
    prevState.plans.code.value = value?.plan_code;
    prevState.plans.first_payment.value = value.first_payment;
    prevState.plans.remaining_payments.value = value.payments_price;
    prevState.plans.price.value = value?.price;
    prevState.plans.characteristics = value?.characteristics;
    savePlan({
      plan: {
        plan: value.text,
        plan_code: value.plan_code,
        first_payment: Number(value.first_payment),
        payment_left: Number(value.payments_price),
        price: Number(value.price),
        bills_amount: value.payments_amount
      },
      payment_method: value.payments_amount.toString()
    });
    onChange(prevState);
    nextStep();
  };

  const showErrorMessage = () => {
    const whithoutPlans = plansData.data?.quote?.planList?.length === 0;
    return (
      <Box className='align-text'>
        <Typography variant='h5'>¡Lo sentimos!</Typography>
        <Typography>
          {whithoutPlans ? t('QUOTATIONS.STEP_3.WHITHOUT_PLANS') : t('QUOTATIONS.STEP_3.SERVICE_ERROR')}
        </Typography>
        {!whithoutPlans && <Typography>{plansData.message.errors}</Typography>}
      </Box>
    );
  };

  const handleDownloadPdf = () => {
    // setLoader(true);
    // Api()
    //   .getDownloadPlans(stepsStore.plans.data?.quote?.id)
    //   .then((response: any) => {
    //     if (response) {
    //       const url = window.URL.createObjectURL(response?.data);
    //       const link = document.createElement('a');
    //       link.href = url;
    //       link.setAttribute('download', 'planes.pdf');
    //       document.body.appendChild(link);
    //       link.click();
    //       setLoader(false);
    //     } else {
    //       setLoader(false);
    //     }
    //   });
  };

  const renderPlansCards = () => {
    return plansData?.data?.quote?.planList?.map((plan: any, i: number) => (
      <span key={i}>
        <PlanCard
          data={plan}
          formatPrice={formatPrice}
          currencySimbol={'₲'}
          onSelect={handleSelectPlan}
          widthTitle={'85%'}
          itemsToShow={5}
          productId={productInfo.id}
        />
      </span>
    ));
  };

  const renderPlans = () => {
    SwiperCore.use([Navigation, Pagination]);
    const params = {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 30,
      grabCursor: true,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index: number, className: any) => {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        }
      }
    };
    if (plansData.loading)
      return (
        <>
          <LoadingPlanBox />
          <LoadingPlanBox />
          <LoadingPlanBox />
        </>
      );
    else
      return (
        <div>
          <Swiper {...params}>{renderPlansCards()}</Swiper>
        </div>
      );
  };

  return (
    <>
      <Box className={props.className}>
        {plansData.error ? (
          showErrorMessage()
        ) : (
          <>
            <Grid container spacing={3} alignItems='flex-end'>
              <Grid item xs={12} md={4}>
                <Typography>
                  {t('QUOTATIONS.STEP_1.BILLING_MODE')}
                  <span className='colored-mark'>*</span>
                </Typography>
                <Input
                  // defaultValue={opciones[0] as string}
                  onChange={(value: string): void => handleOnChange(value, 'billing_mode')}
                  // disabled={!billingModeList.length}
                  required
                  select
                >
                  {billingModeList.map((item: any) => (
                    <MenuItem key={String(item.code)} value={String(item.code)}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Input>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>
                  {t('QUOTATIONS.STEP_1.PAYMENT_METHOD')}
                  <span className='colored-mark'>*</span>
                </Typography>
                <Input
                  // error={dataForm?.plans?.payment_method?.error}
                  // defaultValue={dataForm?.plans?.payment_method?.value as string}
                  onChange={(value: string): void => handleOnChange(value, 'payment_method')}
                  // disabled={!paymentMethodList.length}
                  required
                  select
                >
                  {paymentMethodList.map((item: any) => (
                    <MenuItem key={String(item.code)} value={String(item.code)}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Input>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>
                  {t('QUOTATIONS.STEP_1.PAYMENT_CONDITION')}
                  <span className='colored-mark'>*</span>
                </Typography>
                <Input
                  // defaultValue={dataForm?.plans?.payment_condition?.value as string}
                  onChange={(value: string): void => handleOnChange(value, 'payment_condition')}
                  // disabled={!paymentConditionList.length}
                  required
                  select
                >
                  {paymentConditionList.map((item: any) => (
                    <MenuItem key={String(item.code)} value={String(item.code)}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Input>
              </Grid>
              <Grid container justifyContent='center' spacing={2} className='plans'>
                {renderPlans()}
              </Grid>
              {plansData?.data?.quote?.planList.length && (
                <Typography style={{ margin: '50px 0 0 41px' }}>
                  (*) {t('HOME_QUOTATIONS.STEP_2.INFO_PLANS')}
                </Typography>
              )}
            </Grid>
            <NavButtons {...props} />
          </>
        )}
      </Box>
    </>
  );
};

export const HomePlansComponent = styled(({ ...props }) => <CleanHomePlansComponent {...props} />)`
  margin-top: 2em;
  .swiper-container {
    height: 100%;
    max-height: 100vw;
    min-height: 0;
    min-width: 0;
    max-width: 70vw;
    width: 100%;
    overflow: hidden;
  }
  .swiper-slide {
    width: 100%;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
  }
  .swiper-wrapper {
    width: 100%;
    max-height: 100%;
    height: 100%;
    display: flex;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 632px;
  }
  .swiper-button-prev {
    left: 262px;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    left: 239px;
    margin-top: 25px;
    bottom: initial;
    max-width: 83.5%;
  }
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    background-color: var(--color-main);
    color: var(--color-white);
    font-size: 12px;
    line-height: 20px;
    opacity: 0.5;
    text-align: center;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
  .note {
    padding-top: 2em;
  }
  .green-title {
    color: var(--color-green);
    display: flex;
    align-items: center;
    margin-top: 1em;
  }
  .green-icon {
    fill: var(--color-green);
    margin-right: 2px;
  }
  .plans {
    margin-top: 2em;
  }
  .align-text {
    text-align: center;
  }
  .MuiSlider-rail {
    height: 8px;
    border-radius: 4px;
  }
  .MuiSlider-track {
    height: 8px;
    border-radius: 4px;
  }
  .MuiSlider-thumb {
    height: 24px;
    width: 24px;
    margin: -8px 0 0 -12px;
  }
`;
