// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IReportsProps } from './models';

const initialState: IReportsProps = {
  ordersGroupedByDate: { loading: false, error: false, ordersGroupedByDate: [] },
  products: { loading: false, error: false, products: [] },
  salesTeams: { loading: false, error: false, salesTeams: [] },
  salesmen: { loading: false, error: false, salesmen: [] },
  orders: {
    loading: false,
    error: false,
    nextPage: '',
    orders: [],
    ordersToExport: []
  }
};

export const reportsReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.REPORTS.ORDERS_GROUPED_BY_DATE.FETCH:
      return {
        ...state,
        ordersGroupedByDate: { ...state.ordersGroupedByDate, loading: true, error: false, ordersGroupedByDate: [] }
      };
    case ACTIONS.REPORTS.ORDERS_GROUPED_BY_DATE.SUCCESS:
      return {
        ...state,
        ordersGroupedByDate: {
          ...state.ordersGroupedByDate,
          loading: false,
          error: false,
          ordersGroupedByDate: action.payload.data
        }
      };
    case ACTIONS.REPORTS.ORDERS_GROUPED_BY_DATE.FAIL:
      return {
        ...state,
        ordersGroupedByDate: { ...state.ordersGroupedByDate, loading: false, error: true }
      };

    case ACTIONS.REPORTS.PRODUCTS.FETCH:
      return {
        ...state,
        products: { ...state.products, loading: true, error: false, products: [] }
      };
    case ACTIONS.REPORTS.PRODUCTS.SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          error: false,
          products: action.payload.data
        }
      };
    case ACTIONS.REPORTS.PRODUCTS.FAIL:
      return {
        ...state,
        products: { ...state.products, loading: false, error: true }
      };

    case ACTIONS.REPORTS.SALESTEAMS.FETCH:
      return {
        ...state,
        salesTeams: { ...state.salesTeams, loading: true, error: false, salesTeams: [] }
      };
    case ACTIONS.REPORTS.SALESTEAMS.SUCCESS:
      return {
        ...state,
        salesTeams: {
          ...state.salesTeams,
          loading: false,
          error: false,
          salesTeams: action.payload.data
        }
      };
    case ACTIONS.REPORTS.SALESTEAMS.FAIL:
      return {
        ...state,
        salesTeams: { ...state.salesTeams, loading: false, error: true }
      };

    case ACTIONS.REPORTS.SALESMEN.FETCH:
      return {
        ...state,
        salesmen: { ...state.salesmen, loading: true, error: false, salesmen: [] }
      };
    case ACTIONS.REPORTS.SALESMEN.SUCCESS:
      return {
        ...state,
        salesmen: {
          ...state.salesmen,
          loading: false,
          error: false,
          salesmen: action.payload.data
        }
      };
    case ACTIONS.REPORTS.SALESMEN.FAIL:
      return {
        ...state,
        salesmen: { ...state.salesmen, loading: false, error: true }
      };

    case ACTIONS.REPORTS.ORDERS.FETCH:
      return {
        ...state,
        orders: { ...state.orders, loading: true, error: false, orders: [] }
      };
    case ACTIONS.REPORTS.ORDERS.SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
          error: false,
          orders: [...state.orders.orders, ...action.payload.data.items],
          nextPage: action.payload.data.next
        }
      };
    case ACTIONS.REPORTS.ORDERS.FAIL:
      return {
        ...state,
        orders: { ...state.orders, loading: false, error: true }
      };
    case ACTIONS.REPORTS.ORDERS.NEXT_PAGE:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: true
        }
      };
    case ACTIONS.REPORTS.ORDERS.TO_EXPORT.FETCH:
      return {
        ...state,
        orders: { ...state.orders, loading: true, error: false, ordersToExport: [] }
      };
    case ACTIONS.REPORTS.ORDERS.TO_EXPORT.SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
          error: false,
          ordersToExport: action.payload.data
        }
      };

    default:
      return state;
  }
};
