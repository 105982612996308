import React from 'react';
import { Popper, StyledButton, DatePicker, substractTime } from '@wdynamo/common';
import moment from 'moment';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import iconEntered from '../../../../assets/img/icons_dynamo/icon_entered.svg';
import iconCheck from '../../../../assets/img/icons_dynamo/icon_check_filter.svg';
import iconTime from '../../../../assets/img/icons_dynamo/icon_time_filter.svg';
import iconTrash from '../../../../assets/img/icons_dynamo/icon_trash_filter.svg';
import iconExpired from '../../../../assets/img/icons_dynamo/icon_expired_filter.svg';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { FilterOrders, OrderStatus } from '../../models';

interface DateFilterPopperProps {
  onClick?: any;
  actionTitle: string;
  anchorEl: any;
  toggleOpen: any;
  dateFrom?: string;
  dateTo?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    popper: { maxWidth: '20em', textAlign: 'left' },
    fullWidth: { width: '100%' },
    check: {
      backgroundColor: 'transparent',
      color: '#666',
      fontSize: '0.9em !important',
      width: '100%',
      padding: '2px 5px !important',
      border: '1px solid #666',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        color: '#666'
      }
    },
    active: {
      backgroundColor: (props: any) => props.mainColor,
      color: 'white',
      border: 'none',
      '&:hover': {
        backgroundColor: (props: any) => props.mainColor,
        color: 'white'
      }
    },
    cancel: {
      backgroundColor: 'white',
      color: '#666',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    },
    iconButton: {
      border: '1px solid',
      margin: '2px',
      '&.active': {
        backgroundColor: (props: any) => props.mainColor
      }
    },
    brightness: {
      filter: 'brightness(10)'
    }
  })
);

export const DateFilterPopper: React.FC<DateFilterPopperProps> = (props: DateFilterPopperProps) => {
  const mainColor = document.body.style.getPropertyValue('--color-main') || '#ff9800';
  const classes = useStyles({ mainColor });
  const { t } = useTranslation();
  const [filterOrder, setFilterOrder] = React.useState<FilterOrders>(FilterOrders.ORDERS);
  const [filterOrderStatus, setFilterOrderStatus] = React.useState<OrderStatus>(OrderStatus.NONE);
  const [filterPaymentStatus, setFilterPaymentStatus] = React.useState<OrderStatus>(OrderStatus.NONE);
  const [dateFrom, setDateFrom] = React.useState<string>(
    props.dateFrom ||
      moment(substractTime(moment().toString(), 'months', 6)).format(process.env.REACT_APP_DATE_FORMAT).toString()
  );
  const [dateTo, setDateTo] = React.useState<string>(
    props.dateTo || moment().format(process.env.REACT_APP_DATE_FORMAT).toString()
  );
  const { anchorEl } = props;

  const handleCancel = (): void => {
    props.toggleOpen();
    setFilterOrder(FilterOrders.ORDERS);
    setFilterOrderStatus(OrderStatus.NONE);
    setFilterPaymentStatus(OrderStatus.NONE);
  };
  return (
    <Popper anchorEl={anchorEl}>
      <Grid container spacing={1} className={classes.popper}>
        <Grid item xs={6}>
          <DatePicker
            label={t('REPORTS.EXPORT.DATE_FROM')}
            value={dateFrom}
            onChange={(value: string): void => setDateFrom(value)}
            testId='dateFrom'
            format={process.env.REACT_APP_DATE_FORMAT!}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label={t('REPORTS.EXPORT.DATE_TO')}
            value={dateTo}
            onChange={(value: string): void => setDateTo(value)}
            testId='dateTo'
            format={process.env.REACT_APP_DATE_FORMAT!}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledButton
            testId='Sellers'
            onClick={(): void => setFilterOrder(FilterOrders.SELLERS)}
            className={`${classes.check} ${filterOrder === FilterOrders.SELLERS ? classes.active : 'secondary'}`}
          >
            {t('REPORTS.EXPORT.TOP_SELLERS')}
          </StyledButton>
        </Grid>
        <Grid item xs={6}>
          <StyledButton
            onClick={(): void => setFilterOrder(FilterOrders.PRODUCTS)}
            className={`${classes.check} ${filterOrder === FilterOrders.PRODUCTS ? classes.active : 'secondary'}`}
          >
            {t('REPORTS.EXPORT.TOP_PRODUCTS')}
          </StyledButton>
        </Grid>
        <Grid item xs={6}>
          <StyledButton
            testId='SellsDone'
            onClick={(): void => setFilterOrder(FilterOrders.ORDERS)}
            className={`${classes.check} ${filterOrder === FilterOrders.ORDERS ? classes.active : 'secondary'}`}
          >
            {t('REPORTS.EXPORT.SELLS_DONE')}
          </StyledButton>
        </Grid>
        {filterOrder === FilterOrders.ORDERS && (
          <>
            <Grid item xs={12}>
              <Typography variant='subtitle1' align='left'>
                {t('REPORTS.EXPORT.ORDER_STATUS')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <IconButton
                onClick={(): void =>
                  setFilterOrderStatus(
                    filterOrderStatus === OrderStatus.APPROVED ? OrderStatus.NONE : OrderStatus.APPROVED
                  )
                }
                className={`${classes.iconButton} ${filterOrderStatus === OrderStatus.APPROVED && classes.active}`}
              >
                <img
                  src={iconCheck}
                  className={`${filterOrderStatus === OrderStatus.APPROVED && classes.brightness}`}
                  alt=''
                />
              </IconButton>
              <IconButton
                onClick={(): void =>
                  setFilterOrderStatus(
                    filterOrderStatus === OrderStatus.DECLINED ? OrderStatus.NONE : OrderStatus.DECLINED
                  )
                }
                className={`${classes.iconButton} ${filterOrderStatus === OrderStatus.DECLINED && classes.active}`}
              >
                <img
                  src={iconTrash}
                  className={`${filterOrderStatus === OrderStatus.DECLINED && classes.brightness}`}
                  alt=''
                />
              </IconButton>
              <IconButton
                onClick={(): void =>
                  setFilterOrderStatus(
                    filterOrderStatus === OrderStatus.ENTERED ? OrderStatus.NONE : OrderStatus.ENTERED
                  )
                }
                className={`${classes.iconButton} ${filterOrderStatus === OrderStatus.ENTERED && classes.active}`}
              >
                <img
                  src={iconEntered}
                  className={`${filterOrderStatus === OrderStatus.ENTERED && classes.brightness}`}
                  alt=''
                />
              </IconButton>
              <IconButton
                onClick={(): void =>
                  setFilterOrderStatus(
                    filterOrderStatus === OrderStatus.EXPIRED ? OrderStatus.NONE : OrderStatus.EXPIRED
                  )
                }
                className={`${classes.iconButton} ${filterOrderStatus === OrderStatus.EXPIRED && classes.active}`}
              >
                <img
                  src={iconExpired}
                  className={`${filterOrderStatus === OrderStatus.EXPIRED && classes.brightness}`}
                  alt=''
                />
              </IconButton>
              <IconButton
                onClick={(): void =>
                  setFilterOrderStatus(
                    filterOrderStatus === OrderStatus.PENDING ? OrderStatus.NONE : OrderStatus.PENDING
                  )
                }
                className={`${classes.iconButton} ${filterOrderStatus === OrderStatus.PENDING && classes.active}`}
              >
                <img
                  src={iconTime}
                  className={`${filterOrderStatus === OrderStatus.PENDING && classes.brightness}`}
                  alt=''
                />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle1' align='left'>
                {t('REPORTS.EXPORT.PAYMENT_STATUS')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <IconButton
                onClick={(): void =>
                  setFilterPaymentStatus(
                    filterPaymentStatus === OrderStatus.APPROVED ? OrderStatus.NONE : OrderStatus.APPROVED
                  )
                }
                className={`${classes.iconButton} ${filterPaymentStatus === OrderStatus.APPROVED && classes.active}`}
              >
                <img
                  src={iconCheck}
                  className={`${filterPaymentStatus === OrderStatus.APPROVED && classes.brightness}`}
                  alt=''
                />
              </IconButton>
              <IconButton
                onClick={(): void =>
                  setFilterPaymentStatus(
                    filterPaymentStatus === OrderStatus.DECLINED ? OrderStatus.NONE : OrderStatus.DECLINED
                  )
                }
                className={`${classes.iconButton} ${filterPaymentStatus === OrderStatus.DECLINED && classes.active}`}
              >
                <img
                  src={iconTrash}
                  className={`${filterPaymentStatus === OrderStatus.DECLINED && classes.brightness}`}
                  alt=''
                />
              </IconButton>
              <IconButton
                onClick={(): void =>
                  setFilterPaymentStatus(
                    filterPaymentStatus === OrderStatus.ENTERED ? OrderStatus.NONE : OrderStatus.ENTERED
                  )
                }
                className={`${classes.iconButton} ${filterPaymentStatus === OrderStatus.ENTERED && classes.active}`}
              >
                <img
                  src={iconEntered}
                  className={`${filterPaymentStatus === OrderStatus.ENTERED && classes.brightness}`}
                  alt=''
                />
              </IconButton>
              <IconButton
                onClick={(): void =>
                  setFilterPaymentStatus(
                    filterPaymentStatus === OrderStatus.EXPIRED ? OrderStatus.NONE : OrderStatus.EXPIRED
                  )
                }
                className={`${classes.iconButton} ${filterPaymentStatus === OrderStatus.EXPIRED && classes.active}`}
              >
                <img
                  src={iconEntered}
                  className={`${filterPaymentStatus === OrderStatus.EXPIRED && classes.brightness}`}
                  alt=''
                />
              </IconButton>
              <IconButton
                onClick={(): void =>
                  setFilterPaymentStatus(
                    filterPaymentStatus === OrderStatus.PENDING ? OrderStatus.NONE : OrderStatus.PENDING
                  )
                }
                className={`${classes.iconButton} ${filterPaymentStatus === OrderStatus.PENDING && classes.active}`}
              >
                <img
                  src={iconExpired}
                  className={`${filterPaymentStatus === OrderStatus.PENDING && classes.brightness}`}
                  alt=''
                />
              </IconButton>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <StyledButton
            className={classes.fullWidth + ' default'}
            onClick={() => {
              props.onClick(dateFrom, dateTo, filterOrderStatus);
            }}
          >
            {props.actionTitle}
          </StyledButton>
        </Grid>
        <Grid item xs={12}>
          <StyledButton className={`secondary ${classes.fullWidth} ${classes.cancel}`} onClick={handleCancel}>
            {t('COMMONS.BUTTONS.CANCEL')}
          </StyledButton>
        </Grid>
      </Grid>
    </Popper>
  );
};
