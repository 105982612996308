const phoneValidator = (phone: string): boolean => {
  let isValid = true;
  if (phone) {
    const re = /^595[09]\d{2}-?\d{6}$/;
    isValid = re.test(String(phone));
  }
  return isValid;
};

export default phoneValidator;
