import React from 'react';
import styled from 'styled-components';
import { Tabs, Tab } from '@material-ui/core';

interface PropsSalesTabs {
  tabHeaders: Array<TabHeaderProps>;
  tabPanels: Array<TabPanelProps>;
  value: number;
  handleChange(event: React.ChangeEvent<{}>, newValue: number): void;
  className: string;
}
interface TabHeaderProps {
  index: number;
  label: string;
}

interface TabPanelProps {
  index: number;
  content: React.ReactNode;
}
const CleanSalesTab = (props: PropsSalesTabs) => {
  const { value, tabPanels, tabHeaders, handleChange } = props;

  return (
    <div className={props.className}>
      <Tabs value={value} onChange={handleChange} indicatorColor='primary' className='tabs'>
        {tabHeaders.map((tabHeader) => (
          <Tab
            key={tabHeader.index}
            label={tabHeader.label}
            className={value == tabHeader.index ? 'tab tab-active' : 'tab'}
          />
        ))}
      </Tabs>
      {tabPanels.map((tabPanel) => (
        <div key={tabPanel.index} role='tabpanel' hidden={value !== tabPanel.index} id={`tabpanel-${tabPanel.index}`}>
          {tabPanel.content}
        </div>
      ))}
    </div>
  );
};

export const SalesTab = styled(({ ...props }) => <CleanSalesTab {...props} />)`
  .tabs {
  }
  .tab {
    border-radius: 10px 10px 0 0;
    height: 38px;
    width: 580px;
    border-bottom: 4px solid #f1f1f1;
    color: black;
    opacity: 1;
  }
  .tab-active {
    border-bottom: 0;
    background-color: var(--color-main);
    color: white;
  }
`;
