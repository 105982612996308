import React from 'react';
import styled from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface CleanUserSelectProps {
  className?: string;
  data: any[];
  setResult(user: any): void;
  setShowResult(showResult: boolean): void;
  filterUsersFetch(filter: string): void;
  filteredUsers: any;
}

const CleanUserSelect = (props: CleanUserSelectProps) => {
  const { t } = useTranslation();
  const [list, setList] = React.useState<any[]>([]);
  const { setShowResult } = props;

  React.useEffect(() => {
    setList(props.filteredUsers.list);
  }, [props.filteredUsers]);

  const handleInputChange = (_event: any, value: string) => {
    if (value.length > 2) {
      props.filterUsersFetch(value);
    } else {
      setShowResult(false);
      setList([]);
    }
  };

  const handleOnChange = (value: any) => {
    setShowResult(false);
    props.setResult(value);
  };

  return (
    <Autocomplete
      options={list}
      className={props.className}
      onInputChange={(event, value) => handleInputChange(event, value)}
      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
      onChange={(e, value) => handleOnChange(value)}
      renderInput={(params) => <TextField {...params} variant='standard' />}
      noOptionsText={t('GROUPS.EDIT_GROUP.SELECT_USER.NO_RESULTS')}
    ></Autocomplete>
  );
};

export const UserSelect = styled(({ ...props }) => <CleanUserSelect {...props} />)``;
