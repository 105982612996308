// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType, IBaseStateProps } from '../ActionTypes';

const initialState: IBaseStateProps = {
  loading: false,
  error: false,
  data: {}
};

export const exampleReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.EXAMPLE.FETCH:
      return {
        ...state,
        loading: true,
        error: false,
        data: {}
      };
    case ACTIONS.EXAMPLE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data.data
      };
    case ACTIONS.EXAMPLE.FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
};
