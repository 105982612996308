import axios from 'axios';

const Api = () => {
  const bookingOrdersURL = `${process.env.REACT_APP_BOOKING_BASE_URL}/collaborator/orders`;

  const getGroupsFetch = (): Promise<unknown> => axios.get(`${process.env.REACT_APP_KAISER_V3_BASE_URL}/groups`);

  const getMySalesFetch = (nextPage?: string): Promise<unknown> =>
    nextPage
      ? axios.get(nextPage)
      : axios.get(bookingOrdersURL, {
          params: {
            pagelen: 10,
            sort: '-created_at',
            page: 1
          }
        });

  const getMySalesDashboardFetch = (): Promise<unknown> =>
    axios.get(bookingOrdersURL, {
      params: {
        pagelen: 100,
        sort: '-created_at',
        page: 1
      }
    });

  return { getMySalesFetch, getMySalesDashboardFetch, getGroupsFetch };
};

export default Api;
