import { ACTIONS } from '../ActionTypes';

// Reset
export const resetCheckout = () => ({ type: ACTIONS.CHECKOUT.RESET });

// Search Doc Number info
export const getDocNumberInfoFetch = (docType: string, docNumber: string) => ({
  type: ACTIONS.CHECKOUT.FIND_DOC_NUMBER.FETCH,
  payload: { docType, docNumber }
});

export const getDocNumberInfoSuccess = (data: any) => ({
  type: ACTIONS.CHECKOUT.FIND_DOC_NUMBER.SUCCESS,
  payload: { data }
});

export const getDocNumberInfoFail = (error: object) => ({
  type: ACTIONS.CHECKOUT.FIND_DOC_NUMBER.FAIL,
  payload: { error }
});

// Update client's info
export const updateClientInfo = (fieldKey: string, value: string) => ({
  type: ACTIONS.CHECKOUT.UPDATE_CLIENT_INFO,
  payload: { [fieldKey]: value }
});

// Update address's info
export const updateAddressInfo = (fieldKey: string, value: string) => ({
  type: ACTIONS.CHECKOUT.UPDATE_ADDRESS_INFO,
  payload: { [fieldKey]: value }
});

// Update payment's info
export const updatePaymentInfo = (fieldKey: string, value: string) => ({
  type: ACTIONS.CHECKOUT.UPDATE_PAYMENT_INFO,
  payload: { [fieldKey]: value }
});

// Checkout's confirmation
export const confirmationFetch = (checkout: object) => ({
  type: ACTIONS.CHECKOUT.CONFIRMATION.FETCH,
  payload: { checkout }
});

export const confirmationSuccess = (orderId: number | string) => ({
  type: ACTIONS.CHECKOUT.CONFIRMATION.SUCCESS,
  payload: { orderId }
});

export const confirmationFail = (error: object) => ({
  type: ACTIONS.CHECKOUT.CONFIRMATION.FAIL,
  payload: { error }
});

// Payment's url
export const getPaymentUrlFetch = (orderId: number | string) => ({
  type: ACTIONS.CHECKOUT.PAYMENT_URL.FETCH,
  payload: { orderId }
});

export const getPaymentUrlSuccess = (paymentUrl?: string) => ({
  type: ACTIONS.CHECKOUT.PAYMENT_URL.SUCCESS,
  payload: { paymentUrl }
});

export const getPaymentUrlFail = (error: object) => ({
  type: ACTIONS.CHECKOUT.PAYMENT_URL.FAIL,
  payload: { error }
});
