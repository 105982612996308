import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Grid, ClickAwayListener } from '@material-ui/core';
import {
  Date,
  Price,
  CustomInfiniteScroll,
  Loading,
  Modal,
  TextPoint,
  StyledButton,
  CustomTooltip,
  IconButton,
  Input
} from '@wdynamo/common';
// eslint-disable-next-line no-unused-vars
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ReactComponent as SearchIcon } from '../../../assets/img/icons_dynamo/icon_search_new.svg';
import { ReactComponent as NewIcon } from '../../../assets/img/icons_dynamo/icon_filter_new.svg';
import copyIcon from '../../../assets/img/icons_dynamo/icon_copy.svg';
import { ReactComponent as IconDetail } from '../../../assets/img/icons_dynamo/icon_detail.svg';
// eslint-disable-next-line no-unused-vars
import { IMySalesTableProps } from '../models';
import { uris } from '../../../siteMap';
import { SalesFilter } from './SalesFilter';

interface IOrdersScrollProps {
  [key: string]: any;
  ordersData: IMySalesTableProps;
  firstLoading: boolean;
  paginate(nextPage: string): void;
  className?: string;
}
const useStyles = makeStyles(() =>
  createStyles({
    popper: { textAlign: 'left', color: 'var(--color-black)', width: '100%' }
  })
);
const CleanOrdersScroll: React.FC<IOrdersScrollProps> = (props: IOrdersScrollProps) => {
  const { ordersData } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>({});
  const [showModalTooltip, setShowModalTooltip] = useState<boolean>(false);
  const [isSearchBarVisible, setIsSearchBarVisible] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const togglePopper = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchText.trim() !== '') {
      //TODO: Falta que este definido el param
      console.log(`filtro: "${searchText}"`);
    }
  };
  const toggleSearchBar = () => {
    setIsSearchBarVisible(!isSearchBarVisible);
  };

  useEffect(() => {
    setIsPaginating(ordersData.loading);
  }, [ordersData.loading]);

  const handleChangePage = (): void => {
    if (ordersData.nextPage && !ordersData.loading && !isPaginating) {
      setIsPaginating(true);
      props.paginate(ordersData.nextPage);
    }
  };

  const handlePayment = (paymentUrl: string): void => {
    window.location.replace(
      `${paymentUrl}&callbackURL=${window.location.origin.replace('http://', 'https://')}${uris.mySales.uri}`
    );
  };

  const handleCopy = (paymentUrl: string): void => {
    navigator.clipboard.writeText(paymentUrl);
    setShowModalTooltip(true);
    setTimeout(() => {
      setShowModalTooltip(false);
    }, 3000);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const renderModal = (): React.ReactNode => {
    let totalAmount: number = 0;
    selectedOrder.items?.forEach((item: any): void => (totalAmount += item.price.amount));

    return (
      <Modal
        open={openModal}
        handleClose={() => {
          setSelectedOrder({});
          setOpenModal(false);
        }}
        className={classes.popper}
        title={
          <h1 style={{ padding: '0.5em' }}>
            {t('MY_SALES.TABLE.SOLD_MODAL.TITLE')}
            <TextPoint />
          </h1>
        }
        body={
          <>
            {selectedOrder.items && (
              <Grid container style={{ padding: '0.5em' }}>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6} className='item-container'>
                    <div className='item-label'>{t('MY_SALES.TABLE.QUOTATION')}</div>
                    <Date date={selectedOrder.created_at} format={process.env.REACT_APP_DATE_FORMAT!} />
                  </Grid>
                  <Grid xs={12} sm={6} item className='item-container'>
                    <div className='item-label'>{t('MY_SALES.TABLE.COMISSION')}</div>
                    <div className='item-value'>u$d100,00</div>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid xs={12} sm={6} item className='item-container'>
                    <div className='item-label'>{t('MY_SALES.TABLE.INSURANCE')}</div>
                    {selectedOrder.items.map(
                      (item: any, index: number): React.ReactNode => (
                        <div key={index} className='item-value'>
                          {item.product.title}
                        </div>
                      )
                    )}
                  </Grid>
                  <Grid xs={12} sm={6} item className='item-container'>
                    <div className='item-label'>{t('MY_SALES.TABLE.SOLD_MODAL.AMOUNT')}</div>
                    <div className='item-value'>{selectedOrder.items.length}</div>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid xs={12} sm={6} item className='item-container'>
                    <div className='item-label'>{t('MY_SALES.TABLE.CLIENT')}</div>
                    <div className='item-value'>{`${selectedOrder.customer.first_name} ${selectedOrder.customer.last_name} `}</div>
                  </Grid>
                  <Grid xs={12} sm={6} item className='item-container'>
                    <div className='item-label'>{t('MY_SALES.TABLE.REQUEST')}</div>
                    <div className='item-value'>
                      {t(`MY_SALES.TABLE.TOOLTIPS.${selectedOrder.current_status?.status}`)}
                    </div>
                  </Grid>
                </Grid>
                {selectedOrder.current_status.reasons.length > 0 && (
                  <Grid container item xs={12}>
                    <Grid xs={6} sm={4} item className='item-container'>
                      <>
                        <div className='item-label'>{t('MY_SALES.TABLE.REASON')}</div>
                        <div className='item-value'>
                          {selectedOrder.current_status?.reasons.map((reason: any) => (
                            <p key={reason.id}>{reason}</p>
                          ))}
                        </div>
                      </>
                    </Grid>
                  </Grid>
                )}
                <Grid container item xs={12}>
                  <Grid item xs={12} className='price'>
                    <span className='total'>Total</span>
                    <Price amount={totalAmount} />
                  </Grid>
                </Grid>
                {selectedOrder.current_status?.status === 'APPROVED' &&
                  selectedOrder.payment_current_status?.status !== 'APPROVED' &&
                  (selectedOrder.payments[selectedOrder.payments.length - 1].payment_request_url ? (
                    <Grid container item xs={12} spacing={3} className='pointer'>
                      <Grid
                        item
                        container
                        xs={6}
                        sm={8}
                        spacing={2}
                        justify='flex-end'
                        alignItems='center'
                        onClick={() => {
                          handleCopy(selectedOrder.payments[selectedOrder.payments.length - 1].payment_request_url);
                        }}
                      >
                        <Grid item>
                          <img src={copyIcon} alt='' />
                        </Grid>
                        <Grid item>
                          <CustomTooltip
                            open={showModalTooltip}
                            title={t('MY_SALES.TABLE.SOLD_MODAL.COPIED_LINK') as string}
                            arrow
                            placement='top'
                          >
                            <span>{t('MY_SALES.TABLE.SOLD_MODAL.COPY_LINK')}</span>
                          </CustomTooltip>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <StyledButton
                          className='default'
                          onClick={() =>
                            handlePayment(selectedOrder.payments[selectedOrder.payments.length - 1].payment_request_url)
                          }
                        >
                          {t('COMMONS.BUTTONS.PAY')}
                        </StyledButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item container justify='flex-end'>
                      <Grid item>{t('MY_SALES.TABLE.SOLD_MODAL.NO_PAYMENT_LINK_AVAILABLE')}</Grid>
                    </Grid>
                  ))}
              </Grid>
            )}
          </>
        }
      />
    );
  };

  const renderItemsTable = (): React.ReactNode => {
    const { loading, items, nextPage } = ordersData;
    if (!items.length && loading) return <Loading />;
    else if (!items.length) return <></>;
    else
      return (
        <Grid container className='my-sales-table'>
          <Grid xs={12} container item direction='row' justify='flex-end'>
            <ClickAwayListener mouseEvent='onMouseDown' touchEvent='onTouchStart' onClickAway={handleClickAway}>
              <div>
                <span onClick={togglePopper}>
                  <IconButton Icon={NewIcon} className={anchorEl != null ? 'filter-icon-active' : ''} />
                </span>
                <SalesFilter
                  insurances={[
                    { name: 'Seguro 1', key: '1299' },
                    { name: 'Seguro 2', key: '11299' }
                  ]}
                  requests={[
                    { name: 'Emitida', key: '123123' },
                    { name: 'Recibida', key: '9123' }
                  ]}
                  actionTitle={t('MY_SALES.FILTER')}
                  anchorEl={anchorEl}
                  toggleOpen={togglePopper}
                />
              </div>
            </ClickAwayListener>
            <span>
              {isSearchBarVisible && (
                <Input
                  label={t('COMMONS.SEARCH_BAR.PLACEHOLD')}
                  onChange={(value: string): void => setSearchText(value)}
                  onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>): void => handleKeyPress(event)}
                  testId='Search'
                  fullWidth={true}
                />
              )}
              <IconButton
                Icon={SearchIcon}
                onClick={toggleSearchBar}
                className={isSearchBarVisible ? 'search-icon-active' : ''}
              />
            </span>
          </Grid>
          <Grid item xs={12} className='header'>
            <Grid container>
              <Grid item xs={6} sm={2}>
                {t('MY_SALES.TABLE.QUOTATION')}
              </Grid>
              <Grid item xs={6} sm={2}>
                {t('MY_SALES.TABLE.INSURANCE')}
              </Grid>
              <Grid item xs={12} sm={2}>
                {t('MY_SALES.TABLE.CLIENT')}
              </Grid>
              <Grid item xs={3} sm={2}>
                {t('MY_SALES.TABLE.REQUEST')}
              </Grid>
              <Grid item xs={6} sm={2} style={{ textAlign: 'right' }}>
                {t('MY_SALES.TABLE.COMISSION')}
              </Grid>
              <Grid item xs={6} sm={2}></Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className='content'>
            <CustomInfiniteScroll nextUrl={nextPage} paginate={handleChangePage} firstLoading={props.firstLoading}>
              {items.map((order: any) => (
                <Grid container key={order.id} className='sale' onClick={() => {}}>
                  <Grid item xs={6} sm={2}>
                    <Date date={order.created_at} format={process.env.REACT_APP_DATE_FORMAT!} />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {order.items.map(
                      (item: any, index: number): React.ReactNode => (
                        <div key={index} className='item-value'>
                          {item.product.title}
                        </div>
                      )
                    )}
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <span>{`${order.customer.first_name} ${order.customer.last_name} `}</span>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    {t(`MY_SALES.TABLE.TOOLTIPS.${order.current_status?.status}`)}
                  </Grid>
                  <Grid item xs={6} sm={2} className='price'>
                    {order.items.map((boughtItem: any, index: number) => (
                      <div key={index}>
                        <Price amount={boughtItem.price.amount} />
                      </div>
                    ))}
                  </Grid>
                  <Grid item xs={2} className='details'>
                    <IconButton
                      onClick={() => {
                        setSelectedOrder(order);
                        setOpenModal(true);
                      }}
                      Icon={IconDetail}
                    />
                  </Grid>
                </Grid>
              ))}
            </CustomInfiniteScroll>
          </Grid>
        </Grid>
      );
  };

  return (
    <div className={props.className}>
      {renderItemsTable()}
      {renderModal()}
    </div>
  );
};

export const OrdersScroll = styled(({ ...props }) => <CleanOrdersScroll {...props} />)`
  .details {
    text-align: right;
  }
  .my-sales-table {
    color: var(--color-black);
    .header {
      border: none;
      div {
        padding: 5px 0;
        font-weight: bold;
      }
    }
    .content {
      .sale:nth-child(odd) {
        background-color: #f3f3f3;
      }
      .sale {
        padding: 10px 0;
        &:last-child {
          border: none;
        }
        .price {
          text-align: right;
          font-size: 20px;
          color: var(--color-black);
        }
      }
    }
  }
  .pointer {
    cursor: pointer !important;
  }
  .search-icon-active {
    svg {
      #SearchIcon {
        fill: var(--color-main);
      }
    }
  }
  .filter-icon-active {
    svg {
      #FilterIcon {
        fill: var(--color-main);
      }
    }
  }
`;
