// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../../../ActionTypes';

// eslint-disable-next-line no-unused-vars
import { IGeneralDataBaseStore } from './models';

const initialState: IGeneralDataBaseStore = {
  contacts: {
    loading: false,
    error: false,
    data: []
  }
};

export const homeQuotationGeneralDataReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.HOME_QUOTATIONS.CONTACTS.FETCH:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: true,
          error: false
        }
      };
    case ACTIONS.HOME_QUOTATIONS.CONTACTS.SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
          error: false,
          data: action.payload.data
        }
      };
    case ACTIONS.HOME_QUOTATIONS.CONTACTS.FAIL:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.HOME_QUOTATIONS.CONTACTS.CLEAR:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
          error: false,
          data: []
        }
      };

    default:
      return state;
  }
};
