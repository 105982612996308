import { ACTIONS } from '../../../../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { GroupInfoForm } from './models';

// Group Info
export const groupInfoFetch = (groupInfoForm: GroupInfoForm, groupId?: string) => ({
  type: ACTIONS.GROUPS.GROUP_INFO.FETCH,
  payload: { data: groupInfoForm, groupId: groupId }
});

export const groupInfoSuccess = () => ({ type: ACTIONS.GROUPS.GROUP_INFO.SUCCESS });

export const groupInfoFail = (error: object) => ({ type: ACTIONS.GROUPS.GROUP_INFO.FAIL, payload: { error } });

// Group members
export const getGroupMembersFetch = (groupId: string) => ({
  type: ACTIONS.GROUPS.GROUP_MEMBERS.FETCH,
  payload: { groupId: groupId }
});

export const getGroupMembersSuccess = (data: any) => ({
  type: ACTIONS.GROUPS.GROUP_MEMBERS.SUCCESS,
  payload: { data: data }
});

export const getGroupMembersFail = (error: object) => ({ type: ACTIONS.GROUPS.GROUP_MEMBERS.FAIL, payload: { error } });

// delete Groups member
export const deleteMemberFetch = (groupId: string) => ({
  type: ACTIONS.GROUPS.GROUP_MEMBERS.REMOVE.FETCH,
  payload: { id: groupId }
});

export const deleteMemberSuccess = (data: any) => ({
  type: ACTIONS.GROUPS.GROUP_MEMBERS.REMOVE.SUCCESS,
  payload: { data: data }
});

export const deleteMemberFail = (error: object) => ({
  type: ACTIONS.GROUPS.GROUP_MEMBERS.REMOVE.FAIL,
  payload: { error }
});
