// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IUsersProps } from './models';

const initialState: IUsersProps = {
  loading: false,
  error: false,
  users: [],
  filteredUsers: {
    loading: false,
    error: false,
    list: []
  },
  current_page: undefined,
  total_pages: undefined
};

export const usersReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    //get users
    case ACTIONS.USERS.FETCH:
      return {
        ...state,
        loading: true,
        error: false,
        users: [],
        current_page: undefined,
        total_pages: undefined
      };
    case ACTIONS.USERS.SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...state.users, ...action.payload.users],
        current_page: action.payload.page,
        total_pages: action.payload.total_pages
      };
    case ACTIONS.USERS.FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ACTIONS.USERS.NON_PAGED_LIST.FETCH:
      return {
        ...state,
        filteredUsers: {
          loading: true,
          error: false,
          list: []
        }
      };
    case ACTIONS.USERS.NON_PAGED_LIST.SUCCESS:
      return {
        ...state,
        filteredUsers: {
          loading: false,
          error: false,
          list: action.payload.data
        }
      };
    case ACTIONS.USERS.NON_PAGED_LIST.FAIL:
      return {
        ...state,
        filteredUsers: {
          loading: false,
          error: true,
          list: []
        }
      };

    default:
      return state;
  }
};
