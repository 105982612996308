import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Analytics } from '@wdynamo/common/lib/services';
import { Box, Grid, Typography, TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { NavButtons } from '../../common/NavButtons';
import { Autocomplete } from '@material-ui/lab';
import emailValidator from '../../common/emailValidator';
// eslint-disable-next-line no-unused-vars
import { IContact } from '../models';

interface IGeneralDataProps {
  className?: string;
  data: any;
  stepsApStore: any;
  dataForm: any;
  onChange(data: any): void;
  currentContact: IContact;
  setCurrentContact(contact: any | undefined): void;
  getContactsFetch(params: any): void;
  getContactsClear(): void;
  dataContacts: any;
}

const CleanGeneralDataComponent: React.FC<IGeneralDataProps> = (props: IGeneralDataProps) => {
  // eslint-disable-next-line no-unused-vars
  const {
    // eslint-disable-next-line no-unused-vars
    data,
    //eslint-disable-next-line no-unused-vars
    stepsApStore,
    dataForm,
    onChange,
    // eslint-disable-next-line no-unused-vars
    currentContact,
    setCurrentContact,
    getContactsFetch,
    getContactsClear,
    dataContacts
  } = props;

  const [listContacts, setListContacts] = useState<any>([]);

  useEffect(() => {
    Analytics().pageview('ap-badget | general-data');
    if (currentContact?.id) {
      handleOnChange(currentContact?.phone, 'telephone');
      handleOnChange(currentContact?.mail, 'mail');
    }
  }, []);

  useEffect(() => {
    setListContacts(Array.isArray(dataContacts.data) ? dataContacts.data : dataContacts.data.items.items);
  }, [dataContacts.data]);

  const handleOnChange = (value: any, inputName: any) => {
    const prevState = { ...dataForm };
    const currentField = prevState.general_data[inputName];
    currentField.value = value;
    if (inputName === 'mail') {
      currentField.error = !emailValidator(value);
    }
    onChange(prevState);
  };

  const dataIsInList = (key: string, value: string): any[] => {
    return listContacts.filter((contact: any) => contact[key] === value);
  };

  const handleOnInputChangePhone = (value: any) => {
    handleOnChange(value, 'telephone');
    if (value.length === 4) {
      getContactsFetch({ search: value });
    } else if (dataIsInList('phone', value).length) {
      setCurrentContact(dataIsInList('phone', value)[0]);
      handleOnChange(dataIsInList('phone', value)[0].mail, 'mail');
    } else if (value !== '') {
      setCurrentContact(undefined);
    } else if (value.length === 10) {
      getContactsClear();
    }
  };

  const handleOnInputChange = (value: any, input: string) => {
    const prevState = { ...dataForm };
    const currentInput = prevState.general_data[input];
    currentInput.value = value;
    onChange(prevState);
  };

  const handleOnChangePhone = (value: any) => {
    setCurrentContact(value);
    if (value === null) {
      handleOnChange('', 'email');
      getContactsClear();
    }
  };

  const handleOnChangeEmail = (value: any) => {
    setCurrentContact(value);
  };

  const handleOnInputChangeEmail = (value: any) => {
    handleOnChange(value, 'mail');
    if (dataForm?.general_data?.telephone?.value === '') {
      if (value.length === 4) {
        getContactsFetch({ search: value });
      } else if (dataIsInList('mail', value).length) {
        handleOnChange(dataIsInList('mail', value)[0].phone, 'telephone');
      } else if (value !== '') {
        setCurrentContact(undefined);
        handleOnInputChange('', 'telephone');
      }
    }
  };

  return (
    <Box>
      <Grid container spacing={3} className={props.className}>
        <Grid item xs={6} className='py'>
          <Typography>
            Cantidad de asegurados
            <span className='colored-mark'>*</span>
          </Typography>
          <TextField
            type='number'
            value={dataForm?.general_data?.amount_asured?.value ? dataForm.general_data.amount_asured.value : ''}
            placeholder='Ingresar'
            onChange={(event) => handleOnChange(event.target.value, 'amount_asured')}
            InputProps={{
              inputProps: { min: 1 }
            }}
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={5} className='py'>
          <Typography>
            Actividad
            <span className='colored-mark'>*</span>
          </Typography>
          <Autocomplete
            options={opciones}
            renderInput={(params) => <TextField {...params} variant='standard' placeholder={'Seleccionar'} />}
            autoComplete={true}
            autoHighlight={true}
            clearOnBlur={false}
            blurOnSelect={false}
            // getOptionLabel={(option: any) => `${option.description}`}
            onChange={(e, value) => handleOnChange(value, 'activity')}
          />
        </Grid> */}
        <Grid item xs={3} className='py'>
          <Typography>
            Con uso de moto?
            <span className='colored-mark'>*</span>
          </Typography>
          <RadioGroup
            row
            name='position'
            defaultValue='top'
            className='radio-group-style'
            onChange={(e) => handleOnChange(e.target.value, 'has_motorcycle')}
            value={dataForm?.general_data?.has_motorcycle?.value}
          >
            <FormControlLabel value={'0'} control={<Radio />} label='No' />
            <FormControlLabel value={'1'} control={<Radio />} label='Si' />
          </RadioGroup>
        </Grid>
        <Grid item xs={6} className='py'>
          <Typography>
            Teléfono
            <span className='colored-mark'>*</span>
          </Typography>
          <Autocomplete
            clearOnBlur={false}
            blurOnSelect={false}
            options={listContacts ? listContacts : []}
            getOptionLabel={(option: any) => `${option.phone}`}
            onChange={(e, value) => handleOnChangePhone(value)}
            onInputChange={(e, value) => handleOnInputChangePhone(value)}
            renderInput={(params) => <TextField {...params} variant='standard' placeholder={'Ingresar'} />}
            inputValue={
              dataForm?.general_data?.telephone?.value ||
              stepsApStore?.createContact?.data?.data?.clientRepresentation[0]?.phone ||
              ''
            }
            noOptionsText={'Sin coincidencias'}
          />
        </Grid>
        <Grid item xs={6} className='py'>
          <Typography>
            Correo electrónico
            <span className='colored-mark'>*</span>
          </Typography>
          <Autocomplete
            clearOnBlur={false}
            blurOnSelect={false}
            options={listContacts ? listContacts : []}
            getOptionLabel={(option: any) => `${option.mail}`}
            onChange={(e, value) => handleOnChangeEmail(value)}
            onInputChange={(e, value) => handleOnInputChangeEmail(value)}
            renderInput={(params) => <TextField {...params} variant='standard' placeholder={'Ingresar'} />}
            inputValue={
              dataForm?.general_data?.mail?.value ||
              stepsApStore?.createContact?.data?.data?.clientRepresentation[0]?.mail ||
              ''
            }
            noOptionsText={'Sin coincidencias'}
          />
        </Grid>
      </Grid>
      <NavButtons {...props} />
    </Box>
  );
};

export const GeneralDataComponent = styled(({ ...props }) => <CleanGeneralDataComponent {...props} />)`
  .py {
    padding-top: 2em;
  }
  .radio-group-style {
    display: flex;
    justify-content: flex-start;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
