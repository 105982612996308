import { connect } from 'react-redux';
import { ApPlansComponent } from './view';
import { getBillingModeFetch, getPaymentMethodFetch, getPaymentConditionFetch } from '../actions';

const mapStateToProps = (state: any) => ({
  stepsApStore: state.quotationApState
});

const mapDispatchToProps = {
  getBillingModeFetch,
  getPaymentMethodFetch,
  getPaymentConditionFetch
};

export const ApPlans = connect(mapStateToProps, mapDispatchToProps)(ApPlansComponent);
