import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Backdrop, Fade, Grid, List, ListItem, ListItemIcon, ListItemText, Modal, Typography } from '@material-ui/core';
import { StyledButton } from '@wdynamo/common';
import { ReactComponent as EllipseIcon } from '../../../../../../../../assets/img/icons_dynamo/icon_ellipse.svg';

interface IPlanBoxProp {
  className?: string;
  currencySimbol: string;
  data: any;
  onSelect(plan: any): void;
  formatPrice(number: number): string;
  bonus: string;
  isModal?: boolean;
}

const CleanPlanBox: React.FC<IPlanBoxProp> = (props: IPlanBoxProp) => {
  const { t } = useTranslation();
  const { data, currencySimbol, onSelect, formatPrice, isModal } = props;
  const characteristicsList = data.characteristics;
  const [openModal, setOpenModal] = useState(false);
  const complexPlan: string = 'COV';

  const handleOpenModal = () => {
    setOpenModal(true);
    //loading time the last item in the DOM
    setTimeout(() => {
      const modalCardContent = document.getElementsByClassName('card-content').item(6);
      const modalCardHeight = characteristicsList.length / 0.363;
      modalCardContent!.setAttribute('style', `height: ${modalCardHeight}em`);
    }, 0);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const renderCharacteristics = (itemsToShow: number) => {
    if (characteristicsList.length <= itemsToShow || isModal) {
      return (
        <List dense>
          {characteristicsList.map((item: string, i: number) => (
            <ListItem key={i}>
              <ListItemIcon>
                <EllipseIcon fill='var(--color-main)' />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      );
    } else {
      return (
        <List dense>
          {characteristicsList.map((item: string, i: number) => {
            if (i <= itemsToShow - 1)
              return (
                <ListItem key={i}>
                  <ListItemIcon>
                    <EllipseIcon fill='var(--color-main)' />
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>
              );
          })}
          <a className='see-more' onClick={handleOpenModal}>
            <Typography variant='body2' align='right' style={{ paddingRight: '2em' }}>
              {t('QUOTATIONS.STEP_4.SEE_MORE')}
            </Typography>
          </a>
        </List>
      );
    }
  };

  return (
    <Grid className={props.className}>
      <Grid direction='column' container>
        <Grid item className='card-header'>
          <Typography style={{ fontWeight: 600 }}>{`${data.text}`}</Typography>
          {data.payments_amount > 1 && (
            <>
              {formatPrice(Number(data.first_payment)) !== formatPrice(Number(data.payments_price)) ? (
                <>
                  <Typography className='large'>
                    {t('QUOTATIONS.STEP_4.FIRST_PAYMENT')}{' '}
                    <strong>{`${currencySimbol} ${formatPrice(Number(data.first_payment))}*`}</strong>
                  </Typography>
                  <Typography className='large'>
                    {(data.payments_amount - 1).toString()} {t('QUOTATIONS.STEP_4.REMAINING_PAYMENTS')}{' '}
                    <strong>{`${currencySimbol} ${formatPrice(Number(data.payments_price))}*`}</strong>
                  </Typography>
                </>
              ) : (
                <Typography className='large'>
                  {data.payments_amount} {t('QUOTATIONS.STEP_4.REMAINING_PAYMENTS')}{' '}
                  <strong>{`${currencySimbol} ${formatPrice(Number(data.payments_price))}*`}</strong>
                </Typography>
              )}
            </>
          )}
          <Typography>
            {t('QUOTATIONS.STEP_4.TOTAL_PRICE')}
            <strong style={{ marginLeft: '0.3rem' }}>{`${currencySimbol} ${formatPrice(Number(data.price))}*`}</strong>
          </Typography>
          <Typography>{props.bonus}% Bonificado</Typography>
        </Grid>
        <Grid item className='card-content'>
          {data.plan_code === complexPlan && (
            <Typography className='green-background'>¡{t('QUOTATIONS.STEP_4.SUGGESTED')}!</Typography>
          )}
          {renderCharacteristics(7)}
          <Grid item container className='card-action' justify='center'>
            <StyledButton onClick={() => onSelect(data)}>{t('QUOTATIONS.STEP_4.SELECT')}</StyledButton>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        open={openModal}
        onClose={handleCloseModal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openModal}>
          <CleanPlanBox {...props} isModal />
        </Fade>
      </Modal>
    </Grid>
  );
};

export const PlanBox = styled(({ ...props }) => <CleanPlanBox {...props} />)`
  /* width: 320px; */
  .MuiTypography-body1 {
    margin-bottom: 0.1rem;
  }
  .card-header {
    padding: 0.5em 1em;
    color: var(--color-white);
    background-color: var(--color-main);
    border-radius: 24px 24px 0 0;
    .large {
      font-size: large;
    }
  }
  .card-content {
    min-height: 27em;
    background-color: var(--color-white);
    border: 2px solid var(--color-main);
    border-radius: 0 0 24px 24px;
    position: relative;
    .green-background {
      background-color: var(--color-green);
      height: 25px;
      margin-top: 1em;
      padding: 4px 18px;
      border-radius: 0px 5px 5px 0px;
      width: 40%;
      color: var(--color-white);
    }
    .MuiList-root {
      color: var(--color-black);
      .MuiListItem-dense {
        padding: 3px 16px;
        .MuiListItemIcon-root {
          min-width: 25px;
        }
      }
    }
    .see-more {
      color: var(--color-main);
      &:hover {
        cursor: pointer;
      }
    }
    .card-action {
      margin: 1em 0;
      position: absolute;
      bottom: 0;
      button {
        padding: 5px 50px;
        background-color: var(--color-main);
        color: var(--color-white);
      }
    }
  }
`;
