// eslint-disable-next-line no-unused-vars
import { SiteMap } from '@wdynamo/common';

import { uris } from './siteMap';
import { Home } from './views/Example';
import { Login } from './views/Auth';
import { UserInfo } from './views/Users/components/UserInfo';
import { RolesList } from './views/Users/components/Roles/RolesList';
import { Products } from './views/Products';
import { ServicesList } from './views/Services/ServicesList';
import { NewService } from './views/Services/NewService';
import { MySales } from './views/MySales';
import { Contacts } from './views/Contacts';
import { ContactInfo } from './views/Contacts/components';
import { Checkout } from './views/Checkout';
import { Users } from './views/Users';
//import { Reports } from './views/Reports';
import { Settings } from './views/Settings';
import { RoleInfo } from './views/Users/components/Roles/RoleInfo';
import { Groups } from './views/Users/components/Groups';
import { GroupInfo } from './views/Users/components/Groups/components/GroupInfo';
import { ServicesUpdate } from './views/Services/ServicesUpdate';
import { Quotations } from './views/Services/Quotations/components/AutoQuotation';
import { Emissions } from './views/Services/Emissions';
import { ContactDetails } from './views/Contacts/components/ContactDetails';
import { Inspection } from './views/Inspection';
import { QuoteManagement } from './views/QuoteManagement';
import { HomeQuotations } from './views/Services/Quotations/components/HomeQuotation';
import { ApQuotations } from './views/Services/Quotations/components/APQuotation/index';

type AppConfig = {
  apiUrl: string;
  loggedSitemap: SiteMap[];
  anonymousSiteMap: SiteMap[];
};

const loggedSitemap: SiteMap[] = [
  { ...uris.login, label: 'Logout', component: Login, exact: true },
  { ...uris.home, component: Home, exact: true },
  { ...uris.checkout, component: Checkout, exact: true },
  {
    ...uris.products,
    component: Products,
    exact: true
  },
  {
    ...uris.services,
    component: ServicesList,
    exact: true,
    displayAsFastLink: true
  },
  { ...uris.newService, component: NewService, exact: true },
  { ...uris.serviceUpdate, component: ServicesUpdate, exact: true },
  { ...uris.contactDetails, component: ContactDetails, exact: true },
  { ...uris.quotationsAuto, component: Quotations, exact: true },
  { ...uris.emissions, component: Emissions, exact: true },
  { ...uris.quotationsHome, component: HomeQuotations, exact: true },
  { ...uris.quotationsAp, component: ApQuotations, exact: true },
  {
    ...uris.mySales,
    component: MySales,
    exact: true
  },
  {
    ...uris.contacts,
    component: Contacts,
    exact: true,
    displayAsFastLink: true
  },
  { ...uris.quotes, component: QuoteManagement, exact: true, displayAsFastLink: true },
  { ...uris.sales, component: QuoteManagement, exact: true, displayAsFastLink: true },
  {
    ...uris.contactInfo,
    component: ContactInfo,
    exact: true
  },
  /*{
    ...uris.reports,
    component: Reports,
    exact: true,
    displayAsFastLink: true
  },*/
  {
    ...uris.users,
    component: Users,
    exact: true,
    displayAsFastLink: true
  },
  {
    ...uris.userInfo,
    component: UserInfo
  },
  {
    ...uris.groups,
    component: Groups
  },
  { ...uris.groupInfo, component: GroupInfo, exact: true },
  { ...uris.roles, component: RolesList, exact: true },
  {
    ...uris.roleInfo,
    component: RoleInfo
  },
  {
    ...uris.settings,
    component: Settings,
    exact: true,
    displayAsFastLink: true
  }
];

const anonymousSiteMap: SiteMap[] = [
  { ...uris.inspection, component: Inspection, exact: true },
  { ...uris.home, component: Login }
];

const Config: AppConfig = {
  apiUrl: process.env.REACT_APP_PUBLIC_URL as string,
  loggedSitemap,
  anonymousSiteMap
};

export default Config;
