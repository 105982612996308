import React from 'react';
import { Grid } from '@material-ui/core';
import { StyledButton, Input } from '@wdynamo/common';
import { LogoComponent } from './LogoComponent';
import { ColorComponent } from './ColorComponent';

import { useTranslation } from 'react-i18next';
import { History } from 'history'; // eslint-disable-line
import { uris } from '../../../siteMap';

interface SettingsFormComponentProps {
  settings: any;
  uploadImageFetch(imageData: any, partnerId: string): void;
  updatePartnerFetch(partnerData: any, partnerId: string): void;
  history: History;
}

export const SettingsFormComponent = (props: SettingsFormComponentProps) => {
  const { t } = useTranslation();

  const [settings, setSettings] = React.useState(props.settings);

  React.useEffect(() => {
    setSettings(props.settings);
  }, [props.settings]);

  const handleOnChange = (fieldToUpdate: string, value: string) => {
    setSettings({ ...settings, [fieldToUpdate]: value });
  };

  const updatePartner = () => {
    props.updatePartnerFetch(settings, settings.partner_id);
    props.history.push(uris.home.uri);
  };

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Input
          name='partner_id'
          label={'ID'}
          defaultValue={settings.partner_id}
          onChange={(value) => handleOnChange('partner_id', value)}
          testId='partner_id'
          required={true}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name='name'
          label={'Denominacion de la empresa'}
          defaultValue={settings.name}
          onChange={(value) => handleOnChange('name', value)}
          testId='name'
          required={true}
        />
      </Grid>
      <Grid item xs={12}>
        <LogoComponent
          partnerId={settings.partner_id}
          logoFilename={settings.logo_filename}
          uploadImageFetch={props.uploadImageFetch}
          onImageUploaded={(location) => handleOnChange('logo_filename', location)}
        />
      </Grid>
      <Grid item xs={12}>
        <ColorComponent color={settings.color} onColorSelected={(color) => handleOnChange('color', color)} />
      </Grid>
      <Grid item direction='row' justify={'center'} container spacing={2}>
        <Grid item>
          <StyledButton
            className='secondary'
            onClick={() => {
              props.history.push(uris.home.uri);
            }}
          >
            {t('COMMONS.BUTTONS.CANCEL')}
          </StyledButton>
        </Grid>
        <Grid item>
          <StyledButton className='default' onClick={updatePartner}>
            {t('COMMONS.BUTTONS.UPDATE')}
          </StyledButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SettingsFormComponent;
