import { call, put, takeLatest, all } from 'redux-saga/effects';

import Api from './Api';
import {
  getInspectionFormSuccess,
  getInspectionFormFail,
  sendInspectionFormSuccess,
  sendInspectionFormFail
} from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';

const api = new Api();

function* sagaGetInspectionFormFetch(action: actionType) {
  try {
    const data: any = yield call(api.getInspectionFormFetch, action.payload.params);
    yield put(getInspectionFormSuccess(data));
  } catch (e) {
    yield put(getInspectionFormFail(e.response.data));
  }
}

function* sagaSendInspectionFormFetch(action: actionType) {
  try {
    const data: any = yield call(api.sendInspectionFormFetch, action.payload.params);
    yield put(sendInspectionFormSuccess(data));
  } catch (e) {
    yield put(sendInspectionFormFail(e.response.data));
  }
}

// Listen for redux actions
function* inspectionSaga() {
  yield all([takeLatest(ACTIONS.INSPECTION.GET_FORM.FETCH, sagaGetInspectionFormFetch)]);
  yield all([takeLatest(ACTIONS.INSPECTION.SEND_FORM.FETCH, sagaSendInspectionFormFetch)]);
}

export { inspectionSaga };
