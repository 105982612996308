import { Grid, MenuItem } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Input } from '@wdynamo/common';
// eslint-disable-next-line no-unused-vars
import { IGroupItem } from '../models';

const CleanFormStep1 = (props: any) => {
  const { t } = useTranslation();
  const { groups, handleOnChange, groupInfoForm, isUpdateGroupView } = props;
  const groupList = groups ? (groups as IGroupItem[]) : [];

  const isRootGroup = () => {
    return groupInfoForm.id_parent_group.value === undefined;
  };

  return (
    <div className='group-form'>
      <Grid direction='row' container spacing={2}>
        <Grid item xs={12} sm={6} className='form-input'>
          <Input
            label={t('GROUPS.PARENT_GROUP')}
            error={groupInfoForm.id_parent_group?.error}
            defaultValue={groupInfoForm.id_parent_group?.value as number}
            onChange={(value: string): void => handleOnChange('id_parent_group', value)}
            testId='parent'
            select={true}
            disabled={isUpdateGroupView && isRootGroup()}
            required={true}
          >
            {groupList.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Input>
        </Grid>
        <Grid item xs={12} sm={6} className='form-input'>
          <Input
            label={t('GROUPS.GROUP_NAME')}
            autoComplete={true}
            defaultValue={groupInfoForm.name.value as string}
            error={groupInfoForm.name.error}
            onChange={(value: string): void => handleOnChange('name', value)}
            disabled={isUpdateGroupView}
            testId='groupName'
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} className='form-input'>
          <Input
            label={t('GROUPS.DESCRIPTION')}
            autoComplete={true}
            defaultValue={groupInfoForm.description.value as string}
            error={groupInfoForm.description.error}
            onChange={(value: string): void => handleOnChange('description', value)}
            testId='description'
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const FormStep1 = styled(({ ...props }) => <CleanFormStep1 {...props} />)``;
