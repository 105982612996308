import { connect } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { RootState } from '../../../../../../store';
import { GeneralDataComponent } from './view';
import { getContactsFetch } from '../actions';

const mapStateToProps = (state: RootState) => ({
  stepsApStore: state.quotationApState
});

const mapDispatchToProps = {
  getContactsFetch
};

export const GeneralData = connect(mapStateToProps, mapDispatchToProps)(GeneralDataComponent);
