import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { Analytics } from '@wdynamo/common/lib/services';
// eslint-disable-next-line no-unused-vars
import { ICustomDataForm, IContact, IContactsByPartial } from '../models';
import { Grid, Typography, TextField } from '@material-ui/core';
import { NavButtons } from '../../common/NavButtons';
import AlphaMask from '../../../../../Contacts/components/common/AlphaMask';
import emailValidator from '../../common/emailValidator';
import phoneValidator from '../../../../../Contacts/components/common/phoneValidator';
import { InputPhone } from '../GeneralInfo/components/InputPhone';

interface IGeneralInfoProp {
  className?: string;
  dataForm: ICustomDataForm;
  productInfo: any;
  stepsStore: any;
  onChange(data: ICustomDataForm): void;
  nextStep(): void;
  prevStep(): void;
  getContactsByPartial(params: IContactsByPartial): void;
  getContactsClear(): void;
  dataContacts: any;
  currentContact: any;
  setCurrentContact(contact: IContact | undefined): void;
}

const CleanGeneralInfoComponent: React.FC<IGeneralInfoProp> = (props: IGeneralInfoProp) => {
  const {
    className,
    dataForm,
    stepsStore,
    onChange,
    getContactsByPartial,
    dataContacts,
    getContactsClear,
    currentContact,
    setCurrentContact
  } = props;
  const { t } = useTranslation();
  const [listContacts, setListContacts] = React.useState<any[]>([]);
  const [contactPrefill, setContactPrefill] = React.useState<boolean>(false);
  const [phoneContact, setPhoneContact] = React.useState<any>('');
  const [bttnClicked, setBttnClicked] = React.useState<boolean>(false);
  const [showPopup, setShowPopup] = React.useState<boolean>(false);
  //const previousStep = usePrevious(currentStep);

  useEffect(() => {
    Analytics().pageview('car-badget | general-info');
    if (currentContact?.id) {
      handleOnChange(currentContact?.name, 'name');
      handleOnChange(currentContact?.last_name, 'last_name');
      handleOnChange(currentContact?.phone, 'phone_number');
      handleOnChange(currentContact?.mail, 'email');
      setContactPrefill(true);
      // setContactFoundByPhone(true);
    } else if (
      stepsStore?.newContact?.data?.data?.clientRepresentation[0].name ||
      stepsStore?.newContact?.data?.data?.clientRepresentation[0].last_name ||
      stepsStore?.newContact?.data?.data?.clientRepresentation[0].phone ||
      stepsStore?.newContact?.data?.data?.clientRepresentation[0].mail
    ) {
      setContactPrefill(true);
      handleOnChange(stepsStore?.newContact?.data?.data?.clientRepresentation[0].name, 'name');
      handleOnChange(stepsStore?.newContact?.data?.data?.clientRepresentation[0].last_name, 'last_name');
      handleOnChange(stepsStore?.newContact?.data?.data?.clientRepresentation[0].phone, 'phone_number');
      handleOnChange(stepsStore?.newContact?.data?.data?.clientRepresentation[0].mail, 'email');
    } else {
      onChange((prevState: any) => ({
        ...prevState,
        general_info: {
          ...prevState.general_info,
          name: { ...prevState.general_info.name, value: '' },
          last_name: { ...prevState.general_info.last_name, value: '' },
          phone_number: { ...prevState.general_info.phone_number, value: '' },
          email: { ...prevState.general_info.email, value: '' }
        }
      }));
    }
  }, []);

  useEffect(() => {
    setListContacts(Array.isArray(dataContacts.data) ? dataContacts.data : dataContacts.data.items.items);
  }, [dataContacts.data]);

  useEffect(() => {
    if (currentContact?.id) {
      setContactPrefill(true);
      setShowPopup(false);
      setCurrentContact(currentContact);
      setPhoneContact(currentContact?.phone);
      getContactsClear();
    }
  }, [currentContact]);

  const handleOnChange = (value: any, field: string) => {
    const prevState = { ...dataForm };
    const currentField = prevState.general_info[field];
    currentField.value = value;
    if (field === 'phone_number') currentField.error = !phoneValidator(value);
    if (field === 'email') currentField.error = !emailValidator(value);
    onChange(prevState);
  };

  const dataIsInList = (key: string, value: string): any[] => {
    return listContacts.filter((contact) => contact[key] === value);
  };

  const handleOnInputChangePhone = (value: string) => {
    handleOnChange(value, 'phone_number');
    if (value.length === 4) {
      setShowPopup(true);
      getContactsByPartial({ search: value });
    } else if (dataIsInList('phone', value).length) {
      setShowPopup(true);
      setPhoneContact(value);
      setCurrentContact(dataIsInList('phone', value)[0]);
      handleOnChange(dataIsInList('phone', value)[0].mail, 'email');
      handleOnChange(dataIsInList('phone', value)[0].name, 'name');
      handleOnChange(dataIsInList('phone', value)[0].last_name, 'last_name');
    } else {
      if (value !== '') {
        setPhoneContact(value);
        setCurrentContact(undefined);
      }
      if (value.length === 8) {
        getContactsClear();
        setShowPopup(false);
      }
    }
  };

  const handleOnChangePhone = (value: any) => {
    setPhoneContact(value);
    setCurrentContact(value);
    if (value === null) {
      handleOnChange('', 'email');
      getContactsClear();
    }
  };

  const handleOnClickPhone = (e?: any, contact?: any) => {
    const numberPhone = e.currentTarget.innerText;
    setPhoneContact(contact.phone);
    setCurrentContact(contact.phone);
    handleOnInputChangePhone(numberPhone);
  };

  const handleClickBttn = () => {
    if (showPopup) {
      setBttnClicked(false);
      setShowPopup(false);
    } else {
      setBttnClicked(true);
      setShowPopup(true);
    }
  };

  const handleOnInputChangeEmail = (value: string) => {
    const prevState = { ...dataForm };
    const currentField = prevState.general_info['email'];
    currentField.value = value;
    currentField.error = !emailValidator(value);
    onChange(prevState);
    if (dataForm?.general_info?.phone_number?.value === '') {
      if (value.length === 4) getContactsByPartial({ search: value });
      else if (dataIsInList('mail', value).length) {
        setPhoneContact(dataIsInList('mail', value)[0].phone);
        setCurrentContact(dataIsInList('mail', value)[0]);
        handleOnChange(dataIsInList('mail', value)[0].phone, 'phone_number');
        handleOnChange(dataIsInList('mail', value)[0].name, 'name');
        handleOnChange(dataIsInList('mail', value)[0].last_name, 'last_name');
        // setContactFoundByEmail(true);
      } else {
        if (value !== '') {
          setCurrentContact(undefined);
          handleOnChange('', 'phone_number');
          //  setContactFoundByEmail(false);
        }
      }
    }
  };

  const handleOnChangeEmail = (value: IContact) => {
    setCurrentContact(value);
  };

  return (
    <>
      <Grid container spacing={4} className={className} justifyContent='center'>
        <Grid item xs={12} sm={6} className='py'>
          <Typography>{t('QUOTATIONS.STEP_2.NAME')}</Typography>
          <TextField
            onChange={(event) => handleOnChange(event.target.value, 'name')}
            value={dataForm?.general_info?.name?.value! ?? ''}
            disabled={contactPrefill}
            inputProps={{ maxLength: 30 }}
            InputProps={{
              inputComponent: AlphaMask
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} className='py'>
          <Typography>{t('QUOTATIONS.STEP_2.LAST_NAME')}</Typography>
          <TextField
            onChange={(event) => handleOnChange(event.target.value, 'last_name')}
            value={dataForm?.general_info?.last_name?.value! ?? ''}
            disabled={contactPrefill}
            inputProps={{ maxLength: 30 }}
            InputProps={{
              inputComponent: AlphaMask
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} className='py'>
          <Typography>
            {t('QUOTATIONS.STEP_2.EMAIL')}
            <span className='colored-mark'>*</span>
          </Typography>
          <Autocomplete
            inputValue={
              (dataForm?.general_info?.email?.value as string) ||
              (stepsStore?.newContact?.data?.data?.clientRepresentation[0]?.mail as string) ||
              ''
            }
            clearOnBlur={false}
            options={listContacts}
            blurOnSelect={false}
            getOptionLabel={(option: any) => `${option.mail}`}
            noOptionsText={'Sin coincidencias'}
            onChange={(e, value) => handleOnChangeEmail(value)}
            onInputChange={(e, value) => handleOnInputChangeEmail(value)}
            disabled={contactPrefill}
            renderInput={(params) => (
              <TextField {...params} variant='standard' inputProps={{ ...params.inputProps, maxLength: 30 }} />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item xs={12} sm={6} className='py'>
          <Typography>
            {t('QUOTATIONS.STEP_2.PHONE.TITLE')}
            <span className='colored-mark'>*</span>
          </Typography>
          <InputPhone
            handleOnChangePhone={handleOnChangePhone}
            handleOnInputChangePhone={handleOnInputChangePhone}
            handleOnClickPhone={handleOnClickPhone}
            handleClickBttn={handleClickBttn}
            phoneContact={phoneContact}
            dataForm={dataForm}
            contactPrefill={contactPrefill}
            showPopup={showPopup}
            listContacts={listContacts}
            bttnClicked={bttnClicked}
          />
        </Grid>
      </Grid>
      <NavButtons {...props} currentContact={currentContact} />
    </>
  );
};
export const GeneralInfoComponent = styled(({ ...props }) => <CleanGeneralInfoComponent {...props} />)`
  .py {
    padding-top: 2em;
  }
`;
