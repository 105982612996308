import { SummaryComponent } from './Summary';
import { connect } from 'react-redux';
import { getBillingModeFetch, getPaymentMethodFetch, getPaymentConditionFetch } from '../actions';
import { getQuotationByIdFetch } from '../../../../Emissions/actions';

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state: any) => ({
  stepsStore: { ...state.quotationState, quotationById: state.emissionState.quotationById }
});
const mapDispatchToProps = {
  getBillingModeFetch,
  getPaymentMethodFetch,
  getPaymentConditionFetch,
  getQuotationByIdFetch
};

export const Summary = connect(mapStateToProps, mapDispatchToProps)(SummaryComponent);
