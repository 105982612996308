// eslint-disable-next-line no-unused-vars
import { PAYMENT_METHOD, SALE_CHANNELS, actionType, IBaseStateProps } from './types';

const initialState: IBaseStateProps = {
  loading: false,
  error: false,
  data: {}
};

export const paymentsFormReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case PAYMENT_METHOD.FETCH:
      return {
        ...state,
        loading: true,
        error: false
      };
    case PAYMENT_METHOD.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: { ...state.data, paymentMethodsList: action.payload.data }
      };
    case PAYMENT_METHOD.FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    case SALE_CHANNELS.FETCH:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SALE_CHANNELS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: { ...state.data, salesChannelsList: action.payload.data }
      };
    case SALE_CHANNELS.FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
};
